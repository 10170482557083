/* eslint-disable import/prefer-default-export */
import { createTheme } from '@material-ui/core/styles';
// import colors from './colors'
// import units from './units'

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#2C3E66'
    },
    secondary: {
      main: '#A6CBDF'
    },
    tertiary: {
      main: '#00C0FF'
    },
    blue: {
      main: '#BCDEF0'
    },
    paleBlue: {
      main: '#EAF7FD'
    },
    gray: {
      main: '#F2FAFF'
    },
    white: {
      main: '#FFFFFF'
    },
    error: {
      main: '#FF2800'
    },
    hoverButton: {
      main: '#8DC8E8'
    },
    focusButtonBorder: {
      main: '#64AAD0'
    },
    focusSecondaryButtonBorder: {
      main: '#8DC8E8'
    },
    disabledButton: {
      main: '#DDEFF7'
    },
    disabledButtonText: {
      main: '#8597AF'
    },
    disabledSecondaryButton: {
      main: '#FEFFFF'
    },
    disabledSecondaryButtonText: {
      main: '#959FB3'
    },
    blocked: {
      main: '#e7aaaa'
    },
    unblocked: {
      main: '#a2dfb0'
    },
    fontFamily: "'Montserrat', sans-serif",
    inputTypes: {
      1: '100%',
      2: '50%',
      3: '70%',
      4: '25%',
      5: '35%',
      6: '60%',
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(44, 62, 102, 0.5)'
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});

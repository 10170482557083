"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FigureMap = exports.Figure = exports.Latches = exports.Handles = exports.Connections = exports.Trapezes = exports.Triangles = exports.Rectangles = void 0;
var rectangle_1 = require("./rectangle/rectangle");
var concaveRectangle_1 = require("./rectangle/concaveRectangle");
var rectangleWithHole_1 = require("./rectangle/rectangleWithHole");
var trapeze_1 = require("./trapeze/trapeze");
var concaveTrapeze_1 = require("./trapeze/concaveTrapeze");
var triangle_1 = require("./triangle/triangle");
var ark_1 = require("./handle/ark");
var cuttedBottomTrapeze_1 = require("./trapeze/cuttedBottomTrapeze");
var cuttedEdgesRectangle_1 = require("./rectangle/cuttedEdgesRectangle");
var cuttedTowerTopConnection_1 = require("./connection/cuttedTowerTopConnection");
var handleBox_1 = require("./handle/handleBox");
var roundedTrapeze_1 = require("./trapeze/roundedTrapeze");
var roundedCuttedBottomTrapeze_1 = require("./trapeze/roundedCuttedBottomTrapeze");
var RoundedCuttedBottomTrapezeWithHole_1 = require("./handle/RoundedCuttedBottomTrapezeWithHole");
var petal_1 = require("./latch/petal");
var roundedRectangle_1 = require("./rectangle/roundedRectangle");
var trapezeWithHole_1 = require("./trapeze/trapezeWithHole");
var triangleWithHalfHole_1 = require("./triangle/triangleWithHalfHole");
var RoundedCuttedBottomTrapezeWithHalfHole_1 = require("./handle/RoundedCuttedBottomTrapezeWithHalfHole");
var roundedTriangle_1 = require("./triangle/roundedTriangle");
var ufoRectangle_1 = require("./handle/ufoRectangle");
var ufoRoundedRectangle_1 = require("./handle/ufoRoundedRectangle");
var boot_1 = require("./latch/boot");
var kShape_1 = require("./latch/kShape");
var roundedHandleBox_1 = require("./handle/roundedHandleBox");
var rounded_1 = require("./latch/rounded");
var hat_1 = require("./latch/hat");
var roundedTriangleWithGap_1 = require("./triangle/roundedTriangleWithGap");
var hookEar_1 = require("./triangle/hookEar");
var trapezoid_1 = require("./trapeze/trapezoid");
var uncenteredTrapeze_1 = require("./trapeze/uncenteredTrapeze");
var shotgunBottom_1 = require("./connection/shotgunBottom");
var towerTopConnection_1 = require("./connection/towerTopConnection");
var tower2TopConnection_1 = require("./connection/tower2TopConnection");
var sharkFin_1 = require("./latch/sharkFin");
var roundedPetal_1 = require("./latch/roundedPetal");
var towerTopConnectionRounded_1 = require("./connection/towerTopConnectionRounded");
exports.Rectangles = [
    "Rectangle",
    "Rounded Rectangle",
    "Concave Rectangle",
    "Rectangle with Hole",
    "Cutted Edges Rectangle"
];
exports.Triangles = [
    "Triangle",
    "Hook Ear",
    "Triangle With Half Hole",
    "Rounded Triangle",
    "Rounded Triangle With Gap"
];
exports.Trapezes = [
    "Trapeze",
    "Concave Trapeze",
    "Cutted Bottom Trapeze",
    "Trapeze With Hole",
    "Rounded Trapeze",
    "Rounded Cutted Bottom Trapeze",
    "Trapezoid",
    "Uncentered Trapeze"
];
exports.Connections = [
    "Cutted Tower Top Connection",
    "Shotgun Bottom",
    "Tower Top Connection",
    "Tower 2 Top Connection",
    "Tower Top Connection Rounded"
];
exports.Handles = [
    "Ark",
    "Handle Box",
    "Ufo Rectangle",
    "Ufo Rounded Rectangle",
    "Rounded Cutted Bottom Trapeze With Hole",
    "Rounded Cutted Bottom Trapeze With Half Hole",
    "Rounded Handle Box"
];
exports.Latches = [
    "Petal",
    "Boot",
    "K Shape",
    "Rounded",
    "Hat",
    "Shark Fin",
    "Rounded Petal"
];
var Figure;
(function (Figure) {
    Figure["Rectangle"] = "Rectangle";
    Figure["ConcaveRectangle"] = "Concave Rectangle";
    Figure["RectangleWithHole"] = "Rectangle with Hole";
    Figure["Trapeze"] = "Trapeze";
    Figure["ConcaveTrapeze"] = "Concave Trapeze";
    Figure["Triangle"] = "Triangle";
    Figure["Ark"] = "Ark";
    Figure["CuttedBottomTrapeze"] = "Cutted Bottom Trapeze";
    Figure["CuttedEdgesRectangle"] = "Cutted Edges Rectangle";
    Figure["CuttedTowerTopConnection"] = "Cutted Tower Top Connection";
    Figure["HandleBox"] = "Handle Box";
    Figure["RoundedTrapeze"] = "Rounded Trapeze";
    Figure["RoundedCuttedBottomTrapeze"] = "Rounded Cutted Bottom  Trapeze";
    Figure["RoundedCuttedBottomTrapezeWithHole"] = "Rounded Cutted Bottom Trapeze With Hole";
    Figure["Petal"] = "Petal";
    Figure["RoundedRectangle"] = "Rounded Rectangle";
    Figure["TrapezeWithHole"] = "Trapeze with Hole";
    Figure["TriangleWithHalfHole"] = "Triangle With Half Hole";
    Figure["RoundedCuttedBottomTrapezeWithHalfHole"] = "Rounded Cutted Bottom Trapeze With Half Hole";
    Figure["RoundedTriangle"] = "Rounded Triangle";
    Figure["UfoRectangle"] = "Ufo Rectangle";
    Figure["UfoRoundedRectangle"] = "Ufo Rounded Rectangle";
    Figure["Boot"] = "Boot";
    Figure["KShape"] = "K Shape";
    Figure["RoundedHandleBox"] = "Rounded Handle Box";
    Figure["Rounded"] = "Rounded";
    Figure["Hat"] = "Hat";
    Figure["HookEar"] = "Hook Ear";
    Figure["RoundedTriangleWithGap"] = "Rounded Triangle With Gap";
    Figure["Trapezoid"] = "Trapezoid";
    Figure["UncenteredTrapeze"] = "Uncentered Trapeze";
    Figure["ShotgunBottom"] = "Shotgun Bottom";
    Figure["TowerTopConnection"] = "Tower Top Connection";
    Figure["Tower2TopConnection"] = "Tower 2 Top Connection";
    Figure["SharkFin"] = "Shark Fin";
    Figure["RoundedPetal"] = "Rounded Petal";
    Figure["TowerTopConnectionRounded"] = "Tower Top Connection Rounded";
})(Figure = exports.Figure || (exports.Figure = {}));
exports.FigureMap = new Map([
    [Figure.Rectangle, rectangle_1.Rectangle],
    [Figure.ConcaveRectangle, concaveRectangle_1.ConcaveRectangle],
    [Figure.RectangleWithHole, rectangleWithHole_1.RectangleWithHole],
    [Figure.Trapeze, trapeze_1.Trapeze],
    [Figure.ConcaveTrapeze, concaveTrapeze_1.ConcaveTrapeze],
    [Figure.Triangle, triangle_1.Triangle],
    [Figure.Ark, ark_1.Ark],
    [Figure.CuttedBottomTrapeze, cuttedBottomTrapeze_1.CuttedBottomTrapeze],
    [Figure.CuttedEdgesRectangle, cuttedEdgesRectangle_1.CuttedEdgesRectangle],
    [Figure.CuttedTowerTopConnection, cuttedTowerTopConnection_1.CuttedTowerTopConnection],
    [Figure.HandleBox, handleBox_1.HandleBox],
    [Figure.RoundedTrapeze, roundedTrapeze_1.RoundedTrapeze],
    [Figure.RoundedCuttedBottomTrapeze, roundedCuttedBottomTrapeze_1.RoundedCuttedBottomTrapeze],
    [Figure.RoundedCuttedBottomTrapezeWithHole, RoundedCuttedBottomTrapezeWithHole_1.RoundedCuttedBottomTrapezeWithHole],
    [Figure.Petal, petal_1.Petal],
    [Figure.RoundedRectangle, roundedRectangle_1.RoundedRectangle],
    [Figure.TrapezeWithHole, trapezeWithHole_1.TrapezeWithHole],
    [Figure.TriangleWithHalfHole, triangleWithHalfHole_1.TriangleWithHalfHole],
    [Figure.RoundedCuttedBottomTrapezeWithHalfHole, RoundedCuttedBottomTrapezeWithHalfHole_1.RoundedCuttedBottomTrapezeWithHalfHole],
    [Figure.RoundedTriangle, roundedTriangle_1.RoundedTriangle],
    [Figure.UfoRectangle, ufoRectangle_1.UfoRectangle],
    [Figure.UfoRoundedRectangle, ufoRoundedRectangle_1.UfoRoundedRectangle],
    [Figure.Boot, boot_1.Boot],
    [Figure.KShape, kShape_1.KShape],
    [Figure.RoundedHandleBox, roundedHandleBox_1.RoundedHandleBox],
    [Figure.Rounded, rounded_1.Rounded],
    [Figure.Hat, hat_1.Hat],
    [Figure.HookEar, hookEar_1.HookEar],
    [Figure.RoundedTriangleWithGap, roundedTriangleWithGap_1.RoundedTriangleWithGap],
    [Figure.Trapezoid, trapezoid_1.Trapezoid],
    [Figure.UncenteredTrapeze, uncenteredTrapeze_1.UncenteredTrapeze],
    [Figure.ShotgunBottom, shotgunBottom_1.ShotgunBottom],
    [Figure.TowerTopConnection, towerTopConnection_1.TowerTopConnection],
    [Figure.Tower2TopConnection, tower2TopConnection_1.Tower2TopConnection],
    [Figure.SharkFin, sharkFin_1.SharkFin],
    [Figure.RoundedPetal, roundedPetal_1.RoundedPetal],
    [Figure.TowerTopConnectionRounded, towerTopConnectionRounded_1.TowerTopConnectionRounded]
]);
Object.freeze(exports.FigureMap);

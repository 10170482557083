/* eslint-disable react/prop-types */
import React from 'react';

import {
  Button, withStyles,
} from '@material-ui/core';


import {materialTheme} from '../../../materialTheme'

const { palette } = materialTheme


const styles = () => ({
  layerAlign: {
    display: 'flex',
    gap: '0 9.5px',
    background: '#F7FCFE',
    borderRadius: 10,
    marginBottom: 24,
    width: 107
  },
  alignButton: {
    borderRadius: 0,
    '&:first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '&:last-child': {
      borderRadius: '0 10px 10px 0',
    },
    padding: 0,
    minWidth: 48,
    height: 48,
    '&:hover': {
      background: 'transparent',
      boxShadow: `0 0 2px 0 ${palette.blue.main}`,
    },
  },
  alignButtonChecked: {
    boxShadow: `0 0 2px 0 ${palette.blue.main}`,
  },
});

const aligns = [
  {
    title: 'left',
    checked: false,
    icon: (
      <path d="M3 22C2.45 22 2 21.55 2 21V3C2 2.45 2.45 2 3 2C3.55 2 4 2.45 4 3V21C4 21.55 3.55 22 3 22ZM20.5 7H7.5C6.67 7 6 7.67 6 8.5C6 9.33 6.67 10 7.5 10H20.5C21.33 10 22 9.33 22 8.5C22 7.67 21.33 7 20.5 7ZM14.5 14H7.5C6.67 14 6 14.67 6 15.5C6 16.33 6.67 17 7.5 17H14.5C15.33 17 16 16.33 16 15.5C16 14.67 15.33 14 14.5 14Z" fill="#2C3E66" />
    )
  },
  // {
  //   title: 'horizontal',
  //   checked: false,
  //   icon: (
  //     <path d="M12 2C12.55 2 13 2.45 13 3V7H19.5C20.33 7 21 7.67 21 8.5C21 9.33 20.33 10 19.5 10H13V14H16.5C17.33 14 18 14.67 18 15.5C18 16.33 17.33 17 16.5 17H13V21C13 21.55 12.55 22 12 22C11.45 22 11 21.55 11 21V17H7.5C6.67 17 6 16.33 6 15.5C6 14.67 6.67 14 7.5 14H11V10H4.5C3.67 10 3 9.33 3 8.5C3 7.67 3.67 7 4.5 7H11V3C11 2.45 11.45 2 12 2Z" fill="#2C3E66" />
  //   )
  // },
  {
    title: 'right',
    checked: false,
    icon: (
      <path d="M21 2C21.55 2 22 2.45 22 3V21C22 21.55 21.55 22 21 22C20.45 22 20 21.55 20 21V3C20 2.45 20.45 2 21 2ZM3.5 10H16.5C17.33 10 18 9.33 18 8.5C18 7.67 17.33 7 16.5 7H3.5C2.67 7 2 7.67 2 8.5C2 9.33 2.67 10 3.5 10ZM9.5 17H16.5C17.33 17 18 16.33 18 15.5C18 14.67 17.33 14 16.5 14H9.5C8.67 14 8 14.67 8 15.5C8 16.33 8.67 17 9.5 17Z" fill="#2C3E66" />
    )
  },
  // {
  //   title: 'top',
  //   checked: false,
  //   icon: (
  //     <path d="M22 3C22 3.55 21.55 4 21 4H3C2.45 4 2 3.55 2 3C2 2.45 2.45 2 3 2H21C21.55 2 22 2.45 22 3ZM8.5 22C9.33 22 10 21.33 10 20.5V7.5C10 6.67 9.33 6 8.5 6C7.67 6 7 6.67 7 7.5V20.5C7 21.33 7.67 22 8.5 22ZM15.5 16C16.33 16 17 15.33 17 14.5V7.5C17 6.67 16.33 6 15.5 6C14.67 6 14 6.67 14 7.5V14.5C14 15.33 14.67 16 15.5 16Z" fill="#2C3E66" />
  //   )
  // },
  // {
  //   title: 'vertical',
  //   checked: false,
  //   icon: (
  //     <path d="M20.9998 11H16.9998V7.5C16.9998 6.67 16.3298 6 15.4998 6C14.6698 6 13.9998 6.67 13.9998 7.5V11H9.99984V4.5C9.99984 3.67 9.32984 3 8.49984 3C7.66984 3 6.99984 3.67 6.99984 4.5V11H2.83984C2.28984 11 1.83984 11.45 1.83984 12C1.83984 12.55 2.28984 13 2.83984 13H6.99984V19.5C6.99984 20.33 7.66984 21 8.49984 21C9.32984 21 9.99984 20.33 9.99984 19.5V13H13.9998V16.5C13.9998 17.33 14.6698 18 15.4998 18C16.3298 18 16.9998 17.33 16.9998 16.5V13H20.9998C21.5498 13 21.9998 12.55 21.9998 12C21.9998 11.45 21.5498 11 20.9998 11Z" fill="#2C3E66" />
  //   )
  // },
  // {
  //   title: 'bottom',
  //   checked: false,
  //   icon: (
  //     <path d="M21 22H3C2.45 22 2 21.55 2 21C2 20.45 2.45 20 3 20H21C21.55 20 22 20.45 22 21C22 21.55 21.55 22 21 22ZM8.5 2C7.67 2 7 2.67 7 3.5V16.5C7 17.33 7.67 18 8.5 18C9.33 18 10 17.33 10 16.5V3.5C10 2.67 9.33 2 8.5 2ZM15.5 8C14.67 8 14 8.67 14 9.5V16.5C14 17.33 14.67 18 15.5 18C16.33 18 17 17.33 17 16.5V9.5C17 8.67 16.33 8 15.5 8Z" fill="#2C3E66" />
  //   )
  // },
];

class LayerAlign extends React.Component {
  state = {
    layerAligns: aligns
  }

  componentDidMount() {
    const { layerAlign } = this.props;
    this.updateLayerAligns(layerAlign);
  }

  updateLayerAligns = alignTitle => {
    const { layerAligns } = this.state;
    const alignSettings = layerAligns.map(alignSetting => {
      if (alignSetting.title === alignTitle) {
        alignSetting.checked = true;
      } else {
        alignSetting.checked = false;
      }

      return alignSetting;
    });
    this.setState({ layerAligns: alignSettings });
  };

  handleChange = alignTitle => {
    const { changelayerAlign } = this.props;
    this.updateLayerAligns(alignTitle);
    changelayerAlign(alignTitle);
  };

  render() {
    const { layerAligns } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.layerAlign}>
        {layerAligns.map(align => (
          <Button
            key={align.title}
            className={
              `${classes.alignButton} ${align.checked && classes.alignButtonChecked}`
            }
            onClick={() => this.handleChange(align.title)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              {align.icon}
            </svg>
          </Button>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(LayerAlign);

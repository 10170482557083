import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuex from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEye,
  faEyeSlash,
  faTimes,
  faSearch,
  faPlus,
  faSave,
  faArrowRight,
  faDownload,
  faFolder,
  faUndo,
  faRedo,
  faShapes,
  faComment,
  faPaperPlane,
  faVideo,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue } from "bootstrap-vue";
const { VuePlugin } = require('vuera');
import ProgressBar from 'vuejs-progress-bar';
import VueGtag from 'vue-gtag';
import i18n from './i18n'

Vue.use(VueGtag, {
  config: { id: "UA-195034409-1" }
});

Vue.use(ProgressBar)
Vue.use(VuePlugin)
Vue.use(Vuex);
Vue.use(BootstrapVue);
library.add(
  faEye,
  faEyeSlash,
  faTimes,
  faSearch,
  faPlus,
  faSave,
  faArrowRight,
  faDownload,
  faFolder,
  faUndo,
  faRedo,
  faShapes,
  faComment,
  faPaperPlane,
  faVideo,
  faEdit
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");

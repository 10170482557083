import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Ruler from '../components/react-ruler/ruler';
import { roundUnit } from '../helpers/pixelConverter';

class CustomizedRuler extends Component {

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      setRef,
      type,
      units = 38,
      unitType,
      activeSelection,
      size,
      zoom,
    } = this.props;

    return(
      <Ruler
        type={type}
        ref={setRef}
        backgroundColor="#EAF7FD"
        textColor="#A6CBDF"
        lineColor="#A6CBDF"
        zoom={zoom}
        unit={units}
        size={size}
        textFormat={n => roundUnit(n, unitType)}
        activeSelection={activeSelection}
      />
    )
  }
};

CustomizedRuler.propTypes = {
  type: PropTypes.string.isRequired,
  units: PropTypes.number,
  zoom: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  unitType: PropTypes.string.isRequired,
  activeSelection: PropTypes.instanceOf(Object),
};

CustomizedRuler.defaultProps = {
  activeSelection: null,
  units: 38
};

export default CustomizedRuler;

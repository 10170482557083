



























import Vue from "vue";
import SignIn from "./SignIn.vue";
import SignUp from "./SignUp.vue";

const vm = Vue.component("DownloadButton", {
  data: function() {
    return {
      signUp: false,
      signIn: true,
      shop: "",
    };
  },
  methods: {
    selectSignIn: function() {
      this.signIn = true;
      this.signUp = false;
    },
    selectSignUp: function() {
      this.signUp = true;
      this.signIn = false;
    },
  },
  mounted() {
    this.shop = this.$route.params.shop;
    if (this.shop) {
      this.selectSignUp();
      if (this.$store.state?.user?.shopifyStoreId) {
        this.$router.push({
          path: `/shopify`,
        });
      }
    }
  },
  components: {
    SignIn,
    SignUp,
  },
});

export default vm;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreePack = void 0;
var figureBase_1 = require("../base/figureBase");
var figure_1 = require("../../figures/new/figure");
var three_1 = require("three");
var line3_1 = require("../base/line3");
var lodash_1 = require("lodash");
var TreePack = /** @class */ (function () {
    function TreePack() {
    }
    TreePack.initialize = function (source) {
        var type = figure_1.FigureMap.get(source.type);
        return new type(source === null || source === void 0 ? void 0 : source.config, source.id, source.childs, source.parentSize);
    };
    TreePack.unpack = function (source, scale) {
        var _this = this;
        var mappedObjects = source.map(function (node) { return _this.initialize(node); });
        var representations3d = this.unpackNode(mappedObjects, mappedObjects[0], this.BASE_PARENT_LINE, this.BASE_NORMAL);
        this.scaleRepresentations(representations3d, scale);
        var representations2d = lodash_1.cloneDeep(representations3d);
        this.resolveCollisions(representations3d);
        this.calculate2dRepresentations(representations2d);
        return { representations3d: representations3d, representations2d: representations2d };
    };
    TreePack.resolveCollisions = function (representations) {
        var _a;
        var shifts = [];
        shifts.length = representations.length;
        var applyingTime = 0;
        var shiftMultiplier = 0.02;
        var shift = new three_1.Vector3();
        for (var i = 0; i < representations.length; i++) {
            for (var j = i + 1; j < representations.length; j++) {
                var crossProduct = representations[i].normal.clone().cross(representations[j].normal);
                if (crossProduct.length() < 0.001) {
                    var applyTime = Date.now();
                    shift.x = representations[j].normal.x * shiftMultiplier;
                    shift.y = representations[j].normal.y * shiftMultiplier;
                    shift.z = representations[j].normal.z * shiftMultiplier;
                    if (!shifts[j]) {
                        shifts[j] = shift.clone();
                    }
                    else {
                        (_a = shifts[j]) === null || _a === void 0 ? void 0 : _a.add(shift);
                    }
                    applyingTime += Date.now() - applyTime;
                }
            }
        }
        shifts.forEach(function (shift, index) {
            representations[index].applyShift(shift);
        });
    };
    TreePack.unpackNode = function (allFigures, source, parentLine, baseNormal, lineIndex) {
        var _this = this;
        var representation = source.calculateAbsoluteRepresentation(parentLine, baseNormal, lineIndex);
        if (representation.childs.length == 0)
            return [representation];
        var target = representation.childs.reduce(function (acc, childInfo) {
            var childFigure = allFigures.find(function (figure) { return figure.id === childInfo.childId; });
            if (childFigure === undefined)
                throw new Error("wrong child id:" + childInfo.childId);
            var childRepresentation = _this.unpackNode(allFigures, childFigure, representation.shape[childInfo.parentEdgeIndex], representation.normal, childInfo.childEdgeIndex);
            return acc.concat(childRepresentation);
        }, []);
        target.unshift(representation);
        return target;
    };
    TreePack.scaleRepresentations = function (representations, scale) {
        representations.forEach(function (representation) { return representation.applyScale(scale); });
    };
    TreePack.calculate2dRepresentations = function (representations) {
        representations.forEach(function (representation) {
            representation.applyMatrix4(representation.rotationToOxy);
            var shift = new three_1.Vector3(0, 0, -representation.shape[0].startPoint.z);
            representation.applyShift(shift);
        });
        this.fixFiguresPosition(representations, representations[0], TreePack.BASE_PARENT_LINE.clone(), 0);
    };
    TreePack.fixFiguresPosition = function (representations, currentElemet, parentLine, connectedLineIndex) {
        var _this = this;
        var connectedLine = currentElemet.shape[connectedLineIndex];
        var parentDirection = parentLine.calculateDirectionVector();
        var connectedLineDirection = connectedLine.calculateDirectionVector();
        var parentDirection2D = new three_1.Vector2(parentDirection.x, parentDirection.y).normalize();
        var connectedLineDirection2D = new three_1.Vector2(connectedLineDirection.x, connectedLineDirection.y);
        var rotationToParentLine = figureBase_1.FigureBase.calculateRotationToParentLine(connectedLineDirection2D, parentDirection2D);
        currentElemet.applyMatrix4(rotationToParentLine);
        var shiftToParentLine = figureBase_1.FigureBase.calculateShiftToParentLine(parentLine, connectedLine);
        currentElemet.applyShift(shiftToParentLine);
        if (currentElemet.childs.length > 0) {
            currentElemet.childs.forEach(function (childNode) {
                var childElement = representations.find(function (rep) { return rep.id == childNode.childId; });
                if (!!childElement) {
                    _this.fixFiguresPosition(representations, childElement, currentElemet.shape[childNode.parentEdgeIndex], childNode.childEdgeIndex);
                }
            });
        }
    };
    TreePack.BASE_NORMAL = new three_1.Vector3(0, 0, 1);
    TreePack.BASE_PARENT_LINE = new line3_1.Line3(line3_1.LineType.Straight, [new three_1.Vector3(-1, 0, 0), new three_1.Vector3(1, 0, 0)]);
    return TreePack;
}());
exports.TreePack = TreePack;

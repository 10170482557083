"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrapezeWithHole = void 0;
var trapeze_1 = require("./trapeze");
var CutHoleHelper_1 = require("../../utils/CutHoleHelper");
var TrapezeWithHole = /** @class */ (function (_super) {
    __extends(TrapezeWithHole, _super);
    function TrapezeWithHole() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TrapezeWithHole.prototype.getConfigProperties = function () {
        return CutHoleHelper_1.CutHoleHelper.addConfigProperties(_super.prototype.getConfigProperties.call(this));
    };
    TrapezeWithHole.prototype.calculateHoleLines = function () {
        return [CutHoleHelper_1.CutHoleHelper.drawHolderHole(this.config.options)];
    };
    return TrapezeWithHole;
}(trapeze_1.Trapeze));
exports.TrapezeWithHole = TrapezeWithHole;

const defaultButtonStyle = {
  marginTop: 35,
  padding: '12px 24px',
  lineHeight: '20px',
  fontSize: 16,
  height: 48,
  textTransform: 'none',
  borderRadius: 10,
  fontWeight: 700,
};

import {materialTheme} from '../materialTheme'

const { palette } = materialTheme

export const buttonStyle = () => ({
  button: {
    ...defaultButtonStyle,
    background: palette.blue.main,
    color: palette.primary.main,
    fontFamily: palette.fontFamily,
    '&:hover': {
      background: palette.hoverButton.main
    },
    '&:focus': {
      boxShadow: `0 0 0 2px ${palette.focusButtonBorder.main}`
    }
  }
});

const createSimpleDisabledButton = (type) => {
  const isMain = type === 'main';
  const color = isMain
    ? palette.disabledButton.main : palette.disabledSecondaryButton.main;
  const textColor = isMain
    ? palette.disabledButtonText.main : palette.disabledSecondaryButtonText.main;

  return ({
    cursor: 'auto',
    color: `${textColor} !important`,
    background: `${color} !important`,
    '&:hover': {
      background: `${color} !important`
    },
    '&:focus': {
      boxShadow: `none !important`
    }
  });
};

export const disabledButtonStyle = () => ({
  disabledButton: createSimpleDisabledButton('main', palette),
  disabledSecondaryButton: createSimpleDisabledButton('secondary', palette),
});

export const secondaryButtonStyle = () => ({
  secondaryButton: {
    ...defaultButtonStyle,
    background: palette.paleBlue.main,
    color: palette.primary.main,
    fontFamily: palette.fontFamily,
    '&:hover': {
      background: palette.blue.main
    },
    '&:focus': {
      boxShadow: `0 0 0 2px ${palette.focusSecondaryButtonBorder.main}`
    }
  },
  focusedButton: {
    boxShadow: `0 0 0 2px ${palette.focusSecondaryButtonBorder.main}`
  }
});

export const transparentButtonStyle = () => ({
  transparentButton: {
    ...defaultButtonStyle,
    marginTop: 0,
    marginRight: 25,
    color: palette.primary.main,
    fontFamily: palette.fontFamily,
    '&:hover': {
      background: 'transparent'
    },
  }
});

/* eslint-disable import/prefer-default-export */
import {materialTheme} from '../materialTheme'

const { palette } = materialTheme


export const selectStyle = () => ({
  selectBorder: {
    borderColor: props => props.error ? palette.error.main : palette.blue.main
  },
  selectFocus: {
    borderColor: `${palette.tertiary.main} !important`,
  },
  selectedDropdown: {
    background: 'transparent !important',
    '& p': {
      fontWeight: 'bold',
    }
  },
  selectDropDown: {
    borderRadius: 10,
    padding: '10px 12px!important',
    marginBottom: 8,
    '& p': {
      fontSize: 14,
      lineHeight: '20px',
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&:hover': {
      background: `${palette.paleBlue.main}!important`,
    },
  },
  listItemText: {
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: palette.fontFamily,
    color: palette.primary.main,
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  popover: {
    borderRadius: 10,
    boxShadow: '0px 50px 80px rgba(44, 62, 102, 0.2)',
    '&::after': {
      content: '\' \'',
      position: 'absolute',
      top: 0,
      left: 0,
      height: 14,
      width: '100%',
      background: 'white',
    },
  },
  selectMenuWrapper: {
    padding: '12px 0 0 0',
    paddingInline: 12,
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: 6
    },
    '&::-webkit-scrollbar-track': {
      background: 'white'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      border: '2px solid white',
      backgroundColor: '#BCDEF0',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#A6CBDF'
    },
  },
});

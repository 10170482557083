"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailableHoleOptions = void 0;
var uuid_1 = require("uuid");
var lodash_1 = require("lodash");
var three_1 = require("three");
var line3_1 = require("../../core/base/line3");
var utilsGeneral_1 = require("./utilsGeneral");
var arcFitter_1 = require("./arcFitter");
var RoundLinesHelper_1 = require("./RoundLinesHelper");
var ShiftType;
(function (ShiftType) {
    ShiftType[ShiftType["INSIDE"] = 0] = "INSIDE";
    ShiftType[ShiftType["OUTSIDE"] = 1] = "OUTSIDE";
})(ShiftType || (ShiftType = {}));
;
var AvailableHoleOptions = /** @class */ (function () {
    function AvailableHoleOptions() {
        this._holeOptions = [
            {
                name: 'rectangle',
                id: 2
            }
        ];
    }
    Object.defineProperty(AvailableHoleOptions.prototype, "list", {
        get: function () {
            return this._holeOptions;
        },
        enumerable: false,
        configurable: true
    });
    AvailableHoleOptions.prototype.addHole = function (node, selectedObject, holeType) {
        var _a;
        var hole = {
            id: uuid_1.v1(),
            name: holeType
        };
        node.config = node.config !== null ? node.config : lodash_1.cloneDeep(selectedObject.config);
        if ((_a = node.config.holes) === null || _a === void 0 ? void 0 : _a.length) {
            node.config.holes.push(hole);
        }
        else {
            node.config.holes = [hole];
        }
        switch (hole.name) {
            case 'rectangle':
                this.addConfigProperties(node.config, hole.id);
                break;
            default:
                break;
        }
    };
    AvailableHoleOptions.prototype.deleteHole = function (config, id) {
        var _a, _b, _c, _d;
        var selectedIndexHoleInConfig = (_a = config.holes) === null || _a === void 0 ? void 0 : _a.findIndex(function (hole) { return hole.id === id; });
        var selectedIndexHoleInOptions = (_b = config.holesOptions) === null || _b === void 0 ? void 0 : _b.findIndex(function (hole) { return hole.id === id; });
        if (selectedIndexHoleInConfig !== undefined && selectedIndexHoleInOptions !== undefined) {
            (_c = config.holes) === null || _c === void 0 ? void 0 : _c.splice(selectedIndexHoleInConfig, 1);
            (_d = config.holesOptions) === null || _d === void 0 ? void 0 : _d.splice(selectedIndexHoleInOptions, 1);
        }
    };
    AvailableHoleOptions.prototype.addConfigProperties = function (config, id) {
        var holeConfig = {
            id: id,
            holeRectangleWidth: 10,
            holeRectangleheight: 10,
            rectanglePositionX: 0,
            rectanglePositionY: 0,
            rectangleRotate: 0,
            roundOffTop: false,
            roundOffBottom: false
        };
        if (Array.isArray(config.holesOptions)) {
            config.holesOptions.push(holeConfig);
        }
        else {
            config.holesOptions = [holeConfig];
        }
        return config;
    };
    AvailableHoleOptions.calculateCenterRectangle = function (hole, options) {
        if (options.roundOffTop && options.roundOffBottom) {
            return new three_1.Vector3((hole[1].startPoint.x + hole[5].startPoint.x) / 2, (hole[1].startPoint.y + hole[5].startPoint.y) / 2, 0);
        }
        else if (options.roundOffTop) {
            return new three_1.Vector3((hole[0].startPoint.x + hole[4].startPoint.x) / 2, (hole[0].startPoint.y + hole[4].startPoint.y) / 2, 0);
        }
        else if (options.roundOffBottom) {
            return new three_1.Vector3((hole[1].startPoint.x + hole[5].startPoint.x) / 2, (hole[1].startPoint.y + hole[5].startPoint.y) / 2, 0);
        }
        return new three_1.Vector3((hole[0].startPoint.x + hole[1].endPoint.x) / 2, (hole[0].startPoint.y + hole[1].endPoint.y) / 2, 0);
    };
    AvailableHoleOptions.calculateRectangle = function (options, figureHeight) {
        var width = options.holeRectangleWidth > 0 ? options.holeRectangleWidth : 1;
        var height = options.holeRectangleheight > 0 ? options.holeRectangleheight : 1;
        var aPoint = new three_1.Vector3(width / 2, (figureHeight - height) / 2, 0);
        var bPoint = new three_1.Vector3(-width / 2, (figureHeight - height) / 2, 0);
        var cPoint = new three_1.Vector3(-width / 2, (figureHeight + height) / 2, 0);
        var dPoint = new three_1.Vector3(width / 2, (figureHeight + height) / 2, 0);
        var additionalPointsTop = options.roundOffTop ? arcFitter_1.ArcFitter.fitArcCircle(bPoint, dPoint, height) : [];
        var additionalPointsBottom = options.roundOffBottom ? arcFitter_1.ArcFitter.fitArcCircle(bPoint, dPoint, height) : [];
        var result = [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, aPoint]),
        ];
        var radius = 3;
        if (additionalPointsTop.length > 0 && additionalPointsBottom.length > 0) {
            var roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[0], result[1], radius);
            result.splice.apply(result, __spread([0, 2], roundedLines));
            roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[2], result[3], radius);
            result.splice.apply(result, __spread([2, 2], roundedLines));
            roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[4], result[5], radius);
            result.splice.apply(result, __spread([4, 2], roundedLines));
            roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[6], result[0], radius);
            result.splice.apply(result, __spread([6, 2], roundedLines));
            result.splice(result.length - 1, 1);
            result.splice(0, 1);
        }
        else if (additionalPointsTop.length > 0) {
            var roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[0], result[1], radius);
            result.splice.apply(result, __spread([0, 2], roundedLines));
            roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[2], result[3], radius);
            result.splice.apply(result, __spread([3, 2], roundedLines));
            result.splice(2, 1);
        }
        else if (additionalPointsBottom.length > 0) {
            var roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[2], result[3], radius);
            result.splice.apply(result, __spread([2, 3], roundedLines));
            roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[4], result[0], radius);
            result.splice.apply(result, __spread([4, 3], roundedLines));
            result.splice(0, 1);
        }
        return result;
    };
    // check if Shape includes point ( based on Cauchy-Euler method )
    AvailableHoleOptions.checkPointInPolygon = function (test, polygon) {
        var q_patt = [[0, 1], [3, 2]];
        if (polygon.length < 3) {
            return false;
        }
        var pred_pt = { 'x': 0, 'y': 0 };
        pred_pt.x = polygon[polygon.length - 1].x;
        pred_pt.y = polygon[polygon.length - 1].y;
        pred_pt.x -= test.x;
        pred_pt.y -= test.y;
        var pred_q = q_patt[pred_pt.y < 0 ? 1 : 0][pred_pt.x < 0 ? 1 : 0];
        var w = 0;
        for (var i = 0; i < polygon.length; i++) {
            var cur_pt = Object.assign({}, polygon[i]);
            cur_pt.x -= test.x;
            cur_pt.y -= test.y;
            var q = q_patt[cur_pt.y < 0 ? 1 : 0][cur_pt.x < 0 ? 1 : 0];
            switch (q - pred_q) {
                case -3:
                    ++w;
                    break;
                case 3:
                    --w;
                    break;
                case -2:
                    if (pred_pt.x * cur_pt.y >= pred_pt.y * cur_pt.x)
                        ++w;
                    break;
                case 2:
                    if (!(pred_pt.x * cur_pt.y >= pred_pt.y * cur_pt.x))
                        --w;
                    break;
            }
            pred_pt = cur_pt;
            pred_q = q;
        }
        return w != 0;
    };
    AvailableHoleOptions.shiftHole = function (figure, otherHoles, shape) {
        var _this = this;
        var allHoles = otherHoles.reduce(function (acc, hole) {
            acc.push(hole);
            return acc;
        }, [figure]);
        var wasShifting = false;
        allHoles.forEach(function (hole) {
            var holePolygonPoints = [];
            var figurePolygonPoints = [];
            hole.forEach(function (lineHole) {
                holePolygonPoints.push({ 'x': lineHole.startPoint.x, 'y': lineHole.startPoint.y });
            });
            shape.forEach(function (lineShape) {
                figurePolygonPoints.push({ 'x': lineShape.startPoint.x, 'y': lineShape.startPoint.y });
            });
            wasShifting = holePolygonPoints.some(function (holePoint) {
                var isPointInsideShape = _this.checkPointInPolygon(holePoint, figurePolygonPoints);
                if (!isPointInsideShape) {
                    var temprVectorSum_1 = 0;
                    var nearestVector_1;
                    // calculate nearest vector by summing range between holePoint and startPoint / endPoint
                    shape.forEach(function (lineShape, index) {
                        var firstApe = Math.abs(holePoint.x - lineShape.startPoint.x) + Math.abs(holePoint.y - lineShape.startPoint.y);
                        var secondApe = Math.abs(holePoint.x - lineShape.endPoint.x) + Math.abs(holePoint.y - lineShape.endPoint.y);
                        if (index === 0) {
                            temprVectorSum_1 = firstApe + secondApe;
                            nearestVector_1 = lineShape;
                        }
                        if (temprVectorSum_1 > (firstApe + secondApe)) {
                            temprVectorSum_1 = firstApe + secondApe;
                            nearestVector_1 = lineShape;
                        }
                    });
                    // calculate normal from point to nearest vector and find (x,y) of crossDot
                    var k = ((nearestVector_1.endPoint.y - nearestVector_1.startPoint.y) * (holePoint.x - nearestVector_1.startPoint.x) - (nearestVector_1.endPoint.x - nearestVector_1.startPoint.x) * (holePoint.y - nearestVector_1.startPoint.y)) / (((nearestVector_1.endPoint.y - nearestVector_1.startPoint.y) * (nearestVector_1.endPoint.y - nearestVector_1.startPoint.y)) + ((nearestVector_1.endPoint.x - nearestVector_1.startPoint.x) * (nearestVector_1.endPoint.x - nearestVector_1.startPoint.x)));
                    var crossXDot = holePoint.x - k * (nearestVector_1.endPoint.y - nearestVector_1.startPoint.y);
                    var crossYDot = holePoint.y + k * (nearestVector_1.endPoint.x - nearestVector_1.startPoint.x);
                    // calculate distance between point and crossDot
                    var distanceCrossX_1 = (holePoint.x - crossXDot) > 0 ? (holePoint.x - crossXDot) + 0.1 : (holePoint.x - crossXDot) - 0.1;
                    var distanceCrossY_1 = (holePoint.y - crossYDot) > 0 ? (holePoint.y - crossYDot) + 0.1 : (holePoint.y - crossYDot) - 0.1;
                    hole.forEach(function (lineHole) {
                        // Ceil number, becouse wrong float js logic
                        lineHole.startPoint.x = Math.round((lineHole.startPoint.x - distanceCrossX_1) * 100) / 100;
                        lineHole.startPoint.y = Math.round((lineHole.startPoint.y - distanceCrossY_1) * 100) / 100;
                        lineHole.endPoint.x = Math.round((lineHole.endPoint.x - distanceCrossX_1) * 100) / 100;
                        lineHole.endPoint.y = Math.round((lineHole.endPoint.y - distanceCrossY_1) * 100) / 100;
                    });
                    return true; // return true for some, because we dont need in extra calculations
                }
            });
        });
        return wasShifting ? true : false;
    };
    AvailableHoleOptions.drawRectangleHole = function (options, figureHeight, otherHoles, shape) {
        var convertAngleToRadian = (options.rectangleRotate * Math.PI) / 180;
        var figure = this.calculateRectangle(options, figureHeight);
        var center = this.calculateCenterRectangle(figure, options);
        if (options.rectanglePositionX == 0 && options.rectanglePositionY == 0) {
            options.rectanglePositionX = center.x;
            options.rectanglePositionY = center.y;
        }
        if (options.rectanglePositionX !== center.x || options.rectanglePositionY !== center.y) {
            var shift_1 = new three_1.Vector3(options.rectanglePositionX - center.x, options.rectanglePositionY - center.y);
            figure.forEach(function (line) { return line.applyShift(shift_1); });
        }
        this.rotateHole(figure, convertAngleToRadian, options);
        var allHoles = otherHoles.reduce(function (acc, hole) {
            acc.push(hole);
            return acc;
        }, [figure]);
        this.isIintersectionHoleWithBorder(allHoles, shape, options);
        var resultofShift = false;
        var iteration = 0;
        do {
            resultofShift = this.shiftHole(figure, otherHoles, shape);
            iteration += 1;
        } while (resultofShift && iteration < 11); // this is balance cycle for sharp corners 
        if (iteration >= 11)
            throw new Error("hole outside the shape"); // if balacing iterations more then 10 throw err
        return figure;
    };
    AvailableHoleOptions.rotateHole = function (hole, angle, options) {
        var holeCenter = this.calculateCenterRectangle(hole, options);
        hole.forEach(function (line, index) {
            var startingValues = {
                lineStartX: line.startPoint.x,
                lineStartY: line.startPoint.y,
                lineMiddleX: line.middlePoint.x,
                lineMiddleY: line.middlePoint.y,
                lineEndX: line.endPoint.x,
                lineEndY: line.endPoint.y
            };
            line.startPoint.x = holeCenter.x + (startingValues.lineStartX - holeCenter.x) * Math.cos(angle) - (startingValues.lineStartY - holeCenter.y) * Math.sin(angle);
            line.startPoint.y = holeCenter.y + (startingValues.lineStartY - holeCenter.y) * Math.cos(angle) + (startingValues.lineStartX - holeCenter.x) * Math.sin(angle);
            line.middlePoint.x = holeCenter.x + (startingValues.lineMiddleX - holeCenter.x) * Math.cos(angle) - (startingValues.lineMiddleY - holeCenter.y) * Math.sin(angle);
            line.middlePoint.y = holeCenter.y + (startingValues.lineMiddleY - holeCenter.y) * Math.cos(angle) + (startingValues.lineMiddleX - holeCenter.x) * Math.sin(angle);
            line.endPoint.x = holeCenter.x + (startingValues.lineEndX - holeCenter.x) * Math.cos(angle) - (startingValues.lineEndY - holeCenter.y) * Math.sin(angle);
            line.endPoint.x = Math.round(line.endPoint.x * (Math.pow(10, 15))) / (Math.pow(10, 15));
            line.endPoint.y = holeCenter.y + (startingValues.lineEndY - holeCenter.y) * Math.cos(angle) + (startingValues.lineEndX - holeCenter.x) * Math.sin(angle);
            line.endPoint.y = Math.round(line.endPoint.y * (Math.pow(10, 15))) / (Math.pow(10, 15));
        });
    };
    AvailableHoleOptions.isIintersectionHoleWithBorder = function (holes, shape, options) {
        var _this = this;
        holes.forEach(function (hole, index) {
            var holeWasRotate = false;
            var intersetingShapeLine = null;
            var intersetingHoleLine = null;
            var pointIntersection = null;
            hole.forEach(function (holeLine, index) {
                shape.forEach(function (shapeLine) {
                    if (utilsGeneral_1.getIntersectionOnAPoint(holeLine, shapeLine) && !holeWasRotate) {
                        intersetingHoleLine = holeLine;
                        intersetingShapeLine = shapeLine;
                        holeWasRotate = true;
                        pointIntersection = utilsGeneral_1.getIntersectionOnAPoint(holeLine, shapeLine);
                    }
                });
            });
            if (holeWasRotate) {
                var directionShapeLine = intersetingShapeLine === null || intersetingShapeLine === void 0 ? void 0 : intersetingShapeLine.calculateDirectionVector().normalize();
                var directionHoleLine = intersetingHoleLine === null || intersetingHoleLine === void 0 ? void 0 : intersetingHoleLine.calculateDirectionVector().normalize();
                var dot = directionShapeLine.x * directionHoleLine.x + directionShapeLine.y * directionHoleLine.y;
                var cross = directionShapeLine.x * directionHoleLine.y - directionShapeLine.y * directionHoleLine.x;
                ;
                var angle = utilsGeneral_1.pseudoscalarProduct(dot, cross);
                if (dot !== 0 && cross !== 1) {
                    _this.rotateHole(hole, angle, options);
                    _this.nearestLine(hole, intersetingShapeLine, options);
                }
            }
        });
    };
    AvailableHoleOptions.nearestLine = function (hole, shapeLine, options) {
        var shifts = [];
        hole.forEach(function (line) {
            var directionVector = line.calculateDirectionVector().normalize();
            var normalInside = new three_1.Vector3(directionVector.y, -directionVector.x, 0);
            var normalOutside = new three_1.Vector3(directionVector.y, -directionVector.x, 0);
            normalInside.multiplyScalar(1000);
            normalOutside.multiplyScalar(-1000);
            var startPerpendicular = new three_1.Vector3((line.startPoint.x + line.endPoint.x) / 2, (line.startPoint.y + line.endPoint.y) / 2, 0);
            var perpendicularInside = new line3_1.Line3(line3_1.LineType.Straight, [startPerpendicular, normalInside]);
            var perpendicularOutside = new line3_1.Line3(line3_1.LineType.Straight, [startPerpendicular, normalOutside]);
            var intersectingPointInside = utilsGeneral_1.getIntersectionOnAPoint(perpendicularInside, shapeLine);
            var intersectingPointOutside = utilsGeneral_1.getIntersectionOnAPoint(perpendicularOutside, shapeLine);
            if (intersectingPointInside) {
                shifts.push(new line3_1.Line3(line3_1.LineType.Straight, [startPerpendicular, intersectingPointInside]));
            }
            else if (intersectingPointOutside) {
                shifts.push(new line3_1.Line3(line3_1.LineType.Straight, [startPerpendicular, intersectingPointOutside]));
            }
        });
        var nearest = this.calculateShift(shifts, shapeLine, hole);
        if (nearest && !this.isCenterHoleOutsideFigure(hole, shapeLine, options)) {
            var shift_2 = new three_1.Vector3(nearest.result.endPoint.x - nearest.result.startPoint.x, nearest.result.endPoint.y - nearest.result.startPoint.y, 0);
            nearest.type === ShiftType.INSIDE ? shift_2.multiplyScalar(0.9999999) : shift_2.multiplyScalar(1.01);
            hole.forEach(function (line) { return line.applyShift(shift_2); });
        }
        else {
            var shift_3 = new three_1.Vector3(100, 100, 0);
            shift_3.multiplyScalar(100);
            hole.forEach(function (line) { return line.applyShift(shift_3); });
        }
    };
    AvailableHoleOptions.calculateShift = function (shifts, edge, hole) {
        var insidePoint = new three_1.Vector3(0, 1, 0);
        var result = null;
        var type = ShiftType.OUTSIDE;
        shifts.forEach(function (line) {
            var checkLine = new line3_1.Line3(line3_1.LineType.Straight, [insidePoint, line.startPoint]);
            if (utilsGeneral_1.getIntersectionOnAPoint(edge, checkLine)) {
                result = line;
                type = ShiftType.OUTSIDE;
            }
        });
        if (result == null) {
            shifts.forEach(function (line) {
                if (result) {
                    result = result.length() > line.length() ? line : result;
                }
                else {
                    result = line;
                }
            });
            type = ShiftType.INSIDE;
        }
        if (result)
            return { result: result, type: type };
        return null;
    };
    AvailableHoleOptions.isRightAngle = function (line) {
        var oxStart = new three_1.Vector3(0, 0, 0);
        var oxEnd = new three_1.Vector3(1000, 0, 0);
        var oyStart = new three_1.Vector3(0, 0, 0);
        var oyEnd = new three_1.Vector3(0, 1000, 0);
        var ox = new line3_1.Line3(line3_1.LineType.Straight, [oxStart, oxEnd]);
        var oy = new line3_1.Line3(line3_1.LineType.Straight, [oyStart, oyEnd]);
        var isRightAngle = Boolean(utilsGeneral_1.isParallel(line, ox) || utilsGeneral_1.isParallel(line, oy));
        if (isRightAngle) {
            return true;
        }
        return false;
    };
    AvailableHoleOptions.isCenterHoleOutsideFigure = function (hole, figureEdge, options) {
        var center = this.calculateCenterRectangle(hole, options);
        var startPoint = new three_1.Vector3(5, 5, 0);
        var checkLine = new line3_1.Line3(line3_1.LineType.Straight, [startPoint, center]);
        var pointIntersection = utilsGeneral_1.getIntersectionOnAPoint(checkLine, figureEdge);
        return Boolean(pointIntersection);
    };
    return AvailableHoleOptions;
}());
exports.AvailableHoleOptions = AvailableHoleOptions;

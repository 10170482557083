"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.History = void 0;
var History = /** @class */ (function () {
    function History() {
        this.items = [];
        this.currentIndex = -1;
    }
    Object.defineProperty(History.prototype, "canUndo", {
        get: function () {
            return this.currentIndex >= 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(History.prototype, "canRedo", {
        get: function () {
            return this.items.length > 0 && this.currentIndex < this.items.length - 1;
        },
        enumerable: false,
        configurable: true
    });
    History.prototype.addHistoryItem = function (item, args) {
        this.removeHistory();
        this.items.push(item);
        this.currentIndex++;
    };
    History.prototype.removeHistory = function () {
        if (this.currentIndex + 1 < this.items.length) {
            var index = this.currentIndex + 1;
            this.items.splice(index, this.items.length - index);
        }
    };
    History.prototype.undo = function () {
        if (this.canUndo) {
            var index = this.currentIndex;
            this.currentIndex--;
            return this.items[index].undo;
        }
    };
    History.prototype.redo = function () {
        if (this.canRedo) {
            this.currentIndex++;
            return this.items[this.currentIndex].redo;
        }
    };
    return History;
}());
exports.History = History;










import { Vue } from "vue-property-decorator";
import {FigureBase, TreePack} from "packaging3d-engine/lib";
import axios from "axios";

import { Vector3 } from "three";
import { PropOptions } from "vue";
import {TreeNode} from "packaging3d-engine/lib/core/unpack/treePack";
import { BackgroundTexture, ColoringData, TextData } from "@/types";

let vm = Vue.component("DownloadButton", {
  props: {
    nodes: {
      type: Array
    } as PropOptions<TreeNode[]>,
    backgroundTexture: {
      type: Array
    } as PropOptions<BackgroundTexture[]>,
    pattern:{ type: Number},
    scale: {
      type: Vector3,
      default: () => new Vector3(1, 1, 1)
    },
    fabricTextObjects: {
      type: Array,
      default: () => [],
    } as PropOptions<Array<TextData>>,
    coloringData: {
      type: Map,
      default: new Map<number, ColoringData>(),
    } as PropOptions<Map<number, ColoringData>>,
    meterage: String,
  },
  methods: {
    download: function() {
      const packedNodes = JSON.stringify(this.nodes);
      const packedColoringData = JSON.stringify(Array.from( this.coloringData, ([key, value]) => ({ key, value })));
      const packgedTextObjects = JSON.stringify(this.fabricTextObjects);

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/download/pdf/${this.$route.params.templateId}`,
          {
            pattern: this.pattern,
            data: packedNodes,
            textObjects: packgedTextObjects,
            coloringData: packedColoringData,
            isBackground: true,
            scaling: this.scale,
            meterage: this.meterage,
            peforation: {
              space: 5,
              length: 5
            }
          },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          let fileLink = document.createElement("a");
          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute(
            "download",
            `${this.$route.params.templateName}(3dpackaging).pdf`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    }
  }
});

export default vm;

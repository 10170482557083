"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandleBox = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var RoundLinesHelper_1 = require("./../../utils/RoundLinesHelper");
var HandleBox = /** @class */ (function (_super) {
    __extends(HandleBox, _super);
    function HandleBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HandleBox.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 4];
    };
    HandleBox.prototype.calculateHoleLines = function () {
        var coords = this.config.options;
        var _a = __read([
            coords.holeWidth,
            coords.holeHeight,
            coords.holeStartY,
            coords.roundedRadius
        ], 4), width = _a[0], height = _a[1], holeStartY = _a[2], roundedRadius = _a[3];
        var aPoint = new three_1.Vector3(width / 2, holeStartY + 0.025 * height, 0);
        var bPoint = new three_1.Vector3(-width / 2, holeStartY + 0.025 * height, 0);
        var cPoint = new three_1.Vector3(-width / 2, holeStartY + 0.95 * height, 0);
        var dPoint = new three_1.Vector3(width / 2, holeStartY + 0.95 * height);
        var result = [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, aPoint])
        ];
        var roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[0], result[1], roundedRadius);
        result.splice.apply(result, __spread([0, 2], roundedLines));
        roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[2], result[3], roundedRadius);
        result.splice.apply(result, __spread([2, 2], roundedLines));
        roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[4], result[5], roundedRadius);
        result.splice.apply(result, __spread([4, 2], roundedLines));
        roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(result[6], result[0], roundedRadius);
        result.splice.apply(result, __spread([6, 2], roundedLines));
        result.splice(result.length - 1, 1);
        result.splice(0, 1);
        return [result];
    };
    HandleBox.prototype.calculateLines = function () {
        var coords = this.config.options;
        var _a = __read([
            coords.width,
            coords.heightBottom,
            coords.heightTop,
        ], 3), width = _a[0], heightBottom = _a[1], heightTop = _a[2];
        var aPoint = new three_1.Vector3(width / 2, 0, 0);
        var bPoint = new three_1.Vector3(-width / 2, 0, 0);
        var cPoint = new three_1.Vector3(-width / 2, heightBottom, 0);
        var dPoint = new three_1.Vector3(0, heightBottom + heightTop, 0);
        var ePoint = new three_1.Vector3(width / 2, heightBottom, 0);
        return [new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [cPoint, new three_1.Vector3(cPoint.x, dPoint.y, 0), dPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [dPoint, new three_1.Vector3(ePoint.x, dPoint.y, 0), ePoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [ePoint, aPoint])];
    };
    HandleBox.prototype.getConfigProperties = function () {
        return {
            width: 1,
            heightBottom: 0.8,
            heightTop: 0.2,
            holeWidth: 0.2,
            holeHeight: 0.7,
            holeStartY: 0.15,
            roundedRadius: 0.03
        };
    };
    return HandleBox;
}(figureBase_1.FigureBase));
exports.HandleBox = HandleBox;

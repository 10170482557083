"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CuttedEdgesRectangle = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var CuttedEdgesRectangle = /** @class */ (function (_super) {
    __extends(CuttedEdgesRectangle, _super);
    function CuttedEdgesRectangle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CuttedEdgesRectangle.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 2, 3, 4, 5];
    };
    CuttedEdgesRectangle.prototype.calculateLines = function () {
        var widthBottom = this.config.options.widthBottom;
        var heightBottom = this.config.options.heightBottom;
        var heightTop = this.config.options.heightTop;
        var widthTop = this.config.options.widthTop;
        var aPoint = new three_1.Vector3(widthBottom / 2, 0, 0);
        var bPoint = new three_1.Vector3(-widthBottom / 2, 0, 0);
        var cPoint = new three_1.Vector3(-widthBottom / 2, heightBottom, 0);
        var dPoint = new three_1.Vector3(-widthTop / 2, heightTop + heightBottom, 0);
        var ePoint = new three_1.Vector3(widthTop / 2, heightTop + heightBottom, 0);
        var fPoint = new three_1.Vector3(widthBottom / 2, heightBottom, 0);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, ePoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [ePoint, fPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [fPoint, aPoint])
        ];
    };
    CuttedEdgesRectangle.prototype.getConfigProperties = function () {
        return {
            widthBottom: 1,
            heightBottom: 0.8,
            heightTop: 0.2,
            widthTop: 0.8,
        };
    };
    return CuttedEdgesRectangle;
}(figureBase_1.FigureBase));
exports.CuttedEdgesRectangle = CuttedEdgesRectangle;

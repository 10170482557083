import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthorized: false,
    nameSelectedTemplate: null,
    user: {},
    language: 'en',
  },
  mutations: {
    setAuthorized(state) {
      state.isAuthorized = true;
    },
    setUnauthorized(state) {
      state.isAuthorized = false;
    },
    setNameTemplate(state, name) {
      state.nameSelectedTemplate = name;
    },
    setUser(state, user) {
      state.user = user;
    },
    changeLanguage(state) {
      state.language = state.language === 'en' ? 'de': 'en';
    },
  },

  actions: {},
  modules: {},
  getters: {
    getUser(state) {
      return state.user
    }
  }
});

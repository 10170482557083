"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigObject = void 0;
var command_1 = require("./command");
var __1 = require("..");
var lodash_1 = require("lodash");
var ConfigObject = /** @class */ (function (_super) {
    __extends(ConfigObject, _super);
    function ConfigObject(nodes, config, objectId) {
        var _this = _super.call(this, nodes) || this;
        _this._config = config;
        _this._objectId = objectId;
        return _this;
    }
    ConfigObject.prototype.action = function () {
        var _this = this;
        var node = this.nodes.find(function (node) { return node.id == _this._objectId; });
        var testNode = lodash_1.cloneDeep(node);
        if (testNode) {
            testNode.config = this._config;
            var treePack = __1.TreePack.initialize(testNode);
            if (!treePack.isntHoleOutsideFigure()) {
                throw new Error("hole outside the shape");
            }
            else if (!treePack.isValidHoles()) {
                throw new Error("intersecting holes");
            }
            else if (!treePack.isValid()) {
                throw new Error("wrong data.sorry guys");
            }
        }
        if (node) {
            node.config = this._config;
        }
    };
    return ConfigObject;
}(command_1.Command));
exports.ConfigObject = ConfigObject;

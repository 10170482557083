"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScaleTemplate = void 0;
var command_1 = require("./command");
var lodash_1 = require("lodash");
var ScaleTemplate = /** @class */ (function (_super) {
    __extends(ScaleTemplate, _super);
    function ScaleTemplate(nodes, currentScale, newScale) {
        var _this = _super.call(this, nodes) || this;
        _this._newScale = lodash_1.cloneDeep(newScale);
        _this._currentScale = currentScale;
        return _this;
    }
    ScaleTemplate.prototype.action = function () {
        this._currentScale.x = this._newScale.x;
        this._currentScale.y = this._newScale.y;
        this._currentScale.z = this._newScale.z;
        console.log("SCALE IS CHANGED!!!");
    };
    return ScaleTemplate;
}(command_1.Command));
exports.ScaleTemplate = ScaleTemplate;

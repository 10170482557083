"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TowerTopConnectionRounded = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var TowerTopConnectionRounded = /** @class */ (function (_super) {
    __extends(TowerTopConnectionRounded, _super);
    function TowerTopConnectionRounded() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TowerTopConnectionRounded.prototype.calculateConnectionIndexes = function () {
        return [0, 2, 4, 10, 12];
    };
    TowerTopConnectionRounded.prototype.calculateLines = function () {
        var coords = this.config.options;
        var heightTowers = coords.heightTowers;
        var widthTriangles = coords.widthTriangles;
        var widthLastTowers = coords.widthLastTowers;
        var widthMiddleTower = coords.widthMiddleTower;
        var heightMiddle = coords.heightMiddle;
        var roundedHeight = coords.roundedHeight;
        var widthTopRectRounded = coords.widthTopRectRounded;
        var radius = coords.roundedRadius;
        var aPoint = new three_1.Vector3(widthMiddleTower / 2 + widthLastTowers + widthTriangles, 0);
        var bPoint = new three_1.Vector3(-widthMiddleTower / 2 - widthLastTowers - widthTriangles, 0);
        var cPoint = new three_1.Vector3(-widthMiddleTower / 2 - widthLastTowers, heightMiddle);
        var dPoint = new three_1.Vector3(-widthMiddleTower / 2 - widthLastTowers, heightTowers);
        var ePoint = new three_1.Vector3(-(widthMiddleTower + widthLastTowers + widthTopRectRounded) / 2, heightTowers + roundedHeight);
        var fPoint = new three_1.Vector3(-(widthMiddleTower + widthLastTowers + widthTopRectRounded) / 2 + widthTopRectRounded, heightTowers + roundedHeight);
        var gPoint = new three_1.Vector3(-widthMiddleTower / 2, heightTowers);
        var hPoint = new three_1.Vector3(-widthMiddleTower / 2, heightMiddle);
        var iPoint = new three_1.Vector3(widthMiddleTower / 2, heightMiddle);
        var jPoint = new three_1.Vector3(widthMiddleTower / 2, heightTowers);
        var kPoint = new three_1.Vector3((widthMiddleTower - widthTopRectRounded + widthLastTowers) / 2, heightTowers + roundedHeight);
        var lPoint = new three_1.Vector3((widthMiddleTower - widthTopRectRounded + widthLastTowers) / 2 + widthTopRectRounded, heightTowers + roundedHeight);
        var mPoint = new three_1.Vector3(widthMiddleTower / 2 + widthLastTowers, heightTowers);
        var nPoint = new three_1.Vector3(widthMiddleTower / 2 + widthLastTowers, heightMiddle);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [dPoint, new three_1.Vector3(dPoint.x, ePoint.y), ePoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [ePoint, fPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [fPoint, new three_1.Vector3(gPoint.x, fPoint.y), gPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [gPoint, hPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [hPoint, iPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [iPoint, jPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [jPoint, new three_1.Vector3(jPoint.x, kPoint.y), kPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [kPoint, lPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [lPoint, new three_1.Vector3(mPoint.x, lPoint.y), mPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [mPoint, nPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [nPoint, aPoint]),
        ];
    };
    TowerTopConnectionRounded.prototype.getConfigProperties = function () {
        return {
            heightTowers: 0.8,
            widthTriangles: 0.05,
            widthLastTowers: 0.3,
            widthMiddleTower: 0.3,
            heightMiddle: 0.5,
            roundedHeight: 0.2,
            widthTopRectRounded: 0.21,
        };
    };
    return TowerTopConnectionRounded;
}(figureBase_1.FigureBase));
exports.TowerTopConnectionRounded = TowerTopConnectionRounded;

/* eslint-disable react/prop-types */
import React from 'react';
import 'fabric-webpack';
import DesignCanvas from './DesignCanvas';
import './canvas.scss';

export default class CanvasDemo extends React.Component {
  constructor(props) {
    super(props);

    this.el = React.createRef();
  }

  state = {
    canvasWidth: 0,
    canvasHeight: 0
  };

  componentDidMount() {
    const { offsetWidth, offsetHeight } = this.el.current;
    this.setState({ canvasWidth: offsetWidth, canvasHeight: offsetHeight });
  }

  render() {
    const {
      rects, setGetObjects, style, setCanvas, handActive, lock
    } = this.props;
    const { canvasHeight, canvasWidth } = this.state;
    return (
      <div className="document-canvas" ref={this.el}>
        <DesignCanvas
          lock={lock}
          handActive={handActive}
          setCanvas={setCanvas}
          width={style.width || canvasWidth}
          height={style.height || canvasHeight}
          setGetObjects={setGetObjects}
        >
          {rects}
        </DesignCanvas>
      </div>
    );
  }
}










import { Vue } from "vue-property-decorator";

let vm = Vue.component("EditBtn", {
  data: function() {
    return {
    };
  },
  methods: {
  },
  components: {
  }
});

export default vm;

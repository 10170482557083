














import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { Vue } from "vue-property-decorator";
import _ from "lodash";
import Loader from "./Loader.vue";
import { Vector3, WebGLRenderer } from "three";
import { PropOptions } from "vue";
import { Message } from "@/types/message";

let vm = Vue.component("Comment", {
  props: {
    comment: {
      type: Object
    } as PropOptions<Message>
  },
  data: function() {
    return {};
  },
  updated: function() {
    this.autofit();
  },
  mounted: function() {
    this.autofit();
  },
  methods: {
    autofit: function() {
      const area = this.$el.querySelector(`#area-${this.comment.id}`);
      (area as any).style.height = area!.scrollHeight + "px";
    }
  },
  components: {}
});

export default vm;

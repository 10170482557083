















import { Vue } from "vue-property-decorator";
import Loader from "./Loader.vue";
import axios from "axios";
import { WebGLRenderer } from "three";
import store from "../store/index";

let vm = Vue.component("SaveButton", {
  data: function() {
    return {};
  },
  props: {
    conservation: Boolean,
  },
  mounted() {},
  methods: {
    sendData: function() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/shopify/product/template`,
          {
            templateId: this.$route.params.templateId
          },
          { withCredentials: true }
        )
        .then((res) =>
          res.status > 200 && res.status < 400
            ? alert("Product added")
            : alert("Error")
        );
    },
  },
  components: {
    Loader,
  },
});

export default vm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundedCuttedBottomTrapezeWithHalfHole = void 0;
var roundedCuttedBottomTrapeze_1 = require("../trapeze/roundedCuttedBottomTrapeze");
var CutHalfHoleHelper_1 = require("../../utils/CutHalfHoleHelper");
var three_1 = require("three");
var RoundedCuttedBottomTrapezeWithHalfHole = /** @class */ (function (_super) {
    __extends(RoundedCuttedBottomTrapezeWithHalfHole, _super);
    function RoundedCuttedBottomTrapezeWithHalfHole() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoundedCuttedBottomTrapezeWithHalfHole.prototype.calculateHoleLines = function () {
        var centerPoint = new three_1.Vector3(0, this.config.options.heightBottom);
        return [CutHalfHoleHelper_1.CutHalfHoleHelper.drawHalfHole(centerPoint, this.config.options)];
    };
    RoundedCuttedBottomTrapezeWithHalfHole.prototype.getConfigProperties = function () {
        return CutHalfHoleHelper_1.CutHalfHoleHelper.addProperties(_super.prototype.getConfigProperties.call(this));
    };
    return RoundedCuttedBottomTrapezeWithHalfHole;
}(roundedCuttedBottomTrapeze_1.RoundedCuttedBottomTrapeze));
exports.RoundedCuttedBottomTrapezeWithHalfHole = RoundedCuttedBottomTrapezeWithHalfHole;

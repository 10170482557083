import {materialTheme} from '../materialTheme'

const { palette } = materialTheme


const tooltipStyle = () => ({
  tooltip: {
    background: 'black',
    color: palette.white.main,
    minWidth: 60,
    minHeight: 30,
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0
  },
  tooltipArrow: {
    color: 'black',
  }
});

export default tooltipStyle;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShotgunBottom = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var ShotgunBottom = /** @class */ (function (_super) {
    __extends(ShotgunBottom, _super);
    function ShotgunBottom() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ShotgunBottom.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 2, 3, 4, 5, 6];
    };
    ShotgunBottom.prototype.calculateLines = function () {
        var coords = this.config.options;
        var aPoint = new three_1.Vector3(coords.wBottom / 2, 0);
        var bPoint = new three_1.Vector3(-coords.wBottom / 2, 0);
        var cPoint = new three_1.Vector3(-coords.wBottom / 2 + coords.wLeftTriangle, coords.hBottom);
        var dPoint = new three_1.Vector3(-coords.wBottom / 2 + coords.wLeftTriangle + coords.wMiddle, coords.hBottom);
        var ePoint = new three_1.Vector3(-coords.wBottom / 2 + coords.wLeftTriangle + coords.wMiddle, coords.hBottom + coords.hMiddle);
        var fPoint = new three_1.Vector3(-coords.wBottom / 2 + coords.wLeftTriangle + coords.wMiddle + coords.wRightTriangle, coords.hBottom + coords.hMiddle + coords.hTop);
        var gPoint = new three_1.Vector3(coords.wBottom / 2, coords.hBottom + coords.hMiddle + coords.hTop);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, ePoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [ePoint, fPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [fPoint, gPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [gPoint, aPoint]),
        ];
    };
    ShotgunBottom.prototype.getConfigProperties = function () {
        return {
            wBottom: 1,
            hTop: 0.3,
            hMiddle: 0.2,
            hBottom: 0.6,
            wMiddle: 0.4,
            wLeftTriangle: 0.2,
            wRightTriangle: 0.2,
        };
    };
    return ShotgunBottom;
}(figureBase_1.FigureBase));
exports.ShotgunBottom = ShotgunBottom;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Command = void 0;
var Command = /** @class */ (function () {
    function Command(nodes) {
        this.nodes = nodes;
    }
    Command.prototype.execute = function () {
        this.action();
        // we should to call splice to inform VUE.js that something changes in nodes array
        this.nodes.splice(0, 0);
        console.log("SPLICED!!!");
    };
    Command.center = null;
    return Command;
}());
exports.Command = Command;

/* eslint-disable react/prop-types */
import React from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import download from 'downloadjs';
import {TreePack, PdfRepresentationHelper} from "packaging3d-engine";
import _ from 'lodash';
import { fabric } from 'fabric';
import { Document, Page, pdfjs } from 'react-pdf';

import withStyles from '@material-ui/core/styles/withStyles';

import CanvasDemo from './canvas';
import Image from './canvas/Image';
import TextBox from './canvas/TextBox';
import EditorInfo from './components/EditorInfo';
import ModalImage from './components/ModalImage';
import SelectScale from './components/SelectScale';
import EditorTools from './components/EditorTools';
import AddTemplate from './components/AddTemplate';
import EditorLayers from './components/EditorLayers';
import EditorHeader from './components/EditorHeader';
import CustomizedRuler from './canvas/CustomizedRuler';
import { getRectWidthAndHeight } from './helpers/helper';
import {materialTheme} from '../materialTheme'
import { withRouter } from 'react-router';
import { transparentButtonStyle } from '../styles/Button'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { palette } = materialTheme

const styles = () => ({
  ...transparentButtonStyle(palette),
  rulerContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 1,
  },
  editorCanvas: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  editorMain: {
    width: 720,
    height: 550,
    borderRadius: '10px 10px 0 0',
    border: `2px solid ${palette.paleBlue.main}`,
  },
  editorMainNoFile: {
    border: `2px dashed ${palette.paleBlue.main}`,
  },
  editorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  scaleContainer: {
    position: 'absolute',
    right: 24,
    top: 48,
    zIndex: 1000,
  },
  layers: {
    top: 65,
    right: 0,
    width: 550,
    height: '100vh',
    paddingTop: 10,
    display: 'flex',
    zIndex: 1010,
    position: 'absolute',
    justifyContent: 'center',
    borderRadius: '100px 0 0 0',
    overflow: 'auto',
    '& > div': {
      width: 336,
    },
    '&::-webkit-scrollbar': {
      width: 6
    },
    '&::-webkit-scrollbar-track': {
      background: 'white'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      border: '2px solid white',
      backgroundColor: '#BCDEF0',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#A6CBDF'
    },
  },
  canvas: {
    margin: '0 auto',
    boxShadow: '10px 20px 20px rgba(44, 62, 102, 0.2)',
  },
  canvasContainer: {
    maxHeight: 522,
    overflow: 'hidden',
    margin: '24px 0 0 24px',
    padding: 24,
  },
  maxHeight: {
    height: '100%',
  },
});

const helperLineOpts = {
  stroke: '#00C0FF',
  strokeWidth: 2,
  strokeDashArray: [10, 10],
  hasControls: false,
  hasBorders: false,
  selectable: true,
  lockMovementX: false,
  lockMovementY: true,
  hoverCursor: 'ew-resize',
  // originX: 'center',
  // originY: 'center'
};

const helperHorizontalLineOpts = {
  stroke: '#00C0FF',
  strokeWidth: 2,
  strokeDashArray: [10, 10],
  hasControls: false,
  hasBorders: false,
  selectable: true,
  lockMovementX: true,
  lockMovementY: false,
  hoverCursor: 'ns-resize',
  // originX: 'center',
  // originY: 'center'
};

const helperLilLineOpts = {
  stroke: '#00C0FF',
  strokeWidth: 1,
  // strokeDashArray: [10, 10],
  hasControls: false,
  hasBorders: false,
  selectable: false,
  lockMovementX: false,
  lockMovementY: false,
  // hoverCursor: 'ew-resize',
};


const canvasImage = (canvas, scale, path, id) => {
  fabric.Image.fromURL(path, image => {
    image.id = id;
    image.top = canvas.height / 2;
    image.left = canvas.width / 2;
    image.scaleX = scale;
    image.scaleY = scale;
    image.originX = "center";
    image.originY = "center";
    image.centeredScaling = true;
    image.controls.tr.mouseUpHandler = () => {
      canvas.remove(canvas.getActiveObject());
    }

    image.controls.bl.mouseUpHandler = (e, transform) => {
      transform.target.centeredScaling = !transform.target.centeredScaling;
      image.controls.bl.render = transform.target.centeredScaling
        ? renderScaleFromCenterIcon
        : renderScaleFromEdgeIcon;
      canvas.setActiveObject(transform.target);
      canvas.requestRenderAll();
    };
 
    canvas.add(image);
    if (image.width * image.scaleX > image.canvas.width) {
      image.scaleToWidth(image.canvas.width - 10, false);
    }
    if (image.height * image.scaleY > image.canvas.height) {
      image.scaleToHeight(image.canvas.height - 10, false);
    }
  }, {
    crossOrigin: 'anonymous'
  });
  return <Image />;
}

let resizeFromCenterIcon = null;
let resizeFromEdgeIcon = null;

const loadIcons = () => {
  const resizeFromCenter = `${process.env.VUE_APP_API_URL}/icons/resizeFromCenter.svg`;
  const resizeFromEdge = `${process.env.VUE_APP_API_URL}/icons/resizeFromEdge.svg`;
  resizeFromCenterIcon = document.createElement("img");
  resizeFromEdgeIcon = document.createElement("img");
  resizeFromCenterIcon.src = resizeFromCenter;
  resizeFromEdgeIcon.src = resizeFromEdge;
}

const renderScaleFromEdgeIcon = async (
  ctx,
  left,
  top,
  styleOverride,
  fabricObject
) => {
  const size = 24;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(resizeFromEdgeIcon, -size / 2, -size / 2, size, size);
  ctx.restore();
}

const renderScaleFromCenterIcon = async (
  ctx,
  left,
  top,
  styleOverride,
  fabricObject
) => {
  const size = 24;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(
    resizeFromCenterIcon,
    -size / 2,
    -size / 2,
    size,
    size
  );
  ctx.restore();
}

class TemplateEditModal extends React.Component {
  state = {
    clipboard: {},
    canvas: null,
    selectionRect: null,
    currentImage: null,
    shapesIsActive: true,
    shapeType: '',
    rectParams: [],
    docWidth: 900,
    docHeight: 600,
    handActive: false,
    textActive: false,
    imageActive: false,
    rulerActive: false,
    currentRectId: null,
    layerTab: 'summary',
    activeSelection: null,
    startVerticalLineIndex: 0,
    startHorizontalLineIndex: 0,
    verticalLines: [],
    horizontalLines: [],
    currentUnit: 'cm',
    closestLine: null,
    prevScrollX: 0,
    prevScrollY: 0,
    downloadPdf: false,
    isUpdateModel: false,
    currentVerticalHelpLine: null,
    currentHorizontalHelpLine: null,
    scale: { title: '50%', value: 0.5 },
    isOpen: true,
    isModalActive: false,
    lock: false,
    filePdf: [],
    bgUrl: '',
  };

  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.getObjects = null;
    this.pdfWrapper = React.createRef();
    this.helperLineDiv = React.createRef();
    this.lilLine = null;
    this.lilLine1 = null;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.copyAndPasteRect);
    document.addEventListener('keydown', this.crop);
    loadIcons();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const {
      scale,
      canvas,
      docWidth,
      docHeight,
      rulerActive,
      closestLine,
      downloadPdf,
      isUpdateModel,
      verticalLines,
      horizontalLines,
      currentVerticalHelpLine,
      currentHorizontalHelpLine,
    } = this.state;

    if (prevState.scale.value !== scale.value) {
      const canvasObjects = canvas?.getObjects();
      canvasObjects.forEach(object => {
        if (object.id) {
          if (!object.fontFamily) {
            object.scaleX = object.scaleX / prevState.scale.value * scale.value;
            object.scaleY = object.scaleY / prevState.scale.value * scale.value;
          } else {
            object.scale(scale.value / prevState.scale.value)
          }

          object.left = object.left / prevState.scale.value * scale.value;
          object.top = object.top / prevState.scale.value * scale.value;

          object.setCoords();
        }
      });
    }
    if (prevState.rulerActive !== rulerActive && rulerActive) {
      
    }
    if (!_.isEqual(prevState.verticalLines, verticalLines)) {
      if (currentVerticalHelpLine.left < docWidth / 2) {
        if (verticalLines.length > 1
          && currentVerticalHelpLine.left < (docWidth - closestLine + 10)
          && currentVerticalHelpLine.left > (docWidth - closestLine - 10)
          && !this.lilLine
          && !this.lilLine1
        ) {
          this.lilLine = new fabric.Line([0, 20, (docWidth - closestLine), 20], helperLilLineOpts);
          this.lilLine1 = new fabric.Line([closestLine, 20, docWidth, 20], helperLilLineOpts);
          currentVerticalHelpLine.left = (docWidth - closestLine);
          canvas.add(this.lilLine);
          canvas.add(this.lilLine1);
        } else if (
          !(verticalLines.length > 1
            && currentVerticalHelpLine.left < (docWidth - closestLine + 10)
            && currentVerticalHelpLine.left > (docWidth - closestLine - 10))
          && this.lilLine
          && this.lilLine1
        ) {
          canvas.remove(this.lilLine);
          this.lilLine = null;
          canvas.remove(this.lilLine1);
          this.lilLine1 = null;
        }
      } else if (verticalLines.length > 1
        && (docWidth - currentVerticalHelpLine.left) < (closestLine + 10)
        && (docWidth - currentVerticalHelpLine.left) > (closestLine - 10)
        && !this.lilLine
        && !this.lilLine1
      ) {
        this.lilLine = new fabric.Line([0, 20, closestLine, 20], helperLilLineOpts);
        this.lilLine1 = new fabric.Line([currentVerticalHelpLine.left, 20, docWidth, 20], helperLilLineOpts);
        currentVerticalHelpLine.right = (docWidth - closestLine);
        canvas.add(this.lilLine);
        canvas.add(this.lilLine1);
      } else if (
        !(verticalLines.length > 1
          && (docWidth - currentVerticalHelpLine.left) < (closestLine + 10)
          && (docWidth - currentVerticalHelpLine.left) > (closestLine - 10)
          && !this.lilLine
          && !this.lilLine1) && this.lilLine && this.lilLine1
      ) {
        canvas.remove(this.lilLine);
        this.lilLine = null;
        canvas.remove(this.lilLine1);
        this.lilLine1 = null;
      }
    }
    if (!_.isEqual(prevState.horizontalLines, horizontalLines)) {
      if (currentHorizontalHelpLine.top < docHeight / 2) {
        if (horizontalLines.length > 1
          && currentHorizontalHelpLine.top < (docHeight - closestLine + 10)
          && currentHorizontalHelpLine.top > (docHeight - closestLine - 10)
          && !this.lilLine
          && !this.lilLine1
        ) {
          this.lilLine = new fabric.Line([20, 0, 20, (docHeight - closestLine)], helperLilLineOpts);
          this.lilLine1 = new fabric.Line([20, closestLine, 20, docHeight], helperLilLineOpts);
          currentHorizontalHelpLine.top = (docHeight - closestLine);
          canvas.add(this.lilLine);
          canvas.add(this.lilLine1);
        } else if (
          !(horizontalLines.length > 1
            && currentHorizontalHelpLine.top < (docHeight - closestLine + 10)
            && currentHorizontalHelpLine.top > (docHeight - closestLine - 10)
          ) && this.lilLine && this.lilLine1
        ) {
          canvas.remove(this.lilLine);
          this.lilLine = null;
          canvas.remove(this.lilLine1);
          this.lilLine1 = null;
        }
      } else {
        if (horizontalLines.length > 1
          && (docHeight - currentHorizontalHelpLine.top) < (closestLine + 10)
          && (docHeight - currentHorizontalHelpLine.top) > (closestLine - 10)
          && !this.lilLin
          && !this.lilLine1
        ) {
          this.lilLine = new fabric.Line([20, 0, 20, closestLine], helperLilLineOpts);
          this.lilLine1 = new fabric.Line([20, currentHorizontalHelpLine.top, 20, docHeight], helperLilLineOpts);
          currentHorizontalHelpLine.top = (docHeight - closestLine);
          canvas.add(this.lilLine);
          canvas.add(this.lilLine1);
        } else if (
          !(verticalLines.length > 1
            && (docHeight - currentHorizontalHelpLine.top) < (closestLine + 10)
            && (docHeight - currentHorizontalHelpLine.top) > (closestLine - 10)
            && !this.lilLine
            && !this.lilLine1
          ) && this.lilLine && this.lilLine1
        ) {
          canvas.remove(this.lilLine);
          this.lilLine = null;
          canvas.remove(this.lilLine1);
          this.lilLine1 = null;
        }
      }
    }

    if (prevState.scale.value === scale.value && downloadPdf) {
      this.saveTemplate();
    }

    if (prevState.scale.value === scale.value && isUpdateModel) {
      this.updateModel();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.copyAndPasteRect);
    document.removeEventListener('keydown', this.crop);
  }

  copyAndPasteRect = e => {
    const {
      canvas, currentRectId, rectParams
    } = this.state;
    const keyName = e.code;

    if (keyName === 'ControlLeft') {
      return;
    }

    if (e.ctrlKey) {
      if (keyName === 'KeyC') {
        canvas.getActiveObject().clone(cloned => {
          const rectParam = rectParams.find(param => param.id === currentRectId);

          this.setState({
            clipboard: {
              rectObject: cloned,
              rectParam: { ...rectParam },
            }
          });
        });
      }
      if (keyName === 'KeyV' || keyName === 'м') {
        const { clipboard } = this.state;
        const { rectObject, rectParam } = clipboard;

        rectObject.clone(clonedObject => {
          const copyRectParam = { ...rectParam };

          const id = Math.random();
          const { title } = copyRectParam;

          clonedObject.id = id;
          clonedObject.evented = true;

          copyRectParam.id = id;
          copyRectParam.title = this.getUniqueName(title);

          canvas.add(clonedObject);
          this.setState(
            { rectParams: [...rectParams, copyRectParam], currentRectId: id },
            () => {
              canvas.setActiveObject(clonedObject);
              canvas.requestRenderAll();
            }
          );
        });
      }
    }
    if (keyName === 'Delete') {
      const activeObj = canvas.getActiveObject();
      if (activeObj.type === 'crop') {
        this.deleteRect(currentRectId);
        this.setState({ shapesIsActive: true });
      }
      if (activeObj.id) {
        this.deleteRect(currentRectId);
      }
      canvas.renderAll();
    }
  }

  setCanvas = canvas => {
    canvas.selection = false;
    this.setState({ canvas });
  };

  onPdfLoad = page => {
    const { scale } = this.state;
    const {
      originalWidth, originalHeight, _pageInfo: { rotate }
    } = page;
    const width = originalWidth * scale.value;
    const height = originalHeight * scale.value;

    let docWidth = width;
    let docHeight = height;

    if (rotate === 90) {
      docWidth = height;
      docHeight = width;
    }

    this.setState({ docWidth, docHeight });
    this.loadPrevBGImage();
  };

  addActiveSelection = () => {
    const { canvas } = this.state;
    const activeSelectionEvents = [
      'object:moved',
      'object:scaling',
      'object:modified',
      'object:rotating',
      'selection:created',
      'selection:updated',
    ];
    activeSelectionEvents.forEach(event => {
      canvas.on(event, e => {
        if (e.target.type !== 'line') {
          const activeObj = canvas.getActiveObject();
          const { width, height } = getRectWidthAndHeight(activeObj);

          let anglePath;
          let coordsPath;
          if (e.selected && e.selected.length) {
            anglePath = e.selected[0].angle || 0;
            coordsPath = e.selected[0].aCoords;
          } else {
            anglePath = activeObj.angle;
            coordsPath = activeObj.aCoords;
          }
          if (anglePath >= 0 && anglePath <= 90) {
            this.setState({
              activeSelection: {
                x: coordsPath.bl.x,
                y: coordsPath.tl.y,
                width,
                height
              }
            });
          } else if (anglePath >= 90 && anglePath <= 180) {
            this.setState({
              activeSelection: {
                x: coordsPath.br.x,
                y: coordsPath.bl.y,
                width,
                height
              }
            });
          } else if (anglePath >= 180 && anglePath <= 270) {
            this.setState({
              activeSelection: {
                x: coordsPath.tr.x,
                y: coordsPath.br.y,
                width,
                height
              }
            });
          } else if (anglePath >= 270 && anglePath <= 360) {
            this.setState({
              activeSelection: {
                x: coordsPath.tl.x,
                y: coordsPath.tr.y,
                width,
                height
              }
            });
          }
        }
        this.setState({
          currentRectId: e.target.id,
          isOpen: true,
          layerTab: 'settings'
        });
      });
    });

    canvas.on('selection:cleared', () => {
      this.setState({
        activeSelection: null,
        isOpen: false,
        imageActive: false,
        textActive: false,
      });
    });
  };

  addText = () => {
    const { rectParams, canvas, scale } = this.state;
    const textRect = new TextBox.Class(...TextBox.args);
    const { current } = this.pdfWrapper;

    if (!rectParams.length) {
      this.addActiveSelection();
    }
    const pdfCanvas = document.querySelector('.pdf-doc-canvas canvas');
    const topPosition = pdfCanvas?.getBoundingClientRect().height / 2;
    const leftPosition = pdfCanvas?.getBoundingClientRect().width / 2;

    textRect.top = topPosition - 25;
    textRect.left = leftPosition - 75;
    textRect.scaleX = scale.value;
    textRect.scaleY = scale.value;
    textRect.centeredScaling = true;

    const text = `Text${rectParams.length + 1}`;
    const id = Math.random();

    textRect.text = text;
    textRect.id = id;

    textRect.controls.tr.mouseUpHandler = () => {
      this.deleteRect(canvas.getActiveObject().id);
      canvas.getObjects().forEach(e => {
          e.set({
            hoverCursor: 'default', selectable: true, selected: true, editable: true, isEditable: true
          });
        });
    }

    textRect.controls.bl.mouseUpHandler = (e, transform) => {
      transform.target.centeredScaling = !transform.target.centeredScaling;
      textRect.controls.bl.render = transform.target.centeredScaling
        ? renderScaleFromCenterIcon
        : renderScaleFromEdgeIcon;
      canvas.setActiveObject(transform.target);
      canvas.requestRenderAll();
    };


    textRect.controls.mt.visible = false;
    textRect.controls.mb.visible = false;

    canvas.add(textRect);

    rectParams.push({
      id,
      opacity: 1,
      title: text,
      fill: '#000',
      fontSize: 40,
      locked: false,
      align: 'none',
      charSpacing: 0,
      fontWeight: 400,
      lineHeight: 1.16,
      textAlign: 'left',
      underline: false,
      textCase: 'default',
      fontStyle: 'normal',
      verticalAlign: 'top',
      fontFamily: 'Times new Roman'
    });

    current.removeEventListener('mousedown', this.onHandMouseDown);
    window.removeEventListener('mouseup', this.onHandMouseUp);
    this.setCursor('default');

    this.setState({
      rectParams,
      currentRectId: id,
      layerTab: 'settings',
      handActive: false,
      imageActive: false,
      textActive: true,
      isOpen: true
    });
  };

  getRectObject = (id, canvas) => {
    const canvasObjects = canvas?.getObjects();
    return canvasObjects?.find(object => object.id === id);
  };

  getUniqueName = title => {
    let newTitle = title;
    const { rectParams } = this.state;
    const rectParamsTitle = rectParams.map(param => param.title);

    for (let i = 0; i < rectParamsTitle.length + 1; i += 1) {
      const index = title.indexOf('#');
      const endNumber = index !== -1 ? index : title.length;
      const tempName = i ? `${title.slice(0, endNumber)}#${i}` : title;

      if (!rectParamsTitle.includes(tempName)) {
        newTitle = tempName;
        break;
      }
    }

    return newTitle;
  }

  loadPrevBGImage = () => {
    if (!this.state.isUpdateModel && this.props.bgImage && !this.state.bgUrl) {
      const { rectParams, scale, canvas, bgUrl } = this.state;
      const { rects, setRects, setOpenError, bgImage } = this.props;
      const previousBG = rectParams.filter(filteringRect => filteringRect.title == "prevBG")[0];
      if (previousBG) this.deleteRect(previousBG.id);
      const id = Math.random();
      rectParams.top = canvas.height / 2;
      rectParams.left = canvas.width / 2;
      rectParams.push({
        id,
        title: "prevBG",
        locked: false,
        opacity: 1,
      });
      const finalBGImage = bgUrl ? bgUrl : bgImage;
      rects.push(canvasImage(canvas, scale.value, finalBGImage, id));
      this.setState({
        rectParams,
        currentRectId: id,
        layerTab: 'settings',
        handActive: false,
        imageActive: true,
        textActive: false,
        isOpen: true,
        bgUrl: bgImage,
      });
    }
  };

  uploadImage = file => {
    const reader = new FileReader();
    const { rectParams, scale, canvas } = this.state;
    const { rects, setRects, setOpenError } = this.props;
    const { current } = this.pdfWrapper;


    if (file.type.indexOf('pdf') !== -1) {
      setOpenError({
        message: 'The loaded file is PDF',
      });
      return;
    }

    if (!rectParams.length) {
      this.addActiveSelection();
    }

    reader.readAsDataURL(file);
    reader.onload = () => {
      const id = Math.random();
      const title = this.getUniqueName(file.name);
      rectParams.top = canvas.height / 2;
      rectParams.left = canvas.width / 2;
      rectParams.push({
        id,
        title,
        locked: false,
        opacity: 1,
        // centeredScaling: true,
      });
     

      rects.push(canvasImage(canvas, scale.value, reader.result, id));
   

      // setRects(rects);
      
    
      document.getElementById('uploadImage').value = '';

      current.removeEventListener('mousedown', this.onHandMouseDown);
      window.removeEventListener('mouseup', this.onHandMouseUp);
      this.setCursor('default');

      this.setState({
        rectParams,
        currentRectId: id,
        layerTab: 'settings',
        handActive: false,
        imageActive: true,
        textActive: false,
        isOpen: true
      });
    };
  };

  shapeClick = type => {
    const { canvas } = this.state;
    const selectionRect = this.addSelection(type);
    canvas.setActiveObject(selectionRect);
    canvas.renderAll();
    this.setState({ shapesIsActive: false });
  }

  addSelection = type => {
    const { canvas, currentRectId, rectParams } = this.state;

    const currentImage = canvas.getActiveObject();

    const id = Math.random();
    const title = 'crop';
    let selectionRect;

    if (type === 'Rectangle') {
      selectionRect = new fabric.Rect({
        type: 'crop',
        title,
        id,
        fill: 'rgba(0,0,0,0.3)',
        originX: 'left',
        originY: 'top',
        stroke: 'black',
        opacity: 1,
        left: currentImage.left,
        top: currentImage.top,
        width: currentImage.width * currentImage.scaleX,
        height: currentImage.height * currentImage.scaleY,
        transparentCorners: false,
        padding: 0,
      });
    } else if (type === 'Ellipse') {
      selectionRect = new fabric.Circle({
        type: 'crop',
        id,
        title,
        fill: 'rgba(0,0,0,0.3)',
        originX: 'left',
        originY: 'top',
        stroke: 'black',
        opacity: 1,
        left: currentImage.left,
        top: currentImage.top,
        radius: currentImage.width > currentImage.height
          ? currentImage.height * currentImage.scaleY / 2
          : currentImage.width * currentImage.scaleX / 2,
        padding: 0,
      });
    }

    canvas.add(selectionRect);
    const newRectParams = [...rectParams, selectionRect];
    this.setState({
      selectionRect, currentImage: { id: currentRectId, currentImage }, shapeType: type, rectParams: newRectParams
    });
    return selectionRect;
  }

  crop = e => {
    const {
      canvas, selectionRect, currentImage, shapeType, rectParams, shapesIsActive
    } = this.state;

    const keyName = e.code;
    if (keyName === 'Enter' && !shapesIsActive) {
      let rect;
      const currentImg = currentImage.currentImage;
      if (shapeType === 'Rectangle') {
        rect = new fabric.Rect({
          left: selectionRect.left,
          top: selectionRect.top,
          width: selectionRect.width * selectionRect.scaleX,
          height: selectionRect.width * selectionRect.scaleY,
          absolutePositioned: true,
        });
      } else if (shapeType === 'Ellipse') {
        rect = new fabric.Circle({
          left: selectionRect.left,
          top: selectionRect.top,
          radius: selectionRect.radius,
          absolutePositioned: true,
        });
      }

      canvas.remove(selectionRect);

      const cropped = new Image();

      cropped.src = canvas.toDataURL({
        left: selectionRect.left,
        top: selectionRect.top,
        width: selectionRect.width * selectionRect.scaleX,
        height: selectionRect.height * selectionRect.scaleY
      });
      const clipPath = new fabric.Circle({
        radius: rect.radius * selectionRect.scaleX,
        left: -rect.radius * selectionRect.scaleX,
        top: -rect.radius * selectionRect.scaleX
      });
      fabric.Image.fromURL(cropped.src, img => {
        img.id = currentImage.id;
        img.set({
          left: currentImg.left,
          top: currentImg.top,
          clipPath: shapeType === 'Ellipse' ? clipPath : null
        });
        canvas.remove(currentImg);
        canvas.remove(rect);
        canvas.add(img);
      });

      canvas.renderAll();
      const newRectParams = rectParams.filter(param => param.type !== 'crop');
      this.setState({ shapesIsActive: true, rectParams: newRectParams });
    }
  }

  onMouseMove = e => {
    const { clientX, clientY } = e;
    const { prevScrollX, prevScrollY } = this.state;
    this.pdfWrapper.current.scrollTop -= clientY - prevScrollY;
    this.pdfWrapper.current.scrollLeft -= clientX - prevScrollX;

    this.setState({ prevScrollX: clientX, prevScrollY: clientY });
  };

  onHandMouseUp = () => {
    if (this.pdfWrapper.current) {
      window.removeEventListener('mousemove', this.onMouseMove);
    }
  };

  onHandMouseDown = ({ clientX, clientY }) => {
    this.setState({ prevScrollX: clientX, prevScrollY: clientY });

    window.addEventListener('mousemove', this.onMouseMove);
  };

  setCursor = type => {
    const { canvas } = this.state;
    const events = ['mouse:move', 'mouse:up', 'mouse:down'];
    events.forEach(event => {
      canvas.on(event, () => {
        canvas.setCursor(type);
      });
    });
  };

  onSetActive = () => {
    const { current } = this.pdfWrapper;
    current.removeEventListener('mousedown', this.onHandMouseDown);
    window.removeEventListener('mouseup', this.onHandMouseUp);
    this.setCursor('default');
    const canvasObjects = this.state.canvas.getObjects();
    canvasObjects.forEach(rectParam => {
      const rectObject = this.getRectObject(rectParam.id, this.state.canvas);
      const locked = rectObject?.locked === undefined ? true : !rectObject.locked;
      if (!rectObject.locked) {
        rectObject.set({
          hoverCursor: 'default', selectable: locked, selected: locked, editable: locked,
        });
      }
    });
  }

  

  onHandClick = () => {
    const {
      handActive, canvas
    } = this.state;
    const { current } = this.pdfWrapper;
    if (!handActive) {
      current.addEventListener('mousedown', this.onHandMouseDown);
      window.addEventListener('mouseup', this.onHandMouseUp);
      this.setCursor('pointer');
      const objs = canvas.getObjects();
      objs.forEach(obj => obj.set({
        hoverCursor: 'pointer', selectable: false, selected: false, editable: false,
      }));
      canvas.discardActiveObject().renderAll();
    } else {
      current.removeEventListener('mousedown', this.onHandMouseDown);
      window.removeEventListener('mouseup', this.onHandMouseUp);
      this.setCursor('default');
      const canvasObjects = canvas.getObjects();
      canvasObjects.forEach(rectParam => {
        const rectObject = this.getRectObject(rectParam.id, canvas);
        const locked = rectObject?.locked === undefined ? true : !rectObject.locked;
        if (!rectObject.locked) {
          rectObject.set({
            hoverCursor: 'default', selectable: locked, selected: locked, editable: locked,
          });
        }
      });
    }
   
    this.setState({
      handActive: !handActive, textActive: false, imageActive: false,
    });
  
  };

  onRulerClick = () => {
    const { rulerActive } = this.state;
    this.setState({ rulerActive: !rulerActive });
  };

  addVerticalLine = () => {
    const {
      canvas, docWidth, docHeight, startVerticalLineIndex, verticalLines
    } = this.state;
    const line = new fabric.Line([docWidth / 2, 0, docWidth / 2, docHeight], helperLineOpts);
    line.index = `${startVerticalLineIndex}v`;
    verticalLines.push(docWidth / 2);
    this.setState({
      startVerticalLineIndex: startVerticalLineIndex + 1,
      verticalLines,
    }, () => {
      canvas.add(line);
      canvas.on('object:moving', e => {
        if (e.target.type === 'line') {
          if (e.target.index[e.target.index.length - 1] === 'v') {
            const { currentVerticalHelpLine } = this.state;
            const verticalLinesCopy = _.cloneDeep(verticalLines);
            verticalLinesCopy[+e.target.index[0]] = e.target.left;

            if (!currentVerticalHelpLine) {
              this.setState({
                verticalLines: verticalLinesCopy,
                currentVerticalHelpLine: e.target
              });
            } else {
              const closestLine = verticalLinesCopy.reduce((prev, curr, i) => (
                (Math.abs((docWidth - curr) - e.target.left)
                  < Math.abs((docWidth - prev) - e.target.left)
                  && i !== +e.target.index[0]) ? curr : prev
              ));
              this.setState({
                verticalLines: verticalLinesCopy,
                closestLine
              });
            }
          }
        }
      });
      canvas.on('object:moved', () => {
        canvas.remove(this.lilLine);
        this.lilLine = null;
        canvas.remove(this.lilLine1);
        this.lilLine1 = null;
        this.setState({
          currentVerticalHelpLine: null
        });
      });
    });
  };

  addHorizontalLine = () => {
    const {
      canvas, docWidth, docHeight, startHorizontalLineIndex, horizontalLines, verticalLines
    } = this.state;
    const line = new fabric.Line([0, docHeight / 2, docWidth, docHeight / 2], helperHorizontalLineOpts);
    line.index = `${startHorizontalLineIndex}h`;
    horizontalLines.push(docHeight / 2);
    this.setState({
      startHorizontalLineIndex: startHorizontalLineIndex + 1,
      horizontalLines,
    }, () => {
      canvas.add(line);
      canvas.on('object:moving', e => {
        if (e.target.type === 'line') {
          if (e.target.index[e.target.index.length - 1] === 'h') {
            const { currentHorizontalHelpLine } = this.state;
            const horizontalLinesCopy = _.cloneDeep(horizontalLines);
            horizontalLinesCopy[+e.target.index[0]] = e.target.top;

            if (!currentHorizontalHelpLine) {
              this.setState({
                horizontalLines: horizontalLinesCopy,
                currentHorizontalHelpLine: e.target
              });
            } else {
              const closestLine = horizontalLinesCopy.reduce((prev, curr, i) => ((
                Math.abs((docHeight - curr) - e.target.top)
                  < Math.abs((docHeight - prev) - e.target.top)
                  && i !== +e.target.index[0]) ? curr : prev
              ));
              this.setState({
                horizontalLines: horizontalLinesCopy,
                closestLine
              });
            }
          }
        }
      });
      canvas.on('object:moved', () => {
        canvas.remove(this.lilLine);
        this.lilLine = null;
        canvas.remove(this.lilLine1);
        this.lilLine1 = null;
        this.setState({
          currentHorizontalHelpLine: null
        });
      });
    });
  }

  moveHelperLineDiv = (e, index) => {
    const {
      canvas, verticalLines, currentVerticalHelpLine, docWidth
    } = this.state;
    e.preventDefault(); // предотвратить запуск выделения (действие браузера)
    e.persist();

    const onMouseMove = event => {
      const shiftX = event.clientX - this.pdfWrapper.current.getBoundingClientRect().left;
      const verticalLinesCopy = _.cloneDeep(verticalLines);
      const currentVerticalLine = canvas._objects.find(obj => obj.index === `${index}v`);
      const newLeft = shiftX - 161;
      currentVerticalLine.left = newLeft;
      verticalLinesCopy[index] = newLeft;

      // this.setState({
      //   verticalLines: verticalLinesCopy,
      //   currentVerticalHelpLine: currentVerticalLine
      // })

      if (!currentVerticalHelpLine) {
        this.setState({
          verticalLines: verticalLinesCopy,
          currentVerticalHelpLine: currentVerticalLine
        });
      } else {
        verticalLinesCopy.reduce((prev, curr, i) => (
          (Math.abs((docWidth - curr) - currentVerticalLine.left)
            < Math.abs((docWidth - prev) - currentVerticalLine.left)
            && i !== +currentVerticalLine.index[0]) ? curr : prev
        ));
      }
    };

    const onMouseUp = () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  changeUnit = unitValue => {
    this.setState({ currentUnit: unitValue });
  };

  changeScale = value => {
    this.setState({ scale: value });
  };

  deleteFocus = value => {
    const { canvas } = this.state;
    canvas.discardActiveObject().renderAll();
    this.setState({ isOpen: value });
  };

  deleteRect = id => {
    const { setRects, rects } = this.props;
    const { rectParams, canvas } = this.state;
    const rectObject = this.getRectObject(id, canvas);
    const newRectParam = rectParams.filter(param => param.id !== id);

    const newRectParamLength = newRectParam.length;
    const currentRectId = newRectParam[newRectParamLength - 1]?.id;

    setRects(rects.filter(rect => rect.props.id !== id));
    canvas.remove(rectObject);

    this.setState({ rectParams: newRectParam, currentRectId: newRectParamLength ? currentRectId : null });
    if (rectObject.type === 'crop') {
      this.setState({ shapesIsActive: true });
    }
  };

  lockRect = id => {
    const { rectParams, canvas, handActive } = this.state;
    const rectObject = this.getRectObject(id, canvas);
    const locked = rectObject?.locked === undefined ? true : !rectObject.locked;
    if (!handActive) {
      rectObject.lockMovementX = locked;
      rectObject.lockMovementY = locked;
      rectObject.lockScalingX = locked;
      rectObject.lockScalingY = locked;
      rectObject.lockUniScaling = locked;
      rectObject.lockRotation = locked;
      rectObject.locked = locked;
      rectObject.hasControls = !locked;
      rectObject.hoverCursor = 'default';
      rectObject.selectable = !locked;
      rectObject.selected = !locked;
      rectObject.editable = !locked;
    }
    const newRectParam = rectParams.map(param => {
      if (param.id === id) {
        param.locked = locked;
      }
      return param;
    });
    this.setState({ rectParams: newRectParam, lock: !this.state.lock });
  }

  readFileAsync = file => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  })

  saveTemplate = async () => {
    const { canvas } = this.state;
    const { file } = this.props;

    const oldFileArray = await this.readFileAsync(file);
    const canvasObjects = canvas?.getObjects();

    const pdf = await PDFDocument.load(oldFileArray);
    const page = pdf.getPages()[0];

    canvasObjects.forEach(object => {
      if (!object.id) {
        canvas.remove(object);
      }
    });

    const imgData = canvas.toDataURL('image/png', 1.0);

    const embImgData = await pdf.embedPng(imgData);

    page.drawImage(embImgData, 0, 0);

    const pdfBytes = await pdf.save();
    download(pdfBytes, 'output.pdf', 'application/pdf');

    this.setState({ downloadPdf: false, scale: { title: '100%', value: 1 } });
  };

  onDragEnd = result => {
    const { rectParams, canvas } = this.state;
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const changeItemPosition = items => {
      const item = items.splice(source.index, 1)[0];
      items.splice(destination.index, 0, item);
    };

    const newRectParams = [...rectParams];
    changeItemPosition(newRectParams);
    changeItemPosition(canvas._objects);

    this.setState({ rectParams: newRectParams });
  };

  clearEditor = () => {
    const { setFile, setRects } = this.props;
    const { rectParams, canvas } = this.state;
    setFile(null);
    setRects([]);

    rectParams.forEach(param => {
      const rectObject = this.getRectObject(param.id, canvas);
      canvas.remove(rectObject);
    });

    this.setState({ rectParams: [], currentRectId: null });
    this.props.history.push("http://localhost:8081/#/")
  };

  updateRectParams = () => {
    const { rectParams } = this.state;
    this.setState({ rectParams });
  }

  setLayerTab = value => {
    this.setState({ layerTab: value });
  }

  setIsModalActive = () => {
    this.setState({isModalActive: false})
  }

  setIsModalActiveTrue = () => {
    this.setState({isModalActive: true})
  }


  updateModel = async () => {
    const { update, file } = this.props;
    const { canvas } = this.state;

    const oldFileArray = await this.readFileAsync(file);
    const canvasObjects = canvas?.getObjects();

    
    const pdf = await PDFDocument.load(oldFileArray);
    const page = pdf.getPages()[0];
    const { width, height } = page.getSize()

    canvasObjects.forEach(object => {
      if (!object.id) {
        canvas.remove(object);
      }
    });

    const imgData = canvas.toDataURL('image/png', 1.0);
    const image = await fetch(imgData);
    const blob = await image.blob();     

    update(blob);
    this.setState({ isUpdateModel: false});
  };

  render() {
    const {
      classes, rects, file, handleChange, bgImage
    } = this.props;
    const {
      scale,
      canvas,
      layerTab,
      docWidth,
      docHeight,
      rectParams,
      handActive,
      currentUnit,
      rulerActive,
      currentRectId,
      verticalLines,
      horizontalLines,
      activeSelection,
      imageActive,
      textActive,
      isOpen,
      shapesIsActive,
      isModalActive,
      filePdf
    } = this.state;

    const currentRect = rectParams.find(param => param.id === currentRectId) || '';

    if (!filePdf.some(el => el.size > 0) || filePdf[filePdf.length - 1].size > 0 && filePdf[filePdf.length - 1].size < file.size || filePdf[filePdf.length - 1].size > file.size) {
      filePdf.push(file);
    }

    return (
      <div className={classes.editorContainer}>
        <div className={classes.editor}>
          {isModalActive && <ModalImage uploadImage={this.uploadImage} setIsModalActive={this.setIsModalActive}/>}
          {!isModalActive &&
            <EditorHeader
            setIsActiveImage={() => {
              this.setState({
                isModalActive: true,
                rulerActive: false,
                handActive: false
              })
            }}
            fileLoaded={file}
            width={docWidth / scale.value}
            height={docHeight / scale.value}
            currentUnit={currentUnit}
            setFile={this.clearEditor}
            changeUnit={this.changeUnit}
            saveTemplate={() => {
              this.setState({
                scale: { title: '100%', value: 1 },
                downloadPdf: true,
              });
            }}
            uploadTemplate={() => {
              this.setState({ isUpdateModel: true, scale: { title: '100%', value: 1 } });
            }}
          />
          }
          
          <div className={`${classes.editorMain} ${!file && classes.editorMainNoFile}`} style={{position: 'absolute'}}>
          {!!rulerActive && (
                  <div className={classes.rulerContainer} style={{ width: '100%', height: 24 }}>
                  <div style={{ width: '100%' }}>
                      <CustomizedRuler
                        zoom={scale.value}
                        unitType={currentUnit}
                        activeSelection={activeSelection}
                        size={docWidth}
                        type="horizontal"
                        setRef={e => { this.ruler = e; }}
                      />
                    </div>
                  </div>
                )}
                {!!rulerActive && (
                 <div id="verticalRuler" className={classes.rulerContainer} style={{ height: '100%', width: 24 }}>
                 <div style={{ height: '100%' }} id={docHeight}>
                      <CustomizedRuler
                        zoom={scale.value}
                        unitType={currentUnit}
                        activeSelection={activeSelection}
                        size={docHeight}
                        type="vertical"
                        setRef={e => { this.ruler1 = e; }}
                      />
                    </div>
                  </div>
                )}
            <div className={classes.scaleContainer}>
            {!isModalActive &&
               <SelectScale
               disabled={!file}
               currentScale={scale}
               changeScale={this.changeScale}
               deleteFocus={this.deleteFocus}
             />
            }
            </div>
            {!isModalActive &&
              <EditorTools
              onSetActive={this.onSetActive}
              disabled={!file}
              handActive={handActive}
              textActive={textActive}
              imageActive={imageActive}
              rulerActive={rulerActive}
              currentRect={currentRect}
              onHandClick={this.onHandClick}
              onTextFieldClick={this.addText}
              onRulerClick={this.onRulerClick}
              onImageFieldClick={this.uploadImage}
            />
            }
            <div
              className={`${classes.maxHeight} ${file && classes.canvasContainer}`}
              ref={this.pdfWrapper}
            >
              {verticalLines.map((position, index) => (
                <div
                  aria-hidden="true"
                  ref={this.helperLineDiv}
                  onMouseDown={e => this.moveHelperLineDiv(e, index)}
                  style={{
                    position: 'absolute',
                    left: position + 262,
                    height: 'calc(100% + 48px)',
                    top: -24,
                    width: 2,
                    background: '#00C0FF',
                    cursor: 'ew-resize'
                  }}
                />
              ))}
              {horizontalLines.map((position, index) => (
                <div
                  aria-hidden="true"
                  ref={this.helperLineDiv}
                  onMouseDown={e => this.moveHelperLineDiv(e, index)}
                  style={{
                    position: 'absolute',
                    top: position + 24,
                    width: 'calc(100% + 24px)',
                    left: -24,
                    height: 2,
                    background: '#00C0FF',
                    cursor: 'ns-resize'
                  }}
                />
              ))}
              {file ? (
                <div
                  className={classes.canvas}
                  style={{ width: docWidth }}
                >
                  <div style={{ width: docWidth }}>
                    <Document file={filePdf[filePdf.length - 1]}>
                      <Page
                        pageNumber={1}
                        onRenderSuccess={this.onPdfLoad}
                        className="pdf-doc-canvas"
                        renderTextLayer={false}
                        scale={scale.value}
                      >
                        <div id="editorCanvas" className={classes.editorCanvas}>
                          <CanvasDemo
                            lock={this.state.lock}
                            handActive={this.state.handActive}
                            style={{ width: docWidth, height: docHeight }}
                            setCanvas={this.setCanvas}
                            setGetObjects={getObjects => {
                              this.getObjects = getObjects;
                            }}
                            rects={rects}
                          />
                        </div>
                      </Page>
                    </Document>
                  </div>
                </div>
              ) : <AddTemplate handleChange={handleChange} />
              }
            </div>
          </div>
        </div>
        {!isModalActive &&
           <div className={classes.layers}>
              {rectParams.length ? (
                 <EditorLayers
                  canvas={canvas}
                  layerTab={layerTab}
                  setLayerTab={this.setLayerTab}
                  currentRect={currentRect}
                  rectParams={rectParams}
                  onDragEnd={this.onDragEnd}
                  lockRect={this.lockRect}
                  deleteRect={this.deleteRect}
                  updateRectParams={this.updateRectParams}
                  isOpen={isOpen}
                  deleteFocus={this.deleteFocus}
                  shapeClick={this.shapeClick}
                  shapesIsActive={shapesIsActive}
                   />
                 ) : <EditorInfo fileLoaded={file} />
                 }
               </div> 
        }

      </div>
    );
  }
}

export default withStyles(styles)(TemplateEditModal);








// /* eslint-disable react/prop-types */
// import React from 'react';
// import JsPDF from 'jspdf';
// import { PDFDocument } from 'pdf-lib';
// import {
//   Input,
//   Button,
//   Collapse,
//   Form,
//   Upload,
//   List
// } from 'antd';
// import _ from 'lodash';
// import { fabric } from 'fabric';
// import { Document, Page, pdfjs } from 'react-pdf';
// import { ChromePicker } from 'react-color';
// import ReactDragListView from 'react-drag-listview';

// import { CloseOutlined } from '@ant-design/icons';

// import fonts from './fonts';
// import fontSizes from './fontSizes';
// import { roundUnit } from './helpers/pixelConverter';

// import CanvasDemo from './canvas';
// import Image from './canvas/Image';
// import TextBox from './canvas/TextBox';
// import SelectFont from './components/SelectFont';
// import AlignButton from './components/AlignButton';
// import StyleButton from './components/StyleButton';
// import SelectUnits from './components/SelectUnits';
// import SelectScale from './components/SelectScale';
// import CustomizedRuler from './canvas/CustomizedRuler';
// import SelectFontSizes from './components/SelectFontSizes';

// import 'antd/dist/antd.css';
// import './TemplateEditModal.css';
// import {TreePack, PdfRepresentationHelper} from "packaging3d-engine";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const helperLineOpts = {
//   stroke: '#00C0FF',
//   strokeWidth: 2,
//   strokeDashArray: [10, 10],
//   hasControls: false,
//   hasBorders: false,
//   selectable: true,
//   lockMovementX: false,
//   lockMovementY: true,
//   hoverCursor: 'ew-resize',
//   // originX: 'center',
//   // originY: 'center'
// };

// const helperHorizontalLineOpts = {
//   stroke: '#00C0FF',
//   strokeWidth: 2,
//   strokeDashArray: [10, 10],
//   hasControls: false,
//   hasBorders: false,
//   selectable: true,
//   lockMovementX: true,
//   lockMovementY: false,
//   hoverCursor: 'ns-resize',
//   // originX: 'center',
//   // originY: 'center'
// };

// const helperLilLineOpts = {
//   stroke: '#00C0FF',
//   strokeWidth: 1,
//   // strokeDashArray: [10, 10],
//   hasControls: false,
//   hasBorders: false,
//   selectable: false,
//   lockMovementX: false,
//   lockMovementY: false,
//   // hoverCursor: 'ew-resize',
// };

// const { Panel } = Collapse;

// const canvasImage = (path, id) => <Image id={id} path={path} />;

// const formItemLayout = {
//   labelCol: { span: 6 },
//   wrapperCol: { span: 18 }
// };

// const alignParams = ['left', 'center', 'right'];
// const styleParams = [
//   { type: 'Weight', value: '700', icon: 'bold' },
//   { type: 'Style', value: 'italic', icon: 'italic' },
//   { type: 'Decoration', value: 'underline', icon: 'underline' }
// ];

// class TemplateEditModal extends React.Component {
//   state = {
//     canvas: null,
//     rectParams: [],
//     docWidth: 900,
//     docHeight: 600,
//     handActive: false,
//     rulerActive: false,
//     activeSelection: null,
//     startVerticalLineIndex: 0,
//     startHorizontalLineIndex: 0,
//     verticalLines: [],
//     horizontalLines: [],
//     currentUnit: 'cm',
//     closestLine: null,
//     prevScrollX: 0,
//     prevScrollY: 0,
//     downloadPdf: false,
//     currentVerticalHelpLine: null,
//     currentHorizontalHelpLine: null,
//     scale: { title: '100%', value: 1 },
//   };

//   constructor(props) {
//     super(props);
//     this.canvas = React.createRef();
//     this.removeRect = null;
//     this.getObjects = null;
//     this.pdfWrapper = React.createRef();
//     this.helperLineDiv = React.createRef();
//     this.lilLine = null;
//     this.lilLine1 = null;
//   }

//   // eslint-disable-next-line no-unused-vars
//   componentDidUpdate(prevProps, prevState) {
//     const {
//       scale,
//       canvas,
//       docWidth,
//       docHeight,
//       rulerActive,
//       closestLine,
//       downloadPdf,
//       verticalLines,
//       horizontalLines,
//       currentVerticalHelpLine,
//       currentHorizontalHelpLine,
//     } = this.state;

//     if (prevState.scale.value !== scale.value) {
//       const canvasObjects = canvas?.getObjects();
//       canvasObjects.forEach(object => {
//         if (object.id) {
//           if (!object.font) {
//             object.scaleX = object.scaleX / prevState.scale.value * scale.value;
//             object.scaleY = object.scaleY / prevState.scale.value * scale.value;
//           } else {
//             object.scale(scale.value);
//           }

//           object.left = object.left / prevState.scale.value * scale.value;
//           object.top = object.top / prevState.scale.value * scale.value;

//           object.setCoords();
//         }
//       });
//     }
//     if (prevState.rulerActive !== rulerActive && rulerActive) {
//       this.ruler.resize();
//     }
//     if (!_.isEqual(prevState.verticalLines, verticalLines)) {
//       if (currentVerticalHelpLine.left < docWidth / 2) {
//         if (verticalLines.length > 1 && currentVerticalHelpLine.left < (docWidth - closestLine + 10) && currentVerticalHelpLine.left > (docWidth - closestLine - 10) && !this.lilLine && !this.lilLine1) {
//           this.lilLine = new fabric.Line([0, 20, (docWidth - closestLine), 20], helperLilLineOpts);
//           this.lilLine1 = new fabric.Line([closestLine, 20, docWidth, 20], helperLilLineOpts);
//           currentVerticalHelpLine.left = (docWidth - closestLine);
//           canvas.add(this.lilLine);
//           canvas.add(this.lilLine1);
//         } else if (!(verticalLines.length > 1 && currentVerticalHelpLine.left < (docWidth - closestLine + 10) && currentVerticalHelpLine.left > (docWidth - closestLine - 10)) && this.lilLine && this.lilLine1) {
//           canvas.remove(this.lilLine);
//           this.lilLine = null;
//           canvas.remove(this.lilLine1);
//           this.lilLine1 = null;
//         }
//       } else if (verticalLines.length > 1 && (docWidth - currentVerticalHelpLine.left) < (closestLine + 10) && (docWidth - currentVerticalHelpLine.left) > (closestLine - 10) && !this.lilLine && !this.lilLine1) {
//         this.lilLine = new fabric.Line([0, 20, closestLine, 20], helperLilLineOpts);
//         this.lilLine1 = new fabric.Line([currentVerticalHelpLine.left, 20, docWidth, 20], helperLilLineOpts);
//         currentVerticalHelpLine.right = (docWidth - closestLine);
//         canvas.add(this.lilLine);
//         canvas.add(this.lilLine1);
//       } else if (!(verticalLines.length > 1 && (docWidth - currentVerticalHelpLine.left) < (closestLine + 10) && (docWidth - currentVerticalHelpLine.left) > (closestLine - 10) && !this.lilLine && !this.lilLine1) && this.lilLine && this.lilLine1) {
//         canvas.remove(this.lilLine);
//         this.lilLine = null;
//         canvas.remove(this.lilLine1);
//         this.lilLine1 = null;
//       }
//     }
//     if (!_.isEqual(prevState.horizontalLines, horizontalLines)) {
//       if (currentHorizontalHelpLine.top < docHeight / 2) {
//         if (horizontalLines.length > 1 && currentHorizontalHelpLine.top < (docHeight - closestLine + 10) && currentHorizontalHelpLine.top > (docHeight - closestLine - 10) && !this.lilLine && !this.lilLine1) {
//           this.lilLine = new fabric.Line([20, 0, 20, (docHeight - closestLine)], helperLilLineOpts);
//           this.lilLine1 = new fabric.Line([20, closestLine, 20, docHeight], helperLilLineOpts);
//           currentHorizontalHelpLine.top = (docHeight - closestLine);
//           canvas.add(this.lilLine);
//           canvas.add(this.lilLine1);
//         } else if (!(horizontalLines.length > 1 && currentHorizontalHelpLine.top < (docHeight - closestLine + 10) && currentHorizontalHelpLine.top > (docHeight - closestLine - 10)) && this.lilLine && this.lilLine1) {
//           canvas.remove(this.lilLine);
//           this.lilLine = null;
//           canvas.remove(this.lilLine1);
//           this.lilLine1 = null;
//         }
//       } else {
//         if (horizontalLines.length > 1 && (docHeight - currentHorizontalHelpLine.top) < (closestLine + 10) && (docHeight - currentHorizontalHelpLine.top) > (closestLine - 10) && !this.lilLine && !this.lilLine1) {
//           this.lilLine = new fabric.Line([20, 0, 20, closestLine], helperLilLineOpts);
//           this.lilLine1 = new fabric.Line([20, currentHorizontalHelpLine.top, 20, docHeight], helperLilLineOpts);
//           currentHorizontalHelpLine.top = (docHeight - closestLine);
//           canvas.add(this.lilLine);
//           canvas.add(this.lilLine1);
//         } else if (!(verticalLines.length > 1 && (docHeight - currentHorizontalHelpLine.top) < (closestLine + 10) && (docHeight - currentHorizontalHelpLine.top) > (closestLine - 10) && !this.lilLine && !this.lilLine1) && this.lilLine && this.lilLine1) {
//           canvas.remove(this.lilLine);
//           this.lilLine = null;
//           canvas.remove(this.lilLine1);
//           this.lilLine1 = null;
//         }
//       }
//     }

//     if (prevState.scale.value === scale.value && downloadPdf) {
//       this.saveTemplate();
//     }


//   }

//   setCanvas = canvas => {
//     this.setState({ canvas });
//   };


//   onPdfLoad = page => {
//     const { scale, downloadPdf } = this.state;
//     const {
//       originalWidth, originalHeight, _pageInfo: { rotate }
//     } = page;

//     console.log(originalWidth, originalHeight, 'load pdf')

//     const width = originalWidth * scale.value;
//     const height = originalHeight * scale.value;

//     let docWidth = width;
//     let docHeight = height;

//     this.setState({ docWidth, docHeight });

//     if (downloadPdf) {
//       this.saveTemplate();
//     }
//   };

//   addActiveSelection = () => {
//     const { canvas } = this.state;
//     const activeSelectionEvents = [
//       'object:moved', 'object:scaling', 'object:modified', 'selection:created', 'selection:updated'
//     ];

//     activeSelectionEvents.forEach(event => {
//       canvas.on(event, e => {
//         if (e.target.type !== 'line') {
//           let anglePath;
//           let coordsPath;
//           if (e.selected && e.selected.length) {
//             anglePath = e.selected[0].angle || 0;
//             coordsPath = e.selected[0].aCoords;
//           } else {
//             anglePath = e.transform.target.angle;
//             coordsPath = e.transform.target.aCoords;
//           }
//           if (anglePath >= 0 && anglePath <= 90) {
//             const horizontalSelectionWidth = coordsPath.tr.x - coordsPath.bl.x;
//             const verticalSelectionHeight = coordsPath.br.y - coordsPath.tl.y;
//             this.setState({
//               activeSelection: {
//                 x: coordsPath.bl.x,
//                 y: coordsPath.tl.y,
//                 width: horizontalSelectionWidth,
//                 height: verticalSelectionHeight
//               }
//             });
//           } else if (anglePath >= 90 && anglePath <= 180) {
//             const horizontalSelectionWidth = coordsPath.tl.x - coordsPath.br.x;
//             const verticalSelectionHeight = coordsPath.tr.y - coordsPath.bl.y;
//             this.setState({
//               activeSelection: {
//                 x: coordsPath.br.x,
//                 y: coordsPath.bl.y,
//                 width: horizontalSelectionWidth,
//                 height: verticalSelectionHeight
//               }
//             });
//           } else if (anglePath >= 180 && anglePath <= 270) {
//             const horizontalSelectionWidth = coordsPath.bl.x - coordsPath.tr.x;
//             const verticalSelectionHeight = coordsPath.tl.y - coordsPath.br.y;
//             this.setState({
//               activeSelection: {
//                 x: coordsPath.tr.x,
//                 y: coordsPath.br.y,
//                 width: horizontalSelectionWidth,
//                 height: verticalSelectionHeight
//               }
//             });
//           } else if (anglePath >= 270 && anglePath <= 360) {
//             const horizontalSelectionWidth = coordsPath.br.x - coordsPath.tl.x;
//             const verticalSelectionHeight = coordsPath.bl.y - coordsPath.tr.y;
//             this.setState({
//               activeSelection: {
//                 x: coordsPath.tl.x,
//                 y: coordsPath.tr.y,
//                 width: horizontalSelectionWidth,
//                 height: verticalSelectionHeight
//               }
//             });
//           }
//         }
//       });
//     });

//     canvas.on('selection:cleared', () => {
//       this.setState({
//         activeSelection: null
//       });
//     });
//   };

//   addText = () => {
//     const { rectParams, canvas } = this.state;
//     const textRect = new TextBox.Class(...TextBox.args);

//     if (!rectParams.length) {
//       this.addActiveSelection();
//     }

//     textRect.on('rotating', e => {
//       if (e.transform.target.angle >= 0 && e.transform.target.angle <= 90) {
//         const horizontalSelectionWidth = e.transform.target.aCoords.tr.x - e.transform.target.aCoords.bl.x;
//         const verticalSelectionHeight = e.transform.target.aCoords.br.y - e.transform.target.aCoords.tl.y;
//         this.setState({
//           activeSelection: {
//             x: e.transform.target.aCoords.bl.x,
//             y: e.transform.target.aCoords.tl.y,
//             width: horizontalSelectionWidth,
//             height: verticalSelectionHeight
//           }
//         });
//       } else if (e.transform.target.angle >= 90 && e.transform.target.angle <= 180) {
//         const horizontalSelectionWidth = e.transform.target.aCoords.tl.x - e.transform.target.aCoords.br.x;
//         const verticalSelectionHeight = e.transform.target.aCoords.tr.y - e.transform.target.aCoords.bl.y;
//         this.setState({
//           activeSelection: {
//             x: e.transform.target.aCoords.br.x,
//             y: e.transform.target.aCoords.bl.y,
//             width: horizontalSelectionWidth,
//             height: verticalSelectionHeight
//           }
//         });
//       } else if (e.transform.target.angle >= 180 && e.transform.target.angle <= 270) {
//         const horizontalSelectionWidth = e.transform.target.aCoords.bl.x - e.transform.target.aCoords.tr.x;
//         const verticalSelectionHeight = e.transform.target.aCoords.tl.y - e.transform.target.aCoords.br.y;
//         this.setState({
//           activeSelection: {
//             x: e.transform.target.aCoords.tr.x,
//             y: e.transform.target.aCoords.br.y,
//             width: horizontalSelectionWidth,
//             height: verticalSelectionHeight
//           }
//         });
//       } else if (e.transform.target.angle >= 270 && e.transform.target.angle <= 360) {
//         const horizontalSelectionWidth = e.transform.target.aCoords.br.x - e.transform.target.aCoords.tl.x;
//         const verticalSelectionHeight = e.transform.target.aCoords.bl.y - e.transform.target.aCoords.tr.y;
//         this.setState({
//           activeSelection: {
//             x: e.transform.target.aCoords.tl.x,
//             y: e.transform.target.aCoords.tr.y,
//             width: horizontalSelectionWidth,
//             height: verticalSelectionHeight
//           }
//         });
//       }
//     });

//     canvas.add(textRect);

//     const text = `Text${rectParams.length + 1}`;
//     const id = Math.random();

//     textRect.text = text;
//     textRect.id = id;

//     rectParams.push({
//       id,
//       title: text,
//       color: '#000',
//       font: 'Times new Roman',
//       size: 40,
//       align: 'left',
//     });

//     this.setState({ rectParams });
//   };

//   getRectParamAndObject = (id, rectParams, canvas) => {
//     const canvasObjects = canvas?.getObjects();
//     const rectParam = rectParams.find(param => param.id === id);
//     const rectObject = canvasObjects?.find(object => object.id === id);

//     return { rectParam, rectObject };
//   };

//   onFontChange = (id, value) => {
//     const { rectParams, canvas } = this.state;
//     const { rectParam, rectObject } = this.getRectParamAndObject(id, rectParams, canvas);

//     rectParam.font = value;
//     rectObject.fontFamily = value;
//     canvas.requestRenderAll();

//     this.setState({ rectParams });
//   };

//   onFontSizeChange = (id, value) => {
//     const { rectParams, canvas } = this.state;
//     const { rectParam, rectObject } = this.getRectParamAndObject(id, rectParams, canvas);

//     rectParam.size = value;
//     rectObject.fontSize = value;
//     canvas.requestRenderAll();

//     this.setState({ rectParams });
//   };

//   onStyleChange = (id, type, value) => {
//     const { rectParams, canvas } = this.state;
//     const { rectParam, rectObject } = this.getRectParamAndObject(id, rectParams, canvas);

//     rectParam[value] = !rectParam[value];

//     rectObject[`font${type}`] = rectParam[value] ? value : '';
//     canvas.requestRenderAll();

//     this.setState({ rectParams });
//   };

//   onAlignChange = (id, value) => {
//     const { rectParams, canvas } = this.state;
//     const { rectParam, rectObject } = this.getRectParamAndObject(id, rectParams, canvas);

//     rectParam.align = value;
//     rectObject.textAlign = value;
//     canvas.requestRenderAll();

//     this.setState({ rectParams });
//   };

//   onInputChange = (id, value) => {
//     const { rectParams } = this.state;
//     const { rectParam } = this.getRectParamAndObject(id, rectParams);

//     rectParam.title = value;

//     this.setState({ rectParams });
//   };

//   onColorChange = (id, { hex: color }) => {
//     const { rectParams, canvas } = this.state;
//     const { rectParam, rectObject } = this.getRectParamAndObject(id, rectParams, canvas);

//     rectParam.color = color;
//     rectObject.set('fill', color);
//     canvas.requestRenderAll();

//     this.setState({ rectParams });
//   };

  // uploadImage = ({ file }) => {
  //   const reader = new FileReader();
  //   const { rectParams } = this.state;
  //   const { rects, setRects } = this.props;

  //   if (!rectParams.length) {
  //     this.addActiveSelection();
  //   }

  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     const id = Math.random();

  //     rectParams.push({
  //       id,
  //       title: file.name
  //     });
  //     rects.push(canvasImage(reader.result, id));
  //     setRects(rects);

  //     this.setState({ rectParams });
  //   };
  // };

//   onMouseMove = e => {
//     const { clientX, clientY } = e;
//     const { prevScrollX, prevScrollY } = this.state;
//     this.pdfWrapper.current.scrollTop -= clientY - prevScrollY;
//     this.pdfWrapper.current.scrollLeft -= clientX - prevScrollX;

//     this.setState({ prevScrollX: clientX, prevScrollY: clientY });
//   };

//   onHandMouseUp = () => {
//     if (this.pdfWrapper.current) {
//       window.removeEventListener('mousemove', this.onMouseMove);
//     }
//   };

//   onHandMouseDown = ({ clientX, clientY }) => {
//     this.setState({ prevScrollX: clientX, prevScrollY: clientY });

//     window.addEventListener('mousemove', this.onMouseMove);
//   };

//   onHandClick = () => {
//     const { handActive } = this.state;
//     const { current } = this.pdfWrapper;
//     if (!handActive) {
//       current.addEventListener('mousedown', this.onHandMouseDown);
//       window.addEventListener('mouseup', this.onHandMouseUp);
//     } else {
//       current.removeEventListener('mousedown', this.onHandMouseDown);
//       window.removeEventListener('mouseup', this.onHandMouseUp);
//     }

//     this.setState({ handActive: !handActive });
//   };

//   onRulerClick = () => {
//     const { rulerActive } = this.state;
//     this.setState({ rulerActive: !rulerActive });
//   };

//   addVerticalLine = () => {
//     const {
//       canvas, docWidth, docHeight, startVerticalLineIndex, verticalLines
//     } = this.state;
//     const line = new fabric.Line([docWidth / 2, 0, docWidth / 2, docHeight], helperLineOpts);
//     line.index = `${startVerticalLineIndex}v`;
//     verticalLines.push(docWidth / 2);
//     this.setState({
//       startVerticalLineIndex: startVerticalLineIndex + 1,
//       verticalLines,
//     }, () => {
//       canvas.add(line);
//       canvas.on('object:moving', e => {
//         if (e.target.type === 'line') {
//           if (e.target.index[e.target.index.length - 1] === 'v') {
//             const { currentVerticalHelpLine } = this.state;
//             const verticalLinesCopy = _.cloneDeep(verticalLines);
//             verticalLinesCopy[+e.target.index[0]] = e.target.left;

//             if (!currentVerticalHelpLine) {
//               this.setState({
//                 verticalLines: verticalLinesCopy,
//                 currentVerticalHelpLine: e.target
//               });
//             } else {
//               const closestLine = verticalLinesCopy.reduce((prev, curr, i) => ((Math.abs((docWidth - curr) - e.target.left) < Math.abs((docWidth - prev) - e.target.left) && i !== +e.target.index[0]) ? curr : prev));
//               this.setState({
//                 verticalLines: verticalLinesCopy,
//                 closestLine
//               });
//             }
//           }
//         }
//       });
//       canvas.on('object:moved', () => {
//         canvas.remove(this.lilLine);
//         this.lilLine = null;
//         canvas.remove(this.lilLine1);
//         this.lilLine1 = null;
//         this.setState({
//           currentVerticalHelpLine: null
//         });
//       });
//     });
//   };

//   addHorizontalLine = () => {
//     const {
//       canvas, docWidth, docHeight, startHorizontalLineIndex, horizontalLines, verticalLines
//     } = this.state;
//     const line = new fabric.Line([0, docHeight / 2, docWidth, docHeight / 2], helperHorizontalLineOpts);
//     line.index = `${startHorizontalLineIndex}h`;
//     horizontalLines.push(docHeight / 2);
//     this.setState({
//       startHorizontalLineIndex: startHorizontalLineIndex + 1,
//       horizontalLines,
//     }, () => {
//       canvas.add(line);
//       canvas.on('object:moving', e => {
//         if (e.target.type === 'line') {
//           if (e.target.index[e.target.index.length - 1] === 'h') {
//             const { currentHorizontalHelpLine } = this.state;
//             const horizontalLinesCopy = _.cloneDeep(horizontalLines);
//             horizontalLinesCopy[+e.target.index[0]] = e.target.top;

//             if (!currentHorizontalHelpLine) {
//               this.setState({
//                 horizontalLines: horizontalLinesCopy,
//                 currentHorizontalHelpLine: e.target
//               });
//             } else {
//               const closestLine = horizontalLinesCopy.reduce((prev, curr, i) => ((Math.abs((docHeight - curr) - e.target.top) < Math.abs((docHeight - prev) - e.target.top) && i !== +e.target.index[0]) ? curr : prev));
//               this.setState({
//                 horizontalLines: horizontalLinesCopy,
//                 closestLine
//               });
//             }
//           }
//         }
//       });
//       canvas.on('object:moved', () => {
//         canvas.remove(this.lilLine);
//         this.lilLine = null;
//         canvas.remove(this.lilLine1);
//         this.lilLine1 = null;
//         this.setState({
//           currentHorizontalHelpLine: null
//         });
//       });
//     });
//   }

//   moveHelperLineDiv = (e, index) => {
//     const {
//       canvas, verticalLines, currentVerticalHelpLine, docWidth
//     } = this.state;
//     e.preventDefault(); // предотвратить запуск выделения (действие браузера)
//     e.persist();

//     const onMouseMove = event => {
//       const shiftX = event.clientX - this.pdfWrapper.current.getBoundingClientRect().left;
//       const verticalLinesCopy = _.cloneDeep(verticalLines);
//       const currentVerticalLine = canvas._objects.find(obj => obj.index === `${index}v`);
//       const newLeft = shiftX - 161;
//       currentVerticalLine.left = newLeft;
//       verticalLinesCopy[index] = newLeft;

//       // this.setState({
//       //   verticalLines: verticalLinesCopy,
//       //   currentVerticalHelpLine: currentVerticalLine
//       // })

//       if (!currentVerticalHelpLine) {
//         this.setState({
//           verticalLines: verticalLinesCopy,
//           currentVerticalHelpLine: currentVerticalLine
//         });
//       } else {
//         verticalLinesCopy.reduce((prev, curr, i) => (
//           (Math.abs((docWidth - curr) - currentVerticalLine.left) < Math.abs((docWidth - prev) - currentVerticalLine.left) && i !== +currentVerticalLine.index[0])
//             ? curr : prev
//         ));
//       }
//     };

//     const onMouseUp = () => {
//       document.removeEventListener('mouseup', onMouseUp);
//       document.removeEventListener('mousemove', onMouseMove);
//     };

//     document.addEventListener('mousemove', onMouseMove);
//     document.addEventListener('mouseup', onMouseUp);
//   };

//   changeUnit = unitValue => {
//     this.setState({ currentUnit: unitValue });
//   };

//   changeScale = value => {
//     this.setState({ scale: value });
//   };

//   getCollapseHeader = (title, id) => (
//     <div className="fields-wrapper">
//       <Input
//         value={title}
//         onClick={e => e.stopPropagation()}
//         onChange={e => this.onInputChange(id, e.target.value)}
//       />
//       <CloseOutlined onClick={() => this.deleteRect(id)} />
//     </div>
//   );

//   deleteRect = id => {
//     const { rectParams, canvas } = this.state;
//     const { rectObject } = this.getRectParamAndObject(id, rectParams, canvas);
//     const newRectParam = rectParams.filter(param => param.id !== id);

//     canvas.remove(rectObject);

//     this.setState({ rectParams: newRectParam });
//   };

//   getPdf = async () => {
//     const { canvas } = this.state;
//     const { meta } = this.props;
//     const pdfCanvas = document.querySelector('.pdf-doc-canvas canvas');
//     let representations2D = TreePack.unpack(JSON.parse(meta.data), meta.scaling).representations2d;
//     const {size} = PdfRepresentationHelper.mapToPdfRepresentation(representations2D, meta.meterage); 
    
//     let pdf = await PDFDocument.create()

//     const canvasObjects = canvas?.getObjects();

//     canvasObjects.forEach(object => {
//       if (!object.id) {
//         canvas.remove(object);
//       }
//     });

//     const imgData = canvas.toDataURL('image/png', 1.0);
//     const imageBackground = pdfCanvas.toDataURL('image/png', 1.0);

//     const embImgData = await pdf.embedPng(imgData)
//     const embImgBack = await pdf.embedPng(imageBackground)

//     const page = pdf.addPage(size);

//     page.drawImage(embImgBack, {
//       x: 0,
//       y: 0,
//       width: size[0],
//       height: size[1]
//     });

//     page.drawImage(embImgData, {
//       x: 0,
//       y: 0,
//       width: size[0],
//       height: size[1]
//     });



//     pdf.setAuthor(JSON.stringify({meta, size}));

//     return pdf;
//   };

//   getJpeg = () => {
//     const { canvas } = this.state;
//     const imgData = canvas.toDataURL('image/jpeg', 1.0);

//     return imgData;
//   }

//   saveByteArray = function(reportName, byte) {
//     var blob = new Blob([byte], {type: "application/pdf"});
//     var link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     var fileName = reportName;
//     link.download = fileName;
//     link.click();
//   };


//   saveTemplate = async() => {
//     const {meta} = this.props;
//     const pdf = await this.getPdf();
//     const resullt = await pdf.save();
//     this.saveByteArray(meta.name, resullt);

//     this.setState({ downloadPdf: false });
//   };

//   updateModel = async () => {
//     const { update, file } = this.props;
//     const pdf = await this.getPdf();
//     const savedPdf = await pdf.save();
    
//     update(true, new Blob([savedPdf], {type: "application/pdf"}))
//   };

//   onDragEnd = (fromIndex, toIndex) => {
//     const { rectParams, canvas } = this.state;

//     if (toIndex < 0) return;

//     const changeItemPosition = items => {
//       const item = items.splice(fromIndex, 1)[0];
//       items.splice(toIndex, 0, item);
//     };

//     const newRectParams = [...rectParams];
//     changeItemPosition(newRectParams);
//     changeItemPosition(canvas._objects);

//     this.setState({ rectParams: newRectParams });
//   };

//   render() {
//     const {
//       rects, file, upload, update
//     } = this.props;
//     const {
//       scale,
//       docWidth,
//       docHeight,
//       rectParams,
//       handActive,
//       currentUnit,
//       rulerActive,
//       verticalLines,
//       horizontalLines,
//       activeSelection,
//     } = this.state;
//     const units = ['mm', 'cm', 'm'];
//     const scales = [
//       { title: '25%', value: 0.25 },
//       { title: '50%', value: 0.5 },
//       { title: '75%', value: 0.75 },
//       { title: '100%', value: 1 },
//       { title: '125%', value: 1.25 },
//       { title: '150%', value: 1.5 },
//       { title: '175%', value: 1.75 },
//       { title: '200%', value: 2 },
//     ];

//     const addRectButton = (
//       <>
//         <Button className="tool-button" type="primary" ghost onClick={this.addText}>
//           Add Text
//         </Button>
//       </>
//     );

//     const uploadButton = (
//       <>
//         <Upload customRequest={this.uploadImage} fileList={[]}>
//           <Button className="tool-button" type="primary" ghost>
//             Add Image
//           </Button>
//         </Upload>
//       </>
//     );
//     return (
//       <div className="editor-container">
//         {file ? (
//           <div className="editor-wrapper">
//             <div className="editor">
//               <div className="size-container">
//                 <div className="size-info">
//                   <p className="size-value">
//                     <span className="size">W </span>
//                     {roundUnit(docWidth / scale.value, currentUnit)}
//                   </p>
//                   <p className="size-value">
//                     <span className="size">H </span>
//                     {roundUnit(docHeight / scale.value, currentUnit)}
//                   </p>
//                 </div>
//                 <SelectUnits
//                   currentUnit={currentUnit}
//                   units={units}
//                   changeUnit={this.changeUnit}
//                 />
//               </div>
//               <div className="editor-main">
//                 {!!rulerActive && (
//                   <div className="ruler-container-horizontal" style={{ width: '100%' }}>
//                     <div style={{ width: '100%' }}>
//                       <CustomizedRuler
//                         zoom={scale.value}
//                         unitType={currentUnit}
//                         activeSelection={activeSelection}
//                         size={docWidth}
//                         type="horizontal"
//                         setRef={e => { this.ruler = e; }}
//                       />
//                     </div>
//                   </div>
//                 )}
//                 {!!rulerActive && (
//                   <div id="verticalRuler" className="ruler-container-vertical" style={{ height: '100%' }}>
//                     <div style={{ height: '100%', width: 24 }}>
//                       <CustomizedRuler
//                         zoom={scale.value}
//                         unitType={currentUnit}
//                         activeSelection={activeSelection}
//                         size={docHeight}
//                         type="vertical"
//                         setRef={e => { this.ruler1 = e; }}
//                       />
//                     </div>
//                   </div>
//                 )}
//                 <div className="scale-container">
//                   <SelectScale
//                     currentScale={scale}
//                     scales={scales}
//                     changeScale={this.changeScale}
//                   />
//                 </div>
//                 <div className="canvas-wrapper" ref={this.pdfWrapper}>
//                   {verticalLines.map((position, index) => (
//                     <div
//                       aria-hidden="true"
//                       ref={this.helperLineDiv}
//                       onMouseDown={e => this.moveHelperLineDiv(e, index)}
//                       style={{
//                         position: 'absolute',
//                         left: position + 262,
//                         height: 'calc(100% + 48px)',
//                         top: -24,
//                         width: 2,
//                         background: '#00C0FF',
//                         cursor: 'ew-resize'
//                       }}
//                     />
//                   ))}
//                   {horizontalLines.map((position, index) => (
//                     <div
//                       aria-hidden="true"
//                       ref={this.helperLineDiv}
//                       onMouseDown={e => this.moveHelperLineDiv(e, index)}
//                       style={{
//                         position: 'absolute',
//                         top: position + 24,
//                         width: 'calc(100% + 24px)',
//                         left: -24,
//                         height: 2,
//                         background: '#00C0FF',
//                         cursor: 'ns-resize'
//                       }}
//                     />
//                   ))}
//                   <div
//                     className="canvas"
//                     style={{ width: docWidth }}
//                   >
//                     <div style={{ width: docWidth }}>
//                       <Document file={file}>
//                         <Page
//                           pageNumber={1}
//                           onRenderSuccess={this.onPdfLoad}
//                           className="pdf-doc-canvas"
//                           renderTextLayer={false}
//                           scale={scale.value}
//                         >
//                           <div id="editorCanvas" className="editor-canvas">
//                             <CanvasDemo
//                               style={{ width: docWidth, height: docHeight }}
//                               setCanvas={this.setCanvas}
//                               setRemove={removeRect => {
//                                 this.removeRect = removeRect;
//                               }}
//                               setGetObjects={getObjects => {
//                                 this.getObjects = getObjects;
//                               }}
//                               rects={rects}
//                             />
//                           </div>
//                         </Page>
//                       </Document>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="tools">
//               <div className="editor-tools-draggable">
//                 <ReactDragListView
//                   nodeSelector=".draggble"
//                   onDragEnd={this.onDragEnd}
//                 >
//                   <List
//                     size="small"
//                     bordered
//                     dataSource={rectParams}
//                     renderItem={item => (
//                       <Collapse className="draggble">
//                         <Panel header={this.getCollapseHeader(item.title, item.id)} key={item.id}>
//                           {item.font && (
//                             <Form layout="vertical" labelAlign="right" className="attributes-form">
//                               <Form.Item className="attributes-item" label="Font:" {...formItemLayout}>
//                                 <SelectFont
//                                   items={fonts}
//                                   onSelectChange={value => this.onFontChange(item.id, value)}
//                                   value={item.font}
//                                 />
//                               </Form.Item>
//                               <Form.Item className="attributes-item" label="Size:" {...formItemLayout}>
//                                 <SelectFontSizes
//                                   items={fontSizes}
//                                   onSelectChange={value => this.onFontSizeChange(item.id, value)}
//                                   value={item.size}
//                                 />
//                               </Form.Item>
//                               <Form.Item className="attributes-item" label="Align:" {...formItemLayout}>
//                                 {alignParams.map(align => (
//                                   <AlignButton
//                                     align={align}
//                                     key={align}
//                                     value={item.align}
//                                     onAlignChange={() => this.onAlignChange(item.id, align)}
//                                   />
//                                 ))}
//                               </Form.Item>
//                               <Form.Item className="attributes-item" label="Style: " {...formItemLayout}>
//                                 {styleParams.map(({ type, value, icon }) => (
//                                   <StyleButton
//                                     icon={icon}
//                                     key={icon}
//                                     active={item[value] || ''}
//                                     onStyleChange={() => this.onStyleChange(item.id, type, value)}
//                                   />
//                                 ))}
//                               </Form.Item>
//                               <Form.Item className="attributes-item" label="Color: " {...formItemLayout}>
//                                 <ChromePicker color={item.color} onChange={color => this.onColorChange(item.id, color)} />
//                               </Form.Item>
//                             </Form>
//                           )}
//                         </Panel>
//                       </Collapse>
//                     )}
//                   />
//                 </ReactDragListView>
//                 <div className="tool-buttons">
//                   {addRectButton}
//                   {uploadButton}
//                   <Button className="tool-button" type="primary" ghost={!handActive} onClick={this.onHandClick}>
//                     Hand
//                   </Button>
//                   <Button className="tool-button" type="primary" ghost={!rulerActive} onClick={this.onRulerClick}>
//                     Ruler
//                   </Button>
//                   <Button
//                     className="tool-button"
//                     type="primary"
//                     ghost
//                     onClick={() => {
//                       this.setState({
//                         scale: { title: '100%', value: 1 },
//                         downloadPdf: true,
//                       });
//                     }}
//                   >
//                     Save Template
//                   </Button>
//                   <Button
//                     className="tool-button"
//                     type="primary"
//                     ghost
//                     onClick={this.updateModel}
//                   >
//                     Update model
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : upload
//         }
//       </div>
//     );
//   }
// }

// export default TemplateEditModal;
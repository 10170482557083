"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveObject = void 0;
var command_1 = require("./command");
var treeNode_1 = require("../core/unpack/treeNode");
var lodash_1 = require("lodash");
var RemoveObject = /** @class */ (function (_super) {
    __extends(RemoveObject, _super);
    function RemoveObject(nodes, parentId, deletedNodeId) {
        var _this = _super.call(this, nodes) || this;
        _this._deletedNodeId = deletedNodeId;
        _this._parentId = parentId;
        return _this;
    }
    RemoveObject.prototype.action = function () {
        var _this = this;
        var parentNode = this.nodes.find(function (node) { return node.id === _this._parentId; });
        console.log("PARENTNODE::", parentNode);
        if (!!parentNode) {
            var deletedNode = this.nodes.find(function (node) { return node.id === _this._deletedNodeId; });
            treeNode_1.TreeNodeUtils.removeChild(this.nodes, deletedNode);
            if (parentNode.childs) {
                lodash_1.remove(parentNode.childs, function (child) { return child.childId == _this._deletedNodeId; });
            }
            console.log(this.nodes);
        }
    };
    return RemoveObject;
}(command_1.Command));
exports.RemoveObject = RemoveObject;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Petal = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var Petal = /** @class */ (function (_super) {
    __extends(Petal, _super);
    function Petal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Petal.prototype.getConfigProperties = function () {
        return {
            width: 1,
            leftHeight: 0.9,
            rightHeight: 1.5,
        };
    };
    Petal.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 2, 3];
    };
    Petal.prototype.calculateLines = function () {
        var coords = this.config.options;
        var width = coords.width;
        var leftHeight = coords.leftHeight;
        var rightHeight = coords.rightHeight;
        var aPoint = new three_1.Vector3(width / 2, 0);
        var bPoint = new three_1.Vector3(-width / 2, 0);
        var cPoint = new three_1.Vector3(-width / 2, leftHeight);
        var dPoint = new three_1.Vector3(width / 2, rightHeight);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, aPoint]),
        ];
    };
    return Petal;
}(figureBase_1.FigureBase));
exports.Petal = Petal;

/* eslint-disable import/prefer-default-export,react/prop-types */
import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import withStyles from '@material-ui/core/styles/withStyles';
import { selectStyle } from '../../styles/Select';
import {materialTheme} from '../../materialTheme'

const { palette } = materialTheme

const styles = () => ({
  select: {
    border: `2px solid ${palette.blue.main}`,
    background: '#F7FCFE',
    borderRadius: 10,
    width: props => props.styles.width || 'auto',
    height: 48,
    color: palette.primary.main,
    fontFamily: palette.fontFamily,
    '&:hover': {
      borderColor: props => props.disabled ? palette.blue.main : palette.secondary.main,
    },
    '& > svg': {
      marginRight: 10,
      fontSize: 24,
      color: palette.primary.main
    },
    '& .MuiInputBase-root.Mui-disabled': {
      border: '2px solid #FBFDFF',
      color: '#959FB3',
      '&:hover': {
        borderColor: '#FBFDFF',
      },
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 36,
    }
  },
  ...selectStyle(palette),
  selectNoFile: {
    '&:after': {
      content: "' '",
      borderRadius: 10,
      left: -2,
      position: 'absolute',
      background: '#F2FAFF',
      height: 48,
      minWidth: 94,
      opacity: 0.5,
    }
  },
  selectMenu: {
    boxSizing: 'border-box',
    background: 'transparent',
    padding: 12,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      width: '100%',
      background: 'transparent'
    }
  },
  menuParams: {
    height: props => props.styles.height || 'auto',
    width: props => props.styles.width || 'auto',
  }
});

class EditorSelect extends React.Component {
  state = {
    open: false,
    topPosition: 0,
    leftPosition: 0
  }

  changeOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
    this.onRefValueChange();
  };

  setRef = ref => {
    this.ref = ref;
  }

  onRefValueChange = () => {
    const topPosition = this.ref.getBoundingClientRect().top + 60;
    const leftPosition = this.ref.getBoundingClientRect().left;
    this.setState({ topPosition, leftPosition });
  };

  render() {
    const {
      currentValue, classes, values, disabled, changeValue
    } = this.props;
    const { open, leftPosition, topPosition } = this.state;
    return (
      <Select
        disabled={disabled}
        ref={this.setRef}
        onOpen={this.changeOpen}
        onClose={this.changeOpen}
        open={open}
        onChange={e => changeValue(e.target.value)}
        MenuProps={{
          anchorReference: 'anchorPosition',
          anchorPosition: { top: topPosition, left: leftPosition },
          MenuListProps: {
            classes: {
              root: `${classes.selectMenuWrapper} ${classes.menuParams}`
            }
          },
          PopoverClasses: {
            paper: classes.popover,
          }
        }}
        classes={{ selectMenu: classes.selectMenu, select: classes.selectMenu }}
        IconComponent={ExpandMoreIcon}
        disableUnderline
        value={currentValue}
        className={open
          ? `${classes.select} ${classes.selectFocus}`
          : `${disabled && classes.selectNoFile} ${classes.select} ${classes.selectBorder}`
        }
      >
        {
          values.map(value => (
            <MenuItem
              key={value}
              classes={{ root: classes.selectDropDown, selected: classes.selectedDropdown }}
              value={value}
            >
              <Typography classes={{ root: classes.listItemText }}>
                {value}
              </Typography>
            </MenuItem>
          ))
        }
      </Select>
    );
  }
}

export default withStyles(styles)(EditorSelect);
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ark = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var arcFitter_1 = require("../../utils/arcFitter");
var Ark = /** @class */ (function (_super) {
    __extends(Ark, _super);
    function Ark() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Ark.prototype.calculateConnectionIndexes = function () {
        return [0];
    };
    Ark.prototype.isValid = function () {
        if (!_super.prototype.isValid.call(this))
            return false;
        var innerRadius = this.config.options.radiusInner;
        var outerRadius = this.config.options.radiusOuter;
        return outerRadius > innerRadius;
    };
    Ark.prototype.calculateHoleLines = function () {
        var radius = this.config.options.radiusInner;
        var aPoints = new three_1.Vector3(radius, 0, 0);
        var bPoints = new three_1.Vector3(-radius, 0, 0);
        var additionalPoints = arcFitter_1.ArcFitter.fitArcCircle(aPoints, bPoints, radius);
        return [[
                new line3_1.Line3(line3_1.LineType.Straight, [aPoints, bPoints]),
                new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPoints[0]),
                new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPoints[1])
            ]];
    };
    Ark.prototype.calculateLines = function () {
        var radius = this.config.options.radiusOuter;
        var aPoints = new three_1.Vector3(radius, 0, 0);
        var bPoints = new three_1.Vector3(-radius, 0, 0);
        var additionalPoints = arcFitter_1.ArcFitter.fitArcCircle(aPoints, bPoints, radius);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoints, bPoints]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPoints[0]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPoints[1]),
        ];
    };
    Ark.prototype.getConfigProperties = function () {
        return {
            radiusOuter: 0.5,
            radiusInner: 0.4,
        };
    };
    return Ark;
}(figureBase_1.FigureBase));
exports.Ark = Ark;

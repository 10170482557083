










import { Vue } from "vue-property-decorator";
import axios from "axios";
import TemplateRender from './TemplateRender.vue';
import Object3dModel from './Object3dModel.vue';
import Loader from "./Loader.vue";

let vm = Vue.component("ObjectMapper", {
  data: function() {
    return {
      typeModel: 'pdf',
      isLoaded: true
    };
  },
  computed: {
    isPdfModel: function(): boolean {
      return this.typeModel == 'pdf';
    }
  },
  mounted: async function() {
    this.isLoaded = true;
    await this.getTemplate();
    this.isLoaded = false;
  },
  methods: {
    getTemplate: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/shopify/template/${this.$route.params.templateId}`,
          { withCredentials: true }
        );

        this.typeModel = response.data.typeFile;
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    TemplateRender,
    Object3dModel,
    Loader
  }
});

export default vm;

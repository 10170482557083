"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TriangleWithHalfHole = void 0;
var triangle_1 = require("./triangle");
var CutHalfHoleHelper_1 = require("../../utils/CutHalfHoleHelper");
var three_1 = require("three");
var TriangleWithHalfHole = /** @class */ (function (_super) {
    __extends(TriangleWithHalfHole, _super);
    function TriangleWithHalfHole() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TriangleWithHalfHole.prototype.calculateHoleLines = function () {
        var centerPoint = new three_1.Vector3(0, 0);
        return [CutHalfHoleHelper_1.CutHalfHoleHelper.drawHalfHole(centerPoint, this.config.options)];
    };
    TriangleWithHalfHole.prototype.getConfigProperties = function () {
        return CutHalfHoleHelper_1.CutHalfHoleHelper.addProperties(_super.prototype.getConfigProperties.call(this));
    };
    return TriangleWithHalfHole;
}(triangle_1.Triangle));
exports.TriangleWithHalfHole = TriangleWithHalfHole;

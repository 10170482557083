











































import { Vue } from "vue-property-decorator";

let vm = Vue.component("ViewerSettings", {
  props: {
    value: String,
    opacity: {
      type: Number,
      default: 0.7
    },
    showBorders: {
      type: Boolean,
      default: true
    },
    showGrid: {
      type: Boolean,
      default: true
    },
    showAxes: {
      type: Boolean,
      default: true
    },
    isBackgroundTexture: {
      type: Boolean,
      default: false
    },
    backgroundImage: {
      type: String
    }
  },
  data: function() {
    return {
      meterages: [
        {
          text: 'in',
          value: 'in'
        },
        {
          text: 'ft',
          value: 'ft'
        },
        {
          text: 'cm',
          value: 'cm'
        },
        {
          text: 'mm',
          value: 'mm'
        }
      ],
      opacityData: 0.7,
      showBordersData: true,
      showGridData: true,
      showAxesData: true
    };
  },
  methods: {
    update: function() {
      this.$emit(
        "update",
        this.showBordersData,
        this.showGridData,
        Number(this.opacityData),
        this.showAxesData
      );
    },
    changeMeterage: function(newVal: string) {
      this.$emit('input', newVal);
    }
  },
  watch: {
    opacity: function(newVal) {
      this.opacityData = newVal;
    },
    showBorders: function(newVal) {
      this.showBordersData = newVal;
    },
    showGrid: function(newVal) {
      this.showGridData = newVal; 
    },
    showAxes: function(newVal) {
      this.showAxesData = newVal;
    }
  }
});

export default vm;

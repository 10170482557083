"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConcaveRectangle = void 0;
var rectangle_1 = require("./rectangle");
var line3_1 = require("../../../core/base/line3");
var arcFitter_1 = require("../../utils/arcFitter");
var ConcaveRectangle = /** @class */ (function (_super) {
    __extends(ConcaveRectangle, _super);
    function ConcaveRectangle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConcaveRectangle.prototype.calculateHoleLines = function () {
        var innerRadius = this.config.options.radiusInner;
        var line = this.calculateLines()[2];
        var additionalPoints = arcFitter_1.ArcFitter.fitArcCircle(line.startPoint, line.endPoint, innerRadius);
        return [[
                new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPoints[0]),
                new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPoints[1]),
                line.clone()
            ]];
    };
    ConcaveRectangle.prototype.getConfigProperties = function () {
        var config = _super.prototype.getConfigProperties.call(this);
        config.radiusInner = 0.7;
        return config;
    };
    return ConcaveRectangle;
}(rectangle_1.Rectangle));
exports.ConcaveRectangle = ConcaveRectangle;

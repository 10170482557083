/* eslint-disable import/prefer-default-export,react/prop-types */
import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/core';
import tooltipStyle from '../../styles/Tooltip';

import EditorLayer from './EditorLayer.js';
import LayerSettings from './LayerSettings/LayerSettings.js';
import {materialTheme} from '../../materialTheme'

const { palette } = materialTheme

const styles = () => ({
  ...tooltipStyle(palette),
  tooltip: {
    ...tooltipStyle(palette).tooltip,
    top: 6,
    maxWidth: 250,
    padding: 10
  },
  layerTabs: {
    width: 336,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    marginBottom: 24,
    '& .MuiIconButton-root': {
      display: 'none',
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
    }
  },
  layerTab: {
    fontSize: 16,
    fontWeight: 300,
    fontFamily: palette.fontFamily,
    lineHeight: '24px',
    color: palette.primary.main,
  },
  layerTabChecked: {
    fontWeight: 700,
    margin: 0,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -10,
      right: 0,
      left: 0,
      width: 24,
      height: 2,
      background: palette.primary.main,
    }
  },
  layerName: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 32,
    lineHeight: '24px',
    color: palette.secondary.main,
    fontFamily: palette.fontFamily,
  },
});

class EditorLayers extends React.Component {
  handleChange = e => {
    const { setLayerTab } = this.props;
    setLayerTab(e.target.value);
  };

  render() {
    const {
      classes,
      canvas,
      currentRect,
      layerTab,
      isOpen,
      onDragEnd,
      deleteRect,
      lockRect,
      updateRectParams,
      deleteFocus,
      shapeClick,
      shapesIsActive,
      rectParams
    } = this.props;

    const settingsName = currentRect?.fontFamily ? 'Text' : 'Image';

    return (
      <div>
      <div>
        <div className={classes.layerTabs} value={layerTab} onClick={(e) => this.handleChange(e)}>
          <FormControlLabel
            value="summary"
            classes={{ label: `${(layerTab === 'summary' || !isOpen) && classes.layerTabChecked} ${classes.layerTab}` }}
            control={(<Radio checkedIcon={<span />} icon={<span />} />)}
            label="Summary"
          />
          {isOpen && (
            <FormControlLabel
              value="settings"
              classes={{ label: `${layerTab === 'settings' && classes.layerTabChecked} ${classes.layerTab}` }}
              control={(<Radio checkedIcon={<span />} icon={<span />} />)}
              label={`${settingsName} field settings`}
            />
          )}
        </div>
      </div>
      
      {(layerTab === 'summary') && (
        // <DragDropContext onDragEnd={onDragEnd}>
        //   <Droppable droppableId="droppable" direction="vertical">
        //     {provided => (
        //       <div {...provided.droppableProps} ref={provided.innerRef}>
        //         {rectParams.map((rect, index) => (
        //           <Draggable key={rect.id} draggableId={`${rect.id}`} index={index}>
        //             {prov => (
        //               <div
        //                 ref={prov.innerRef}
        //                 {...prov.draggableProps}
        //                 {...prov.dragHandleProps}
        //               >
        //                 <EditorLayer
        //                   layer={rect}
        //                   lockLayer={lockRect}
        //                   deleteLayer={deleteRect}
        //                   updateRectParams={updateRectParams}
        //                 />
        //               </div>
        //             )}
        //           </Draggable>
        //         ))
        //         }
        //         {provided.placeholder}
        //       </div>
        //     )}
        //   </Droppable>
        // </DragDropContext>
        <div>
            {rectParams.map(rect => (
                <EditorLayer
                layer={rect}
                lockLayer={lockRect}
                deleteLayer={deleteRect}
                updateRectParams={updateRectParams}
              />
            ))}
        </div>
      )}
      {isOpen && layerTab === 'settings' && (
        <div>
          {currentRect.title ? (
            <Tooltip
              arrow
              placement="top"
              disableHoverListener={currentRect.title.length < 20}
              title={<span style={{ wordBreak: 'break-all' }}>{currentRect.title}</span>}
              classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
            >
              <p className={classes.layerName}>{
                settingsName} field: {currentRect.title.slice(0, 20)}{currentRect.title.length > 20 && '...'}
              </p>
            </Tooltip>
          )
            : (
              <p className={classes.layerName}>{
                settingsName} field: {currentRect.type}
              </p>
            )
          }

          {currentRect.type !== 'crop' ? (
            <LayerSettings
              type={currentRect?.fontFamily ? 'text' : 'image'}
              layer={currentRect}
              canvas={canvas}
              updateRectParams={updateRectParams}
              deleteFocus={deleteFocus}
              shapeClick={shapeClick}
              shapesIsActive={shapesIsActive}
            />
          ) : ''}
        </div>
      )}
    </div>
    );
  }
}

export default withStyles(styles)(EditorLayers);




























import { Vue } from "vue-property-decorator";
import { PropOptions } from "vue/types/umd";
import axios from "axios";

let vm = Vue.component("TemplateCollection", {
  props: {
    template: {
      type: Object,
    } as PropOptions<{
      name: string;
      id: number;
      isChanged?: boolean;
      oldName: string;
      isNewNameUniq?: boolean;
      collectionId: number;
      typeFile: string;
      imagePath: string;
    }>,
    index: Number,
  },
  data: function() {
    return {
      unreadQuantity: 0,
      intervalId: 0,
    };
  },
  computed: {
    templatePath: function(): string {
      return this.template.typeFile === "pdf"
        ? `/collection_public/template/${this.template.collectionId}/${this.template.id}/${this.template.name}`
        : `/collection/public/template/${this.template.collectionId}/${this.template.id}/3dModel`;
    },
  },
  beforeDestroy: function() {
    clearInterval(this.intervalId);
  },
  mounted: function() {
    this.getUnreadQuantity();
    this.intervalId = setInterval(() => {
      this.getUnreadQuantity;
    }, 30000);
  },
  methods: {
    getUnreadQuantity: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/comments/unreadQuantity/${this.template.id}`,
          { withCredentials: true }
        );
        this.unreadQuantity = response.data.amountOfUnreadMessages;
      } catch (error) {
        console.log(error);
      }
    },
  },
});

export default vm;

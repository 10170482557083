"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcFitter = void 0;
var three_1 = require("three");
var utilsGeneral_1 = require("../utils/utilsGeneral");
var ArcFitter = /** @class */ (function () {
    function ArcFitter() {
    }
    //TODO update
    // approximates arc of circle by 2 bezier curves using least squares
    ArcFitter.fitArcCircle = function (touchPoint1v3, touchPoint2v3, innerRadius) {
        var touchPoint1 = new three_1.Vector2(touchPoint1v3.x, touchPoint1v3.y);
        var touchPoint2 = new three_1.Vector2(touchPoint2v3.x, touchPoint2v3.y);
        var bottomV = new three_1.Vector2(touchPoint2.x, touchPoint2.y);
        bottomV.addScaledVector(touchPoint1, -1);
        var edgeLen = bottomV.length();
        var radius = innerRadius > edgeLen / 2 ? innerRadius : edgeLen / 2;
        var normal = new three_1.Vector2(-bottomV.y, bottomV.x);
        normal.normalize();
        var edgeCenter = new three_1.Vector2((touchPoint2.x + touchPoint1.x) / 2, (touchPoint1.y + touchPoint2.y) / 2);
        var len = ArcFitter.calculateConcaveDeep(touchPoint1, touchPoint2, radius); //
        var centerBottom = new three_1.Vector2(edgeCenter.x, edgeCenter.y);
        centerBottom.addScaledVector(normal, -len);
        var holeCenter = new three_1.Vector2(edgeCenter.x, edgeCenter.y);
        holeCenter.addScaledVector(normal, radius - len);
        if (touchPoint1v3.distanceTo(touchPoint2v3) === innerRadius * 2) {
            var holeCenterV3 = new three_1.Vector3(centerBottom.x, centerBottom.y);
            return [[touchPoint1v3, new three_1.Vector3(touchPoint1v3.x, holeCenterV3.y), holeCenterV3],
                [holeCenterV3, new three_1.Vector3(touchPoint2v3.x, holeCenterV3.y), touchPoint2v3]];
        }
        var leftCenter = new three_1.Vector2(touchPoint1.x, touchPoint1.y);
        var rightCenter = new three_1.Vector2(touchPoint2.x, touchPoint2.y);
        var arcLen = ArcFitter.calculateArcLen(touchPoint1, touchPoint2, radius);
        var delta = arcLen / (2 * Math.PI * radius * 2);
        var a = 1 / (2 * Math.PI * delta);
        var alpha = 1 / a;
        var dot = normal.dot(new three_1.Vector2(-1, 0));
        var cross = normal.cross(new three_1.Vector2(-1, 0));
        var angle = utilsGeneral_1.pseudoscalarProduct(dot, cross);
        var t0 = -(-angle * a + 1) / a;
        var leftBottom = ArcFitter.calculateMiddlePoint(leftCenter, centerBottom, holeCenter, radius, alpha, t0);
        t0 = angle;
        var rightBottom = ArcFitter.calculateMiddlePoint(centerBottom, rightCenter, holeCenter, radius, alpha, t0);
        return [[leftCenter, leftBottom, centerBottom], [centerBottom, rightBottom, rightCenter]]
            .map(function (e) { return e.map(function (e1) { return new three_1.Vector3(e1.x, e1.y, 0); }); });
    };
    ArcFitter.calculateConcaveDeep = function (p1, p2, radius) {
        var halfHord = 0.5 * Math.sqrt(Math.pow((p1.x - p2.x), 2) + Math.pow((p1.y - p2.y), 2));
        return radius - Math.sqrt(Math.pow(radius, 2) - Math.pow(halfHord, 2));
    };
    ArcFitter.calculateArcLen = function (p1, p2, radius) {
        var halfHord = 0.5 * Math.sqrt(Math.pow((p1.x - p2.x), 2) + Math.pow((p1.y - p2.y), 2));
        var sin = halfHord / radius;
        var angle = 2 * Math.asin(sin);
        var arcLen = radius * angle;
        return arcLen;
    };
    // Searching for middle control point of the Bezier curve B(t) which fits half of needed arc
    // Explanation:
    // B(t) = B0(t) q0 + B1(t) q1 + B2(t) q2
    // B(t) should approximate arc of the circle with radius r, which is passing throw points q0 and q2
    // In our case we use parametric form of circle equation:
    // x = r cos(t); y = r sin(t) + c
    // where c= const  is shift for y component which depends on circle cerner placing (holeCenter variable).
    // Our goal is to find point q1 for B(t), which minimizes L2-norm of difference between arc and circle:
    // ||B - phi||_2 --> min,
    // where phi is arc
    // To define of arc we need to define linear transformation: t = alpha z + t0.
    ArcFitter.calculateMiddlePoint = function (q0, q2, holeCenter, radius, alpha, t0) {
        // rpartX is the result of next operation: Integrate[B1(z) * radius * Cos[alpha z + t0], {z,0,1}]
        // rpartY is the result of next operation: Integrate[B1(z) * (radius * Sin[alpha z + t0] + c), {z,0,1}]
        // 1/10 = Integrate[B1(z) * B0(z), {z,0,1}] = Integrate[B1(z) * B2(z), {z,0,1}]
        // 2/15 = Integrate[B1(z) * B1(z), {z,0,1}]
        var rpartX = holeCenter.x / 3 - 2 * radius * (alpha * Math.cos(t0) + alpha * Math.cos(t0 + alpha) + 2 * Math.sin(t0) - 2 * Math.sin(t0 + alpha)) / Math.pow(alpha, 3);
        var q1X = 15 / 2 * (rpartX - 1 / 10 * (q0.x + q2.x));
        var rpartY = holeCenter.y / 3 - 2 * radius * (-2 * Math.cos(t0) + 2 * Math.cos(t0 + alpha) + alpha * (Math.sin(t0) + Math.sin(t0 + alpha))) / Math.pow(alpha, 3);
        var q1Y = 15 / 2 * (rpartY - 1 / 10 * (q0.y + q2.y));
        return new three_1.Vector2(q1X, q1Y);
    };
    return ArcFitter;
}());
exports.ArcFitter = ArcFitter;


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { FigureBase, Line3, LineType } from "packaging3d-engine/lib";
import _ from "lodash";
import TextureSettings from "./TextureSettings.vue";

import {
  Vector3,
  Mesh,
  WebGLRenderer,
  PerspectiveCamera,
  Group,
  Scene,
  Color,
  AxesHelper,
  MeshBasicMaterial,
  Vector2,
  Raycaster,
  Material,
  Shape,
  ShapeGeometry,
  CircleGeometry,
  LineSegments,
  EdgesGeometry,
  LineBasicMaterial,
  GridHelper,
  DoubleSide,
  Object3D,
  MathUtils,
  Matrix4,
  MeshStandardMaterial,
  ImageLoader,
  Box3,
  MeshLambertMaterial,
  FrontSide,
  BackSide
} from "three";
import { PropOptions } from "vue";
import ResetCameraBtn from "./ResetCameraBtn.vue";
const THREE = require("three");
import { OrbitControls } from "../lib/OrbitControls";
import { Settings } from "@/types/viewer";
import { Representation } from "packaging3d-engine/lib/core/base/representation";
import { Utils } from "packaging3d-engine/lib/lib/utils";
import {
  ColoringData,
  PatternData,
  LoadedColoringImage,
  TextData,
  BackgroundTexture
} from "@/types";
import DragControls from "three-dragcontrols";
import { fabric } from "fabric";
import * as PromiseBird from "bluebird";
import { v4 } from "uuid";
import { generateRandomColor } from 'packaging3d-engine';
import { Image } from 'fabric';

let vm = Vue.component("ObjectRender", {
  props: {
    wereUpdated: Boolean,
    representations: {
      type: Array,
      default: () => [],
    } as PropOptions<Representation[]>,
    representations2d: {
      type: Array,
      default: () => [],
    } as PropOptions<Representation[]>,
    settings: {
      type: Object,
    } as PropOptions<Settings>,
    selectedObject: {
      type: Object,
    } as PropOptions<Representation | null>,
    selectedSide: {
      type: Object,
    } as PropOptions<{ elem: Representation; side: number } | null>,
    renderer: {
      type: Object,
    } as PropOptions<WebGLRenderer | null>,
    scale: {
      type: Object,
    } as PropOptions<Vector3>,
    helper: {
      type: Object,
    } as PropOptions<AxesHelper>,
    localGroup: {
      type: Object,
    } as PropOptions<Group>,
    coloringData: {
      type: Map,
      default: new Map<number, ColoringData>(),
    } as PropOptions<Map<number, ColoringData>>,
    lockedModel: Boolean,
    removedObjectId: {
      type: Number,
    },
    coloringDataTracker: {
      type: Number,
    },
    backgroundImage: {
      type: String
    },
    pattern: {
      type: Number
    },
    renderTracker: {
      type: Number
    },
    fabricTextObjects: {
      type: Array,
      default: () => [],
    } as PropOptions<Array<TextData>>,
    backgroundTexture: {
      type: Array,
      default: () => [],
    } as PropOptions<Array<BackgroundTexture>>,
    isTexture: Boolean,
    isIframePage: Boolean,
  },
  data: function() {
    return {
      camera: new PerspectiveCamera(),
      scene: new Scene(),
      controls: {} as any,
      mouse: new Vector2(),
      grid: new GridHelper(10000, 1000),
      gridPow: 3,
      scanTime: 0,
      lineMaterial: new MeshBasicMaterial({
        color: 0x000000,
        wireframe: true,
        wireframeLinewidth: 1,
        side: THREE.DoubleSide,
      }),
      localRenderer:
        this.renderer ??
        new WebGLRenderer({
          antialias: true,
        }),
      loadedTextures: new Map<string | number, LoadedColoringImage[]>(),
      loadedPatternTetures: new Map<string | number, ColoringData[]>(),
      hoveredSide: null as { side: number; id: number } | null,
      is2d: true,
      fabricCanvas: [] as any[],
      selectedTexture: null as any,
      fabricRenderTracker: 0,
      localColoringImages: [] as any,
      localBackgroundTextures: [] as any,
      cashedBackgroundImage: null as any,
      cashedPatternImage: null as any,
      isMergedGroup: false,
      resizeFromCenterIcon: null as any,
      resizeFromEdgeIcon: null as any,
    };
  },
  watch: {
    pattern: function(newVal) {
      this.cashedPatternImage = null;
      if(this.isMergedGroup && newVal) this.updateMaterialForGroup()
      else this.generateLocalGroup(); 
    },
    backgroundImage: function(newVal) {
      this.cashedBackgroundImage = null;
      if(this.isMergedGroup && newVal) this.updateMaterialForGroup()
      else this.generateLocalGroup();
    },
    removedObjectId: function(newValue, oldValue) {
      if (newValue) {
        const removeObjects = this.localGroup.children.filter((child) =>
          child.name.includes(`${newValue}`)
        );
        this.localGroup.remove(...removeObjects);
        this.$emit("objectRemoved");
      }
    },
    selectedObject: {
      deep: true,
      handler: async function(newValue: any, oldValue: any) {
        let tstart = Date.now();
        this.scanTime = 0;

        this.removOldObjects();
        if (
          newValue &&
          !this.$route.path.includes("/collection_public/template")
        ) {
          this.selectedTexture = this.fabricCanvas.find(
            (canvas) => canvas.id == newValue.id
          );
          this.$emit("selectTexture", this.selectedTexture?.id);
          this.fabricRenderTracker += 1;
        } else {
          this.selectedTexture = null;
        }
        setTimeout(async () => {
          await this.updateSelectdCanvas();
          this.updateSelectedObject();
        }, 0);
      },
    },
    coloringDataTracker: async function(newValue: any, oldValue: any) {
      await this.updateSelectdCanvas();
      this.generateLocalGroup();
      if (newValue - oldValue == 5) this.$emit("select", -1);
    },
    selectedSide: {
      deep: true,
      handler: function(newValue: any, oldValue: any) {
        this.updateSelectedObject();
      },
    },
    representations: {
      deep: true,
      handler: async function(newValue: any, oldValue: any) {
        await this.updateFabricCanvas();
        this.generateLocalGroup();
        this.$emit("update_done");
      },
    },
    settings: {
      deep: true,
      handler: function(newValue: any, oldValue: any) {
        let somethingChanges = false;
        Object.keys(newValue).forEach((key) => {
          if (newValue[key] !== oldValue[key]) {
            somethingChanges = true;
          }
        });
        if (!somethingChanges) {
          return;
        }
        this.renderGrid();
        if (newValue.showHelper !== oldValue.showHelper) {
          if (newValue.showHelper) {
            this.scene.add(this.helper);
          } else {
            this.scene.remove(this.helper);
          }
        }
        this.generateLocalGroup();
      },
    },
    scale: {
      deep: true,
      handler: function() {
        this.generateLocalGroup();
      },
    },
    "settings.size": {
      deep: true,
      handler: function(newValue: any, oldValue: any) {
        this.localRenderer.setSize(newValue.x, newValue.y);
      },
    },
  },
  computed: {
    size: function(): Vector2 {
      return (
        this.settings.size ??
        new Vector2(window.innerWidth / 2, window.innerHeight - 55)
      );
    },
    checkFrame: function() {
      let isFramed = false;
      try {
        isFramed =
          window != window.top ||
          document != top.document ||
          self.location != top.location;
      } catch (e) {
        isFramed = true;
      }
      return isFramed;
    },
    isCastomizationFrame: function(): boolean {
      return Boolean(Number(this.$route.params?.customization));
    },
  },
  mounted: async function() {
    this.camera = new PerspectiveCamera(
      50,
      this.size.x / this.size.y,
      1,
      100000
    );
    this.is2d = this.settings.type === 0;
    if (this.is2d) {
      this.camera.position.set(700, 0, 0);
    } else {
      this.camera.position.set(-11, 112, -601);
    }

    this.scene.background = new Color(0xf0f0f0);
    if (this.isIframePage) {
    this.scene.background = new Color(0xffffff);
    }
    if (!this.is2d) this.scene.rotation.x = -180 * Math.PI/180;

    if (!this.is2d && this.settings.showHelper && !this.isIframePage) {
      this.scene.add(this.helper);
    }

    this.localRenderer.setPixelRatio(window.devicePixelRatio);
    if (
      window.location.href.includes(
        "https://3dpack.codex-soft.net/#/iframemodel/"
      ) || this.isIframePage
    ) {
      this.localRenderer.setSize(400, 400);
    } else {
      this.localRenderer.setSize(this.size.x, this.size.y);
    }

    this.$el.appendChild(this.localRenderer.domElement);

    this.controls = new OrbitControls(this.camera, this.$el as HTMLElement);

    if (this.is2d) {
      this.controls.noRotate = true;
      this.controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
    } else {
      this.controls.enablePan = false;
    }

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
    hemiLight.color.setHSL(1, 1, 1);
    hemiLight.groundColor.setHSL(1, 1, 1);
    hemiLight.position.set(0, 500, 0);
    this.scene.add(hemiLight);

    document.addEventListener("mousemove", this.onDocumentMouseMove, false);
    this.$el.addEventListener("click", this.selectFigure, false);
    this.$el.addEventListener("touch", this.selectFigure, false);

    this.renderGrid();

    if (!this.is2d) {
      this.localGroup.rotateX(-Math.PI / 2);
      this.localGroup.rotateZ(Math.PI);
    } else {
      this.localGroup.rotateY(Math.PI / 2);
    }

    new Box3()
      .setFromObject(this.localGroup)
      .getCenter(this.localGroup.position)
      .multiplyScalar(-1);
    this.localGroup.position.y = 0;

    this.scene.add(this.localGroup);
    await this.updateFabricCanvas();
    this.generateLocalGroup();
    this.loadIcons();
    this.animate();
  },
  beforeDestroy: function() {
    document.removeEventListener("mousemove", this.onDocumentMouseMove, false);
    this.$el.removeEventListener("click", this.selectFigure, false);
    this.$el.removeEventListener("touch", this.selectFigure, false);

    this.loadedTextures = new Map<string, LoadedColoringImage[]>();
    this.loadedPatternTetures = new Map<string, ColoringData[]>();

    this.scene.remove(this.localGroup);
    const children = this.localGroup.children;
    this.localGroup.remove(...children);
    children.forEach(this.disposeObject);

    this.scene.dispose();
    this.localRenderer.dispose();
    this.controls.dispose();
  },
  methods: {
    enableControls: function() {
      this.controls.enabled = true;
      this.$el.addEventListener("click", this.selectFigure, false);
      this.$el.addEventListener("touch", this.selectFigure, false);
    },
    disableControls: function() {
      this.controls.enabled = false;
      this.$el.removeEventListener("click", this.selectFigure, false);
      this.$el.removeEventListener("touch", this.selectFigure, false);
    },
    updateSelectdCanvas: async function() {
      if (this.selectedTexture && this.selectedObject) {
        const selectedColoringData =
          this.coloringData.get(this.selectedTexture.id) ??
          ({
            color: generateRandomColor(),
            images: [],
            id: v4(),
          } as ColoringData);

        const selectedRepresentation = this.representations2d.find(
          (rep) => rep.id === this.selectedObject?.id
        );

        const selectedTextData =
          this.fabricTextObjects.find(
            (textObject) => textObject.id == this.selectedObject?.id
          ) ?? ({ id: v4(), texts: [] } as TextData);

        const selectedBackgroundTexture =
          this.backgroundTexture.find(texture => texture.figureId == this.selectedObject?.id) ??
          ({ figureId: this.selectedObject?.id, imagePath: '' } as BackgroundTexture); 

        if (selectedRepresentation) {
          const material = await this.generateFabricCanvas(
            selectedRepresentation,
            selectedColoringData,
            selectedTextData,
            selectedBackgroundTexture
          );
          const selectedCanvas = this.fabricCanvas.find(
            (canvas) => canvas.id == material.id
          );
          selectedCanvas.canvas = material.canvas;
        }
      }

      return;
    },
    updateFabricCanvas: async function() {
      this.fabricCanvas = await PromiseBird.map(
        this.representations2d,
        async (representation) => {
          let coloringData =
            this.coloringData.get(representation.id) ??
            ({
              color: generateRandomColor(),
              images: [],
              id: v4(),
            } as ColoringData);

          let textData =
            this.fabricTextObjects.find(
              (textObject) => textObject.id == representation.id
            ) ?? ({ id: v4(), texts: [] } as TextData);

          const backgroundTexture =
            this.backgroundTexture.find(texture => texture.figureId == representation.id) ??
            ({ figureId: this.selectedObject?.id, imagePath: '' } as BackgroundTexture);
          
        const material = await this.generateFabricCanvas(
            representation,
            coloringData,
            textData,
            backgroundTexture
          );
          return material;
        }
      );
      return;
    },
    generateHashColor(color): string {
      return `#${color.toString(16).padStart(6, "0").split(".")[0]}`;
    },
    generateFabricCanvas: async function(
      figure: Representation,
      coloringData: ColoringData,
      textData: TextData,
      backgroundTexture: BackgroundTexture 
    ) {
      const meshs = this.generateMeshes(figure, new THREE.MeshPhongMaterial());
      const foundMesh = meshs.find((mesh) => mesh.name.includes("select"));
      const meshSize = this.getSelectedObjectSize(foundMesh);

      const canvas = new fabric.Canvas(`canvas-${figure.id}`, {
        backgroundColor: isNaN(Number(coloringData.color)) ? coloringData.color : this.generateHashColor(coloringData.color),
        width: meshSize.x * 2.83,
        height: meshSize.y * 2.83,
        preserveObjectStacking: true
      });

      const background: Image[] = [];
      const selectableImages: Image[] = [];

      await PromiseBird.map(
        coloringData.images,
        async (image) => {
          let imageFabric;
          const { x, y, scaleX, scaleY, angle, fullSize, id, isScalingChanged } = image;
          let coloringDataImg;

          if (image.imagePath) {
            const loadedColoringData = this.localColoringImages.find(
              (image) => image.id == id
            );

            if (loadedColoringData) {
              coloringDataImg = loadedColoringData.file;
            } else {
              const loader = new ImageLoader();
              coloringDataImg = await loader.loadAsync(image.imagePath);
              this.localColoringImages.push({
                id,
                file: coloringDataImg,
              });
            }
          }

          let sizeX = fullSize ? canvas.width / coloringDataImg.width : scaleX;
          let sizeY = fullSize ? canvas.height / coloringDataImg.height : scaleY;

          const isImageBigger = coloringDataImg.width > canvas.width || coloringDataImg.height > canvas.height;

          if(!fullSize && isImageBigger) {
            if(coloringDataImg.width >= coloringDataImg.height) {
              sizeX = isScalingChanged ? scaleX : canvas.width / coloringDataImg.width;
              sizeY = isScalingChanged ? scaleY : (canvas.height / coloringDataImg.height) * (coloringDataImg.height / coloringDataImg.width);
            } else {
              sizeY = isScalingChanged ? scaleY : canvas.height / coloringDataImg.height;
              sizeX = isScalingChanged ? scaleX : (canvas.width / coloringDataImg.width) * (coloringDataImg.width / coloringDataImg.height);
            }       
          }

          imageFabric = new fabric.Image(coloringDataImg, {
            left: x,
            top: y,
            scaleX: sizeX,
            scaleY: sizeY,
            angle: angle,
            customKey: image.id,
            selectable: !fullSize, 
            evented: !fullSize,
            centeredScaling: true,
          });

        // imageFabric.controls.tr.mouseUpHandler = () => {alert('TR')};
        imageFabric.controls.bl.mouseUpHandler = (e, transform) => {
          transform.target.centeredScaling = !transform.target.centeredScaling;
          imageFabric.controls.bl.render = transform.target.centeredScaling
            ? this.renderScaleFromCenterIcon
            : this.renderScaleFromEdgeIcon;
          canvas.setActiveObject(transform.target);
          canvas.requestRenderAll();
        };


          if(fullSize) {
            background.push(imageFabric);
          } else {
            selectableImages.push(imageFabric);
          }
        },
        { concurrency: 3 }
      );

      if(backgroundTexture.imagePath) {
        let backgroundImage;
        const loadedBackgroundTexture = this.localBackgroundTextures.find( image => image.id == figure.id );

        if(loadedBackgroundTexture) {
          backgroundImage = loadedBackgroundTexture.file;
        } else {
          const loader = new ImageLoader();
          backgroundImage = await loader.loadAsync(backgroundTexture.imagePath);
          this.localBackgroundTextures.push({
            id: figure.id,
            file: backgroundImage,
          });
        }

        if (backgroundImage) {
          const imageFabric = new fabric.Image(backgroundImage, {
            left: 0,
            top: 0,
            scaleX: canvas.width / backgroundImage.width,
            scaleY: canvas.height / backgroundImage.height
          });

          background.push(imageFabric);
        }
      };

      canvas.insertAt(new fabric.Group(background, {
        originX: 'left',
        originY: 'top',
        selectable: false
      }), 50);
      canvas.add(...selectableImages);


      textData.texts.forEach((textObject) => {
        const fabricText = new fabric.Textbox(textObject.text, {
          left: textObject.x,
          top: textObject.y,
          angle: textObject.angle,
          width: textObject.width,
          height: textObject.height,
          scaleX: textObject.scaleX,
          scaleY: textObject.scaleY,
          editable: true,
          customKey: textObject.id,
          fontSize: 30,
          flipX: false,
          centeredScaling: true,
        });

        fabricText.controls.tr.mouseUpHandler = async (ev,transform) => {await this.$emit("removeFabricObject", transform.target.customKey)};
        fabricText.controls.bl.mouseUpHandler = (e, transform) => {
          transform.target.centeredScaling = !transform.target.centeredScaling;
          fabricText.controls.bl.render = transform.target.centeredScaling
            ? this.renderScaleFromCenterIcon
            : this.renderScaleFromEdgeIcon;
          canvas.setActiveObject(transform.target);
          canvas.requestRenderAll();
        };
        fabricText.controls.mt.visible = false;
        fabricText.controls.mb.visible = false;
        
        canvas.add(fabricText);
      });

      canvas.on("object:moving", (e) => {
        this.dragAndDropValidation(e);
      });

      canvas.on("selection:created", (e) => {
        const activeObject = canvas.getActiveObject();
        activeObject.controls.bl.render = activeObject.centeredScaling
          ? this.renderScaleFromCenterIcon
          : this.renderScaleFromEdgeIcon;
      });

      canvas.on("selection:updated", (e) => {
        const activeObject = canvas.getActiveObject();
        activeObject.controls.bl.render = activeObject.centeredScaling
          ? this.renderScaleFromCenterIcon
          : this.renderScaleFromEdgeIcon;
      });

      canvas.on("object:modified", (e) => {
        this.dragAndDropValidation(e);
        this.scalingValidation(e);
        const activeObject = canvas.getActiveObject();
        this.$emit("changeTextureData", activeObject);
      });

      return {
        id: figure.id,
        canvas,
      };
    },
    loadIcons: function() {
      const resizeFromCenter = `${process.env.VUE_APP_API_URL}/icons/resizeFromCenter.svg`;
      const resizeFromEdge = `${process.env.VUE_APP_API_URL}/icons/resizeFromEdge.svg`;
      this.resizeFromCenterIcon = document.createElement("img");
      this.resizeFromEdgeIcon = document.createElement("img");
      this.resizeFromCenterIcon.src = resizeFromCenter;
      this.resizeFromEdgeIcon.src = resizeFromEdge;
    },
    renderScaleFromEdgeIcon: async function(
      ctx,
      left,
      top,
      styleOverride,
      fabricObject
    ) {
      const size = 24;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(this.resizeFromEdgeIcon, -size / 2, -size / 2, size, size);
      ctx.restore();
    },
    renderScaleFromCenterIcon: async function(
      ctx,
      left,
      top,
      styleOverride,
      fabricObject
    ) {
      const size = 24;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(
        this.resizeFromCenterIcon,
        -size / 2,
        -size / 2,
        size,
        size
      );
      ctx.restore();
    },
    scalingValidation: function(e) {
      let obj = e.target;
      let boundingRect = obj.getBoundingRect(true);
      const selectedCanvas = this.fabricCanvas.find( canvas => canvas.id == this.selectedObject?.id);

      if (boundingRect.left < 0
          || boundingRect.top < 0
          || boundingRect.left + boundingRect.width > selectedCanvas.canvas.getWidth()
          || boundingRect.top + boundingRect.height > selectedCanvas.canvas.getHeight()) {
          throw new Error('bad params')
      }
    },
    dragAndDropValidation: function(e) {
      const obj = e.target;
      const selectedCanvas = this.fabricCanvas.find( canvas => canvas.id == this.selectedObject?.id);

      if(obj.height * obj.scaleY > obj.canvas.height || obj.width * obj.scaleX > obj.canvas.width){
        return;
      }   
      obj.setCoords(); 

      if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
        obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
      }

      if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
        obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
        obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
      }
    },
    selectFigure: function(): void {
      let raycaster = new Raycaster();
      raycaster.setFromCamera(this.mouse, this.camera);
      let intersects = raycaster.intersectObjects(
        this.localGroup.children,
        true
      );
      if (intersects.length > 0) {
        try {
          let obj = JSON.parse(intersects[0].object.name);
          if (obj.type === "select") {
            this.$emit(obj.type, obj.id);
          } else if (obj.type === "addTexture") {
            this.$emit(obj.type, obj.id, obj.side);
          } else {
            this.$emit(obj.type, obj.id, obj.side);
          }
        } catch (e) {
          this.$emit("select", -1);
        }
      } else if (this.settings.showControls) {
        this.$emit("select", -1);
      }
    },
    disposeObject: function(object: Object3D): void {
      const mesh = object as Mesh;
      const { geometry, material } = mesh;
      geometry.dispose();
      if (material instanceof Material) material.dispose();
    },
    randInt: function(min, max) {
      if (max === undefined) {
        max = min;
        min = 0;
      }

      return (Math.random() * (max - min) + min) | 0;
    },
    calculateRotationAngle: function(line: Line3) {
      let directionVector = line.calculateDirectionVector();
      let directionVector2d = new Vector2(directionVector.x, directionVector.y);
      let oXdirectionVector2d = new Vector2(-1, 0);
      let cos = directionVector2d.dot(oXdirectionVector2d);
      let sin = directionVector2d.cross(oXdirectionVector2d);
      let acos = MathUtils.radToDeg(Math.acos(cos));
      return sin > 0 ? acos : 360 - acos;
    },
    generateMaterial: function(figure: Representation) {
      const foundTexture = this.fabricCanvas.find(
        (canvas: any) => canvas.id == figure.id
      ) as any;
      let material: MeshStandardMaterial | MeshStandardMaterial[] = new MeshStandardMaterial({});
      const figure2d = this.representations2d.find(representation => representation.id == figure.id); 
      const angle = figure2d ? this.calculateRotationAngle(figure2d.shape[0]) : 0;
      const radians = (angle * Math.PI) / 180;

      if (foundTexture) {
        foundTexture.canvas.renderAll();
        const texture = new THREE.Texture(foundTexture.canvas.lowerCanvasEl);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.needsUpdate = true;
        if(Math.round(angle) % 90 == 0 ) texture.rotation = radians;

        const currentOpacity = this.isIframePage ? 0.98 : this.settings.opacity;

        material.needsUpdate = true;
        if (this.is2d) {
          material = new MeshStandardMaterial({
            wireframeLinewidth: 1,
            side: FrontSide,
            opacity: currentOpacity,
            transparent: true,
            map: texture,
          });
        } else {
          material = [new MeshStandardMaterial({
            wireframeLinewidth: 1,
            side: FrontSide,
            opacity: currentOpacity,
            transparent: true,
            map: texture,
          }), new MeshStandardMaterial({
            wireframeLinewidth: 1,
            side: BackSide,
            opacity: currentOpacity,
            transparent: true,
            color: "#DABDAB"
          })]
        }
      }


      return material;
    },
    updateSelectedObject: function() {
      if (this.selectedObject) {
        let selectedChilds = this.localGroup.children.filter((child) =>
          child.name.includes(`${this.selectedObject?.id}`)
        );
        let selectedRepresentation = this.representations.find(
          (representation) => representation.id == this.selectedObject?.id
        );
        this.localGroup.remove(...selectedChilds);

        if (selectedRepresentation) {
          let material = this.generateMaterial(selectedRepresentation);
          let mesh = this.generateMeshes(
            selectedRepresentation,
            material
          ).filter((meshItem) => meshItem.name);

          this.localGroup.add(...mesh);
        }
      }
    },
    removOldObjects: function() {
      const buttons = this.localGroup.children.filter((child) =>
        child.name.includes("add")
      );
      this.localGroup.remove(...buttons);
    },
    generateLocalGroup: async function() {
      let children = this.localGroup.children;
      this.localGroup.remove(...children);
      children.forEach(this.disposeObject);
      let promises = this.representations.map(async (representation) => {
        let material = this.generateMaterial(representation);
        return this.generateMeshes(representation, material);
      });

      await PromiseBird.map(promises, async (mesh) => {
        this.localGroup.add(...mesh);
      });

      if(this.isTexture) {
        await this.mergeLocalGroup();
        this.isMergedGroup = true;
      } else {
        this.isMergedGroup = false;
      }
    },
    updateMaterialForGroup: async function() {
      const foundMergedMesh: any = this.localGroup.children.find(child => child.type == 'Mesh');
      const material = await this.generateMaterialForGroup();

      if(foundMergedMesh?.material) foundMergedMesh.material = material;
    },
    generateMaterialForGroup: async function() {
      const canvas = new fabric.Canvas(`canvas-background`, {
        backgroundColor: generateRandomColor(),
        width: 1920,
        height: 1920,
        preserveObjectStacking: true
      });

      if(this.pattern) {
        let patternImage;

        if(!this.cashedPatternImage) {
          const loader = new ImageLoader();
          this.cashedPatternImage = await loader.loadAsync(`${process.env.VUE_APP_API_URL}/icons/pattern/${this.pattern}.jpg`);
        }

        patternImage = this.cashedPatternImage;

        const patternImageFabric = new fabric.Image(patternImage, {
          left: 0,
          top: 0,
          scaleX: canvas.width / patternImage.width,
          scaleY: canvas.height / patternImage.height
        });

        canvas.add(patternImageFabric);
      }

      
      if(this.backgroundImage) {
        let backgroundImage; 
        if(!this.cashedBackgroundImage) {
          const loader = new ImageLoader();
          this.cashedBackgroundImage = await loader.loadAsync(this.backgroundImage);
        }
        
        backgroundImage = this.cashedBackgroundImage;

        const backgroundImageFabric = new fabric.Image(backgroundImage, {
          left: 0,
          top: 0,
          scaleX: canvas.width / backgroundImage.width,
          scaleY: canvas.height / backgroundImage.height
        });



        canvas.add(backgroundImageFabric);
      }
      

      canvas.renderAll();
      const texture = new THREE.Texture(canvas.lowerCanvasEl);
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.needsUpdate = true;
        const currentOpacity = this.isIframePage ? 0.98 : this.settings.opacity;
      if (this.is2d) {
        return new MeshStandardMaterial({
          side: DoubleSide,
          opacity: currentOpacity,
          transparent: true,
          map: texture,
        });
      } else {
        return [new MeshStandardMaterial({
          side: FrontSide,
          opacity: currentOpacity,
          transparent: true,
          map: texture,
        }), new MeshStandardMaterial({
          side: BackSide,
          opacity: currentOpacity,
          transparent: true,
          color: "#DABDAB"
        })]
      }
    },
    mergeLocalGroup: async function() {
      const mergeGeometry2d = new THREE.Geometry();
      if(!this.is2d) {
        const meshes2d: any[] = [];
        await PromiseBird.map(this.representations2d, async (representation) => {
          const meshs = await this.generateMeshes(representation, new THREE.MeshPhongMaterial());

          meshes2d.push(...meshs);
        });
        
        let childrenMeshs2d = meshes2d.filter(child => child.type == "Mesh");
        childrenMeshs2d.forEach( (child) => {
          child.updateMatrix();
          mergeGeometry2d.mergeMesh(child)
        });
      }

      let childrenMeshs = this.localGroup.children.filter(child => child.type == "Mesh");

      const mergeGeometry = new THREE.Geometry();

      childrenMeshs.forEach( (child) => {
        child.updateMatrix();
        mergeGeometry.mergeMesh(child)
      });


      if(this.is2d) this.createUVMapping(mergeGeometry);
      else {
        mergeGeometry.faceVertexUvs[0] = [];
        mergeGeometry.computeBoundingBox();
        const uvs = this.createUVMapping(mergeGeometry2d);
        mergeGeometry.faceVertexUvs[0] = uvs;
        mergeGeometry.uvsNeedUpdate = true;
      }

      const material = await this.generateMaterialForGroup();
      if (this.is2d) {
        const mesh = new Mesh( mergeGeometry, material );
        mesh.updateMatrix();
        this.localGroup.add(mesh);
        this.localGroup.remove(...childrenMeshs);
      } else {
        const mesh0 = new Mesh( mergeGeometry, material[0] );
        const mesh1 = new Mesh( mergeGeometry, material[1] );
        mesh0.updateMatrix();
        mesh1.updateMatrix();
        this.localGroup.add(mesh0);
        this.localGroup.add(mesh1);
        this.localGroup.remove(...childrenMeshs);
      }
    },
    getSelectedObjectSize: function(mesh) {
      const box = new Box3().setFromObject(mesh);
      return box.getSize(new Vector3(0,0,0));
    },
    scanFigure: function(
      figure: Representation
    ): {
      projectedLines: Line3[];
      projectedHoles: Line3[][];
      forwardRotation: Matrix4;
      reverseRotation: Matrix4;
      shift: Vector3;
    } {
      //project on Oxy
      let normal = figure.normal.clone();
      let forwardRotation = Utils.calculateRotationToOzAxis(normal);
      let projectedLines = _.cloneDeep(figure.shape) as Line3[];
      let projectedHoles = figure.holesShape
        ? (_.cloneDeep(figure.holesShape) as Line3[][])
        : ([[]] as Line3[][]);
      projectedLines.forEach((line) => {
        line.applyMatrix4(forwardRotation);
      });

      let directionVector = projectedLines[0].calculateDirectionVector();
      let directionVector2d = new Vector2(
        directionVector.x,
        directionVector.y
      ).normalize();
      let [cos, sin] = [-directionVector2d.x, -directionVector2d.y];
      let rotationToOx = new Matrix4().fromArray([
        cos,
        -sin,
        0,
        0,
        sin,
        cos,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
      ]);

      projectedLines.forEach((line) => {
        line.applyMatrix4(rotationToOx);
      });

      forwardRotation.premultiply(rotationToOx);

      projectedHoles.forEach((hole) => {
        hole.forEach((line) => {
          line.applyMatrix4(forwardRotation);
        });
      });
      let reverseRotation = new Matrix4().getInverse(forwardRotation);
      let shift = projectedLines[0].startPoint.clone();
      shift.z = 0;
      shift.applyMatrix4(reverseRotation);
      shift.sub(figure.shape[0].startPoint).multiplyScalar(-1);
      return {
        projectedLines,
        projectedHoles,
        forwardRotation,
        reverseRotation,
        shift,
      };
    },
    coordsToShape: function(lines: Line3[]) {
      const shape = new Shape();
      for (let line of lines) {
        if (line.type === LineType.Straight) {
          let points = [line.startPoint, line.endPoint];
          shape.moveTo(points[0].x, points[0].y);
          shape.lineTo(points[1].x, points[1].y);
        } else if (line.type === LineType.BazierCurve) {
          let points = [line.startPoint, line.middlePoint, line.endPoint];
          shape.moveTo(points[0].x, points[0].y);
          shape.bezierCurveTo(
            points[0].x,
            points[0].y,
            points[1].x,
            points[1].y,
            points[2].x,
            points[2].y
          );
        }
      }

      return shape;
    },
    createUVMapping: function(geometryProjected: ShapeGeometry) {
      geometryProjected.faceVertexUvs[0] = [];
      geometryProjected.computeBoundingBox();
      let max = geometryProjected.boundingBox!.max;
      let min = geometryProjected.boundingBox!.min;
      let offset = new THREE.Vector2(0 - min.x, 0 - min.y);
      let range = new THREE.Vector2(max.x - min.x, max.y - min.y);
      let faces = geometryProjected.faces;

      for (let i = 0; i < geometryProjected.faces.length; i++) {
        let v1 = geometryProjected.vertices[faces[i].a];
        let v2 = geometryProjected.vertices[faces[i].b];
        let v3 = geometryProjected.vertices[faces[i].c];

        let uv0: Vector2 = new THREE.Vector2(
          (v1.x + offset.x) / range.x,
          (v1.y + offset.y) / range.y
        );
        let uv1: Vector2 = new THREE.Vector2(
          (v2.x + offset.x) / range.x,
          (v2.y + offset.y) / range.y
        );
        let uv2: Vector2 = new THREE.Vector2(
          (v3.x + offset.x) / range.x,
          (v3.y + offset.y) / range.y
        );

        geometryProjected.faceVertexUvs[0].push([uv0, uv1, uv2]);
        
      }

      geometryProjected.uvsNeedUpdate = true;

      return geometryProjected.faceVertexUvs[0]
    },
    generateMeshes: function(
      figure: Representation,
      material: Material | Material[]
    ): Object3D[] {
      let tstart = Date.now();
      let {
        projectedLines,
        projectedHoles,
        forwardRotation,
        reverseRotation,
        shift,
      } = this.scanFigure(figure);
      this.scanTime += Date.now() - tstart;
      let shape = this.coordsToShape(projectedLines);

      if (figure.holesShape) {
        const holesShape = projectedHoles.map(this.coordsToShape);

        holesShape.forEach((holeShape) => shape.holes.push(holeShape));
      }

      let result: Object3D[] = [];
      let geometry = new ShapeGeometry(shape, 100);

      let id = figure.id;


      const mesh = new Mesh(geometry, Array.isArray(material) ? material[0] : material);
      mesh.name = JSON.stringify({
        id,
        type: "select",
      });

      if (
        this.selectedObject &&
        this.selectedObject.id == id &&
        !this.lockedModel
      ) {
        if (
          this.settings.showControls &&
          !this.$route.path.includes("/collection_public/template")
        ) {
          result.push(...this.generateButtons(figure));
        }
      }

      mesh.position.copy(shift);
      mesh.setRotationFromMatrix(reverseRotation);
      this.createUVMapping(geometry);

      let edges = new EdgesGeometry(geometry);
      let line = new LineSegments(
        edges,
        new LineBasicMaterial({ color: 0x000000 })
      );

      line.rotation.copy(mesh.rotation);
      line.position.copy(shift);
      if (this.settings.showBorders) {
        result.push(line);
      }
      result.push(mesh);

      if (Array.isArray(material) && !(!!this.backgroundImage || !!this.pattern)) {
        let insideMesh = new Mesh(geometry, material[1]);

        insideMesh.position.copy(shift);
        insideMesh.setRotationFromMatrix(reverseRotation);
        this.createUVMapping(geometry);

        let edges = new EdgesGeometry(geometry);
        let line = new LineSegments(
          edges,
          new LineBasicMaterial({ color: 0x000000 })
        );

        line.rotation.copy(insideMesh.rotation);
        line.position.copy(shift);
        if (this.settings.showBorders) {
          result.push(line);
        }

        result.push(insideMesh);
      }

      return result;
    },
    generateButtons: function(fig: Representation): Object3D[] {
      let result: Object3D[] = [];
      fig.connectionLineIndexes.forEach((lineIndex) => {
        let choose = this.selectedSide
          ? this.selectedSide.elem.id === fig.id &&
            this.selectedSide.side === lineIndex
          : false;
        const hovered =
          this.hoveredSide?.id === fig.id &&
          this.hoveredSide.side === lineIndex;

        let geometry = new CircleGeometry(50 / 8, 32);
        let mesh = new Mesh(
          geometry,
          new MeshBasicMaterial({
            color:
              choose || hovered ? new Color(0x4811ae) : new Color(0x000000),
            opacity: this.settings.opacity,
            transparent: true,
            side: THREE.DoubleSide,
          })
        );
        mesh.name = JSON.stringify({
          id: fig.id,
          type: "add",
          side: lineIndex,
        });
        let line = fig.shape[lineIndex];
        let connectionPointPosition = line.startPoint
          .clone()
          .add(line.endPoint)
          .multiplyScalar(0.5);
        connectionPointPosition.z += 1;
        mesh.position.copy(connectionPointPosition);
        result.push(mesh);
      });

      return result;
    },
    generatePlus: function(figure: Representation, side: Object3D[]): void {
      let geometryPlus = new CircleGeometry(50 / 8, 32);

      let meshPlus = new Mesh(
        geometryPlus,
        new MeshBasicMaterial({
          color: new Color(0x000000),
          opacity: this.settings.opacity,
          transparent: true,
          side: THREE.DoubleSide,
        })
      );

      meshPlus.name = JSON.stringify({
        id: figure.id,
        type: "addTexture",
      });

      const lines = figure.shape.map((line) => {
        return {
          x: line.startPoint.x,
          y: line.startPoint.y,
        };
      });
      const center = this.getCenterMesh(lines);

      meshPlus.position.copy(center);
      side.push(meshPlus);
    },
    getCenterMesh: function(lines) {
      const first = lines[0];
      const last = lines[lines.length - 1];
      if (first.x != last.x || first.y != last.y) lines.push(first);
      let twicearea = 0;
      let x = 0;
      let y = 0;
      let nPts = lines.length;
      let p1;
      let p2;
      let f;
      for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
        p1 = lines[i];
        p2 = lines[j];
        f = p1.x * p2.y - p2.x * p1.y;
        twicearea += f;
        x += (p1.x + p2.x) * f;
        y += (p1.y + p2.y) * f;
      }
      f = twicearea * 3;
      return new THREE.Vector3(x / f, y / f, 1);
    },
    renderGrid: function() {
      if (this.settings.showGrid && !this.isIframePage) {
        this.scene.remove(this.grid);
        this.grid = new GridHelper(Math.pow(2, this.gridPow) * 15, 1000);
        if (this.is2d) {
          this.grid.rotateZ(Math.PI / 2);
        }
        this.scene.add(this.grid);
      } else {
        this.scene.remove(this.grid);
      }
    },
    animate: function() {
      requestAnimationFrame(this.animate);
      this.controls.update();

      let distance = this.camera.position.distanceTo(
        new Vector3(
          0,
          this.is2d ? this.camera.position.y : 0,
          this.is2d ? this.camera.position.z : 0
        )
      );
      const aspect = this.isIframePage ? 1 : this.$el.clientWidth / this.$el.clientHeight;
      this.camera.aspect = aspect;
      this.camera.updateProjectionMatrix();

      if (distance * 2 > Math.pow(2, this.gridPow + 1)) {
        this.gridPow++;
        this.renderGrid();
      }

      if (distance * 2 < Math.pow(2, this.gridPow - 1)) {
        this.gridPow--;
        this.renderGrid();
      }

      this.localRenderer.render(this.scene, this.camera);
    },
    onDocumentMouseMove(event: MouseEvent) {
      const pos = this.getCanvasRelativePosition(event);
      this.mouse.x =
        (pos.x / this.localRenderer.domElement.clientWidth) * 2 - 1;
      this.mouse.y =
        (pos.y / this.localRenderer.domElement.clientHeight) * -2 + 1;
    },
    getCanvasRelativePosition(event: MouseEvent) {
      const rect = this.localRenderer.domElement.getBoundingClientRect();
      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
    },
  },
  components: {
    ResetCameraBtn,
    TextureSettings,
  },
});

export default vm;

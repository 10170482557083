/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/prefer-default-export,react/prop-types */
import React from 'react';
// import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  HandIcon, RulerIcon, ImageFieldIcon, TextFieldIcon
} from '../components/ToolIcons';
import { withStyles } from '@material-ui/core';

import {materialTheme} from '../../materialTheme'

const { palette } = materialTheme

const classes = () => ({
  editorTools: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px 0',
    background: palette.paleBlue.main,
    width: 52,
    height: 252,
    borderRadius: 100,
    position: 'absolute',
    left: 48,
    top: 48,
    zIndex: 1000,
  },
  editorToolsNoFile: {
    '&:after': {
      content: "' '",
      height: 252,
      position: 'absolute',
      background: '#F2FAFF',
      width: 52,
      opacity: 0.5,
    }
  },
  editorButton: {
    width: 44,
    height: 44,
    display: 'flex',
    borderRadius: 10,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: palette.blue.main,
    }
  },
  editorButtonActive: {
    background: palette.blue.main,
  }
});

class EditorInfo extends React.Component {

  editorButtons = [
    {
      name: 'hand',
      icon: HandIcon.render(),
      active: this.props.handActive,
      buttonHandler: () => {
        this.props.onHandClick();
        this.editorButtons[0].active = !this.editorButtons[0].active;
      },
    },
    {
      name: 'text field',
      icon: TextFieldIcon.render(),
      active: this.props.currentRect?.fontFamily && this.props.textActive,
      buttonHandler: () => {
        this.props.onTextFieldClick();
        this.editorButtons[0].active = false
        this.props.onSetActive()
      } 
    },
    {
      name: 'image field',
      icon: (
        <div>
          <input
            style={{ display: 'none' }}
            id="uploadImage"
            type="file"
            onChange={e => this.props.onImageFieldClick(e.target.files[0])}
            accept="image/*"
          />
          <label
            htmlFor="uploadImage"
            style={{
              position: 'absolute',
              marginBottom: 0,
              height: 24,
              padding: 24,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
          </label>
          {ImageFieldIcon.render()}
        </div>
      ),
      active: this.props.currentRect !== undefined && !this.props.currentRect?.fontFamily && this.props.imageActive,
      buttonHandler: () => {
       
      },
    },
    {
      name: 'ruler',
      icon: RulerIcon.render({ props: {}, active: true }),
      active: false,
      buttonHandler: () => {
        this.props.onRulerClick()
        this.editorButtons[3].active = !this.editorButtons[3].active;
      },
    },
  ];

  render() {
    const { classes, disabled } = this.props

    return (
      
      <div className={`${classes.editorTools} ${disabled && classes.editorToolsNoFile}`}>
        {this.editorButtons.map(button => (
          <div style={{position: 'relative'}}
            aria-hidden="true"
            key={button.name}
            onClick={() => button?.buttonHandler()}
            className={`${classes.editorButton} ${button.active && classes.editorButtonActive}`}
          >
            {button.icon}
          </div>
        ))
        }
      </div>
    );
  }
}

export default withStyles(classes)(EditorInfo);














// const useStyles = makeStyles(({ palette }) => ({
//   editorTools: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '4px 0',
//     background: palette.paleBlue.main,
//     width: 52,
//     height: 252,
//     borderRadius: 100,
//     position: 'absolute',
//     left: 48,
//     top: 48,
//     zIndex: 1000,
//   },
//   editorToolsNoFile: {
//     '&:after': {
//       content: "' '",
//       height: 252,
//       position: 'absolute',
//       background: '#F2FAFF',
//       width: 52,
//       opacity: 0.5,
//     }
//   },
//   editorButton: {
//     width: 44,
//     height: 44,
//     display: 'flex',
//     borderRadius: 10,
//     cursor: 'pointer',
//     alignItems: 'center',
//     justifyContent: 'center',
//     '&:hover': {
//       background: palette.blue.main,
//     }
//   },
//   editorButtonActive: {
//     background: palette.blue.main,
//   }
// }));

// const EditorTools = ({
//   disabled,
//   handActive,
//   textActive,
//   imageActive,
//   currentRect,
//   rulerActive,
//   onHandClick,
//   onRulerClick,
//   onTextFieldClick,
//   onImageFieldClick,
// }) => {
//   const classes = useStyles();
//   const editorButtons = [
//     {
//       name: 'hand',
//       icon: <HandIcon />,
//       active: handActive,
//       buttonHandler: onHandClick,
//     },
//     {
//       name: 'text field',
//       icon: <TextFieldIcon />,
//       active: currentRect?.fontFamily && textActive,
//       buttonHandler: onTextFieldClick,
//     },
//     {
//       name: 'image field',
//       icon: (
//         <>
//           <input
//             style={{ display: 'none' }}
//             id="uploadImage"
//             type="file"
//             onChange={e => onImageFieldClick(e.target.files[0])}
//           />
//           <label
//             htmlFor="uploadImage"
//             style={{
//               marginBottom: 0,
//               height: 24,
//               padding: 24,
//               cursor: 'pointer',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center'
//             }}
//           >
//             <ImageFieldIcon />
//           </label>
//         </>
//       ),
//       active: currentRect !== undefined && !currentRect?.fontFamily && imageActive,
//       buttonHandler: () => {
//       },
//     },
//     {
//       name: 'ruler',
//       icon: <RulerIcon active={rulerActive} />,
//       buttonHandler: onRulerClick,
//     },
//   ];


//   return (
//     <div className={`${classes.editorTools} ${disabled && classes.editorToolsNoFile}`}>
//       {editorButtons.map(button => (
//         <div
//           aria-hidden="true"
//           key={button.name}
//           onClick={() => button?.buttonHandler()}
//           className={`${classes.editorButton} ${button.active && classes.editorButtonActive}`}
//         >
//           {button.icon}
//         </div>
//       ))
//       }
//     </div>
//   );
// };

// export default EditorTools;

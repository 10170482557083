"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PdfRepresentationHelper = void 0;
var three_1 = require("three");
var physicalQuantities = {
    mm: 2.834645669
};
var PdfRepresentationHelper = /** @class */ (function () {
    function PdfRepresentationHelper() {
    }
    PdfRepresentationHelper.mapToPdfRepresentation = function (source, meterage) {
        var SheetScaling = new three_1.Vector3(physicalQuantities["mm"], -physicalQuantities["mm"], 0);
        var lines = source.reduce(function (acc, figure) {
            return acc.concat(figure.shape);
        }, []);
        var cutLines = source.reduce(function (acc, figure) {
            return acc.concat(figure.holesShape);
        }, []);
        lines.forEach(function (line) { return line.applyScaling(SheetScaling); });
        cutLines.forEach(function (holeShape) { return holeShape.forEach(function (line) { return line.applyScaling(SheetScaling); }); });
        var minC = new three_1.Vector3(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        var maxC = new three_1.Vector3(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);
        lines.forEach(function (line) {
            minC.x = Math.min(minC.x, line.startPoint.x, line.endPoint.x);
            minC.y = Math.min(minC.y, line.startPoint.y, line.endPoint.y);
            maxC.x = Math.max(maxC.x, line.startPoint.x, line.endPoint.x);
            maxC.y = Math.max(maxC.y, line.startPoint.y, line.endPoint.y);
        });
        var padding = new three_1.Vector3(this.SheetPadding.y - minC.x, this.SheetPadding.x - minC.y);
        lines.forEach(function (line) { return line.add(padding); });
        cutLines.forEach(function (holeShape) { return holeShape.forEach(function (line) { return line.add(padding); }); });
        var size = [
            maxC.x - minC.x + this.SheetPadding.x * 2,
            maxC.y - minC.y + this.SheetPadding.y * 2
        ]; // this.SheetPadding.y * 2
        return {
            representations: source,
            size: size
        };
    };
    PdfRepresentationHelper.SheetPadding = new three_1.Vector3(14.2, 14.2); // 5 5 mm
    return PdfRepresentationHelper;
}());
exports.PdfRepresentationHelper = PdfRepresentationHelper;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';

const circle = `${process.env.VUE_APP_API_URL}/icons/circleControls.svg`;
const rotate = `${process.env.VUE_APP_API_URL}/icons/rotateButton.svg`;
const close = `${process.env.VUE_APP_API_URL}/icons/closeButton.svg`;
const resize = `${process.env.VUE_APP_API_URL}/icons/resizeButton.svg`;
const resizeFromCenter = `${process.env.VUE_APP_API_URL}/icons/resizeFromCenter.svg`;
const resizeFromEdge = `${process.env.VUE_APP_API_URL}/icons/resizeFromEdge.svg`;

const isMobile = window.innerWidth < 520;

const circleIcon = document.createElement('img');
circleIcon.src = circle;
const rotateIcon = document.createElement('img');
rotateIcon.src = rotate;
const closeIcon = document.createElement('img');
closeIcon.src = close;
const resizeIcon = document.createElement('img');
resizeIcon.src = resize;
const resizeFromCenterIcon = document.createElement('img');
resizeFromCenterIcon.src = resizeFromCenter;
const resizeFromEdgeIcon = document.createElement('img');
resizeFromEdgeIcon.src = resizeFromEdge;

function renderIcon(ctx, left, top, styleOverride, fabricObject, icon) {
  let iconEl = document.createElement('img');
  let size = 24;
  switch (icon) {
    case circle:
      size = 8;
      iconEl = circleIcon;
      break;
    case rotate:
      size = isMobile ? 36 : 24;
      iconEl = rotateIcon;
      break;
    case close:
      size = isMobile ? 36 : 24;
      iconEl = closeIcon;
      break;
    case resize:
      size = isMobile ? 36 : 24;
      iconEl = resizeIcon;
      break;
    case resizeFromCenter:
      size = isMobile ? 36 : 24;
      iconEl = resizeFromCenterIcon;
      break;
    case resizeFromEdge:
      size = isMobile ? 36 : 24;
      iconEl = resizeFromEdgeIcon;
      break;
  }
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(iconEl, -size / 2, -size / 2, size, size);
  ctx.restore();
}

renderIcon.bind(fabric);

function renderRotate(ctx, left, top, styleOverride, fabricObject) {
  renderIcon(ctx, left, top, styleOverride, fabricObject, rotate);
}

function renderCorners(ctx, left, top, styleOverride, fabricObject) {
  renderIcon(ctx, left, top, styleOverride, fabricObject, circle);
}

function renderClose(ctx, left, top, styleOverride, fabricObject) {
  renderIcon(ctx, left, top, styleOverride, fabricObject, close);
}

function renderResize(ctx, left, top, styleOverride, fabricObject) {
  renderIcon(ctx, left, top, styleOverride, fabricObject, resize);
}

function renderResizeFromEdge(ctx, left, top, styleOverride, fabricObject) {
  renderIcon(ctx, left, top, styleOverride, fabricObject, resizeFromEdge);
}

function renderResizeFromCenter(ctx, left, top, styleOverride, fabricObject) {
  renderIcon(ctx, left, top, styleOverride, fabricObject, resizeFromCenter);
}

function controlOptions(x, y, offsetY, actionName, cornerSize, render, actionHandler) {
  const options = {
    x,
    y,
    offsetY,
    visible: true,
    cursorStyle: 'pointer',
    actionName,
    render,
    cornerSize,
  };

  if (actionHandler) {
    options.actionHandler = actionHandler;
  }

  return options;
}

function customizeFabric(prototype) {
  let changeWidthType = fabric.controlsUtils.scalingX;
  let changeHeightType = fabric.controlsUtils.scalingY;

  const middleDots = [
    { name: 'ml', x: -0.5, y: 0 },
    { name: 'mr', x: 0.5, y: 0 },
  ];
  const disabledMiddleDots = [];
  const topAndButtonDots = [
    { name: 'mt', x: 0, y: -0.5 },
    { name: 'mb', x: 0, y: 0.5 },
  ];

  prototype.controls.mtr = new fabric.Control(
    controlOptions(0, 0.5, 0, 'rotate', 24, renderRotate, fabric.controlsUtils.rotationWithSnapping)
  );

  prototype.controls.tl = new fabric.Control(
    controlOptions(-0.5, -0.5, 0, 'scale', 24, renderResize, fabric.controlsUtils.scalingEqually)
  );

  prototype.controls.tr = new fabric.Control(
    controlOptions(0.5, -0.5, 0, 'scale', 24, renderClose)
  );

  prototype.controls.bl = new fabric.Control(
    controlOptions(0, 0.5, 24, 'scale', 24, renderResizeFromEdge)
  );

  prototype.controls.bl = new fabric.Control(
    controlOptions(0, 0.5, 24, 'scale', 24, renderResizeFromCenter)
  );

  prototype.controls.br = new fabric.Control(
    controlOptions(0.5, 0.5, 0, 'scale', 24, renderResize, fabric.controlsUtils.scalingEqually)
  );

  prototype.controls.mtr = new fabric.Control(
    controlOptions(-0.5, 0.5, 0, 'rotate', 24, renderRotate, fabric.controlsUtils.rotationWithSnapping)
  );
  disabledMiddleDots.forEach(entry => {
    prototype.controls[entry] = new fabric.Control({ visible: false });
  });

  if (prototype.type === 'textbox') {
    changeWidthType = fabric.controlsUtils.changeWidth;
  }

  middleDots.forEach(({ name, x, y }) => {
    prototype.controls[name] = new fabric.Control(controlOptions(x, y, 0, 'scale', 8, renderCorners, changeWidthType));
  });

  topAndButtonDots.forEach(({ name, x, y }) => {
    prototype.controls[name] = new fabric.Control(controlOptions(x, y, 0, 'scale', 8, renderCorners, changeHeightType));
  });
}

const prototypes = [fabric.Textbox.prototype, fabric.Image.prototype];

prototypes.forEach(prototype => customizeFabric(prototype));


const helperLineOpts = {
  stroke: '#00C0FF',
  strokeWidth: 2,
  strokeDashArray: [10, 10],
  hasControls: false,
  hasBorders: false,
  selectable: false,
  lockMovementX: true,
  lockMovementY: true,
  hoverCursor: 'default',
  originX: 'center',
  originY: 'center'
};

class DesignCanvas extends React.Component {
  scrollXPos = 0;

  helperXLine = null;

  scrollYPos = 0;

  helperYLine = null

  xLine = null;

  yLine = null;

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    setCanvas: PropTypes.func.isRequired,
    setGetObjects: PropTypes.func.isRequired,
    children: PropTypes.instanceOf(Array).isRequired
  };

  static defaultProps = {
    width: '100',
    height: '100'
  };

  state = {
    canvas: null
  };

  componentDidMount() {
    const {
      height, width, setGetObjects, setCanvas
    } = this.props;
    const canvas = new fabric.Canvas(this.c, {
      preserveObjectStacking: true,
    });
    canvas.setHeight(height);
    canvas.setWidth(width);

    setGetObjects(this.canvasToJson);
    setCanvas(canvas);

    const canvasEvents = ['object:moving', 'object:scaling', 'mouse:up'];

    let left1 = 0;
    let top1 = 0;
    let scale1x = 0;
    let scale1y = 0;
    let width1 = 0;
    let height1 = 0;

    ['moving', 'scaling', 'rotating', 'skewing', 'moved', 'scaled', 'rotated', 'skewed', 'down'].forEach(event => {
      if (event === 'down') {
        canvas.on(`mouse:${event}`, e => {
          if (!this.props.handActive && !this.props.lock) {
            if (canvas.getActiveObject()) this.changeXYLines(e);
        
          }
        });
      } else {
        if (!this.props.handActive && !this.props.lock) {
          canvas.on(`object:${event}`, this.changeXYLines);
          }
      }
    });

    canvasEvents.forEach(e => canvas.on(e, event => {
      const { target, e: { clientX, clientY } } = event;

      if (target && target.type !== 'line' && target.canvas) {
          const centerRadiusX = target.left + (target.width * target.scaleX) / 2 - target.canvas.width / 2;
          const centerRadiusY = target.top + (target.height * target.scaleY) / 2 - target.canvas.height / 2;
          if (this.scrollXPos) {
          const [fromX, toX] = clientX > this.scrollXPos ? [this.scrollXPos, clientX] : [clientX, this.scrollXPos];
          if (toX - fromX > 2) {
            target.lockMovementX = false;
            this.scrollXPos = 0;
            if (this.helperXLine) {
              canvas.remove(this.helperXLine);
              this.helperXLine = null;
            }
          }
        } else if (centerRadiusX > 0 && centerRadiusX < 5) {
          this.scrollXPos = clientX;
          if (!target.lockMovementY) target.lockMovementX = true;
          this.helperXLine = new fabric.Line([target.canvas.width / 2, 0, target.canvas.width / 2, target.canvas.height], helperLineOpts);

          canvas.add(this.helperXLine);
        }
        if (this.scrollYPos) {
          const [fromY, toY] = clientY > this.scrollYPos ? [this.scrollYPos, clientY] : [clientY, this.scrollYPos];
          if (toY - fromY > 2) {
            target.lockMovementY = false;
            this.scrollYPos = 0;
            if (this.helperYLine) {
              canvas.remove(this.helperYLine);
              this.helperYLine = null;
            }
          }
        } else if (centerRadiusY > 0 && centerRadiusY < 5) {
          this.scrollYPos = clientY;
          if (!target.lockMovementX) target.lockMovementY = true;
          this.helperYLine = new fabric.Line([0, target.canvas.height / 2, target.canvas.width, target.canvas.height / 2], helperLineOpts);

          canvas.add(this.helperYLine);
        }
      }

      if (!this.props.handActive && !this.props.lock) {
        if (event.target) this.changeXYLines(event);
      }  
    }));
    canvas.on('object:moving', () => {
      const obj = canvas.getActiveObject();
      if (obj.height * obj.scaleY > obj.canvas.height || obj.width * obj.scaleX > obj.canvas.width) {
        return;
      }

      obj.setCoords();

      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      }

      if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height
        || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width) {
        obj.top = Math.min(obj.top, obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
        obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
      }
    });
    canvas.on('object:scaling', () => {
      const obj = canvas.getActiveObject();
      obj.setCoords();

      if (obj.height * obj.scaleY > obj.canvas.height || obj.width * obj.scaleX > obj.canvas.width) {
        obj.left = left1;
        obj.top = top1;
        obj.scaleX = scale1x;
        obj.scaleY = scale1y;
        obj.width = width1;
        obj.height = height1;
      } else {
        left1 = obj.left;
        top1 = obj.top;
        scale1x = obj.scaleX;
        scale1y = obj.scaleY;
        width1 = obj.width;
        height1 = obj.height;
      }
    });

    if (!this.props.handActive && !this.props.lock) {
      canvas.on('selection:created', this.changeXYLines);
    }  
    canvas.on('before:selection:cleared', () => {
      if (this.helperXLine) {
        canvas.remove(this.helperXLine);
        this.helperXLine = null;
      }

      if (this.helperYLine) {
        canvas.remove(this.helperYLine);
        this.helperYLine = null;
      }

      if (this.xLine) {
        canvas.remove(this.xLine);
        this.xLine = null;
      }

      if (this.yLine) {
        canvas.remove(this.yLine);
        this.yLine = null;
      }
    });

    this.setState({ canvas });
  }

  changeXYLines = ({
    target: {
      left, height: rHeight, width: rWidth, top, canvas, scaleX, scaleY
    },
    target,
    transform,
    selected
  }) => {
    if (this.xLine) {
      canvas.remove(this.xLine);
    }

    if (this.yLine) {
      canvas.remove(this.yLine);
    }

    if (target?.type !== 'line') {
      if ((transform && (transform.action === 'rotate' || transform.action === 'drag' || transform.action === 'scale')) || (selected && selected.length)) {
        let anglePath;
        let coordsPath;
        if (selected && selected.length) {
          anglePath = selected[0].angle || 0;
          coordsPath = selected[0].aCoords;
        } else {
          anglePath = transform.target.angle;
          coordsPath = transform.target.aCoords;
        }

        const centerY = (coordsPath.br.y + coordsPath.tl.y) / 2;
        const centerX = (coordsPath.bl.x + coordsPath.tr.x) / 2;
        if (anglePath >= 0 && anglePath < 90) {
          const leg = coordsPath.bl.y - centerY;
          const leg1 = centerX - coordsPath.tl.x;
          const y = 180 - 90 - anglePath;
          const b = leg * (Math.sin((anglePath * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
          const b1 = leg1 * (Math.sin((anglePath * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
          this.yLine = new fabric.Line([coordsPath.bl.x + b, centerY, 0, centerY], helperLineOpts);
          this.xLine = new fabric.Line([centerX, coordsPath.tl.y + b1, centerX, 0], helperLineOpts);
          if (centerY > coordsPath.bl.y) {
            const newLeg = centerY - coordsPath.bl.y;
            const newY = anglePath;
            const newB = newLeg * (Math.sin(((180 - 90 - newY) * Math.PI) / 180) / Math.sin((newY * Math.PI) / 180));

            this.yLine = new fabric.Line([coordsPath.bl.x + newB, centerY, 0, centerY], helperLineOpts);
          }
          if (centerX < coordsPath.tl.x) {
            const newLeg = coordsPath.tl.x - centerX;
            const newY = 180 - 90 - anglePath;
            const newB = newLeg * (Math.sin((newY * Math.PI) / 180) / Math.sin((anglePath * Math.PI) / 180));
            this.xLine = new fabric.Line([centerX, coordsPath.tl.y + newB, centerX, 0], helperLineOpts);
          }
        } else if (anglePath >= 90 && anglePath < 180) {
          const leg = coordsPath.br.y - centerY;
          const leg1 = coordsPath.tl.x - centerX;
          const y = 180 - 90 - (anglePath - 90);
          const y1 = 180 - 90 - anglePath;
          const b = leg * (Math.sin(((anglePath - 90) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
          const b1 = leg1 * (Math.sin((y1 * Math.PI) / 180) / Math.sin((anglePath * Math.PI) / 180));
          this.yLine = new fabric.Line([coordsPath.br.x + b, centerY, 0, centerY], helperLineOpts);
          this.xLine = new fabric.Line([centerX, coordsPath.tl.y + b1, centerX, 0], helperLineOpts);
          if (centerX < coordsPath.bl.x) {
            const newLeg = coordsPath.bl.x - centerX;
            const newY = anglePath - 90;
            const newB = newLeg * (Math.sin(((180 - 90 - newY) * Math.PI) / 180) / Math.sin((newY * Math.PI) / 180));
            this.xLine = new fabric.Line([centerX, coordsPath.bl.y + newB, centerX, 0], helperLineOpts);
          }
          if (centerY > coordsPath.br.y) {
            const newLeg = centerY - coordsPath.br.y;
            const newY = anglePath - 90;
            const newB = newLeg * (Math.sin(((180 - 90 - newY) * Math.PI) / 180) / Math.sin((newY * Math.PI) / 180));
            this.yLine = new fabric.Line([coordsPath.br.x + newB, centerY, 0, centerY], helperLineOpts);
          }
        } else if (anglePath > 180 && anglePath < 270) {
          const leg = centerY - coordsPath.br.y;
          const leg1 = coordsPath.bl.x - centerX;
          const y = anglePath - 90;
          const b = leg * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
          const b1 = leg1 * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
          this.yLine = new fabric.Line([coordsPath.br.x + b, centerY, 0, centerY], helperLineOpts);
          this.xLine = new fabric.Line([centerX, coordsPath.bl.y + b1, centerX, 0], helperLineOpts);
          if (centerY > coordsPath.tr.y) {
            const neLeg = centerY - coordsPath.tr.y;
            const newY = anglePath - 180;
            const newB = neLeg * (Math.sin(((180 - 90 - newY) * Math.PI) / 180) / Math.sin((newY * Math.PI) / 180));
            this.yLine = new fabric.Line([coordsPath.tr.x + newB, centerY, 0, centerY], helperLineOpts);
          }
          if (centerX < coordsPath.br.x) {
            const newLeg = coordsPath.br.x - centerX;
            const newY = anglePath - 180;
            const newB = newLeg * (Math.sin(((180 - 90 - newY) * Math.PI) / 180) / Math.sin((newY * Math.PI) / 180));
            this.xLine = new fabric.Line([centerX, coordsPath.br.y + newB, centerX, 0], helperLineOpts);
          }
        } else if (anglePath > 270 && anglePath < 360) {
          const leg = centerY - coordsPath.tr.y;
          const leg1 = coordsPath.br.x - centerX;
          const y = anglePath - 180;
          const b = leg * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
          const b1 = leg1 * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
          this.yLine = new fabric.Line([coordsPath.tr.x + b, centerY, 0, centerY], helperLineOpts);
          this.xLine = new fabric.Line([centerX, coordsPath.br.y + b1, centerX, 0], helperLineOpts);
          if (centerX < coordsPath.tr.x) {
            const newLeg = coordsPath.tr.x - centerX;
            const newY = anglePath - 270;
            const newB = newLeg * (Math.sin(((180 - 90 - newY) * Math.PI) / 180) / Math.sin((newY * Math.PI) / 180));
            this.xLine = new fabric.Line([centerX, coordsPath.tr.y + newB, centerX, 0], helperLineOpts);
          }
          if (centerY > coordsPath.tl.y) {
            const newLeg = centerY - coordsPath.tl.y;
            const newY = anglePath - 270;
            const newB = newLeg * (Math.sin(((180 - 90 - newY) * Math.PI) / 180) / Math.sin((newY * Math.PI) / 180));
            this.yLine = new fabric.Line([coordsPath.tl.x + newB, centerY, 0, centerY], helperLineOpts);
          }
        }
        // if (transform.target.angle > 0 && transform.target.angle < 30) {
        //   const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   console.log(111111, transform.target.angle)
        //   // const centerY = (transform.target.aCoords.br.y + transform.target.aCoords.tl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(centerY)
        //   // console.log(111111, centerY)
        //   const leg = transform.target.aCoords.bl.y - centerY;
        //   const leg1 = centerX - transform.target.aCoords.tl.x
        //   // console.log(222222, leg)
        //   const y = 180 - 90 - transform.target.angle
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin((transform.target.angle * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin((transform.target.angle * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([transform.target.aCoords.bl.x + hypotenuse / 2, transform.target.aCoords.tl.y + b1, transform.target.aCoords.bl.x + hypotenuse / 2, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.bl.x + b, centerY, 0, centerY], helperLineOpts);
        // } else if (transform.target.angle > 0 && transform.target.angle < 90) {
        //   const leg1 = centerX - transform.target.aCoords.tl.x;
        //   const y = 180 - 90 - transform.target.angle
        //   const b1 = leg1 * (Math.sin((transform.target.angle * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.tl.y + b1, centerX, 0], helperLineOpts);
        //     if (centerY > transform.target.aCoords.bl.y) {
        //       const leg = centerY - transform.target.aCoords.bl.y
        //       // const leg1 = transform.target.aCoords.tl.x - centerX
        //
        //       const y = transform.target.angle
        //
        //       const b = leg * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //       // const b1 = leg1 * (Math.sin((transform.target.angle * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //
        //       this.yLine = new fabric.Line([transform.target.aCoords.bl.x + b, centerY, 0, centerY], helperLineOpts);
        //       // this.xLine = new fabric.Line([centerX, transform.target.aCoords.tl.y + b1, centerX, 0], helperLineOpts);
        //     }
        // } else if (transform.target.angle > 60 && transform.target.angle < 90) {
        //   console.log(111111, transform.target.angle)
        //   const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.br.y + transform.target.aCoords.tl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = centerY - transform.target.aCoords.bl.y
        //   const leg1 = transform.target.aCoords.tl.x - centerX
        //   // console.log(222222, leg)
        //   const y = 180 - 90 - transform.target.angle
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin((y * Math.PI) / 180) / Math.sin((transform.target.angle * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin((y * Math.PI) / 180) / Math.sin((transform.target.angle * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.tl.y + b1, centerX, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.bl.x + b, transform.target.aCoords.tl.y + hypotenuse / 2, 0, transform.target.aCoords.tl.y + hypotenuse / 2], helperLineOpts);
        // } else if (transform.target.angle > 90 && transform.target.angle < 123) {
        //   console.log(111111, transform.target.angle)
        //   // const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.tr.y + transform.target.aCoords.bl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = transform.target.aCoords.br.y - centerY
        //   const leg1 = transform.target.aCoords.tl.x - centerX
        //   // console.log(222222, leg)
        //   const y = 180 - 90 - (transform.target.angle - 90)
        //   const y1 = 180 - 90 - transform.target.angle
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin(((transform.target.angle - 90) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin((y1 * Math.PI) / 180) / Math.sin((transform.target.angle * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.tl.y + b1, centerX, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.br.x + b, centerY, 0, centerY], helperLineOpts);
        // } else if (transform.target.angle > 123 && transform.target.angle < 146) {
        //   console.log(111111, transform.target.angle)
        //   // const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.tr.y + transform.target.aCoords.bl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = transform.target.aCoords.br.y - centerY
        //   const leg1 = transform.target.aCoords.bl.x - centerX
        //   // console.log(222222, leg)
        //   const y = 180 - 90 - (transform.target.angle - 90)
        //   const y1 = transform.target.angle - 90
        //   console.log(222222, y1)
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin(((transform.target.angle - 90) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin(((180 - 90 - y1) * Math.PI) / 180) / Math.sin((y1 * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.bl.y + b1, centerX, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.br.x + b, centerY, 0, centerY], helperLineOpts);
        // } else if (transform.target.angle > 146 && transform.target.angle < 213) {
        //   console.log(111111, transform.target.angle)
        //   // const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.tr.y + transform.target.aCoords.bl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = centerY - transform.target.aCoords.br.y
        //   const leg1 = transform.target.aCoords.bl.x - centerX
        //   // console.log(222222, leg)
        //   const y = transform.target.angle - 90
        //   const y1 = transform.target.angle - 90
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin(((180 - 90 - y1) * Math.PI) / 180) / Math.sin((y1 * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.bl.y + b1, centerX, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.br.x + b, centerY, 0, centerY], helperLineOpts);
        // } else if (transform.target.angle > 213 && transform.target.angle < 237) {
        //   console.log(111111, transform.target.angle)
        //   // const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.tr.y + transform.target.aCoords.bl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = centerY - transform.target.aCoords.tr.y
        //   const leg1 = transform.target.aCoords.bl.x - centerX
        //   // console.log(222222, leg)
        //   const y = transform.target.angle - 180
        //   const y1 = transform.target.angle - 90
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin(((180 - 90 - y1) * Math.PI) / 180) / Math.sin((y1 * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.bl.y + b1, centerX, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.tr.x + b, centerY, 0, centerY], helperLineOpts);
        // } else if (transform.target.angle > 237 && transform.target.angle < 303) {
        //   console.log(111111, transform.target.angle)
        //   // const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.tr.y + transform.target.aCoords.bl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = centerY - transform.target.aCoords.tr.y
        //   const leg1 = transform.target.aCoords.br.x - centerX
        //   // console.log(222222, leg)
        //   const y = transform.target.angle - 180
        //   const y1 = transform.target.angle - 180
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin(((180 - 90 - y1) * Math.PI) / 180) / Math.sin((y1 * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.br.y + b1, centerX, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.tr.x + b, centerY, 0, centerY], helperLineOpts);
        // } else if (transform.target.angle > 303 && transform.target.angle < 327) {
        //   console.log(111111, transform.target.angle)
        //   // const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.tr.y + transform.target.aCoords.bl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = centerY - transform.target.aCoords.tr.y
        //   const leg1 = transform.target.aCoords.tr.x - centerX
        //   // console.log(222222, leg)
        //   const y = transform.target.angle - 180
        //   const y1 = transform.target.angle - 270
        //   console.log(222222222, y1)
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin(((180 - 90 - y1) * Math.PI) / 180) / Math.sin((y1 * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.tr.y + b1, centerX, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.tr.x + b, centerY, 0, centerY], helperLineOpts);
        // } else if (transform.target.angle > 327) {
        //   console.log(111111, transform.target.angle)
        //   // const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.tr.y + transform.target.aCoords.bl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = centerY - transform.target.aCoords.tl.y
        //   const leg1 = transform.target.aCoords.tr.x - centerX
        //   // console.log(222222, leg)
        //   const y = transform.target.angle - 270
        //   const y1 = transform.target.angle - 270
        //   console.log(222222222, y1)
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin(((180 - 90 - y) * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin(((180 - 90 - y1) * Math.PI) / 180) / Math.sin((y1 * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([centerX, transform.target.aCoords.tr.y + b1, centerX, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.tl.x + b, centerY, 0, centerY], helperLineOpts);
        // } else {
        //   console.log(111111, transform.target.angle)
        //   const hypotenuse = Math.sqrt((transform.target.width * transform.target.width) + (transform.target.height * transform.target.height));
        //   // const centerY = (transform.target.aCoords.br.y + transform.target.aCoords.tl.y) / 2;
        //   // const centerX = (transform.target.aCoords.bl.x + transform.target.aCoords.tr.x) / 2;
        //   // console.log(111111, centerY)
        //   const leg = centerY - transform.target.aCoords.bl.y
        //   const leg1 = centerX - transform.target.aCoords.tl.x
        //   // console.log(222222, leg)
        //   const y = 180 - 90 - transform.target.angle
        //   // console.log(33333, y)
        //   // console.log(44444, transform.target.angle)
        //   // console.log(55555555, transform.target.aCoords.bl.y, centerY)
        //   // console.log(6666666666666666, Math.sin((y * Math.PI) / 180))
        //   // console.log(7777777777777777, Math.sin((transform.target.angle * Math.PI) / 180))
        //   const b = leg * (Math.sin((y * Math.PI) / 180) / Math.sin((transform.target.angle * Math.PI) / 180));
        //   const b1 = leg1 * (Math.sin((transform.target.angle * Math.PI) / 180) / Math.sin((y * Math.PI) / 180));
        //   // console.log(44444, b)
        //   this.xLine = new fabric.Line([transform.target.aCoords.bl.x + hypotenuse / 2, transform.target.aCoords.tl.y + b1, transform.target.aCoords.bl.x + hypotenuse / 2, 0], helperLineOpts);
        //   this.yLine = new fabric.Line([transform.target.aCoords.bl.x + b, transform.target.aCoords.tl.y + hypotenuse / 2, 0, transform.target.aCoords.tl.y + hypotenuse / 2], helperLineOpts);
        // }
      } else {
        // console.log(3333333333333333333);
        this.xLine = new fabric.Line([rWidth * scaleX / 2 + left, top, rWidth * scaleX / 2 + left, 0], helperLineOpts);
        this.yLine = new fabric.Line([left, rHeight * scaleY / 2 + top, 0, rHeight * scaleY / 2 + top], helperLineOpts);
      }
      // this.xLine = new fabric.Line([rWidth / 2 + left, top, rWidth / 2 + left, 0], helperLineOpts);
      // this.yLine = new fabric.Line([left, rHeight / 2 + top, 0, rHeight / 2 + top], helperLineOpts);

      // console.log(3333333333333, this.yLine)
      // console.log(4444444444444, rWidth / 2 + left, top, rWidth / 2 + left, 0)
      if (canvas) {
        canvas.add(this.yLine);
        canvas.add(this.xLine);
      }
    }
  }

  canvasToJson = () => {
    const { canvas } = this.state;
    console.log('CANVAS: ', canvas.toJSON());
    return canvas.toJSON();
  };

  render() {
    const { canvas } = this.state;
    const { children } = this.props;


    const canvasChildren = React.Children.map(children, child => React.cloneElement(child, {
      canvas
    }));

    const { width, height } = this.props;
    if (canvas) {
      canvas.setHeight(height);
      canvas.setWidth(width);
    }

    return (
      <Fragment>
        <canvas
          ref={c => {
            this.c = c;
          }}
          width={width}
          height={height}
        />
        {canvas && canvasChildren}
      </Fragment>
    );
  }
}

export default DesignCanvas;

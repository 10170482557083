"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RotateObject = void 0;
var command_1 = require("./command");
var RotateObject = /** @class */ (function (_super) {
    __extends(RotateObject, _super);
    function RotateObject(nodes, representations, parentNodeId, childId, clockwise) {
        var _this = _super.call(this, nodes) || this;
        _this._parentNodeId = parentNodeId;
        _this._childId = childId;
        _this._clockwise = clockwise;
        _this._representations = representations;
        return _this;
    }
    RotateObject.prototype.action = function () {
        var _this = this;
        var _a;
        var parentNode = this.nodes.find(function (node) { return node.id == _this._parentNodeId; });
        var childRepresentation = this._representations.find(function (node) { return node.id == _this._childId; });
        if (!!parentNode && !!childRepresentation) {
            var childConnectionInfo_1 = (_a = parentNode.childs) === null || _a === void 0 ? void 0 : _a.find(function (child) { return child.childId == _this._childId; });
            if (!!childConnectionInfo_1) {
                var nextIndex = childRepresentation.connectionLineIndexes.findIndex(function (index) {
                    if (childConnectionInfo_1) {
                        return index > childConnectionInfo_1.childEdgeIndex;
                    }
                });
                if (nextIndex < 0) {
                    nextIndex = 0;
                }
                var previousIndex = nextIndex - 1;
                if (previousIndex < 0) {
                    previousIndex = childRepresentation.connectionLineIndexes.length - 1;
                }
                if (this._clockwise) {
                    childConnectionInfo_1.childEdgeIndex = childRepresentation.connectionLineIndexes[previousIndex];
                }
                else {
                    childConnectionInfo_1.childEdgeIndex = childRepresentation.connectionLineIndexes[nextIndex];
                }
            }
        }
    };
    return RotateObject;
}(command_1.Command));
exports.RotateObject = RotateObject;

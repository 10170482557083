
































































































import { Vue } from "vue-property-decorator";
import { PropOptions } from "vue";
import { ValidateResult, InputsConfig, Options, HoleOption } from "@/types";
import _ from "lodash";
import { Representation } from "packaging3d-engine/lib/core/base/representation";

enum mmDependence {
  cm = 10,
  ft = 304.8,
  in = 25.4,
  mm = 1,
}

let vm = Vue.component("ObjectSettings", {
  props: {
    meterage: String,
    rootId: Number,
    selectedObject: {
      type: Object,
    } as PropOptions<Representation>,
  },
  data: function() {
    const inputState: InputsConfig = _.cloneDeep(this.selectedObject.config);

    return {
      configState: _.cloneDeep(this.selectedObject.config),
      selectedObjOptions: this.selectedObject.config.options,
      inputState,
      name: this.selectedObject.name,
      closedBoxAngle: 0,
      mmDependence,
    };
  },
  mounted: function() {
    this.initPhysicalSizes();
  },
  computed: {
    isRoot: function(): boolean {
      return this.selectedObject?.id == this.rootId; // TODO !this.selectedObject.parent;
    },
    disabled: function(): boolean {

      return this.selectedObject.childs.length > 0 || this.selectedObject.connectionLineIndexes.length == 0;
    },
    metric: function(): number {
      return this.mmDependence[this.meterage as keyof typeof mmDependence];
    },
    checkFrame: function() {
      let isFramed = false;
      try {
        isFramed =
          window != window.top ||
          document != top.document ||
          self.location != top.location;
      } catch (e) {
        isFramed = true;
      }
      return isFramed;
    },
  },
  methods: {
    rotate: function() {
      this.$emit("rotate", this.selectedObject);
    },
    addHole: function() {
      this.$emit("addHole");
    },
    addText: function() {
      this.$emit("addText");
    },
    initPhysicalSizes() {
      for (let option in this.inputState.options) {
        if (option !== "holesOptions") {
          this.inputState.options[option] =
            this.configState.options[option] / this.metric;
        }
      }
    },
    setAsRotatedEdge: function() {
      this.$emit("set_rotated_edge", this.selectedObject, this.closedBoxAngle);
    },
    update: async function() {
      this.$emit("update", this.configState);
    },
    changeAngle: function(event: InputEvent) {
      const floatNumber = /^[0-9]*\.?[0-9]*$/gm;
      const target = event.target as HTMLInputElement;
      const { newConfigValue, newInputValue } = this.validateInput(
        floatNumber,
        event,
        this.configState.angle
      );
      target.value = newInputValue.toString();
      this.inputState.angle = newInputValue;
      this.configState.angle = +newConfigValue * -1 as number;
    },
    changeSize: function(event: InputEvent) {
      const floatNumber = /^-?[0-9]*\.?[0-9]*$/gm;
      const target = event.target as HTMLInputElement;
      const { name } = target;
      let { newConfigValue, newInputValue } = this.validateInput(
        floatNumber,
        event,
        this.configState.options[name],
        0
      );
      
      newConfigValue = isNaN(newConfigValue as number) ? 0 : newConfigValue;
      target.value = newInputValue.toString();
      this.inputState.options[name] = newInputValue;
      this.configState.options[name] = Number(newConfigValue) * this.metric;
    },
    changeSizeHole: function(
      event: InputEvent,
      optionName: string,
      index: number
    ) {
      const floatNumber = /^-?[0-9]*\.?[0-9]*$/gm;
      const target = event.target as HTMLInputElement;
      const { name } = target;
      if (this.inputState.holesOptions && this.configState.holesOptions) {
        const optionsState = this.configState.holesOptions[index] as any;
        const optionsInput = this.inputState.holesOptions[index] as any;

        let { newConfigValue, newInputValue } = this.validateInput(
          floatNumber,
          event,
          optionsState[optionName],
          0
        );
        newConfigValue = isNaN(newConfigValue as number) ? 0 : newConfigValue;
        target.value = newInputValue.toString();
        optionsInput[optionName] = newInputValue;
        optionsState[optionName] = Number(newConfigValue) * this.metric;
      }
    },
    remove: function(obj: Representation) {
      this.$emit("remove", obj);
    },
    validateInput: function(
      regExp: RegExp,
      event: InputEvent,
      previousValue: number,
      minValue?: number
    ): ValidateResult {
      const { value, name } = event.target as HTMLInputElement;
      const isInputFilled = !!value;
      const isValidValue = regExp.test(value);
      const boundaryValue = 100000; 
      let newInputValue: number | string;
      let newConfigValue: string | number = 0;
      if (isValidValue && isInputFilled) {
        const parsedValue = parseFloat(value);
        newConfigValue = minValue
          ? parsedValue >= minValue
            ? parsedValue
            : minValue
          : parsedValue;
        newInputValue = minValue
          ? parsedValue >= minValue
            ? value
            : minValue
          : value;
      } else if (isValidValue && !isInputFilled) {
        newConfigValue = minValue ?? 0;
        newInputValue = "";
      } else {
        newInputValue = previousValue;
        newConfigValue = previousValue;
      }

      if(newConfigValue > boundaryValue) {
        newInputValue = boundaryValue;
        newConfigValue = `${boundaryValue}`;
      }

      return { newInputValue, newConfigValue };
    },
  },
  watch: {
    selectedObject: function(newVal: any, oldVal: any) {
      this.configState = _.cloneDeep(newVal.config);
      this.inputState = _.cloneDeep(newVal.config);
      this.closedBoxAngle = 0;
      this.name = this.selectedObject.name!;
      this.initPhysicalSizes();
    },
    meterage: "initPhysicalSizes",
  },
});

export default vm;

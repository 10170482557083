













































































import Vue from "vue";
import axios from "axios";
import jwt_decode from "jwt-decode";
import i18n from "./i18n";

const vm = Vue.component("App", {
  mounted: async function() {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/api/v1/checkForAuth`,
        { withCredentials: true }
      );

      this.$store.commit("setAuthorized");
    } catch (error) {
      console.log(error);
      this.$store.commit("setUnauthorized");
    }
  },
  created: function() {
    let results = document.cookie.match("(^|;) ?" + "token" + "=([^;]*)(;|$)");
    if (results) {
      let decodeToken: any = jwt_decode(results[2]);
      this.$store.commit("setUser", decodeToken);
    }
  },
  methods: {
    changeLanguage: function() {
      this.$store.commit("changeLanguage");
      i18n.locale = this.$store.state.language;
    },
    analyticsHandlerMyCollection: function() {
      this.$gtag.event("click_link_My_collection");
    },
    analyticsHandlerPublicCollection: function() {
      this.$gtag.event("click_link_Public_collection");
    },
    logOut: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/sign-out`,
          {
            withCredentials: true,
          }
        );

        this.$store.commit("setUnauthorized");
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    checkFrame: function(): boolean {
      return this.$route.name == "iFrame";
    },
    isShopifyUser: function() {
      return (
        !!this.$store.state?.user?.shopifyStoreId &&
        !!this.$store.state?.isAuthorized
      );
    },
  },
});

export default vm;



























import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { Vue } from "vue-property-decorator";
import axios from "axios";
import Comments from "./Comments.vue";
import { Message } from "@/types/message";

let vm = Vue.component("SaveButton", {
  props: {},
  data: function() {
    return {
      unreadQuantity: 0,
      readMessages: [] as Message[],
      unreadMessages: [] as Message[],
      visible: false,
      intervalId: 0
    };
  },
  mounted: function() {
    this.getUnreadQuantity();
    this.intervalId = setInterval(() => {
      this.getUnreadQuantity();
    }, 30000);
  },
  beforeDestroy: function() {
    clearInterval(this.intervalId);
  },
  watch: {
    visible: function(newVal, oldVal) {
      if (!newVal) {
        this.getUnreadQuantity();
      }
    }
  },
  methods: {
    getUnreadQuantity: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/comments/unreadQuantity/${this.$route.params.templateId}`,
          { withCredentials: true }
        );
        this.unreadQuantity = response.data.amountOfUnreadMessages;
      } catch (error) {
        console.log(error);
      }
    },
    handleSideBarOpen: async function() {
      this.visible = !this.visible;
    }
  },
  components: {
    Comments
  }
});

export default vm;

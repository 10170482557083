








import { Vue } from "vue-property-decorator";

let vm = Vue.component("SaveAsBtn", {
  props: {
    controls: Object
  },
  data: function() {
    return {};
  },
  methods: {
    resetCamera: function() {
      this.controls.reset();
    }
  },
  components: {}
});

export default vm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundedPetal = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var RoundLinesHelper_1 = require("../../utils/RoundLinesHelper");
var three_1 = require("three");
var RoundedPetal = /** @class */ (function (_super) {
    __extends(RoundedPetal, _super);
    function RoundedPetal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoundedPetal.prototype.calculateConnectionIndexes = function () {
        return [0];
    };
    RoundedPetal.prototype.calculateLines = function () {
        var width = this.config.options.width;
        var widthMiddle = this.config.options.widthMiddle;
        var heightBottom = this.config.options.heightBottom;
        var topASide = this.config.options.topASide;
        var topBSide = this.config.options.topBSide;
        var radius = this.config.options.roundedRadius;
        var bottomAngle = Math.asin(heightBottom / width);
        var topAngle = Math.acos((Math.pow(topASide, 2) + Math.pow(widthMiddle, 2) - Math.pow(topBSide, 2)) / (2 * topASide * widthMiddle));
        var angle = topAngle + bottomAngle;
        var cPointX = -width / 2 + Math.cos(angle) * topASide;
        var cPointY = Math.sin(angle) * topASide;
        var dPointX = -width / 2 + Math.cos(bottomAngle) * widthMiddle;
        var aPoint = new three_1.Vector3(width / 2, 0);
        var bPoint = new three_1.Vector3(-width / 2, 0);
        var cPoint = new three_1.Vector3(cPointX, cPointY);
        var dPoint = new three_1.Vector3(dPointX, heightBottom);
        return __spread([
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint])
        ], RoundLinesHelper_1.RoundLinesHelper.roundOffLines(new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]), new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]), radius), [
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, aPoint]),
        ]);
    };
    RoundedPetal.prototype.getConfigProperties = function () {
        return RoundLinesHelper_1.RoundLinesHelper.addRoundProperties({
            width: 1,
            heightBottom: 0.1,
            topASide: 0.9889,
            topBSide: 0.4257,
            widthMiddle: 1.05,
        });
    };
    return RoundedPetal;
}(figureBase_1.FigureBase));
exports.RoundedPetal = RoundedPetal;

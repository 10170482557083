"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundedTriangleWithGap = void 0;
var roundedTriangle_1 = require("./roundedTriangle");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var RoundedTriangleWithGap = /** @class */ (function (_super) {
    __extends(RoundedTriangleWithGap, _super);
    function RoundedTriangleWithGap() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoundedTriangleWithGap.prototype.getConfigProperties = function () {
        var config = _super.prototype.getConfigProperties.call(this);
        config.widthGap = 0.2;
        config.heightGap = 0.2;
        return config;
    };
    RoundedTriangleWithGap.prototype.calculateHoleLines = function () {
        var coords = this.config.options;
        var aPoint = new three_1.Vector3(coords.widthGap / 2, 0);
        var bPoint = new three_1.Vector3(-coords.widthGap / 2, 0);
        var cPoint = new three_1.Vector3(-coords.widthGap / 2, coords.heightGap);
        var dPoint = new three_1.Vector3(coords.widthGap / 2, coords.heightGap);
        return [[
                new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
                new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
                new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
                new line3_1.Line3(line3_1.LineType.Straight, [dPoint, aPoint]),
            ]];
    };
    return RoundedTriangleWithGap;
}(roundedTriangle_1.RoundedTriangle));
exports.RoundedTriangleWithGap = RoundedTriangleWithGap;

const sliderInput = (palette, width) => ({
  sliderInput: {
    background: '#F7FCFE',
    fontFamily: palette.fontFamily,
    color: palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    border: `2px solid ${palette.blue.main}`,
    minWidth: width,
    maxWidth: width,
    height: 40,
    padding: '10px 0 10px 12px',
    marginLeft: 10,
    borderRadius: 10,
    '&:hover': {
      borderColor: palette.secondary.main
    }
  },
  focusedInput: {
    borderColor: `${palette.tertiary.main}!important`
  },
});

export default sliderInput;

import { fabric } from 'fabric';

const DEFAULT_WIDTH = 150;
const DEFAULT_HEIGHT = 50;

const DEFAULT_TEXT = 'Text';

const props = {
  top: 0,
  left: 0,
  width: DEFAULT_WIDTH,
  height: DEFAULT_HEIGHT,
  fill: '#000',
  stroke: '',
  strokeWidth: 0,
  strokeUniform: true,
  lockScalingFlip: true,
};

export default {
  Class: fabric.Textbox,
  args: [DEFAULT_TEXT, props]
};

const settingButton = palette => ({
  settingButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    cursor: 'pointer',
    backgroundColor: palette.paleBlue.main,
    borderRadius: 10,
    '&:hover': {
      backgroundColor: palette.blue.main,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 0,
    }
  },
  settingButtonFocus: {
    backgroundColor: palette.blue.main,
  }
});

export default settingButton;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HookEar = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var HookEar = /** @class */ (function (_super) {
    __extends(HookEar, _super);
    function HookEar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HookEar.prototype.calculateConnectionIndexes = function () {
        return [0];
    };
    HookEar.prototype.calculateLines = function () {
        var options = this.config.options;
        var baseLength = options.baseLine;
        var leftSideLength = baseLength * 1.179;
        var rightSideLength = baseLength * 0.5654;
        var halfRoundBottomLength = baseLength * 0.191;
        var points = [new three_1.Vector2(-baseLength / 2, 0), new three_1.Vector2(baseLength / 2, 0)];
        var leftSideAngle = -53 * Math.PI / 180; //used the angles from the original picture
        var rightSideAngle = -10 * Math.PI / 180; //used the angles from the original picture
        //calculate params for left side
        var leftPoint = new three_1.Vector2(points[0].x, leftSideLength);
        leftPoint.rotateAround(points[0], leftSideAngle);
        //calculate params for right side
        var rightPoint = new three_1.Vector2(points[1].x, rightSideLength);
        rightPoint.rotateAround(points[1], rightSideAngle);
        var directionVector = new three_1.Vector2(rightPoint.x, rightPoint.y).sub(leftPoint).normalize();
        var circleStartPoint = new three_1.Vector2().copy(rightPoint).addScaledVector(directionVector, halfRoundBottomLength);
        var circleCenter = new three_1.Vector2().copy(circleStartPoint).add(leftPoint).multiplyScalar(0.5);
        var normal = new three_1.Vector2(-directionVector.y, directionVector.x).normalize();
        var radius = 0.5 * circleStartPoint.distanceTo(leftPoint);
        var centralPoint = new three_1.Vector2().copy(circleCenter).addScaledVector(normal, radius);
        var rightUpPoint = new three_1.Vector2().copy(centralPoint).addScaledVector(directionVector, radius);
        var leftUpPoint = new three_1.Vector2().copy(centralPoint).addScaledVector(directionVector, -radius);
        points.push(rightPoint, circleStartPoint, rightUpPoint, centralPoint, leftUpPoint, leftPoint);
        points.push(new three_1.Vector2());
        points = points.map(function (point) {
            point.x = -point.x;
            return new three_1.Vector3(point.x, point.y, 0);
        });
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [points[0], points[1]]),
            new line3_1.Line3(line3_1.LineType.Straight, [points[1], points[2]]),
            new line3_1.Line3(line3_1.LineType.Straight, [points[2], points[3]]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [points[3], points[4], points[5]]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [points[5], points[6], points[7]]),
            new line3_1.Line3(line3_1.LineType.Straight, [points[7], points[0]])
        ];
    };
    HookEar.prototype.isValid = function () {
        return this.config.options.baseLine > 0;
    };
    HookEar.prototype.getConfigProperties = function () {
        return {
            baseLine: 1
        };
    };
    return HookEar;
}(figureBase_1.FigureBase));
exports.HookEar = HookEar;

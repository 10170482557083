/* eslint-disable react/prop-types */
import React from 'react';

import {
  Radio,
  Select,
  Tooltip,
  MenuItem,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import settingSelect from './styles/SettingsSelectStyles';
import {materialTheme} from '../../../materialTheme'

const { palette } = materialTheme


const styles = () => ({
  ...settingSelect(palette),
  radioGroup: {
    flexDirection: 'row',
    gap: '0 12px',
  },
  radioLabel: {
    borderRadius: 10,
    margin: 0,
    background: `${palette.paleBlue.main} !important`,
    '& .MuiIconButton-root': {
      padding: 12,
    },
    '&:hover': {
      background: `${palette.blue.main} !important`,
    },
    '& .MuiTypography-root': {
      display: 'none',
    }
  },
  radioLabelChecked: {
    background: `${palette.blue.main} !important`,
  },
  menuParams: {
    width: '100%',
    height: 72,
  },
});

class AlignSelect extends React.Component {
  state = {
    isOpen: false,
    align: this.props.layerAlign,
    topPosition: 0,
    leftPosition: 0
  }

  handleChange = value => {
    const { onAlignChange } = this.props;
    onAlignChange(value);
    this.setState({ align: value });
  };

  setRef = ref => {
    this.ref = ref;
  }

  onRefChange = () => {
    const topPosition = this.ref.getBoundingClientRect().top + 60;
    const leftPosition = this.ref.getBoundingClientRect().left;
    this.setState({ topPosition, leftPosition });
  };


  someFunc() {
    this.setState({ isOpen: true });
    this.onRefChange();
  }

  render() {
    const {
      isOpen, topPosition, leftPosition, align
    } = this.state;
    const { classes, tooltipTitle, type } = this.props;
    const horizontalAlignParams = ['left', 'center', 'right', 'justify'];
    const verticalAlignParams = ['top', 'middle', 'bottom'];
    const alignParams = type === 'vertical' ? verticalAlignParams : horizontalAlignParams;

    const alignIcons = {
      left: (
        <path d="M14 15H4C3.45 15 3 15.45 3 16C3 16.55 3.45 17 4 17H14C14.55 17 15 16.55 15 16C15 15.45 14.55 15 14 15ZM14 7H4C3.45 7 3 7.45 3 8C3 8.55 3.45 9 4 9H14C14.55 9 15 8.55 15 8C15 7.45 14.55 7 14 7ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21ZM3 4C3 4.55 3.45 5 4 5H20C20.55 5 21 4.55 21 4C21 3.45 20.55 3 20 3H4C3.45 3 3 3.45 3 4Z" fill="#2C3E66" />
      ),
      right: (
        <path d="M4 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21ZM10 17H20C20.55 17 21 16.55 21 16C21 15.45 20.55 15 20 15H10C9.45 15 9 15.45 9 16C9 16.55 9.45 17 10 17ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM10 9H20C20.55 9 21 8.55 21 8C21 7.45 20.55 7 20 7H10C9.45 7 9 7.45 9 8C9 8.55 9.45 9 10 9ZM3 4C3 4.55 3.45 5 4 5H20C20.55 5 21 4.55 21 4C21 3.45 20.55 3 20 3H4C3.45 3 3 3.45 3 4Z" fill="#2C3E66" />
      ),
      center: (
        <path d="M7 16C7 16.55 7.45 17 8 17H16C16.55 17 17 16.55 17 16C17 15.45 16.55 15 16 15H8C7.45 15 7 15.45 7 16ZM4 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM7 8C7 8.55 7.45 9 8 9H16C16.55 9 17 8.55 17 8C17 7.45 16.55 7 16 7H8C7.45 7 7 7.45 7 8ZM3 4C3 4.55 3.45 5 4 5H20C20.55 5 21 4.55 21 4C21 3.45 20.55 3 20 3H4C3.45 3 3 3.45 3 4Z" fill="#2C3E66" />
      ),
      justify: (
        <path d="M4 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21ZM4 17H20C20.55 17 21 16.55 21 16C21 15.45 20.55 15 20 15H4C3.45 15 3 15.45 3 16C3 16.55 3.45 17 4 17ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 9H20C20.55 9 21 8.55 21 8C21 7.45 20.55 7 20 7H4C3.45 7 3 7.45 3 8C3 8.55 3.45 9 4 9ZM3 4C3 4.55 3.45 5 4 5H20C20.55 5 21 4.55 21 4C21 3.45 20.55 3 20 3H4C3.45 3 3 3.45 3 4Z" fill="#2C3E66" />
      ),
      top: (
        <path d="M9.21 11H11V20C11 20.55 11.45 21 12 21C12.55 21 13 20.55 13 20V11H14.79C15.24 11 15.46 10.46 15.14 10.15L12.35 7.36C12.15 7.16 11.84 7.16 11.64 7.36L8.85 10.15C8.7809 10.2205 8.73421 10.3099 8.71582 10.4069C8.69743 10.5038 8.70816 10.6041 8.74665 10.695C8.78515 10.7859 8.8497 10.8634 8.93215 10.9177C9.01461 10.9719 9.11129 11.0006 9.21 11ZM4 4C4 4.55 4.45 5 5 5H19C19.55 5 20 4.55 20 4C20 3.45 19.55 3 19 3H5C4.45 3 4 3.45 4 4Z" fill="#2C3E66" />
      ),
      bottom: (
        <path d="M14.79 13H13V4C13 3.45 12.55 3 12 3C11.45 3 11 3.45 11 4V13H9.21C8.76 13 8.54 13.54 8.86 13.85L11.65 16.64C11.85 16.84 12.16 16.84 12.36 16.64L15.15 13.85C15.2191 13.7795 15.2658 13.6901 15.2842 13.5931C15.3026 13.4962 15.2918 13.3959 15.2533 13.305C15.2148 13.2141 15.1503 13.1366 15.0678 13.0823C14.9854 13.0281 14.8887 12.9994 14.79 13ZM4 20C4 20.55 4.45 21 5 21H19C19.55 21 20 20.55 20 20C20 19.45 19.55 19 19 19H5C4.45 19 4 19.45 4 20Z" fill="#2C3E66" />
      ),
      middle: (
        <path d="M9.21 19H11V22C11 22.55 11.45 23 12 23C12.55 23 13 22.55 13 22V19H14.79C15.24 19 15.46 18.46 15.14 18.15L12.35 15.36C12.15 15.16 11.84 15.16 11.64 15.36L8.85 18.15C8.7809 18.2205 8.73421 18.3099 8.71582 18.4069C8.69743 18.5038 8.70816 18.6041 8.74665 18.695C8.78515 18.7859 8.8497 18.8634 8.93215 18.9177C9.01461 18.9719 9.11129 19.0006 9.21 19ZM14.79 5H13V2C13 1.45 12.55 1 12 1C11.45 1 11 1.45 11 2V5H9.21C9.11129 4.99942 9.01461 5.02807 8.93215 5.08235C8.8497 5.13663 8.78515 5.2141 8.74665 5.30499C8.70816 5.39589 8.69743 5.49616 8.71582 5.59314C8.73421 5.69013 8.7809 5.7795 8.85 5.85L11.64 8.64C11.84 8.84 12.15 8.84 12.35 8.64L15.14 5.85C15.46 5.54 15.24 5 14.79 5ZM4 12C4 12.55 4.45 13 5 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11H5C4.45 11 4 11.45 4 12Z" fill="#2C3E66" />
      ),
    };
    return (
      <Select
        ref={this.setRef}
        onOpen={() => {
          this.setState({ isOpen: true });
          this.onRefChange();
        }}
        onClose={() => this.setState({ isOpen: false })}
        open={isOpen}
        MenuProps={{
          anchorReference: 'anchorPosition',
          anchorPosition: { top: topPosition, left: leftPosition },
          MenuListProps: {
            classes: {
              root: `${classes.selectMenuWrapper} ${classes.menuParams}`
            }
          },
          PopoverClasses: {
            paper: classes.popover,
          }
        }}
        classes={{ selectMenu: classes.selectMenu, select: classes.selectMenu }}
        disableUnderline
        style={{paddingRight: '17px'}}
        IconComponent={() => (<div onClick={() => this.someFunc()}><svg width="24" height="24" viewBox="0 0 24 24">
        {alignIcons[align]}
      </svg></div>)}
        // value="none"
        className={isOpen ? `${classes.select} ${classes.selectFocus}` : classes.select}
      >
        {/* <MenuItem className={classes.placeholder} value="none">
          <Tooltip
            arrow
            placement="top"
            title={tooltipTitle}
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              {alignIcons[align]}
            </svg>
          </Tooltip>
        </MenuItem> */}
        
        
        <FormControl className={classes.defaultColors}>
          <RadioGroup
            onChange={e => this.handleChange(e.target.value)}
            className={classes.radioGroup}
          >
            {alignParams.map(alignParam => (
              <FormControlLabel
                key={alignParam}
                style={{ background: alignParam }}
                classes={{ root: `${classes.radioLabel} ${align === alignParam && classes.radioLabelChecked}` }}
                value={alignParam}
                control={(
                  <Radio
                    checkedIcon={<span />}
                    icon={(
                      <svg width="24" height="24" viewBox="0 0 24 24">
                        {alignIcons[alignParam]}
                      </svg>
                    )}
                  />
                )}
                label={alignParam}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Select>
    );
  }
}

export default withStyles(styles)(AlignSelect);
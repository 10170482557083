/* eslint-disable react/prop-types */
import React from 'react';

import {
  Radio,
  Select,
  Tooltip,
  MenuItem,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import settingSelect from './styles/SettingsSelectStyles';
import {materialTheme} from '../../../materialTheme'

const { palette } = materialTheme


const styles = () => ({
  ...settingSelect(palette),
  radioGroup: {
    flexDirection: 'row',
    gap: '0 12px',
  },
  radioLabel: {
    borderRadius: 10,
    margin: 0,
    background: `${palette.paleBlue.main} !important`,
    '& .MuiIconButton-root': {
      padding: 12,
    },
    '&:hover': {
      background: `${palette.blue.main} !important`,
    },
    '& .MuiTypography-root': {
      display: 'none',
    }
  },
  radioLabelChecked: {
    background: `${palette.blue.main} !important`,
  },
  menuParams: {
    width: 132,
    height: 72,
  },
});

class CaseSelect extends React.Component {
  state = {
    isOpen: false,
    topPosition: 0,
    leftPosition: 0,
    textCase: this.props.layerCase
  }

  handleChange = value => {
    const { onCaseChange } = this.props;
    this.setState({ textCase: value });
    onCaseChange(value);
  };

  setRef = ref => {
    this.ref = ref;
  }

  onRefChange = () => {
    const topPosition = this.ref.getBoundingClientRect().top + 60;
    const leftPosition = this.ref.getBoundingClientRect().left - 72;
    this.setState({ topPosition, leftPosition });
  };

  someFunc() {
    this.setState({ isOpen: true });
    this.onRefChange();
  }

  render() {
    const {
      isOpen, textCase, topPosition, leftPosition
    } = this.state;
    const { classes } = this.props;
    const textCaseParams = ['uppercase', 'lowercase'];

    const textCaseIcons = {
      default: (
        <path d="M20.0597 18C19.9592 17.712 19.8921 17.4133 19.8597 17.11C19.1897 17.81 18.3797 18.16 17.4497 18.16C16.6197 18.16 15.9297 17.92 15.3997 17.45C14.8697 17 14.5997 16.39 14.5997 15.66C14.5997 14.78 14.9297 14.1 15.5997 13.61C16.2697 13.12 17.2097 12.88 18.4297 12.88H19.8297V12.24C19.8297 11.75 19.6797 11.36 19.3797 11.07C19.0797 10.78 18.6297 10.64 18.0497 10.64C17.5297 10.64 17.0997 10.76 16.7497 11C16.3997 11.25 16.2297 11.54 16.2297 11.89H14.7697C14.7697 11.46 14.9197 11.05 15.2197 10.65C15.4997 10.25 15.9297 9.94 16.4397 9.71C16.9497 9.5 17.4997 9.36 18.1297 9.36C19.1097 9.36 19.8697 9.6 20.4197 10.09C20.9697 10.58 21.2597 11.25 21.2797 12.11V16C21.2797 16.8 21.3797 17.42 21.5797 17.88V18H20.0597ZM17.6597 16.88C18.1097 16.88 18.5397 16.77 18.9497 16.56C19.3497 16.35 19.6497 16.07 19.8297 15.73V14.16H18.6997C16.9297 14.16 16.0397 14.63 16.0397 15.57C16.0397 16 16.1897 16.3 16.4997 16.53C16.7997 16.76 17.1797 16.88 17.6597 16.88ZM5.45969 13.71H9.52969L7.49969 8.29L5.45969 13.71ZM6.63969 6H8.35969L13.0697 18H11.1397L10.1697 15.43H4.81969L3.85969 18H1.92969L6.63969 6Z" fill="#2C3E66" />
      ),
      uppercase: (
        <path d="M20.0597 18C19.9592 17.712 19.8921 17.4133 19.8597 17.11C19.1897 17.81 18.3797 18.16 17.4497 18.16C16.6197 18.16 15.9297 17.92 15.3997 17.45C14.8697 17 14.5997 16.39 14.5997 15.66C14.5997 14.78 14.9297 14.1 15.5997 13.61C16.2697 13.12 17.2097 12.88 18.4297 12.88H19.8297V12.24C19.8297 11.75 19.6797 11.36 19.3797 11.07C19.0797 10.78 18.6297 10.64 18.0497 10.64C17.5297 10.64 17.0997 10.76 16.7497 11C16.3997 11.25 16.2297 11.54 16.2297 11.89H14.7697C14.7697 11.46 14.9197 11.05 15.2197 10.65C15.4997 10.25 15.9297 9.94 16.4397 9.71C16.9497 9.5 17.4997 9.36 18.1297 9.36C19.1097 9.36 19.8697 9.6 20.4197 10.09C20.9697 10.58 21.2597 11.25 21.2797 12.11V16C21.2797 16.8 21.3797 17.42 21.5797 17.88V18H20.0597ZM17.6597 16.88C18.1097 16.88 18.5397 16.77 18.9497 16.56C19.3497 16.35 19.6497 16.07 19.8297 15.73V14.16H18.6997C16.9297 14.16 16.0397 14.63 16.0397 15.57C16.0397 16 16.1897 16.3 16.4997 16.53C16.7997 16.76 17.1797 16.88 17.6597 16.88ZM5.45969 13.71H9.52969L7.49969 8.29L5.45969 13.71ZM6.63969 6H8.35969L13.0697 18H11.1397L10.1697 15.43H4.81969L3.85969 18H1.92969L6.63969 6ZM1.99969 21C1.99969 20.4477 2.4474 20 2.99969 20H11.9997C12.552 20 12.9997 20.4477 12.9997 21C12.9997 21.5523 12.552 22 11.9997 22H2.99969C2.4474 22 1.99969 21.5523 1.99969 21Z" fill="#2C3E66" />
      ),
      lowercase: (
        <path d="M20.0597 18C19.9592 17.712 19.8921 17.4133 19.8597 17.11C19.1897 17.81 18.3797 18.16 17.4497 18.16C16.6197 18.16 15.9297 17.92 15.3997 17.45C14.8697 17 14.5997 16.39 14.5997 15.66C14.5997 14.78 14.9297 14.1 15.5997 13.61C16.2697 13.12 17.2097 12.88 18.4297 12.88H19.8297V12.24C19.8297 11.75 19.6797 11.36 19.3797 11.07C19.0797 10.78 18.6297 10.64 18.0497 10.64C17.5297 10.64 17.0997 10.76 16.7497 11C16.3997 11.25 16.2297 11.54 16.2297 11.89H14.7697C14.7697 11.46 14.9197 11.05 15.2197 10.65C15.4997 10.25 15.9297 9.94 16.4397 9.71C16.9497 9.5 17.4997 9.36 18.1297 9.36C19.1097 9.36 19.8697 9.6 20.4197 10.09C20.9697 10.58 21.2597 11.25 21.2797 12.11V16C21.2797 16.8 21.3797 17.42 21.5797 17.88V18H20.0597ZM17.6597 16.88C18.1097 16.88 18.5397 16.77 18.9497 16.56C19.3497 16.35 19.6497 16.07 19.8297 15.73V14.16H18.6997C16.9297 14.16 16.0397 14.63 16.0397 15.57C16.0397 16 16.1897 16.3 16.4997 16.53C16.7997 16.76 17.1797 16.88 17.6597 16.88ZM5.45969 13.71H9.52969L7.49969 8.29L5.45969 13.71ZM6.63969 6H8.35969L13.0697 18H11.1397L10.1697 15.43H4.81969L3.85969 18H1.92969L6.63969 6ZM13.9997 21C13.9997 20.4477 14.4474 20 14.9997 20H21.9997C22.552 20 22.9997 20.4477 22.9997 21C22.9997 21.5523 22.552 22 21.9997 22H14.9997C14.4474 22 13.9997 21.5523 13.9997 21Z" fill="#2C3E66" />
      ),
    };
    return (
      <Select
        ref={this.setRef}
        onOpen={() => {
          this.setState({ isOpen: true });
          this.onRefChange();
        }}
        onClose={() => this.setState({ isOpen: false })}
        open={isOpen}
        MenuProps={{
          anchorReference: 'anchorPosition',
          anchorPosition: { top: topPosition, left: leftPosition },
          MenuListProps: {
            classes: {
              root: `${classes.selectMenuWrapper} ${classes.menuParams}`
            }
          },
          PopoverClasses: {
            paper: classes.popover,
          }
        }}
        classes={{ selectMenu: classes.selectMenu, select: classes.selectMenu }}
        disableUnderline
        style={{paddingRight: '17px'}}
        IconComponent={() => (<div onClick={() => this.someFunc()}><svg width="24" height="24" viewBox="0 0 24 24">
        {textCaseIcons[textCase]}
      </svg></div>)}
        // value="none"
        className={isOpen ? `${classes.select} ${classes.selectFocus}` : classes.select}
      >
        {/* <MenuItem className={classes.placeholder} value="none">
          <Tooltip
            arrow
            placement="top"
            title="As Typed Case"
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            
          </Tooltip>
        </MenuItem> */}
        <FormControl className={classes.defaultColors}>
          <RadioGroup
            onChange={e => this.handleChange(e.target.value)}
            className={classes.radioGroup}
          >
            {textCaseParams.map(textCaseParam => (
              <FormControlLabel
                key={textCaseParam}
                classes={{ root: `${classes.radioLabel} ${textCase === textCaseParam && classes.radioLabelChecked}` }}
                value={textCaseParam}
                control={(
                  <Radio
                    checkedIcon={<span />}
                    icon={(
                      <svg width="24" height="24" viewBox="0 0 24 24">
                        {textCaseIcons[textCaseParam]}
                      </svg>
                    )}
                  />
                )}
                label={textCaseParam}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Select>
    );
  }
}

export default withStyles(styles)(CaseSelect);

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowEditor),expression:"!isShowEditor"}],staticClass:"object-container"},[_c('div',{ref:"mapperContainer",staticClass:"object-mapper-container",style:({ width: _vm.block1W })},[(!_vm.isLoaded)?_c('div',{staticClass:"loader-wrapper"},[_c('Loader')],1):_c('ObjectRender',{ref:"render2d",attrs:{"localGroup":_vm.localGroupImg,"renderer":_vm.rendererImage,"renderTracker":_vm.patternDataTracker,"fabricTextObjects":_vm.fabricTextObjects,"settings":{
          showHelper: false,
          showGrid: _vm.showGrid,
          showBorders: _vm.showBorders,
          size: _vm.size2d,
          type: _vm.typeImaginary,
          showControls: true,
          opacity: _vm.opacity,
        },"representations":_vm.repImag,"representations2d":_vm.repImag,"coloringData":_vm.coloringData,"backgroundImage":_vm.backgroundImage,"backgroundTexture":_vm.backgroundTexture,"pattern":_vm.pattern,"wereUpdated":_vm.wereUpdated,"selectedObject":_vm.selectedObject,"isTexture":_vm.isTexture,"selectedSide":_vm.selectedSide,"scale":_vm.scale,"helper":_vm.helperImg,"lockedModel":_vm.lockedModel,"removedObjectId":_vm.removedObjectId2d,"coloringDataTracker":_vm.coloringDataTracker},on:{"add":_vm.sideSelect,"select":_vm.objectSelect,"addTexture":_vm.addTexture,"selectTexture":_vm.selectTexture,"objectRemoved":function($event){_vm.removedObjectId2d = null},"changeTextureData":_vm.changeTextureData}})],1),_c('div',{staticClass:"slider",on:{"mousedown":_vm.drag}}),_c('div',{staticClass:"object-mapper-container flex-wrapper"},[(!_vm.isLoaded)?_c('div',{staticClass:"loader-wrapper"},[_c('Loader')],1):_c('ObjectRender',{ref:"render3d",attrs:{"localGroup":_vm.localGroupReal,"renderer":_vm.rendererReal,"backgroundImage":_vm.backgroundImage,"renderTracker":_vm.patternDataTracker,"isTexture":_vm.isTexture,"representations":_vm.repReal,"representations2d":_vm.repImag,"wereUpdated":_vm.wereUpdated,"selectedObject":_vm.selectedObject,"fabricTextObjects":_vm.fabricTextObjects,"backgroundTexture":_vm.backgroundTexture,"coloringData":_vm.coloringData,"pattern":_vm.pattern,"helper":_vm.helperReal,"scaling":_vm.scale,"settings":{
          showHelper: _vm.showHelper,
          showGrid: _vm.showGrid,
          showBorders: _vm.showBorders,
          size: _vm.size3d,
          type: _vm.typeReal,
          showControls: false,
          opacity: _vm.opacity,
        },"scale":_vm.scale,"lockedModel":_vm.lockedModel,"removedObjectId":_vm.removedObjectId3d,"coloringDataTracker":_vm.coloringDataTracker},on:{"select":_vm.objectSelect,"update_done":_vm.meshsWereCreated,"objectRemoved":function($event){_vm.removedObjectId3d = null}}}),(!_vm.lockedModel)?_c('div',{staticStyle:{"position":"absolute","right":"10px","top":"10px","max-width":"280px"}},[_c('div',{staticClass:"actions-wrapper"},[_c('SaveAsBtn',{staticClass:"button-wrapper"})],1)]):_vm._e()],1),_c('Notifier',{attrs:{"notification":_vm.notification}}),(_vm.isHoleModal)?_c('HoleModal',{attrs:{"holes":_vm.availableHoles.list},on:{"closeModal":_vm.hideModal,"addHole":_vm.addHole}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowEditor),expression:"isShowEditor"}]},[_c('PdfEditor',{attrs:{"isShowEditor":_vm.isShowEditor,"nodes":_vm.nodes,"coloringData":_vm.coloringData,"pattern":_vm.pattern,"scale":_vm.scale,"meterage":_vm.selectedMeterage,"save":_vm.updateTextureOfEditor},on:{"hideEditor":_vm.hideEditor}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
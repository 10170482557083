











import { Vue } from "vue-property-decorator";
import Loader from "./Loader.vue";

let vm = Vue.component("SaveButton", {
  props: {
    conservation: Boolean
  },
  methods: {
    save: function() {
      this.$emit('update')
    }
  },
  components: {
    Loader
  }
});

export default vm;

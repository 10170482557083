<template>
  <div>
    <div v-if="loading">
      Loading ...
    </div>

    <div v-else-if="errors.length > 0">
      Ooops, something goes wrong...
    </div>

    <div v-else>
      Welcome to 3d pack

      <template v-if="themes.length > 0">
        <select v-model="currentThemeId">
          <option
            v-for="theme in themes"
            :key="theme.id"
            :value="theme.id"
            :class="{ live: isLiveTheme(theme) }"
          >
            {{ theme.name }} {{ isLiveTheme(theme) ? "(Live)" : "" }}
          </option>
        </select>
        <button @click="updateTheme">
          Update
        </button>
        <hr />
        <button @click="openRevertModal" class="btn-error">
          REVERT CHANGES
        </button>
        <button @click="openSnackbar">Open Snackbar</button>
      </template>

      <template v-else>
        No theme Found
      </template>
    </div>
    <modal
      v-show="isRevertModalActive"
      bodyText="Are you sure you want to revert changes?"
      @close="closeRevertModal"
    >
      <template #footer>
        <button @click="revertStore" class="btn-error">Submit</button>
      </template>
    </modal>
    <snackbar
      v-if="isSnackBarOpen"
      text="Error Occured, Try Again"
      @close="closeSnackbar"
    />
  </div>
</template>
<script>
import axios from "axios";
import Modal from "../components/Modal.vue";
import Snackbar from "../components/Snackbar.vue";
export default {
  components: { Modal, Snackbar },
  data() {
    return {
      themes: [],
      currentThemeId: null,
      loading: true,
      errors: [],
      isRevertModalActive: false,
      isSnackBarOpen: false,
    };
  },
  mounted: async function() {
    try {
      const user = this.$store.state.user;
      if (!user.id) {
        this.$router.push({
          path: `auth/`,
        });
      }
      const themesData = await axios.get(
        `${process.env.VUE_APP_API_URL}/api/v1/shopify/store/${this.shopifyStoreId}/themes`,
        { withCredentials: true }
      );
      this.themes = themesData.data.themes;
      const liveTheme = this.themes.find((theme) => this.isLiveTheme(theme));
      this.currentThemeId = liveTheme?.id;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleError(error) {
      this.openSnackbar();
      this.errors.push(error);
      console.log(error);
    },
    openRevertModal() {
      this.isRevertModalActive = true;
    },

    closeRevertModal() {
      this.isRevertModalActive = false;
    },

    openSnackbar() {
      this.isSnackBarOpen = true;
    },

    closeSnackbar() {
      this.isSnackBarOpen = false;
    },

    async updateTheme() {
      try {
        this.loading = true;
        if (!this.currentThemeId) return;

        const updateTheme = await axios.put(
          `${process.env.VUE_APP_API_URL}/api/v1/shopify/store/${this.shopifyStoreId}/themes/${this.currentThemeId}`,
          null,
          { withCredentials: true }
        );
      } catch (error) {
        this.openSnackbar();
      } finally {
        this.loading = false;
      }
    },

    async revertStore() {
      try {
        this.closeRevertModal();
        this.loading = true;
        if (!this.currentThemeId) return;
        const timeout = 1000 * 60 * 5;
        const revertStore = await axios.delete(
          `${process.env.VUE_APP_API_URL}/api/v1/shopify/store/${this.shopifyStoreId}/revert`,
          {
            withCredentials: true,
            timeout,
          }
        );
        for (const theme of Object.values(this.themes)) {
          const revertTheme = await axios.delete(
            `${process.env.VUE_APP_API_URL}/api/v1/shopify/store/${this.shopifyStoreId}/themes/${theme.id}/revert`,
            {
              withCredentials: true,
              timeout,
            }
          );
        }
      } catch (error) {
        this.openSnackbar();
      } finally {
        this.loading = false;
      }
    },

    isLiveTheme(theme) {
      return theme.role === "main";
    },
  },
  computed: {
    shopifyStoreId() {
      return this.$store.state.user.shopifyStoreId;
    },
  },
};
</script>
<style lang="css" scoped>
.btn-error {
  background: red;
  color: #fff;
}

.live {
  font-weight: bold;
}
</style>

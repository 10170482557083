"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundLinesHelper = void 0;
var line3_1 = require("../../core/base/line3");
var three_1 = require("three");
var RoundLinesHelper = /** @class */ (function () {
    function RoundLinesHelper() {
    }
    RoundLinesHelper.addRoundProperties = function (source) {
        source.roundedRadius = 0.1;
        return source;
    };
    RoundLinesHelper.roundOffLines = function (firstLine, secondLine, radius) {
        var firstLength = firstLine.length();
        var secondLength = secondLine.length();
        var ratio = (firstLength - radius) / radius;
        var aPoint = firstLine.startPoint.clone();
        var bPoint = new three_1.Vector3((firstLine.startPoint.x + ratio * firstLine.endPoint.x) / (1 + ratio), (firstLine.startPoint.y + ratio * firstLine.endPoint.y) / (1 + ratio), 0);
        var cPoint = secondLine.startPoint.clone();
        var ratioTopLeft = radius / (secondLength - radius);
        var dPoint = new three_1.Vector3((secondLine.startPoint.x + ratioTopLeft * secondLine.endPoint.x) / (1 + ratioTopLeft), (secondLine.startPoint.y + ratioTopLeft * secondLine.endPoint.y) / (1 + ratioTopLeft), 0);
        var ePoint = secondLine.endPoint.clone();
        return [new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [bPoint, cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, ePoint])];
    };
    return RoundLinesHelper;
}());
exports.RoundLinesHelper = RoundLinesHelper;

























import { Vue } from "vue-property-decorator";
import { Vector3 } from "three";

interface ScalingSettings {
  maxValue: number;
  minValue: number;
  step: number;
  scaleX: number;
  scaleY: number;
  scaleZ: number;
  fixedScaleX: number;
  fixedScaleY: number;
  fixedScaleZ: number;
}



let vm = Vue.component("ScalingSettings", {
  props: ['scaling'],
  data: function() {
    return {
      maxValue: 3 as number,
      minValue: 0.1 as number,
      step: 0.1 as number,
      scaleX: this.scaling.x,
      scaleY: this.scaling.y,
      scaleZ: this.scaling.z,
      fixedScaleX: this.scaling.x,
      fixedScaleY: this.scaling.y,
      fixedScaleZ: this.scaling.z
    } as ScalingSettings;
  },
  methods: {
    updateScale: function(): void {
      let scale = new Vector3(this.scaleX, this.scaleY, this.scaleZ)
        this.$emit("update_scale", scale);
    },
    correctValues: function(event: InputEvent): void {
      if(!!event) {
        let target = event.target as HTMLInputElement;
        let varName = target.name as keyof ScalingSettings;
        let correctedVarName = 'fixed' + varName.charAt(0).toUpperCase() + varName.slice(1) as keyof ScalingSettings;
        this[varName] = this[correctedVarName];
      }
    },
    fixValue: function(newValue: string, oldValue: number) {
      const floatNumber = /^[0-9]*\.?[0-9]*$/gm;
      if(!floatNumber.test(newValue)) {
        return oldValue;
      }
      let parsedNewValue = parseFloat(newValue);
      if(parsedNewValue > this.maxValue) {
        return this.maxValue;
      }
      if(parsedNewValue < this.minValue) {
        return this.minValue;
      }
      return parsedNewValue;
    }
  },
  watch: {
    scaling: {
      handler: function(newValue, oldValue) {
        this.scaleX = this.scaling.x;
        this.scaleY = this.scaling.y;
        this.scaleZ = this.scaling.z;
      },
      deep: true
    },
    scaleX: function(newValue: string) {
      this.fixedScaleX = this.fixValue(newValue, this.fixedScaleX);
    },
    scaleY: function(newValue: string) {
      this.fixedScaleY = this.fixValue(newValue, this.fixedScaleY);
    },
    scaleZ: function(newValue: string) {
      this.fixedScaleZ = this.fixValue(newValue, this.fixedScaleZ);
    }
  },
});

export default vm;


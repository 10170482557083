
























import { Vue } from "vue-property-decorator";

let vm = Vue.component("SearchInput", {
    props: {
        value: String,
        foundElements: {
            type: Array,
            default: null
        }
    },
    methods: {
        updateValue(value: string) {
            this.$emit('input', value)
        }
    },
    computed: {
        isFoundElements: function(): number {
            return this.foundElements?.length;
        }
    }
})

export default vm;

import { render, staticRenderFns } from "./TemplateRender.vue?vue&type=template&id=f7e4d2d0&scoped=true&"
import script from "./TemplateRender.vue?vue&type=script&lang=ts&"
export * from "./TemplateRender.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateRender.vue?vue&type=style&index=0&id=f7e4d2d0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7e4d2d0",
  null
  
)

export default component.exports
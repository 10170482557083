"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundedRectangle = void 0;
var rectangle_1 = require("./rectangle");
var RoundLinesHelper_1 = require("../../utils/RoundLinesHelper");
var RoundedRectangle = /** @class */ (function (_super) {
    __extends(RoundedRectangle, _super);
    function RoundedRectangle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoundedRectangle.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 3, 5];
    };
    RoundedRectangle.prototype.getConfigProperties = function () {
        return RoundLinesHelper_1.RoundLinesHelper.addRoundProperties(_super.prototype.getConfigProperties.call(this));
    };
    RoundedRectangle.prototype.calculateLines = function () {
        var lines = _super.prototype.calculateLines.call(this);
        var radius = this.config.options.roundedRadius;
        var roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(lines[1], lines[2], radius);
        lines.splice.apply(lines, __spread([1, 2], roundedLines));
        roundedLines = RoundLinesHelper_1.RoundLinesHelper.roundOffLines(lines[3], lines[4], radius);
        lines.splice.apply(lines, __spread([3, 2], roundedLines));
        return lines;
    };
    return RoundedRectangle;
}(rectangle_1.Rectangle));
exports.RoundedRectangle = RoundedRectangle;

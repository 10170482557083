"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TowerTopConnection = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var TowerTopConnection = /** @class */ (function (_super) {
    __extends(TowerTopConnection, _super);
    function TowerTopConnection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TowerTopConnection.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 6, 11];
    };
    TowerTopConnection.prototype.calculateLines = function () {
        var coords = this.config.options;
        var aPoint = new three_1.Vector3(coords.width / 2, 0);
        var bPoint = new three_1.Vector3(-coords.width / 2, 0);
        var cPoint = new three_1.Vector3(-coords.width / 2, coords.height);
        var dPoint = new three_1.Vector3(-coords.width / 2 + coords.widthSideTowers, coords.height);
        var ePoint = new three_1.Vector3(-coords.width / 2 + coords.widthSideTowers, coords.heightMiddle);
        var fPoint = new three_1.Vector3(-coords.widthMiddleTower / 2, coords.heightMiddle);
        var gPoint = new three_1.Vector3(-coords.widthMiddleTower / 2, coords.height);
        var hPoint = new three_1.Vector3(coords.widthMiddleTower / 2, coords.height);
        var iPoint = new three_1.Vector3(coords.widthMiddleTower / 2, coords.heightMiddle);
        var jPoint = new three_1.Vector3(coords.width / 2 - coords.widthSideTowers, coords.heightMiddle);
        var kPoint = new three_1.Vector3(coords.width / 2 - coords.widthSideTowers, coords.height);
        var lPoint = new three_1.Vector3(coords.width / 2, coords.height);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, ePoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [ePoint, fPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [fPoint, gPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [gPoint, hPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [hPoint, iPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [iPoint, jPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [jPoint, kPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [kPoint, lPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [lPoint, aPoint]),
        ];
    };
    TowerTopConnection.prototype.getConfigProperties = function () {
        return {
            height: 1,
            width: 1,
            widthSideTowers: 0.1,
            widthMiddleTower: 0.26,
            heightMiddle: 0.5,
        };
    };
    return TowerTopConnection;
}(figureBase_1.FigureBase));
exports.TowerTopConnection = TowerTopConnection;

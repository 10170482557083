/* eslint-disable import/prefer-default-export,react/prop-types */
import React from 'react';

// import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  HandIcon, RulerIcon, ImageFieldIcon, TextFieldIcon
} from './ToolIcons.js';
import { withStyles } from '@material-ui/core';

import {materialTheme} from '../../materialTheme'

const { palette } = materialTheme

const classes = () => ({
  editorInfo: {
    fontFamily: palette.fontFamily,
    color: palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
  },
  infoTitle: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: props => props.fileLoaded ? 36 : 15,
  },
  toolInfoIcon: {
    background: palette.blue.main,
    width: 48,
    height: 48,
    borderRadius: 10,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolsInfoWrapper: {
    display: 'flex',
    marginBottom: 36,
    textAlign: 'start'
  },
  info: {
    width: props => props.fileLoaded ? 276 : '100%',
  },
});

class EditorInfo extends React.Component {
  
  toolsInfo = [
    {
      icon: HandIcon.render(),
      description: 'The Hand tool allows you to move your PDF around its window by dragging',
    },
    {
      icon: TextFieldIcon.render(),
      description: 'The Text field tool allows mapping to  the text field in the required place on the PDF',
    },
    {
      icon: ImageFieldIcon.render(),
      description: 'The Image field tool allows mapping to the image in the required place on the PDF',
    },
    {
      icon: RulerIcon.render(),
      description: 'The Ruler tool allows to turn on/off rulers and use guides',
    },
  ];

  render() {
    const { fileLoaded, classes } = this.props

    return (
      <div>
        {fileLoaded ? (
          <div className={classes.editorInfo}>
            <p className={classes.infoTitle}>Select tool to get started</p>
            {this.toolsInfo.map(tool => (
              <div className={classes.toolsInfoWrapper} key={tool.description}>
                <div className={classes.toolInfoIcon}>
                  {tool.icon}
                </div>
                <p className={classes.info}>
                  {tool.description}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className={classes.editorInfo}>
            <p className={classes.infoTitle}>Upload PDF to get started mapping</p>
            <p className={classes.info}>
              Drag and drop a file in the workspace or click on it to select a file from your device
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(classes)(EditorInfo);














// const useStyles = makeStyles(({ palette }) => ({
//   editorInfo: {
//     fontFamily: palette.fontFamily,
//     color: palette.primary.main,
//     textAlign: 'center',
//     fontWeight: 500,
//   },
//   infoTitle: {
//     fontSize: 16,
//     fontWeight: 700,
//     marginBottom: props => props.fileLoaded ? 36 : 15,
//   },
//   toolInfoIcon: {
//     background: palette.blue.main,
//     width: 48,
//     height: 48,
//     borderRadius: 10,
//     marginRight: 12,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   toolsInfoWrapper: {
//     display: 'flex',
//     marginBottom: 36,
//     textAlign: 'start'
//   },
//   info: {
//     width: props => props.fileLoaded ? 276 : '100%',
//   },
// }));

// const EditorInfo = ({ fileLoaded }) => {
//   const classes = useStyles({ fileLoaded });
//   const toolsInfo = [
//     {
//       icon: <HandIcon />,
//       description: 'The Hand tool allows you to move your PDF around its window by dragging',
//     },
//     {
//       icon: <TextFieldIcon />,
//       description: 'The Text field tool allows mapping to  the text field in the required place on the PDF',
//     },
//     {
//       icon: <ImageFieldIcon />,
//       description: 'The Image field tool allows mapping to the image in the required place on the PDF',
//     },
//     {
//       icon: <RulerIcon />,
//       description: 'The Ruler tool allows to turn on/off rulers and use guides',
//     },
//   ];

//   return (
//     <>
//       {fileLoaded ? (
//         <div className={classes.editorInfo}>
//           <p className={classes.infoTitle}>Select tool to get started</p>
//           {toolsInfo.map(tool => (
//             <div className={classes.toolsInfoWrapper} key={tool.description}>
//               <div className={classes.toolInfoIcon}>
//                 {tool.icon}
//               </div>
//               <p className={classes.info}>
//                 {tool.description}
//               </p>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div className={classes.editorInfo}>
//           <p className={classes.infoTitle}>Upload PDF to get started mapping</p>
//           <p className={classes.info}>
//             Drag and drop a file in the workspace or click on it to select a file from your device
//           </p>
//         </div>
//       )}
//     </>
//   );
// };

// export default EditorInfo;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CutHoleHelper = void 0;
var line3_1 = require("../../core/base/line3");
var three_1 = require("three");
var CutHoleHelper = /** @class */ (function () {
    function CutHoleHelper() {
    }
    CutHoleHelper.addConfigProperties = function (config) {
        config.holeRadius = 0.2;
        config.holeCenterX = 0;
        config.holeCenterY = 0.5;
        config.holeStretch = 0.1;
        return config;
    };
    CutHoleHelper.drawHolderHole = function (options) {
        var radius = options.holeRadius;
        var centerPoint = new three_1.Vector3(options.holeCenterX, options.holeCenterY);
        var stretch = options.holeStretch / 2;
        var aPoint = new three_1.Vector3(centerPoint.x + stretch, centerPoint.y - radius);
        var bPoint = new three_1.Vector3(centerPoint.x - stretch, centerPoint.y - radius);
        var cPoint = new three_1.Vector3(centerPoint.x - stretch - radius, centerPoint.y);
        var dPoint = new three_1.Vector3(centerPoint.x - stretch, centerPoint.y + radius);
        var ePoint = new three_1.Vector3(centerPoint.x + stretch, centerPoint.y + radius);
        var fPoint = new three_1.Vector3(centerPoint.x + stretch + radius, centerPoint.y);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [bPoint, new three_1.Vector3(cPoint.x, bPoint.y, 0), cPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [cPoint, new three_1.Vector3(cPoint.x, dPoint.y, 0), dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, ePoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [ePoint, new three_1.Vector3(fPoint.x, ePoint.y, 0), fPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [fPoint, new three_1.Vector3(fPoint.x, aPoint.y, 0), aPoint]),
        ];
    };
    return CutHoleHelper;
}());
exports.CutHoleHelper = CutHoleHelper;

/* eslint-disable import/prefer-default-export,react/prop-types */
import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Input, SvgIcon, withStyles
} from '@material-ui/core';

import { ImageFieldIcon, TextFieldIcon } from './ToolIcons.js';
import {materialTheme} from '../../materialTheme'

const { palette } = materialTheme


const styles = () => ({
  accordion: {
    background: 'transparent',
    boxShadow: 'none',
    height: 48,
    marginBottom: 8,
    '&::before': {
      background: 'transparent'
    },
    '&$expanded': {
      margin: 0
    }
  },
  accordionExpanded: {
    margin: '0 0 102px 0 !important',
  },
  accordionSummary: {
    padding: 0,
    minHeight: 0,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      height: 48,
      alignItems: 'center',
      margin: 0,
    }
  },
  expanded: {
    right: 0,
    minHeight: '24px!important',
  },
  expandedFocused: {
    backgroundColor: 'transparent !important',
  },
  expandIcon: {
    padding: 0,
    marginRight: 12,
    '&$expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiIconButton-label': {
      transform: 'rotate(0deg)',
    },
    '&$expanded .MuiIconButton-label': {
      transform: 'rotate(90deg)',
    }
  },
  expendIconSize: {
    width: 24,
    height: 24,
  },
  accordionDetails: {
    padding: 0,
    display: 'block',
    marginTop: 12,
  },
  accordionDetailsEnd: {
    height: 2,
    right: -24,
    left: -24,
    background: palette.paleBlue.main,
    position: 'absolute',
    bottom: 0,
    zIndex: 100000,
  },
  layerNameInput: {
    fontSize: 16,
    fontWeight: 700,
    width: 180,
    fontFamily: palette.fontFamily,
    color: palette.primary.main,
    marginLeft: 24
  },
  focusedLayerNameInput: {
    // fontWeight: 500,
    borderRadius: 10,
    // zIndex: 100000,
    // padding: 10,
    // height: 48,
    // width: '100%',
    background: '#F7FCFE',
    border: `2px solid ${palette.tertiary.main}`,
  },
  layerIcon: {
    width: 18,
    height: 18,
  },
  layerInputTitle: {
    fontWeight: 700,
    fontSize: 16,
    fontFamily: palette.fontFamily,
    color: palette.primary.main,
    marginBottom: 8,
  },
  layerInput: {
    border: `2px solid ${palette.blue.main}`,
    background: 'transparent',
    padding: 12,
    borderRadius: 10,
    fontSize: 16,
    width: '100%',
    height: 48,
    color: palette.primary.main,
    fontFamily: palette.fontFamily,
    fontWeight: 500,
    '&:hover': {
      borderColor: palette.secondary.main
    },
  },
  focusedLayerInput: {
    borderColor: `${palette.tertiary.main}!important`
  },
  label: {
    '&::placeholder': {
      fontWeight: 300,
      opacity: '1 !important',
      '-webkit-text-fill-color': '#A6CBDF !important',
    }
  },
  layerButton: {
    display: 'flex',
    zIndex: 1000,
    position: 'relative',
    right: 0,
    gap: '0 25px',
    top: '-27px',
    left: '250px',
    paddingRight: '50px',
  }
});

class LayerInput extends React.Component {
  render() {
    const {
      classes, inputHandler, title, value
    } = this.props;
    return (
      <div>
        <p className={classes.layerInputTitle}>{title}</p>
        <Input
          value={value}
          disableUnderline
          placeholder="Enter additional comments"
          onChange={e => inputHandler(e.target.value)}
          className={classes.layerInput}
          classes={{
            focusVisible: classes.focusedLayerInput,
            input: classes.label,
          }}
        />
      </div>
    );
  }
}

class EditorLayer extends React.Component {
  state = {
    layerComments: ''
  }

  layerNameChange = value => {
    const { layer, updateRectParams } = this.props;
    layer.title = value;

    updateRectParams();
  };

  setLayerComments = layerComments => {
    this.setState({ layerComments });
  }

  render() {
    const {
      classes, layer, deleteLayer, lockLayer
    } = this.props;
    const { layerComments } = this.state;
    const lockIncons = {
      true: (
        <div>
          <path d="M3 6.7207C3 6.16842 3.44772 5.7207 4 5.7207H12.1053C12.6575 5.7207 13.1053 6.16842 13.1053 6.7207V13.5628C13.1053 14.1151 12.6575 14.5628 12.1053 14.5628H4C3.44772 14.5628 3 14.1151 3 13.5628V6.7207Z" fill="#2C3E66" />
          <path fillRule="evenodd" clipRule="evenodd" d="M4.89478 4.4577C4.89478 2.71364 6.30861 1.2998 8.05267 1.2998C9.79673 1.2998 11.2106 2.71364 11.2106 4.4577V5.72346C11.1758 5.72166 11.1408 5.72075 11.1056 5.72075H9.94772V4.4576C9.94772 3.41116 9.09941 2.56286 8.05298 2.56286C7.00654 2.56286 6.15824 3.41116 6.15824 4.4576V5.72075H5.00035C4.96493 5.72075 4.92974 5.72167 4.89478 5.72349V4.4577ZM8.0269 14.5629C8.03548 14.5629 8.04407 14.563 8.05267 14.563C8.06127 14.563 8.06986 14.5629 8.07845 14.5629H8.05298H8.0269Z" fill="#2C3E66" />
        </div>
      ),
      false: (
        <div>
          <path fillRule="evenodd" clipRule="evenodd" d="M4 5.7207C3.44772 5.7207 3 6.16842 3 6.7207V13.5628C3 14.1151 3.44772 14.5628 4 14.5628H12.1053C12.6575 14.5628 13.1053 14.1151 13.1053 13.5628V6.7207C13.1053 6.16842 12.6575 5.7207 12.1053 5.7207H4ZM11.8422 6.98391H4.26326V13.2997H11.8422V6.98391Z" fill="#2C3E66" />
          <path fillRule="evenodd" clipRule="evenodd" d="M4.89453 4.4577C4.89453 2.71364 6.30837 1.2998 8.05243 1.2998C9.79648 1.2998 11.2103 2.71364 11.2103 4.4577V5.72346C11.1756 5.72166 11.1406 5.72075 11.1054 5.72075H9.94747V4.4576C9.94747 3.41116 9.09917 2.56286 8.05273 2.56286C7.0063 2.56286 6.158 3.41116 6.158 4.4576V5.72075H5.0001C4.96469 5.72075 4.92949 5.72167 4.89453 5.72349V4.4577ZM8.02665 14.5629C8.03523 14.5629 8.04383 14.563 8.05243 14.563C8.06103 14.563 8.06962 14.5629 8.0782 14.5629H8.05273H8.02665Z" fill="#2C3E66" />
          <rect x="4.7998" y="4.21973" width="1.5" height="1.5" fill="#E7F6FF" />
        </div>
      )
    };
    return (
      // <Accordion
      //   classes={{
      //     expanded: classes.accordionExpanded
      //   }}
      //   className={classes.accordion}
      // >
      //   <AccordionSummary
      //     className={classes.accordionSummary}
      //     classes={{
      //       expandIcon: classes.expandIcon,
      //       expanded: classes.expanded,
      //       focusVisible: classes.expandedFocused,
      //     }}
      //     expandIcon={(
      //       <SvgIcon className={classes.expendIconSize}>
      //         <path d="M9.29244 7.12799C9.19974 7.2205 9.12619 7.33039 9.07601 7.45137C9.02583 7.57234 9 7.70202 9 7.83299C9 7.96396 9.02583 8.09364 9.07601 8.21461C9.12619 8.33559 9.19974 8.44548 9.29244 8.53799L13.1724 12.418L9.29244 16.298C9.19986 16.3906 9.12642 16.5005 9.07632 16.6214C9.02621 16.7424 9.00042 16.8721 9.00042 17.003C9.00042 17.1339 9.02621 17.2636 9.07632 17.3845C9.12642 17.5055 9.19986 17.6154 9.29244 17.708C9.38503 17.8006 9.49494 17.874 9.6159 17.9241C9.73687 17.9742 9.86651 18 9.99744 18C10.1284 18 10.258 17.9742 10.379 17.9241C10.5 17.874 10.6099 17.8006 10.7024 17.708L15.2924 13.118C15.3851 13.0255 15.4587 12.9156 15.5089 12.7946C15.5591 12.6736 15.5849 12.544 15.5849 12.413C15.5849 12.282 15.5591 12.1523 15.5089 12.0314C15.4587 11.9104 15.3851 11.8005 15.2924 11.708L10.7024 7.11799C10.3224 6.73799 9.68244 6.73799 9.29244 7.12799Z" fill="#2C3E66" />
      //       </SvgIcon>
      //     )}
      //   >
      //     {layer.fontFamily ? <TextFieldIcon /> : <ImageFieldIcon />}
      //     <Input
      //       value={layer.title}
      //       disableUnderline
      //       placeholder="Enter layer name"
      //       onClick={e => e.stopPropagation()}
      //       onChange={e => this.layerNameChange(e.target.value)}
      //       className={classes.layerNameInput}
      //       classes={{
      //         focusVisible: classes.focusedLayerNameInput,
      //         input: classes.label,
      //       }}
      //     />
      //     <div className={classes.layerButton}>
      //       <SvgIcon
      //         className={classes.layerIcon}
      //         viewBox="0 0 16 16"
      //         onClick={e => { e.stopPropagation(); lockLayer(layer.id); }}
      //       >
      //         {lockIncons[layer.locked]}
      //       </SvgIcon>
      //       <SvgIcon
      //         className={classes.layerIcon}
      //         viewBox="0 0 16 16"
      //         onClick={e => { e.stopPropagation(); deleteLayer(layer.id); }}
      //       >
      //         <path fillRule="evenodd" clipRule="evenodd" d="M4.77783 4.61545C4.22555 4.61545 3.77783 5.06316 3.77783 5.61545V13.6154C3.77783 14.1677 4.22555 14.6154 4.77783 14.6154H11.2222C11.7745 14.6154 12.2222 14.1677 12.2222 13.6154V5.61545C12.2222 5.06316 11.7745 4.61545 11.2222 4.61545H4.77783ZM11.0159 5.86546H4.98413V13.3655H11.0159V5.86546Z" fill="#2C3E66" />
      //         <path d="M4.00006 2.99961C4.00006 2.66824 4.26869 2.39961 4.60006 2.39961H11.4001C11.7314 2.39961 12.0001 2.66824 12.0001 2.99961C12.0001 3.33098 11.7314 3.59961 11.4001 3.59961H4.60006C4.26869 3.59961 4.00006 3.33098 4.00006 2.99961Z" fill="#2C3E66" />
      //         <path d="M6.00006 2.59961C6.00006 2.04732 6.44778 1.59961 7.00006 1.59961H9.00006C9.55235 1.59961 10.0001 2.04732 10.0001 2.59961H6.00006Z" fill="#2C3E66" />
      //       </SvgIcon>
      //     </div>
      //   </AccordionSummary>
      //   <AccordionDetails className={classes.accordionDetails}>
      //     <LayerInput
      //       title="Comments"
      //       classes={classes}
      //       value={layerComments}
      //       inputHandler={this.setLayerComments}
      //     />
      //   </AccordionDetails>
      // </Accordion>

      <div className={classes.accordion}>
         {layer.fontFamily ? TextFieldIcon.render() : ImageFieldIcon.render()}
          <Input
            value={layer.title}
            disableUnderline
            placeholder="Enter layer name"
            onClick={e => e.stopPropagation()}
            onChange={e => this.layerNameChange(e.target.value)}
            className={classes.layerNameInput}
            style={{width: '200px', marginRight: '85px' }}
            classes={{
              focused: classes.focusedLayerNameInput,
              input: classes.label,
            }}
          />
          <div className={classes.layerButton}>
            {layer.locked 
              ? <svg
                  className={classes.layerIcon}
                  viewBox="0 0 16 16"
                  onClick={e => { e.stopPropagation(); lockLayer(layer.id); }}
                >
                  <path d="M3 6.7207C3 6.16842 3.44772 5.7207 4 5.7207H12.1053C12.6575 5.7207 13.1053 6.16842 13.1053 6.7207V13.5628C13.1053 14.1151 12.6575 14.5628 12.1053 14.5628H4C3.44772 14.5628 3 14.1151 3 13.5628V6.7207Z" fill="#2C3E66" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M4.89478 4.4577C4.89478 2.71364 6.30861 1.2998 8.05267 1.2998C9.79673 1.2998 11.2106 2.71364 11.2106 4.4577V5.72346C11.1758 5.72166 11.1408 5.72075 11.1056 5.72075H9.94772V4.4576C9.94772 3.41116 9.09941 2.56286 8.05298 2.56286C7.00654 2.56286 6.15824 3.41116 6.15824 4.4576V5.72075H5.00035C4.96493 5.72075 4.92974 5.72167 4.89478 5.72349V4.4577ZM8.0269 14.5629C8.03548 14.5629 8.04407 14.563 8.05267 14.563C8.06127 14.563 8.06986 14.5629 8.07845 14.5629H8.05298H8.0269Z" fill="#2C3E66" />
                </svg>  
              : <svg
                  className={classes.layerIcon}
                  viewBox="0 0 16 16"
                  onClick={e => { e.stopPropagation(); lockLayer(layer.id); }}
                 >
                  <path fillRule="evenodd" clipRule="evenodd" d="M4 5.7207C3.44772 5.7207 3 6.16842 3 6.7207V13.5628C3 14.1151 3.44772 14.5628 4 14.5628H12.1053C12.6575 14.5628 13.1053 14.1151 13.1053 13.5628V6.7207C13.1053 6.16842 12.6575 5.7207 12.1053 5.7207H4ZM11.8422 6.98391H4.26326V13.2997H11.8422V6.98391Z" fill="#2C3E66" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M4.89453 4.4577C4.89453 2.71364 6.30837 1.2998 8.05243 1.2998C9.79648 1.2998 11.2103 2.71364 11.2103 4.4577V5.72346C11.1756 5.72166 11.1406 5.72075 11.1054 5.72075H9.94747V4.4576C9.94747 3.41116 9.09917 2.56286 8.05273 2.56286C7.0063 2.56286 6.158 3.41116 6.158 4.4576V5.72075H5.0001C4.96469 5.72075 4.92949 5.72167 4.89453 5.72349V4.4577ZM8.02665 14.5629C8.03523 14.5629 8.04383 14.563 8.05243 14.563C8.06103 14.563 8.06962 14.5629 8.0782 14.5629H8.05273H8.02665Z" fill="#2C3E66" />
                  <rect x="4.7998" y="4.21973" width="1.5" height="1.5" fill="#E7F6FF" />
                </svg>
            }

            <svg
              className={classes.layerIcon}
              viewBox="0 0 16 16"
              onClick={e => { e.stopPropagation(); deleteLayer(layer.id); }}
            >
              <path fillRule="evenodd" clipRule="evenodd" d="M4.77783 4.61545C4.22555 4.61545 3.77783 5.06316 3.77783 5.61545V13.6154C3.77783 14.1677 4.22555 14.6154 4.77783 14.6154H11.2222C11.7745 14.6154 12.2222 14.1677 12.2222 13.6154V5.61545C12.2222 5.06316 11.7745 4.61545 11.2222 4.61545H4.77783ZM11.0159 5.86546H4.98413V13.3655H11.0159V5.86546Z" fill="#2C3E66" />
              <path d="M4.00006 2.99961C4.00006 2.66824 4.26869 2.39961 4.60006 2.39961H11.4001C11.7314 2.39961 12.0001 2.66824 12.0001 2.99961C12.0001 3.33098 11.7314 3.59961 11.4001 3.59961H4.60006C4.26869 3.59961 4.00006 3.33098 4.00006 2.99961Z" fill="#2C3E66" />
              <path d="M6.00006 2.59961C6.00006 2.04732 6.44778 1.59961 7.00006 1.59961H9.00006C9.55235 1.59961 10.0001 2.04732 10.0001 2.59961H6.00006Z" fill="#2C3E66" />
            </svg>
          </div>
      </div>
    );
  }
}

export default withStyles(styles)(EditorLayer);
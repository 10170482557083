/* eslint-disable react/prop-types */
import React from 'react';

import {
  Input,
  Slider,
  Select,
  Tooltip,
  MenuItem, withStyles,
} from '@material-ui/core';

import sliderInput from './styles/SliderInputStyles';
import settingSelect from './styles/SettingsSelectStyles';
import {materialTheme} from '../../../materialTheme'

const { palette } = materialTheme


const styles = () => ({
  ...settingSelect(palette),
  ...sliderInput(palette, 84),
  sliderInput: {
    ...sliderInput(palette, 84).sliderInput,
    padding: '10px 10px 10px 12px'
  },
  menuParams: {
    width: 252,
    height: 64,
  },
  opacitySlider: {
    width: 128,
    padding: 0,
    '&::after': {
      content: '\' \'',
      position: 'absolute',
      left: 0,
      width: 2,
      height: 2,
      top: -4,
      background: '#BCDEF0',
    }
  },
  track: {
    borderRadius: 10,
  },
  rail: {
    backgroundColor: palette.blue.main,
    borderRadius: 10,
  },
  thumb: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${palette.blue.main}`,
    '&:hover': {
      boxShadow: 'none',
    }
  },
  active: {
    boxShadow: 'none !important',
  },
  sliderInputIcon: {
  },
  opacityWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginLeft: 12,
    }
  }
});
class OpacitySelect extends React.Component {
  state = {
    isOpen: false,
    topPosition: 0,
    leftPosition: 0,
    opacity: this.props.layerOpacity * 100
  }

  setRef = ref => {
    this.ref = ref;
  }

  onRefChange = () => {
    const topPosition = this.ref.getBoundingClientRect().top + 60;
    const leftPosition = this.ref.getBoundingClientRect().left;
    this.setState({ topPosition, leftPosition });
  };

  sliderHandler = (event, value) => {
    let newValue = value;

    if (value < 0) {
      newValue = 0;
    } else if (value > 100) {
      newValue = 100;
    }
    if (/^[0-9]+$/.test(newValue)) {
      const { onOpacityChange } = this.props;
      onOpacityChange(newValue / 100);
      this.setState({ opacity: newValue });
    }
  };

  someFunc() {
    this.setState({ isOpen: true });
    this.onRefChange();
  }

  render() {
    const {
      isOpen, topPosition, leftPosition, opacity
    } = this.state;
    const { classes } = this.props;
    return (
      <Select
        ref={this.setRef}
        onOpen={() => {
          this.setState({ isOpen: true });
          this.onRefChange();
        }}
        onClose={e => {
          if (e.currentTarget.className.indexOf('opacityWrapper') === -1) this.setState({ isOpen: false });
        }}
        open={isOpen}
        MenuProps={{
          anchorReference: 'anchorPosition',
          anchorPosition: { top: topPosition, left: leftPosition },
          MenuListProps: {
            classes: {
              root: `${classes.selectMenuWrapper} ${classes.menuParams}`
            }
          },
          PopoverClasses: {
            paper: classes.popover,
          }
        }}
        classes={{ selectMenu: classes.selectMenu, select: classes.selectMenu }}
        disableUnderline
        style={{paddingRight: '17px'}}
        IconComponent={() => (<div onClick={() => this.someFunc()}><svg width="24" height="24" viewBox="0 0 24 24">
        <g clipPath="url(#clip0)">
          <path d="M12.5303 0.219735C12.2373 -0.0732449 11.7627 -0.0732449 11.4697 0.219735C11.124 0.565434 3 8.76124 3 15C3 19.9629 7.0371 24 12 24C16.9628 24 20.9999 19.9629 20.9999 15C21 8.76124 12.876 0.565434 12.5303 0.219735ZM12 1.83473C13.6098 3.55704 18.3689 8.96274 19.3206 13.5H4.67961C5.63205 8.96549 10.391 3.55758 12 1.83473ZM7.54641 21.0001H16.4536C15.2045 21.93 13.6733 22.5 12 22.5C10.3266 22.5 8.79542 21.93 7.54641 21.0001ZM17.9616 19.5001H6.03833C5.68615 19.0348 5.37302 18.5406 5.13571 18.0001H18.8642C18.6269 18.5406 18.3138 19.0348 17.9616 19.5001ZM19.3483 16.5001H4.6516C4.55275 16.0152 4.49998 15.5137 4.49998 15.0001H19.5C19.5 15.5137 19.4472 16.0152 19.3483 16.5001Z" fill="#2C3E66" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg></div>)}
        // value="none"
        className={isOpen ? `${classes.select} ${classes.selectFocus}` : classes.select}
      >
        {/* <MenuItem className={classes.placeholder} value="none">
          <Tooltip
            arrow
            placement="top"
            title="Transparency"
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <g clipPath="url(#clip0)">
                <path d="M12.5303 0.219735C12.2373 -0.0732449 11.7627 -0.0732449 11.4697 0.219735C11.124 0.565434 3 8.76124 3 15C3 19.9629 7.0371 24 12 24C16.9628 24 20.9999 19.9629 20.9999 15C21 8.76124 12.876 0.565434 12.5303 0.219735ZM12 1.83473C13.6098 3.55704 18.3689 8.96274 19.3206 13.5H4.67961C5.63205 8.96549 10.391 3.55758 12 1.83473ZM7.54641 21.0001H16.4536C15.2045 21.93 13.6733 22.5 12 22.5C10.3266 22.5 8.79542 21.93 7.54641 21.0001ZM17.9616 19.5001H6.03833C5.68615 19.0348 5.37302 18.5406 5.13571 18.0001H18.8642C18.6269 18.5406 18.3138 19.0348 17.9616 19.5001ZM19.3483 16.5001H4.6516C4.55275 16.0152 4.49998 15.5137 4.49998 15.0001H19.5C19.5 15.5137 19.4472 16.0152 19.3483 16.5001Z" fill="#2C3E66" />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Tooltip>
        </MenuItem> */}
        <div className={`${classes.opacityWrapper} opacityWrapper`}>
          <Slider
            min={0}
            max={100}
            value={opacity}
            className={classes.opacitySlider}
            classes={{
              active: classes.active,
              track: classes.track,
              rail: classes.rail,
              thumb: classes.thumb,
            }}
            onChange={this.sliderHandler}
          />
          <Input
            endAdornment={(
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={classes.sliderInputIcon}>
                <path d="M17.5028 4.49781L4.49883 17.5028C4.23415 17.7675 4.08545 18.1265 4.08545 18.5008C4.08545 18.8751 4.23415 19.2341 4.49883 19.4988C4.76352 19.7635 5.12251 19.9122 5.49683 19.9122C5.87116 19.9122 6.23015 19.7635 6.49483 19.4988L19.4988 6.49481C19.7553 6.22842 19.897 5.87205 19.8936 5.50229C19.8901 5.13252 19.7417 4.77887 19.4803 4.51733C19.2189 4.25579 18.8653 4.10723 18.4956 4.10358C18.1258 4.09994 17.7694 4.24148 17.5028 4.49781ZM7.00183 4.99981C6.73919 4.99955 6.47907 5.05102 6.23632 5.15129C5.99356 5.25156 5.77294 5.39865 5.58704 5.58419C5.40113 5.76972 5.25359 5.99005 5.15284 6.2326C5.05209 6.47515 5.0001 6.73517 4.99983 6.99781C4.99957 7.26046 5.05104 7.52058 5.15131 7.76333C5.25158 8.00609 5.39868 8.22671 5.58421 8.41261C5.76974 8.59852 5.99007 8.74606 6.23262 8.84681C6.47517 8.94756 6.73519 8.99955 6.99783 8.99981C7.52827 9.00034 8.03719 8.79014 8.41263 8.41544C8.78808 8.04074 8.9993 7.53225 8.99983 7.00181C9.00036 6.47138 8.79016 5.96246 8.41546 5.58701C8.04076 5.21157 7.53227 5.00034 7.00183 4.99981ZM17.0018 14.9998C16.4714 14.9993 15.9625 15.2095 15.587 15.5842C15.2116 15.9589 15.0004 16.4674 14.9998 16.9978C14.9993 17.5282 15.2095 18.0372 15.5842 18.4126C15.9589 18.7881 16.4674 18.9993 16.9978 18.9998C17.2605 19.0001 17.5206 18.9486 17.7634 18.8483C18.0061 18.7481 18.2267 18.601 18.4126 18.4154C18.5985 18.2299 18.7461 18.0096 18.8468 17.767C18.9476 17.5245 18.9996 17.2645 18.9998 17.0018C19.0001 16.7392 18.9486 16.479 18.8484 16.2363C18.7481 15.9935 18.601 15.7729 18.4155 15.587C18.2299 15.4011 18.0096 15.2536 17.767 15.1528C17.5245 15.0521 17.2645 15.0001 17.0018 14.9998Z" fill="#BCDEF0" />
              </svg>
            )}
            value={opacity}
            onChange={e => this.sliderHandler(e, e.target.value)}
            disableUnderline
            className={classes.sliderInput}
            classes={{ focused: classes.focusedInput }}
          />
        </div>
      </Select>
    );
  }
}

export default withStyles(styles)(OpacitySelect);




















import {Vue} from "vue-property-decorator";

let vm = Vue.component("HoleModal", {
    props: {
        holes: {
            type: Array
        }
    },
    data: function() {
        return {}
    },
    methods: {
        addHole(hole: any) {
            this.$emit('addHole', hole);
            this.$emit('closeModal');
        }
    }
})

export default vm;

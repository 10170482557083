"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddObject = void 0;
var command_1 = require("./command");
var AddObject = /** @class */ (function (_super) {
    __extends(AddObject, _super);
    function AddObject(nodes, parentId, childs, parentSideIndex, childSideIndex) {
        var _this = _super.call(this, nodes) || this;
        _this._parentId = parentId;
        _this._childs = childs;
        _this._parentSideIndex = parentSideIndex;
        _this._childSideIndex = childSideIndex;
        return _this;
    }
    AddObject.prototype.action = function () {
        var _this = this;
        var _a;
        var parentNode = this.nodes.find(function (node) { return node.id === _this._parentId; });
        if (!!parentNode) {
            var childNode = {
                parentEdgeIndex: this._parentSideIndex,
                childEdgeIndex: this._childSideIndex,
                childId: this._childs[0].id
            };
            (_a = parentNode.childs) === null || _a === void 0 ? void 0 : _a.push(childNode);
            this._childs.forEach(function (item) {
                _this.nodes.push(item);
            });
        }
    };
    return AddObject;
}(command_1.Command));
exports.AddObject = AddObject;

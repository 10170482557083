













import Vue, { PropOptions } from "vue";
import axios from "axios";
import { v4 } from "uuid";
import { TextData } from "@/types";

let vm = Vue.component("TextureSettings", {
  props: {
    selectedTexture: {
      type: Object,
    },
    coloringData: {
      type: Map,
      default: new Map(),
    },
    fabricTextObjects: {
      type: Array,
      default: () => [],
    } as PropOptions<Array<TextData>>,
  },
  beforeDestroy: async function() {
    const changedTexture = this.coloringData.get(this.selectedTexture.id);
    const changedText = this.fabricTextObjects.find(
      (text) => text.id == this.selectedTexture.id
    );

    if (changedTexture?.images.length || changedText?.texts.length) {
      const result = await axios.put(
        `${process.env.VUE_APP_API_URL}/api/v1/template/updateTexture/${this.$route.params.templateId}`,
        {
          coloringData: {
            key: this.selectedTexture.id,
            value: changedTexture,
          },
          textData: changedText ?? "",
        },
        { withCredentials: true }
      );
    }
  }
});

export default vm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Triangle = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var Triangle = /** @class */ (function (_super) {
    __extends(Triangle, _super);
    function Triangle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Triangle.prototype.isValid = function () {
        var options = this.config.options;
        var _a = __read([options.a, options.b, options.c], 3), a = _a[0], b = _a[1], c = _a[2];
        return a + b > c && a + c > b && b + c > a;
    };
    Triangle.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 2];
    };
    Triangle.prototype.calculateLines = function () {
        var options = this.config.options;
        var _a = __read([options.a, options.b, options.c], 3), a = _a[0], b = _a[1], c = _a[2];
        var cPointX = (a * a + c * c - b * b) / (c * 2);
        var cPointY = Math.sqrt(a * a - cPointX * cPointX);
        var aPoint = new three_1.Vector3(c / 2, 0, 0);
        var bPoint = new three_1.Vector3(-c / 2, 0, 0);
        var cPoint = new three_1.Vector3(-c / 2 + cPointX, cPointY, 0);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, aPoint])
        ];
    };
    Triangle.prototype.getConfigProperties = function () {
        return {
            a: 1,
            b: 1,
            c: 1,
        };
    };
    return Triangle;
}(figureBase_1.FigureBase));
exports.Triangle = Triangle;

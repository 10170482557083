"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CutUfoHelper = void 0;
var line3_1 = require("../../core/base/line3");
var three_1 = require("three");
var CutUfoHelper = /** @class */ (function () {
    function CutUfoHelper() {
    }
    CutUfoHelper.addProperties = function (config) {
        config.ufoWidth = 0.5;
        config.ufoHeight = 0.2;
        config.ufoRadius = 0.25;
        config.offsetBeforeRadius = 0.1;
        config.sideRadius = 0.1;
        config.centerY = 0.5;
        return config;
    };
    CutUfoHelper.drawUfo = function (coords) {
        var _a = __read([
            coords.centerY,
            coords.ufoWidth,
            coords.ufoHeight,
            coords.ufoRadius,
            coords.offsetBeforeRadius,
            coords.sideRadius,
        ], 6), centerY = _a[0], ufoWidth = _a[1], ufoHeight = _a[2], ufoRadius = _a[3], offsetBeforeRadius = _a[4], sideRadius = _a[5];
        var offsetY = ufoHeight / 2;
        var offsetX = ufoWidth / 2;
        var aPoint = new three_1.Vector3(0 - offsetBeforeRadius, centerY + offsetY);
        var bPoint = new three_1.Vector3(0, centerY + ufoRadius + offsetY);
        var cPoint = new three_1.Vector3(0 + offsetBeforeRadius, centerY + offsetY);
        var dPoint = new three_1.Vector3(offsetX, centerY + offsetY);
        var ePoint = new three_1.Vector3(offsetX + sideRadius, centerY);
        var fPoint = new three_1.Vector3(offsetX, centerY - offsetY);
        var gPoint = new three_1.Vector3(0 + offsetBeforeRadius, centerY - offsetY);
        var hPoint = new three_1.Vector3(0, centerY - ufoRadius - offsetY);
        var iPoint = new three_1.Vector3(0 - offsetBeforeRadius, centerY - offsetY);
        var jPoint = new three_1.Vector3(0 - offsetX, centerY - offsetY);
        var kPoint = new three_1.Vector3(0 - offsetX - sideRadius, centerY);
        var lPoint = new three_1.Vector3(0 - offsetX, centerY + offsetY);
        return [
            new line3_1.Line3(line3_1.LineType.BazierCurve, [aPoint, bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [dPoint, ePoint, fPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [fPoint, gPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [gPoint, hPoint, iPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [iPoint, jPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [jPoint, kPoint, lPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [lPoint, aPoint])
        ];
    };
    return CutUfoHelper;
}());
exports.CutUfoHelper = CutUfoHelper;

/* eslint-disable import/prefer-default-export */
// 1px is 0.2645833333mm
const PIXEL_TO_MM = 0.2645833333;

const pxToMM = pixels => Math.round(pixels * PIXEL_TO_MM);

const pxToCm = pixels => Math.round(pixels * PIXEL_TO_MM) / 10;

const pxToM = pixels => Math.round(pixels * PIXEL_TO_MM) / 1000;

export const roundUnit = (pixels, unitType) => {
  let value = pxToMM(pixels);

  if (unitType === 'cm') {
    value = pxToCm(pixels);
  } else if (unitType === 'm') {
    value = pxToM(pixels);
  }

  return value;
};

export const getRectWidthAndHeight = object => {
  const anglePath = object.angle;
  const coordsPath = object.aCoords;

  const result = {
    width: object.width * object.scaleX,
    height: object.height * object.scaleY,
  };

  if (anglePath >= 0 && anglePath <= 90) {
    result.width = coordsPath.tr.x - coordsPath.bl.x;
    result.height = coordsPath.br.y - coordsPath.tl.y;
  } else if (anglePath >= 90 && anglePath <= 180) {
    result.width = coordsPath.tl.x - coordsPath.br.x;
    result.height = coordsPath.tr.y - coordsPath.bl.y;
  } else if (anglePath >= 180 && anglePath <= 270) {
    result.width = coordsPath.bl.x - coordsPath.tr.x;
    result.height = coordsPath.tl.y - coordsPath.br.y;
  } else if (anglePath >= 270 && anglePath <= 360) {
    result.width = coordsPath.br.x - coordsPath.tl.x;
    result.height = coordsPath.bl.y - coordsPath.tr.y;
  }

  return result;
};

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundedHandleBox = void 0;
var handleBox_1 = require("./handleBox");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var RoundedHandleBox = /** @class */ (function (_super) {
    __extends(RoundedHandleBox, _super);
    function RoundedHandleBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoundedHandleBox.prototype.calculateLines = function () {
        var coords = this.config.options;
        var _a = __read([
            coords.width,
            coords.heightBottom,
            coords.heightTop,
        ], 3), width = _a[0], heightBottom = _a[1], heightTop = _a[2];
        var aPoint = new three_1.Vector3(width / 2, 0, 0);
        var bPoint = new three_1.Vector3(-width / 2, 0, 0);
        var cPoint = new three_1.Vector3(0, heightBottom + heightTop, 0);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [bPoint, new three_1.Vector3(bPoint.x, cPoint.y, 0), cPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [cPoint, new three_1.Vector3(aPoint.x, cPoint.y, 0), aPoint])
        ];
    };
    RoundedHandleBox.prototype.calculateConnectionIndexes = function () {
        return [0];
    };
    return RoundedHandleBox;
}(handleBox_1.HandleBox));
exports.RoundedHandleBox = RoundedHandleBox;











































import Vue, { PropOptions } from "vue";
import {
  Rectangles,
  Trapezes,
  Latches,
  Triangles,
  Connections,
  Figure,
  Handles
} from "packaging3d-engine/lib";

const vm = Vue.component("ShapeSelection", {
  props: {
    options: {
      type: Array
    } as PropOptions<Figure[]>
  },
  data: function() {
    return {
      name: "",
      error: "",
      selectedFigure: Figure.Rectangle,
      path: process.env.VUE_APP_API_URL,
      figures: {
        triangles: [] as Figure[],
        rectangles: [] as Figure[],
        trapezes: [] as Figure[],
        handles: [] as Figure[],
        latches: [] as Figure[],
        connections: [] as Figure[]
      }
    };
  },
  created: function() {
    this.sortFigures();
  },
  methods: {
    updateFigure: function(figure: Figure) {
      this.$emit("figure-selected", figure);
    },
    figureCheck: function(figureName: string) {
      return function(val: string) {
        return val.toLowerCase() === figureName.toLowerCase();
      };
    },
    sortFigures: function() {
      const entries = Object.entries(this.options);
      const rectangles = [] as Figure[];
      const trapezes = [] as Figure[];
      const triangles = [] as Figure[];
      const latches = [] as Figure[];
      const connections = [] as Figure[];
      const handles = [] as Figure[];
      for (let i = 0; i < entries.length; i++) {
        const figureName = entries[i][1];
        if (Rectangles.findIndex(this.figureCheck(figureName)) !== -1) {
          rectangles.push(this.options[i]);
        } else if (Trapezes.findIndex(this.figureCheck(figureName)) !== -1) {
          trapezes.push(this.options[i]);
        } else if (Triangles.findIndex(this.figureCheck(figureName)) !== -1) {
          triangles.push(this.options[i]);
        } else if (Latches.findIndex(this.figureCheck(figureName)) !== -1) {
          latches.push(this.options[i]);
        } else if (Connections.findIndex(this.figureCheck(figureName)) !== -1) {
          connections.push(this.options[i]);
        } else if (Handles.findIndex(this.figureCheck(figureName)) !== -1) {
          handles.push(this.options[i]);
        }
      }
      this.figures = {
        handles: [...handles],
        connections: [...connections],
        rectangles: [...rectangles],
        latches: [...latches],
        triangles: [...triangles],
        trapezes: [...trapezes]
      };
    }
  }
});

export default vm;

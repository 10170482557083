"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Line3 = exports.LineType = void 0;
var LineType;
(function (LineType) {
    LineType[LineType["Straight"] = 0] = "Straight";
    LineType[LineType["BazierCurve"] = 1] = "BazierCurve";
})(LineType = exports.LineType || (exports.LineType = {}));
var Line3 = /** @class */ (function () {
    function Line3(type, points) {
        this.type = type;
        if (type == LineType.BazierCurve && points.length != 3)
            throw new Error("wrong line creation. For type " + type + " you should use 3 points");
        this.startPoint = points[0].clone();
        this.middlePoint = points[1].clone();
        this.endPoint = points[points.length - 1].clone();
    }
    Object.defineProperty(Line3.prototype, "type", {
        get: function () {
            return this._type;
        },
        set: function (value) {
            this._type = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Line3.prototype, "startPoint", {
        get: function () {
            return this._startPoint;
        },
        set: function (value) {
            this._startPoint = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Line3.prototype, "endPoint", {
        get: function () {
            return this._endPoint;
        },
        set: function (value) {
            this._endPoint = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Line3.prototype, "middlePoint", {
        get: function () {
            return this._middlePoint;
        },
        set: function (value) {
            this._middlePoint = value;
        },
        enumerable: false,
        configurable: true
    });
    Line3.prototype.calculateDirectionVector = function () {
        return this.endPoint.clone().sub(this.startPoint.clone()).normalize();
    };
    Line3.prototype.applyMatrix4 = function (matrix) {
        this.startPoint.applyMatrix4(matrix);
        this.middlePoint.applyMatrix4(matrix);
        this.endPoint.applyMatrix4(matrix);
    };
    Line3.prototype.applyShift = function (shift) {
        this.startPoint.add(shift);
        this.middlePoint.add(shift);
        this.endPoint.add(shift);
    };
    Line3.prototype.applyScaling = function (scale) {
        this.scalePoint(this.startPoint, scale);
        this.scalePoint(this.middlePoint, scale);
        this.scalePoint(this.endPoint, scale);
    };
    Line3.prototype.scalePoint = function (point, scale) {
        point.x = point.x * scale.x;
        point.y = point.y * scale.y;
        point.z = point.z * scale.z;
    };
    Line3.prototype.print = function () {
        console.log("=================");
        console.log("STARTPOINT:  ", this.startPoint.x, "  ", this.startPoint.y, "  ", this.startPoint.z);
        console.log("MIDDLEPOINT: ", this.middlePoint.x, "  ", this.middlePoint.y, "  ", this.middlePoint.z);
        console.log("ENDPOINT:    ", this.endPoint.x, "  ", this.endPoint.y, "  ", this.endPoint.z);
        console.log("=================");
    };
    Line3.prototype.clone = function () {
        return new Line3(this.type, [this.startPoint.clone(), this.middlePoint.clone(), this.endPoint.clone()]);
    };
    Line3.prototype.length = function () {
        return this.startPoint.distanceTo(this.endPoint);
    };
    Line3.prototype.equals = function (line) {
        var isEqual = Line3.pointEquals(this.startPoint, line.startPoint) && Line3.pointEquals(this.endPoint, line.endPoint);
        var isReverseEqual = Line3.pointEquals(this.startPoint, line.endPoint) && Line3.pointEquals(this.endPoint, line.startPoint);
        return isEqual || isReverseEqual;
    };
    Line3.pointEquals = function (point1, point2) {
        var difX = point1.x - point2.x;
        var difY = point1.y - point2.y;
        return Math.abs(difX) < Line3.DIF && Math.abs(difY) < Line3.DIF;
    };
    Line3.prototype.calculateAСoefficient = function () {
        if (Math.abs(this.startPoint.x - this.endPoint.x) < Line3.DIF)
            return undefined;
        return (this.endPoint.y - this.startPoint.y) / (this.endPoint.x - this.startPoint.x);
    };
    Line3.prototype.calculateBСoefficient = function () {
        var a = this.calculateAСoefficient();
        if (a === undefined)
            return undefined;
        return this.startPoint.y - a * this.startPoint.x;
    };
    Line3.prototype.cutLine = function (insideLine) {
        var pointBefore = insideLine.startPoint.clone();
        var pointAfter = insideLine.endPoint.clone();
        var beforeLine = this.clone();
        var afterLine = this.clone();
        beforeLine.endPoint = pointBefore;
        afterLine.startPoint = pointAfter;
        return [beforeLine, afterLine];
    };
    Line3.prototype.reverseLine = function () {
        var startPoint = this.startPoint.clone();
        var endPoint = this.endPoint.clone();
        this.startPoint = endPoint;
        this.endPoint = startPoint;
    };
    Line3.prototype.add = function (point) {
        this.startPoint.add(point);
        this.middlePoint.add(point);
        this.endPoint.add(point);
    };
    Line3.prototype.reflectFromOy = function () {
        this.startPoint.x = -this.startPoint.x;
        this.endPoint.x = -this.endPoint.x;
        this.middlePoint.x = -this.middlePoint.x;
    };
    Line3.prototype.changeDirection = function () {
        var tmp = this.startPoint.clone();
        this.startPoint = this.endPoint;
        this.endPoint = tmp;
    };
    Line3.DIF = 1;
    return Line3;
}());
exports.Line3 = Line3;
















































































































































































































































































































































import Vue from "vue";
import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  Color,
  DirectionalLight,
  Mesh,
  Vector2,
  MeshBasicMaterial,
  Box3,
  AxesHelper,
  MeshStandardMaterial,
  Object3D,
  CanvasTexture,
  Vector3,
  DoubleSide,
  ImageLoader,
  Raycaster,
  CircleGeometry,
  MeshPhysicalMaterial,
  SpotLight,
  Group,
  Material,
} from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { OrbitControls } from "../lib/OrbitControls";
const VSwatches = require("vue-swatches");
import "vue-select/dist/vue-select.css";
import "vue-swatches/dist/vue-swatches.css";
import axios from "axios";
import Loader from "./Loader.vue";
import { fabric } from "fabric";
import { v4 } from "uuid";
const THREE = require("three");
import { cloneDeep, chunk } from "lodash";
import { generateRandomColor, Line3 } from "packaging3d-engine";
import * as PromiseBird from "bluebird";
import fonts from "./../components-react/TemplateEditor/Template/fonts.js";
import SaveAsBtn from "./SaveAsBtn.vue";
import ShopifySendBtn from "./ShopifySendBtn.vue";
const WebFont = require("webfontloader");

enum DetailsTypes {
  default_bot = "default_bot",
  default_mid = "default_mid",
  default_top = "default_top",
  bot = "bot",
  mid = "mid",
  top = "top",
}

interface inititalMaterial {
  id: string;
  material: MeshPhysicalMaterial;
}

let vm = Vue.component("Object3dModel", {
  props: {
    isShowSettigs: {
      type: Boolean,
      default: () => true,
    },
    size: {
      type: Object,
      default: () => {
        return {
          x: window.innerWidth,
          y: window.innerHeight,
        };
      },
    },
    renderer: {
      type: WebGLRenderer,
      default: () =>
        new WebGLRenderer({
          antialias: true,
          preserveDrawingBuffer: true,
        }),
    },
    model3dPath: {
      type: String,
    },
  },
  data: function() {
    return {
      scene: new Scene(),
      camera: new PerspectiveCamera(
        30,
        window.innerWidth / window.innerHeight,
        1,
        3500
      ),
      color: "#DDE6E8" as string,
      colorChanged: false as boolean,
      fontList: fonts,
      model: new Group(),
      previewImage: "",
      helper: new AxesHelper(200),
      controls: {} as OrbitControls,
      scale: new Vector3(1, 1, 1),
      selectedOption: "pack",
      meshs: [] as Mesh[],
      generalMesh: [] as any,
      isLoaded: false,
      selectedGeneralMesh: false,
      modifications: [] as any[],
      fabricCanvas: [] as fabric.Canvas[],
      mouse: new Vector2(),
      selectedSide: null as Mesh | null,
      coloringData: new Map(),
      fabricTextObjects: [] as any,
      localColoringImages: [] as any,
      fabricCounter: 0,
      isLocalLoaded: false,
      hdrCubeRenderTarget: null as any,
      light: new SpotLight(0xffffff, 1),
      selectedTextBox: null as any,
      dataFromShopify: "",
      isOpenSettings: false,
      isOpenTextSettings: true,
      mobileSelectedSide: null as Mesh | null,
      imgIsUploaded: false as boolean,
      textIsUploaded: false as boolean,
      generatedCanvasStorage: new Map(),
      largeCanvasStorage: new Map(),
      globalLayerCounter: 0 as number,
      resizeFromCenterIcon: null as any,
      resizeFromEdgeIcon: null as any,
      overlayImage: null as any,
      permitMobileUpdate: true as boolean,
      outlineWithRender: false as boolean,
      modelName: '' as string,
    };
  },
  computed: {
    isSelectedButton: function() {
      return (name: string): boolean => {
        if (this.mobileSelectedSide)
          return this.mobileSelectedSide.name === name;
        return false;
      };
    },
    isMobile: function(): boolean {
      return window.innerWidth < 520;
    },
    planeOptions: function(): Mesh[] | Mesh | null {
      if (this.isOnlyOneSelectMesh) return this.isOnlyOneSelectMesh;
      this.generalMesh.forEach((mesh) => {
        mesh.displayedName = mesh.name.split("_")[1];
      });
      return this.generalMesh;
    },
    isOpenedMobilePlane: function(): boolean {
      return this.isMobile ? Boolean(this.selectedSide) : true;
    },
    isShowSettings: function(): boolean {
      return this.isMobile ? this.isOpenSettings : true;
    },
    isOnlyOneSelectMesh: function(): Mesh | null {
      const selectSides = this.model.children.filter(
        (child) => !child.name.includes("_disabled") 
        && !child.name.includes("_outline")
        && !child.name.includes("size_")
      ) as Mesh[];

      return selectSides.length == 1 ? selectSides[0] : null;
    },
    outlineMesh: function(): Mesh | null {
      return this.model.children.filter(
            (child) => child.name.includes("_outline")
          )[0] as Mesh;
    },
    sizeMesh: function(): Mesh | null {
      return this.model.children.filter(
            (child) => child.name.includes("size_")
          )[0] as Mesh;
    },
    checkFrame: function(): boolean {
      return this.$route.name == "iFrame";
    },
    colorLoader: function(): string {
      return this.checkFrame ? "#212121" : "rgb(111, 218, 165)";
    },
    isCastomizationFrame: function(): boolean {
      return Boolean(Number(this.$route.params?.customization));
    },
    selectedTextBoxFont: function(): string {
      return this.selectedTextBox?.fontFamily
        ? this.selectedTextBox?.fontFamily
        : "";
    },
    isDisabledSelectedMesh: function(): boolean {
      const isDisabledMesh = this.selectedSide?.name.includes("disabled");

      return !isDisabledMesh;
    },
    isPublic: function(): boolean {
      const isAdmin = this.$store.state.user.role == "ADMIN";
      const isPublicCollection = this.$route.name == "publicTemplate";

      return isPublicCollection && !isAdmin;
    },
    isNotAnUploadPage: function(): boolean {
      return this.$route.name !== "upload";
    },
    threeContainer: function(): HTMLElement {
      return this.$refs?.container as HTMLElement;
    },
    canvasIsNotEmpty: function(): boolean {
      let foundCanvas: fabric.Canvas;

      switch (true) {
        case Boolean(this.isOnlyOneSelectMesh):
          foundCanvas = this.fabricCanvas.find(
            (fabricObject) => fabricObject.id == this.isOnlyOneSelectMesh?.name
          );
          break;
        case Boolean(this.mobileSelectedSide):
          foundCanvas = this.fabricCanvas.find(
            (fabricObject) => fabricObject.id == this.mobileSelectedSide?.name
          );
          break;
      }

      return Boolean(foundCanvas?.canvas.getObjects().length);
    },
    canvasIconSize: function(): number {
      return this.isMobile ? 36 : 24;
    },
    canvasIconOffset: function(): number {
      return this.isMobile ? 24 : 12;
    },
    isPhoneCase: function(): boolean {
      let isPhoneCase = false;

      this.meshs.forEach((mesh) => {
        if (mesh.name.includes("phone")) {
          isPhoneCase = true;
        }
      });

      return isPhoneCase;
    },
  },
  mounted: function() {
    this.init();
    this.threeContainer.addEventListener("click", this.selectFigure, false);
    document.addEventListener("mousemove", this.onDocumentMouseMove, false);
  },
  beforeDestroy: function() {
    const children = this.scene.children;
    children.forEach((child) => {
      this.scene.remove(child);
    });

    this.threeContainer.removeEventListener("click", this.selectFigure, false);
    this.threeContainer.removeEventListener(
      "touchstart",
      this.selectFigure,
      false
    );
    document.removeEventListener("mousemove", this.onDocumentMouseMove, false);

    this.scene.dispose();
    this.renderer.dispose();
    this.controls.dispose();
  },
  watch: {
    color: async function(newVal) {
      this.permitMobileUpdate = true;
      await this.updateFabricCanvas();
      await this.renderFabricCanvas();
      this.permitMobileUpdate = false;
      this.colorChanged = true;
    },
    selectedSide: function(newVal, oldVal) {
      if (!newVal && oldVal) this.selectedTextBox = null;
      this.renderFabricCanvas();
    },
    "model.children.length": function() {
      this.fitCameraToObject(this.camera, this.model, null, this.controls);
    },
  },
  methods: {
    toggleTextSettings: function() {
      this.isOpenTextSettings = !this.isOpenTextSettings;
    },
    createUVMapping: function(geometryProjected: any) {
      geometryProjected.faceVertexUvs[0] = [];
      geometryProjected.computeBoundingBox();
      let max = geometryProjected.boundingBox!.max;
      let min = geometryProjected.boundingBox!.min;
      let offset = new THREE.Vector2(0 - min.x, 0 - min.y);
      let range = new THREE.Vector2(max.x - min.x, max.y - min.y);
      let faces = geometryProjected.faces;

      for (let i = 0; i < geometryProjected.faces.length; i++) {
        let v1 = geometryProjected.vertices[faces[i].a];
        let v2 = geometryProjected.vertices[faces[i].b];
        let v3 = geometryProjected.vertices[faces[i].c];

        let uv0: Vector2 = new THREE.Vector2(
          (v1.x + offset.x) / range.x,
          (v1.y + offset.y) / range.y
        );
        let uv1: Vector2 = new THREE.Vector2(
          (v2.x + offset.x) / range.x,
          (v2.y + offset.y) / range.y
        );
        let uv2: Vector2 = new THREE.Vector2(
          (v3.x + offset.x) / range.x,
          (v3.y + offset.y) / range.y
        );

        geometryProjected.faceVertexUvs[0].push([uv0, uv1, uv2]);
      }

      geometryProjected.uvsNeedUpdate = true;

      return geometryProjected.faceVertexUvs[0];
    },
    getLeftTopExtreamPoint: function(points, scale) {
      const extreamPoint = { z: points[0].z, y: points[0].y };

      points.forEach((point) => {
        if (point.z < extreamPoint.z) extreamPoint.z = point.z;
        if (point.y < extreamPoint.y) extreamPoint.y = point.y;
      });

      return extreamPoint;
    },
    getScan: async function(typeScan) {
      if (this.isOnlyOneSelectMesh) {
        const Iphone12Size = [9.02, 15.52];
        const IphoneXSize = [10.04, 16.07];
        
        let neededMesh = this.isOnlyOneSelectMesh;

        if (this.outlineMesh) neededMesh = this.outlineMesh; //setting mesh for case outline

        const edges = new THREE.EdgesGeometry(neededMesh.geometry);
        const scale = this.isOnlyOneSelectMesh.scale;
        const verts = edges.attributes.position.array;

        const canvasSize = this.getObjectSize(this.isOnlyOneSelectMesh);
        const objectSize = this.getObjectSize(neededMesh);
        const resizeCoefficient = 299.212; //coef that used to scale object to real size

        const sortedPositions = [] as any;
        for (let k = 0; k < verts.length; k += 3) {
          sortedPositions.push({
            x: -(verts[k].toFixed(3) * resizeCoefficient) * scale.x,
            y: -(verts[k + 1].toFixed(3) * resizeCoefficient) * scale.y,
            z: -(verts[k + 2].toFixed(3) * resizeCoefficient) * scale.z,
          });
        }

        const offset = this.getLeftTopExtreamPoint(sortedPositions, scale);

        sortedPositions.forEach((position) => {
          position.z = position.z - offset.z;
          position.y = position.y - offset.y;
        });

        const vectors = chunk(sortedPositions, 2);
        const size = [
          objectSize.z * resizeCoefficient,
          objectSize.y * resizeCoefficient,
        ];
        let pdfSize = size;
        if (this.modelName.includes('iPhone X')) {
          pdfSize = IphoneXSize;
        }
        if (this.modelName.includes('iPhone 12 Mini')) {
          pdfSize = Iphone12Size;
        }
          const locaSize = this.getFabricCanvasSize(neededMesh);
        if (this.sizeMesh) {
          pdfSize = [locaSize.width, locaSize.height];
        }
        const objectDepth = canvasSize.x * resizeCoefficient;
        if (!this.overlayImage && typeScan === "overlayImage" && this.outlineMesh) {
          this.generateOutline(vectors, objectDepth, size);
          return;
        }
        const canvas = this.largeCanvasStorage.get("main");
        if (!this.colorChanged && this.color === '#DDE6E8') {
          canvas.backgroundColor = null;
        }
        const image = canvas.toDataURL("image/png");

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/download/scan`,
          {
            vectors,
            image,
            size,
            depth: objectDepth,
            overlayImage: this.overlayImage? this.overlayImage.image : null,
            typeScan,
            pdfSize,
          },
          {
            responseType: typeScan == "file" ? "blob" : "text",
          }
        );

        switch (typeScan) {
          case "file":
            let fileLink = document.createElement("a");
            fileLink.href = window.URL.createObjectURL(
              new Blob([response.data])
            );
            fileLink.setAttribute("download", `print (3dpackaging).pdf`);
            document.body.appendChild(fileLink);
            fileLink.click();
            break;
          case "link":
            return response.data;
        }
      }
    },
    toggleMobileSelectedSide: function(sideName: string) {
      this.mobileSelectedSide = this.model.children.filter(
        (child) => child.name === sideName
      )[0] as Mesh;
    },
    changePositionsMobile: function() {
      this.selectedSide = this.isOnlyOneSelectMesh
        ? this.isOnlyOneSelectMesh
        : this.mobileSelectedSide;
    },
    saveAndHideMobilePlane: async function() {
      this.permitMobileUpdate = true;
      await this.updateSelectedTexture();
      this.permitMobileUpdate = false;
      this.selectedSide = null;
      this.isOpenSettings = false;
      this.mobileSelectedSide = null;
    },
    toggleSettings: function() {
      this.isOpenSettings = !this.isOpenSettings;
    },
    selectFile: function() {
      if (this.$refs?.fileButton) {
        const button = this.$refs.fileButton as HTMLElement;
        button.click();
      }
    },
    addToShopifyOrder: async function() {
      const strMime = "image/jpeg";
      const imgData = this.renderer.domElement.toDataURL(strMime);
      
      const product = await axios.post(
        `${process.env.VUE_APP_API_URL}/api/v1/shopify/product/order`,
        {
          templateId: this.$route.params.templateId,
          preview: imgData,
          ancestorProductId: this.$route?.query?.ancestorProductId,
          shop: this.$route?.query?.shop,
          productSku: this.$route?.query?.productSku,
          productTitle: this.$route?.query?.productTitle,
          productPrice: this.$route?.query?.productPrice,
          customProductId: this.$route?.query?.customProductId,
        },
        { withCredentials: true }
      );

      const coloringDataToArray = Array.from(
        this.coloringData,
        ([key, value]) => ({ key, value })
      );
      let scanLink = '';
      if (this.isPhoneCase) scanLink = await this.getScan("link");

      const fabricData = {
        scanLink,
        preview: imgData,
        product,
      };

      window.parent.postMessage(JSON.stringify(fabricData), "*");
    },
    loadFonts: async function() {
      return new Promise((resolve, reject) => {
        WebFont.load({
          google: {
            families: this.fontList,
          },
          active: () => {
            this.updateFabricCanvas();
            resolve("");
          },
          inactive: () => {
            reject();
          },
        });
      });
    },
    applyScaling: function() {
      for (let key in this.scale) {
        if (this.scale[key] > 10) {
          this.scale[key] = 10;
        } else if (this.scale[key] < 0.5) {
          this.scale[key] = 0.5;
        }
      }

      this.model.scale.set(this.scale.x, this.scale.y, this.scale.z);
      this.model.updateMatrix();
      this.fitCameraToObject(this.camera, this.model, null, this.controls);
      this.updateFabricCanvas();
    },
    changeTextColor: async function(color) {
      const selectedColoringData = this.fabricTextObjects.find(
        (textObj) => textObj.id == this.selectedSide?.name
      );
      const selectedTextObj = selectedColoringData?.texts.find(
        (text) => text.id == this.selectedTextBox.customKey
      );

      if (selectedTextObj) {
        this.selectedTextBox.set({ fill: color });
        const selectedCanvasObj = this.fabricCanvas.find(
          (canvas) => canvas.id == this.selectedSide?.name
        );
        selectedCanvasObj.canvas.renderAll();
        selectedTextObj.fill = color;
        this.renderFabricCanvas();
      }
    },
    changeFontTextBox: async function(font: string) {
      const selectedColoringData = this.fabricTextObjects.find(
        (textObj) => textObj.id == this.selectedSide?.name
      );
      const selectedTextObj = selectedColoringData?.texts.find(
        (text) => text.id == this.selectedTextBox.customKey
      );

      if (selectedTextObj) {
        this.selectedTextBox.set({ fontFamily: font });
        const selectedCanvasObj = this.fabricCanvas.find(
          (canvas) => canvas.id == this.selectedSide?.name
        );
        selectedCanvasObj.canvas.renderAll();
        selectedTextObj.fontFamily = font;
        this.renderFabricCanvas();
      }
    },
    updateSelectedTexture: async function() {
      const allParts = [...this.meshs, ...this.generalMesh];

      let coloringData = this.coloringData.get(this.selectedSide?.name) ?? {
        color: this.color ?? "",
        images: [],
        id: v4(),
      };

      let textData = this.fabricTextObjects.find(
        (textObject) => textObject.id == this.selectedSide?.name
      ) ?? { id: v4(), texts: [] };

      const newCanvas = await this.generateFabricCanvas(
        this.selectedSide,
        coloringData,
        textData
      );

      this.fabricCanvas.forEach((canvasObject, index) => {
        if (canvasObject.id === newCanvas.id) {
          this.fabricCanvas[index].canvas = newCanvas.canvas;
          this.fabricCanvas[index].canvas.renderAll();
        }
      });
      allParts.forEach((part) => {
        if (part.name == newCanvas.id) {
          this.updateMaterial(part, newCanvas.canvas);
        }
      });
    },
    updateFabricCanvas: async function() {
      const allParts = [...this.meshs, ...this.generalMesh];
      this.fabricCanvas = await PromiseBird.map(allParts, async (part) => {
        let coloringData = this.coloringData.get(part.name) ?? {
          color: this.color ?? "",
          images: [],
          id: v4(),
        };

        let textData = this.fabricTextObjects.find(
          (textObject) => textObject.id == part.name
        ) ?? { id: v4(), texts: [] };

        const material = await this.generateFabricCanvas(
          part,
          coloringData,
          textData
        );
        this.updateMaterial(part, material.canvas);
        return material;
      });
    },
    getObjectSize: function(mesh) {
      const box = new THREE.Box3().setFromObject(mesh);
      let size = new Vector3();
      box.getSize(size);
      return size;
    },
    loadIcons: function() {
      const resizeFromCenter = `${process.env.VUE_APP_API_URL}/icons/resizeFromCenter.svg`;
      const resizeFromEdge = `${process.env.VUE_APP_API_URL}/icons/resizeFromEdge.svg`;
      this.resizeFromCenterIcon = document.createElement("img");
      this.resizeFromEdgeIcon = document.createElement("img");
      this.resizeFromCenterIcon.src = resizeFromCenter;
      this.resizeFromEdgeIcon.src = resizeFromEdge;
    },
    generateOutline: function(vectors, objectDepth, size) {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      canvas.width = size[0] + objectDepth * 2;
      canvas.height = size[1] + objectDepth * 2;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        
      vectors.forEach((vector) => {
        const startPoint = vector[0];
        const endPoint = vector[1];
        const offset = objectDepth;
        ctx.lineWidth = 2;
        // ctx.moveTo(startPoint.z + offset, startPoint.y + offset); //variant without beizerCurves
        // ctx.lineTo(endPoint.z + offset, endPoint.y + offset);  //variant without beizerCurves
        ctx.beginPath(); //variant with beizerCurves
        ctx.bezierCurveTo( //variant with beizerCurves
          startPoint.z + offset,
          startPoint.y + offset,
          startPoint.z + offset,
          startPoint.y + offset,
          endPoint.z + offset,
          endPoint.y + offset);
        ctx.strokeStyle = '#0000ff';
        ctx.stroke();
      });

      ctx.font = "600 14px Arial";
      ctx.fillStyle = "#0000ff";
      ctx.textAlign = "center";
      
      const text = "Please position the images/n and texts so that the /n main elements are /n within the contour";
      const lineHeight = 20;
      const lines = text.split("/n")

      for (let i = 0; i<lines.length; i++) ctx.fillText(lines[i], canvas.width/2, canvas.height/2 + (i*lineHeight));

      const image = canvas.toDataURL("image/png", 0.5);
      this.overlayImage = {image: null, height: null}
      this.overlayImage.image = image;
      this.overlayImage.height = canvas.height;
    },
    renderOutline: function(canvas) {
      if (this.overlayImage) {
        let opacity = 0.65;
        if (!this.modelName.includes('contour')) {
          if (this.modelName.includes('iPhone X') || this.modelName.includes('iPhone 12 Mini')) {
            return;
          }
        }

        canvas.setOverlayImage(
          this.overlayImage.image,
          () => {
            if (!this.canvasIsNotEmpty) {
              canvas.renderAll();
              return;
            }
            if (!this.outlineWithRender) return;
            canvas.renderAll();
            this.outlineWithRender = false;
          },
          {
        scaleX: canvas.height/this.overlayImage.height,
        scaleY: canvas.height/this.overlayImage.height,
        originX: "center",
        originY: "center",
        top: canvas.height / 2,
        left: canvas.width / 2,
        opacity,
      });
      }
    },
    renderScaleFromEdgeIcon: async function(
      ctx,
      left,
      top,
      styleOverride,
      fabricObject
    ) {
      const size = this.canvasIconSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(this.resizeFromEdgeIcon, -size / 2, -size / 2, size, size);
      ctx.restore();
    },
    renderScaleFromCenterIcon: async function(
      ctx,
      left,
      top,
      styleOverride,
      fabricObject
    ) {
      const size = this.canvasIconSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(
        this.resizeFromCenterIcon,
        -size / 2,
        -size / 2,
        size,
        size
      );
      ctx.restore();
    },
    deleteObject: function(eventData, transform) {
      const target = transform.target;
      const objectId = target.customKey;
      const objectType = target.get("type");
      const canvas = target.canvas;

      switch (objectType) {
        case "textbox":
          const selectedMesh = this.fabricTextObjects.find(
            (part) => part.id == this.selectedSide?.name
          );
          const indexObject = selectedMesh?.texts.findIndex(
            (text) => text.id == objectId
          );
          if (indexObject >= 0) selectedMesh.texts.splice(indexObject, 1);
          break;
        case "image":
          const selectedColoring = this.coloringData.get(
            this.selectedSide?.name
          );
          const indexColoring = selectedColoring.images.findIndex(
            (image) => image.id == objectId
          );
          const indexCashed = this.localColoringImages.findIndex(
            (image) => image.id == objectId
          );
          if (indexColoring >= 0)
            selectedColoring.images.splice(indexColoring, 1);
          if (indexCashed >= 0) this.localColoringImages.splice(indexCashed, 1);
          break;
      }

      canvas.remove(target);
      canvas.renderAll();

      this.updateFabricCanvas();
      this.outlineWithRender = true;
      this.renderOutline(canvas);
    },
    drawX: function(ctx, x, y, size) {
      ctx.strokeStyle = "#000000";
      ctx.beginPath();

      ctx.moveTo(x - size / 2, y - size / 2);
      ctx.lineTo(x + size / 2, y + size / 2);

      ctx.moveTo(x + size / 2, y - size / 2);
      ctx.lineTo(x - size / 2, y + size / 2);
      ctx.stroke();
    },
    getSizeFromMeshName: function(name) {
      const sizeArray = name.split('_');
      let width = 10;
      let height = 10;
      sizeArray.forEach((el, index) => {
        if (index > 0) {
          if (sizeArray[index-1] === 'width') width = Number(el);
          if (sizeArray[index-1] === 'height') height = Number(el);
        }
      })
      return {width, height};
    },
    getFabricCanvasSize: function(mesh) {
      const size = this.getObjectSize(mesh);

      let planeWidth = size.z > size.x ? size.z : size.x;
      let planeHeight = size.y > size.x ? size.y : size.x;

        const Iphone12Size = [9.02, 15.52];
        const IphoneXSize = [10.04, 16.07];

      if (this.isPhoneCase) {
        planeWidth += (Math.abs(size.x) * 2);
        planeHeight += (Math.abs(size.x) * 2);
      }

      if (this.sizeMesh) {
        const { width: hardWidth, height:hardHeight} = this.getSizeFromMeshName(this.sizeMesh.name);
        planeWidth = hardWidth;
        planeHeight = hardHeight;
      }

      if (this.modelName.includes('iPhone X')) {
        planeWidth = IphoneXSize[0];
        planeHeight = IphoneXSize[1];
      }

      if (this.modelName.includes('iPhone 12 Mini')) {
        planeWidth = Iphone12Size[0];
        planeHeight = Iphone12Size[1];
      }

      let width = 520;
      let height = 520;

      if (planeWidth > planeHeight) {
        height = width / (planeWidth / planeHeight);
      } else {
        width = height / (planeHeight / planeWidth);
      }

      return { width, height };
    },
    generateFabricCanvas: async function(mesh, coloringData, textData) {
      const { width, height } = this.getFabricCanvasSize(mesh);

      const canvas = new fabric.Canvas(`canvas-${mesh.name}`, {
        backgroundColor: this.color,
        width,
        height,
        preserveObjectStacking: true,
        controlsAboveOverlay: true,
      });
      this.renderOutline(canvas);
      for (let i = 0; i <= this.globalLayerCounter; i++) {
        await PromiseBird.map(
          coloringData.images,
          async (image, index, imagesLength) => {
            let imageFabric;
            const {
              x,
              y,
              scaleX,
              scaleY,
              angle,
              fullSize,
              id,
              isScalingChanged,
              imagePath,
              layerNumber,
            } = image;
            if (layerNumber !== i) return;
            let coloringDataImg;

            if (imagePath) {
              const loadedColoringData = this.localColoringImages.find(
                (image) => image.id == id
              );

              if (loadedColoringData) {
                coloringDataImg = loadedColoringData.file;
              } else {
                const loader = new ImageLoader();
                coloringDataImg = await loader.loadAsync(imagePath);
                this.localColoringImages.push({
                  id,
                  file: coloringDataImg,
                });
              }
            }

            let sizeX = fullSize
              ? canvas.width / coloringDataImg.width
              : scaleX;
            let sizeY = fullSize
              ? canvas.height / coloringDataImg.height
              : scaleY;

            const isImageBigger =
              coloringDataImg.width > canvas.width ||
              coloringDataImg.height > canvas.height;

            if (!fullSize && isImageBigger) {
              sizeX = isScalingChanged
                ? scaleX
                : (canvas.width -
                    (this.canvasIconSize + this.canvasIconOffset) * 1.5) /
                  coloringDataImg.width;
              sizeY = isScalingChanged ? scaleY : sizeX;
            }

            imageFabric = new fabric.Image(coloringDataImg, {
              left: image.x,
              top: image.y,
              scaleX: sizeX,
              scaleY: sizeY,
              angle,
              customKey: image.id,
              cornerSize: this.canvasIconSize,
              lockScalingFlip: true,
              originX: "center",
              originY: "center",
              centeredScaling: true,
            });

            imageFabric.controls.tr.mouseUpHandler = this.deleteObject;

            imageFabric.controls.bl.mouseUpHandler = (e, transform) => {
              transform.target.centeredScaling = !transform.target
                .centeredScaling;
              imageFabric.controls.bl.render = transform.target.centeredScaling
                ? this.renderScaleFromCenterIcon
                : this.renderScaleFromEdgeIcon;
              canvas.setActiveObject(transform.target);
              canvas.requestRenderAll();
            };

            imageFabric.controls.tl.offsetX = -this.canvasIconOffset;
            imageFabric.controls.tl.offsetY = -this.canvasIconOffset;

            imageFabric.controls.mtr.offsetX = -this.canvasIconOffset;
            imageFabric.controls.mtr.offsetY = this.canvasIconOffset;

            imageFabric.controls.br.offsetX = this.canvasIconOffset;
            imageFabric.controls.br.offsetY = this.canvasIconOffset;

            imageFabric.controls.tr.offsetX = this.canvasIconOffset;
            imageFabric.controls.tr.offsetY = -this.canvasIconOffset;

            canvas.add(imageFabric);

            if (imagesLength - 1 == index && this.imgIsUploaded) {
              canvas.setActiveObject(imageFabric);
              imageFabric.controls.bl.render = this.renderScaleFromCenterIcon;
              setTimeout(() => {
                canvas.renderAll();
                this.imgIsUploaded = false;
                this.selectedTextBox = null;
              }, 700);
            }
          },
          { concurrency: 3 }
        );

        textData?.texts?.forEach((textObject, index) => {
          if (textObject.layerNumber != i) return;
          const fabricText = new fabric.Textbox(textObject.text, {
            fontFamily: textObject.fontFamily
              ? textObject.fontFamily
              : "Times new Roman",
            left: textObject.x,
            top: textObject.y,
            angle: textObject.angle,
            width: textObject.width,
            height: textObject.height,
            scaleX: textObject.scaleX,
            scaleY: textObject.scaleY,
            fontSize: textObject.fontSize,
            fill: textObject.fill ? textObject.fill : "#000000",
            editable: true,
            customKey: textObject.id,
            cornerSize: this.canvasIconSize,
            originX: "center",
            originY: "center",
            centeredScaling: true,
          });

          fabricText.controls.tr.mouseUpHandler = this.deleteObject;

          fabricText.controls.bl.mouseUpHandler = (e, transform) => {
            transform.target.centeredScaling = !transform.target
              .centeredScaling;
            fabricText.controls.bl.render = transform.target.centeredScaling
              ? this.renderScaleFromCenterIcon
              : this.renderScaleFromEdgeIcon;
            canvas.setActiveObject(transform.target);
            canvas.requestRenderAll();
          };

          fabricText.controls.tl.offsetX = -this.canvasIconOffset;
          fabricText.controls.tl.offsetY = -this.canvasIconOffset;

          fabricText.controls.mtr.offsetX = -this.canvasIconOffset;
          fabricText.controls.mtr.offsetY = this.canvasIconOffset;

          fabricText.controls.br.offsetX = this.canvasIconOffset;
          fabricText.controls.br.offsetY = this.canvasIconOffset;

          fabricText.controls.tr.offsetX = this.canvasIconOffset;
          fabricText.controls.tr.offsetY = -this.canvasIconOffset;

          fabricText.controls.mt.visible = false;
          fabricText.controls.mb.visible = false;

          canvas.add(fabricText);

          if (textData?.texts?.length - 1 == index && this.textIsUploaded) {
            canvas.setActiveObject(fabricText);
            fabricText.controls.bl.render = this.renderScaleFromCenterIcon;
            this.selectedTextBox = fabricText;
            setTimeout(() => {
              canvas.renderAll();
              this.textIsUploaded = false;
            }, 700);
          }
        });
      }

      canvas.on("object:modified", (e) => {
        const activeObject = canvas.getActiveObject();
        this.changeTextureData(activeObject, this.selectedSide);
        if (canvas.overlayImage) canvas.overlayImage.opacity = 0.5;
      });

      canvas.on("text:changed", (e) => {
        const activeObject = canvas.getActiveObject();
        this.changeTextureData(activeObject, this.selectedSide);
      });

      canvas.on("selection:created", (e) => {
        const activeObject = canvas.getActiveObject();
        activeObject.controls.bl.render = activeObject.centeredScaling
          ? this.renderScaleFromCenterIcon
          : this.renderScaleFromEdgeIcon;
        const objectType = activeObject.get("type");
        if (objectType == "textbox") {
          this.selectedTextBox = activeObject;
        }
        if (canvas.overlayImage) canvas.overlayImage.opacity = 0.5;
      });

      canvas.on("selection:updated", (e) => {
        const activeObject = canvas.getActiveObject();
        activeObject.controls.bl.render = activeObject.centeredScaling
          ? this.renderScaleFromCenterIcon
          : this.renderScaleFromEdgeIcon;
        const objectType = activeObject.get("type");
        if (objectType == "textbox") {
          this.selectedTextBox = activeObject;
        } else {
          this.selectedTextBox = null;
        }
      });

      canvas.on("before:selection:cleared", (e) => {
        const activeObject = canvas.getActiveObject();
        const objectType = activeObject.get("type");
        if (canvas.overlayImage) canvas.overlayImage.opacity = 0.65;
        if (objectType == "textbox") this.selectedTextBox = null;
      });

      return {
        id: mesh.name,
        canvas,
      };
    },
    changeTextureData: function(objectParams, side) {
      const objectType = objectParams?.get("type");

      if (side && objectParams) {
        switch (objectType) {
          case "image":
            const selectedColoringData = this.coloringData.get(side.name);

            const selectedImage = selectedColoringData?.images.find(
              (image) => image.id == objectParams.customKey
            ) as any;

            if (selectedImage) {
              if (
                selectedImage.scaleX !== objectParams.scaleX ||
                selectedImage.scaleX !== objectParams.scaleX
              )
                selectedImage.isScalingChanged = true;
              selectedImage.x = objectParams.left;
              selectedImage.y = objectParams.top;
              selectedImage.scaleX = objectParams.scaleX;
              selectedImage.scaleY = objectParams.scaleY;
              selectedImage.angle = objectParams.angle;
            }
            if (objectParams?.group) {
              selectedImage.x +=
                objectParams.group.left + objectParams.group.width / 2;
              selectedImage.y +=
                objectParams.group.top + objectParams.group.height / 2;
            }
            break;
          case "textbox":
            const selectedTextObject = this.fabricTextObjects.find(
              (textObject) => textObject.id == side.name
            );
            const selectedText = selectedTextObject?.texts.find(
              (text) => text.id == objectParams.customKey
            );

            if (selectedText) {
              selectedText.x = objectParams.left;
              selectedText.y = objectParams.top;
              selectedText.width = objectParams.width;
              selectedText.height = objectParams.height;
              selectedText.angle = objectParams.angle;
              selectedText.text = objectParams.text;
              selectedText.scaleX = objectParams.scaleX;
              selectedText.fontSize = objectParams.fontSize;
              selectedText.scaleY = objectParams.scaleY;
            }
            if (objectParams?.group) {
              selectedText.x +=
                objectParams.group.left + objectParams.group.width / 2;
              selectedText.y +=
                objectParams.group.top + objectParams.group.height / 2;
            }
            break;
          case "activeSelection":
            objectParams._objects.forEach((object) => {
              this.changeTextureData(object, side);
            });
            break;
        }

        this.updateSelectedTexture();
      }
    },
    addText: async function() {
      if (this.isOnlyOneSelectMesh)
        this.selectedSide = this.isOnlyOneSelectMesh;
      if (this.mobileSelectedSide) this.selectedSide = this.mobileSelectedSide;
      const mesh = this.selectedSide;
      const foundTextObject = this.fabricTextObjects?.find(
        (textObject) => textObject.id == mesh?.name
      );
      const canvasSize = this.getFabricCanvasSize(this.selectedSide);

      const newText = {
        id: v4(),
        x: canvasSize.width / 2,
        y: canvasSize.height / 2,
        width: 150,
        height: 60,
        angle: 0,
        fontSize: 30,
        scaleX: 1,
        scaleY: 1,
        text: `New text`,
        fontFamily: "Times new Roman",
        fill: "#000000",
        layerNumber: this.globalLayerCounter++,
      };

      if (foundTextObject) {
        foundTextObject.texts.push(newText);
      } else {
        this.fabricTextObjects.push({
          id: mesh?.name,
          texts: [newText],
        });
      }

      const foundColoringData = this.coloringData.get(mesh?.name) ?? {
        color: this.color ?? "#a6a6a6",
        images: [],
        id: v4(),
      };
      const newTextObject = foundTextObject ?? this.fabricTextObjects[0];
      this.textIsUploaded = true;

      if (this.generatedCanvasStorage.has(mesh?.name)) {
        this.generatedCanvasStorage.get(mesh?.name).dispose();
        this.generatedCanvasStorage.delete(mesh?.name);
      }

      const newCanvas = await this.generateFabricCanvas(
        mesh,
        foundColoringData,
        newTextObject
      );
      this.generatedCanvasStorage.set(mesh?.name, newCanvas.canvas);
      this.permitMobileUpdate = false;
      await this.updateMaterial(mesh, newCanvas.canvas);
      this.renderFabricCanvas();
    },
    renderFabricCanvas: function() {
      if (this.selectedSide) {
        this.fabricCounter += 1;
        this.outlineWithRender = true;
        setTimeout(() => {
          this.updateSelectedTexture();
        }, 0);
      }
    },
    enableControls: function() {
      this.controls.enabled = true;
      this.threeContainer.addEventListener("click", this.selectFigure, false);
      this.threeContainer.addEventListener(
        "touchstart",
        this.selectFigure,
        false
      );
    },
    disableControls: function() {
      this.controls.enabled = false;
      this.threeContainer.removeEventListener(
        "click",
        this.selectFigure,
        false
      );
      this.threeContainer.removeEventListener(
        "touchstart",
        this.selectFigure,
        false
      );
    },
    selectFigure: function(e) {
      if (this.isMobile) this.onDocumentMouseMove(e);
      let raycaster = new Raycaster();

      raycaster.setFromCamera(this.mouse, this.camera);

      let intersects = raycaster.intersectObjects(this.scene.children, true);

      if (
        intersects.length > 0 &&
        (!this.checkFrame || this.isCastomizationFrame)
      ) {
        const isDisabled = intersects[0].object.name.includes("_disabled");
        if (!isDisabled) this.selectedSide = intersects[0].object as Mesh;
      } else {
        this.selectedSide = null;
      }
    },
    showFabricCanvas: function() {
      this.selectedGeneralMesh = true;
    },
    onDocumentMouseMove(event: MouseEvent) {
      const pos = this.getCanvasRelativePosition(event);

      this.mouse.x = (pos.x / this.renderer.domElement.clientWidth) * 2 - 1;
      this.mouse.y = (pos.y / this.renderer.domElement.clientHeight) * -2 + 1;
    },
    getCanvasRelativePosition(event) {
      const rect = this.renderer.domElement.getBoundingClientRect();
      let x, y;

      if (this.isMobile && event.touches?.length) {
        x = event.touches[0].pageX - rect.left;
        y = event.touches[0].pageY - rect.top;
      } else {
        x = event.clientX - rect.left;
        y = event.clientY - rect.top;
      }

      return { x, y };
    },
    generateButtons(figure) {
      let geometryPlus = new CircleGeometry(0.1, 32);

      let meshPlus = new Mesh(
        geometryPlus,
        new MeshBasicMaterial({
          color: new Color(0x000000),
          side: DoubleSide,
        })
      );

      meshPlus.name = JSON.stringify({
        id: figure.name,
        type: "addImage",
      });

      const center = this.getCentroid(figure);
      let size = new Vector3();
      new THREE.Box3().setFromObject(figure).getSize(size);

      meshPlus.position.copy(center);
      meshPlus.position.x = size.x;
      meshPlus.rotation.y = Math.PI / 2;
      this.scene.add(meshPlus);
    },
    getCentroid: function(mesh) {
      mesh.geometry.computeBoundingBox();
      const boundingBox = mesh.geometry.boundingBox;

      let x0 = boundingBox.min.x;
      let x1 = boundingBox.max.x;
      let y0 = boundingBox.min.y;
      let y1 = boundingBox.max.y;
      let z0 = boundingBox.min.z;
      let z1 = boundingBox.max.z;

      let bWidth = x0 > x1 ? x0 - x1 : x1 - x0;
      let bHeight = y0 > y1 ? y0 - y1 : y1 - y0;
      let bDepth = z0 > z1 ? z0 - z1 : z1 - z0;

      let centroidX = x0 + bWidth / 2 + mesh.position.x;
      let centroidY = y0 + bHeight / 2 + mesh.position.y;
      let centroidZ = z0 + bDepth / 2 + mesh.position.z;

      return (mesh.geometry.centroid = {
        x: centroidX,
        y: centroidY,
        z: centroidZ,
      } as Vector3);
    },
    changeDetailVisible: function(modification, detail) {
      if (detail.visible) {
        modification.default?.forEach((mesh) => (mesh.visible = false));
        modification.sortDetails.forEach((sortDetail) => {
          if (sortDetail.name == detail.name) {
            sortDetail.meshes.forEach((mesh) => (mesh.visible = true));
          } else {
            sortDetail.visible = false;
            sortDetail.meshes.forEach((mesh) => (mesh.visible = false));
          }
        });
      } else {
        detail.meshes.forEach((mesh) => (mesh.visible = false));
        modification.default?.forEach((mesh) => (mesh.visible = true));
      }
    },
    initModifications: function(selectedDetails: string[]) {
      const result = [
        {
          name: "bot",
          default: [] as Mesh[],
          details: [] as Mesh[],
          sortDetails: [] as any,
          value: "",
        },
        {
          name: "mid",
          default: [] as Mesh[],
          details: [] as Mesh[],
          sortDetails: [] as any,
          value: "",
        },
        {
          name: "top",
          default: [] as Mesh[],
          details: [] as Mesh[],
          sortDetails: [] as any,
          value: "",
        },
        {
          name: "other",
          details: [] as Mesh[],
          sortDetails: [] as any,
        },
      ];

      this.meshs.forEach((mesh: Mesh) => {
        switch (true) {
          case mesh.name.toLowerCase().includes(DetailsTypes.default_bot):
            result[0].default?.push(mesh);
            break;
          case mesh.name.toLowerCase().includes(DetailsTypes.default_mid):
            result[1].default?.push(mesh);
            break;
          case mesh.name.toLowerCase().includes(DetailsTypes.default_top):
            result[2].default?.push(mesh);
            break;
          case mesh.name.toLowerCase().includes(DetailsTypes.bot):
            mesh.visible = false;
            result[0].details.push(mesh);
            break;
          case mesh.name.toLowerCase().includes(DetailsTypes.mid):
            mesh.visible = false;
            result[1].details.push(mesh);
            break;
          case mesh.name.toLowerCase().includes(DetailsTypes.top):
            mesh.visible = false;
            result[2].details.push(mesh);
            break;
          default:
            mesh.visible = false;
            result[3].details.push(mesh);
            break;
        }
      });

      result.forEach((part) => {
        part.details.forEach((detail) => {
          const name = detail.name.split("_")[0];
          const foundSortDetails = part.sortDetails.find(
            (sortDetail) => sortDetail?.name == name
          );
          const isSelectedDetail = Boolean(
            selectedDetails?.find((selectedDetail) => selectedDetail == name)
          );

          if (isSelectedDetail) {
            part.default?.forEach((mesh) => (mesh.visible = false));
            detail.visible = isSelectedDetail;
          }

          if (foundSortDetails) {
            foundSortDetails.meshes.push(detail);
          } else {
            part.sortDetails.push({
              name,
              meshes: [detail],
              visible: isSelectedDetail,
            });
          }
        });
      });

      return result.filter((detail) => detail.sortDetails.length);
    },
    setFigure: function(path: string, selectedDetails: string[]) {
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("three/examples/js/libs/draco/gltf");
      loader.setDRACOLoader(dracoLoader);

      loader.load(path, async (obj: any) => {
        this.model = obj.scene;

        const children = this.model.children as Mesh[];

        children.forEach((item: Mesh) => {
          if (item.name.includes("disabled") && item.type == "Group") {
            item.children.forEach((child) => (child.name += "_disabled"));
          }

          if (!item.name.toLowerCase().includes("main")) {
            return this.meshs.push(item);
          }

          this.generalMesh.push(item);
        });

        this.scene.add(this.model);
        this.modifications = this.initModifications(selectedDetails);
        await this.updateFabricCanvas();
        const size = this.getObjectSize(this.model);
        this.model.position.set(0, size.y / 2, 0);
        this.fitCameraToObject(this.camera, this.model, null, this.controls);
      });
    },
    setFocus: function(event: any) {
      event.target.focus();
    },
    zoomIn: function(canvas, SCALE_FACTOR) {
      canvas.setHeight(canvas.getHeight() * SCALE_FACTOR);
      canvas.setWidth(canvas.getWidth() * SCALE_FACTOR);

      const objects = canvas.getObjects();
      for (let i in objects) {
        const scaleX = objects[i].scaleX;
        const scaleY = objects[i].scaleY;
        const left = objects[i].left;
        const top = objects[i].top;

        const tempScaleX = scaleX * SCALE_FACTOR;
        const tempScaleY = scaleY * SCALE_FACTOR;
        const tempLeft = left * SCALE_FACTOR;
        const tempTop = top * SCALE_FACTOR;

        objects[i].scaleX = tempScaleX;
        objects[i].scaleY = tempScaleY;
        objects[i].left = tempLeft;
        objects[i].top = tempTop;

        objects[i].setCoords();
      }

      canvas.renderAll();
    },
    updateMaterial: function(mesh, canvas) {
      if (this.isMobile && !this.permitMobileUpdate && this.isCastomizationFrame) return;
      const qualityFactor = 1.65;
      if (this.largeCanvasStorage.has(mesh.name)) {
        this.largeCanvasStorage.get(mesh.name).dispose();
        this.largeCanvasStorage.delete(mesh.name);
      }
      const renderJson = JSON.stringify(canvas);
      const newCanvas = new fabric.Canvas("render");
      newCanvas.setDimensions({
        width: canvas.getWidth() * qualityFactor,
        height: canvas.getHeight() * qualityFactor,
      });
      newCanvas.setZoom(qualityFactor);
      newCanvas.loadFromJSON(
        renderJson,
        () => this.sendLargeCanvasOn3dModel(newCanvas, mesh),
        () => newCanvas.renderAll()
      );

    },
    sendLargeCanvasOn3dModel: function(newCanvas, mesh) {
      const isDisabledMesh = mesh.name.includes("disabled");
      const isExistMaterial = mesh.geometry?.material;
      const isGlossyMesh = mesh.name.includes("glossy");
      const roughnessValue = isGlossyMesh ? 0 : 0.95;

      if (!isDisabledMesh || !isExistMaterial) {
        newCanvas.renderAll();
        const texture = new CanvasTexture(newCanvas.lowerCanvasEl);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.anisotropy = 16;
        texture.encoding = THREE.GammaEncoding;
        mesh.material = new MeshPhysicalMaterial({
          map: texture,
          side: THREE.DoubleSide,
          roughness: roughnessValue,
        });
        mesh.material.needsUpdate = true;
      }
      this.largeCanvasStorage.set(mesh.name, newCanvas);
      if (!this.overlayImage && this.outlineMesh) {
        this.getScan('overlayImage');
      }
    },
    uploadImage: async function(event: any) {
      this.isLocalLoaded = true;
      const splitedName = event.target.files[0].name.split(".");
      const typeFile = splitedName[splitedName.length - 1];

      const data = new FormData();
      data.append("file", event.target.files[0]);
      data.append("typeFile", typeFile);

      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/api/v1/upload/image`,
        data,
        { withCredentials: true }
      );

      let selectedMesh: Mesh | null = this.selectedSide;
      switch (true) {
        case Boolean(this.isOnlyOneSelectMesh):
          selectedMesh = this.isOnlyOneSelectMesh;
          this.selectedSide = this.isOnlyOneSelectMesh;
          break;
        case Boolean(this.mobileSelectedSide):
          selectedMesh = this.mobileSelectedSide;
          this.selectedSide = this.mobileSelectedSide;
          break;
      }

      const imagePath = response.data.imagePath;
      const foundColoringData = this.coloringData.get(selectedMesh?.name);
      const foundTextObject = this.fabricTextObjects?.find(
        (textObject) => textObject.id == selectedMesh?.name
      );
      const canvasSize = this.getFabricCanvasSize(this.selectedSide);

      const newImage = {
        x: canvasSize.width / 2,
        y: canvasSize.height / 2,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        fullSize: false,
        id: v4(),
        isScalingChanged: false,
        imagePath,
        flip: false,
        originX: "center",
        originY: "center",
        centeredScaling: true,
        layerNumber: this.globalLayerCounter++,
      };

      if (foundColoringData) {
        foundColoringData.images.push(newImage);
      } else {
        this.coloringData.set(selectedMesh?.name, {
          color: this.color ?? "",
          images: [newImage],
          id: v4(),
        });
      }
      this.imgIsUploaded = true;

      if (this.generatedCanvasStorage.has(selectedMesh?.name)) {
        this.generatedCanvasStorage.get(selectedMesh?.name).dispose();
        this.generatedCanvasStorage.delete(selectedMesh?.name);
      }

      const newCanvas = await this.generateFabricCanvas(
        selectedMesh,
        this.coloringData.get(selectedMesh?.name),
        foundTextObject
      );
      this.generatedCanvasStorage.set(selectedMesh?.name, newCanvas.canvas);
      this.permitMobileUpdate = false;
      await this.updateMaterial(selectedMesh, newCanvas.canvas);
      this.renderFabricCanvas();
      this.isLocalLoaded = false;
    },
    setMaterial: function(mesh: any, color: string) {
      const material = new MeshStandardMaterial({
        side: DoubleSide,
        color,
        wireframe: false,
        flatShading: false,
      });
      mesh.traverse((o: any) => {
        o.material = material;
      });
    },
    initLight: function() {
      this.light.castShadow = true;
      this.light.shadow.bias = -0.0001;
      this.light.shadow.mapSize.width = 1024 * 4;
      this.light.shadow.mapSize.height = 1024 * 4;
      this.scene.add(this.light);
    },
    animate: function() {
      if (this.resizeRendererToDisplaySize(this.renderer)) {
        const canvas = this.renderer.domElement;
        this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
        this.camera.updateProjectionMatrix();
      }

      this.light.position.copy(this.camera.position);

      this.controls.update();
      this.renderer.clear();
      requestAnimationFrame(this.animate);
      this.setEnvMap();
      this.renderer.render(this.scene, this.camera);
    },
    setEnvMap: function() {
      const newEnvMap = this.hdrCubeRenderTarget
        ? this.hdrCubeRenderTarget?.texture
        : null;

      this.model.children.forEach((child: any) => {
        if (child.type == "Group" && newEnvMap) {
          child.children.forEach((child: any) => {
            child.material.envMap = newEnvMap;
            child.material.needsUpdate = true;
          });
        }
      });
    },
    initHDR: async function() {
      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader();
      const texture = await new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .loadAsync(`${process.env.VUE_APP_PUBLIC_URL}/img/textures/plate.hdr`);
      this.hdrCubeRenderTarget = pmremGenerator.fromEquirectangular(texture);
    },
    init: async function() {
      this.isLoaded = true;
      this.camera.rotation.y = (45 / 180) * Math.PI;
      this.scene.background = new Color(0xffffff);

      fabric.Object.prototype.objectCaching = false;
      fabric.Object.NUM_FRACTION_DIGITS = 4;
      fabric.Group.prototype.hasControls = false;

      this.controls = new OrbitControls(
        this.camera,
        this.$refs.container as HTMLElement
      );
      this.controls.enablePan = false;
      this.controls.autoRotate = true;
      this.controls.enableDamping = true;

      this.controls.addEventListener("start", () => {
        this.controls.autoRotate = false;
      });

      this.initLight();
      this.loadFonts();
      this.loadIcons();
      const width = this.size.x;
      const height = this.checkFrame ? this.size.y : this.size.y - 70;
      this.renderer.setSize(width, height);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.shadowMap.enabled = true;

      const container = this.$refs.container as HTMLElement;
      container.appendChild(this.renderer.domElement as HTMLElement);

      if (!this.model3dPath) {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/template/${this.$route.params.templateId}`,
          { withCredentials: true }
        );
        this.modelName = response.data.name;

        this.previewImage = response.data.imagePath;
        const newScale = JSON.parse(response.data.scaling);
        const newSettings = JSON.parse(response.data.settings);
        this.fabricTextObjects = response.data.textData
          ? JSON.parse(response.data.textData)
          : [];
        const newColoringData = response.data.coloringData
          ? JSON.parse(response.data.coloringData)
          : [];
        newColoringData.forEach((item) => {
          this.coloringData.set(item.key, item.value);
        });
        this.fabricTextObjects.forEach((object) => {
          if (!object.texts || !object.texts?.[object.texts.length - 1]) return;
          if (
            object.texts[object.texts.length - 1].layerNumber >
            this.globalLayerCounter
          ) {
            this.globalLayerCounter =
              object.texts[object.texts.length - 1].layerNumber;
          }
        });
        newColoringData.forEach((object) => {
          if (!object.value || !object.value?.images || !object.value?.images?.[object.value.images.length - 1]) return;
          if (
            object.value.images[object.value.images.length - 1].layerNumber >
            this.globalLayerCounter
          ) {
            this.globalLayerCounter =
              object.value.images[object.value.images.length - 1].layerNumber;
          }
        });

        await this.setFigure(response.data.nodes, newSettings?.selectedDetails);

        this.scale = new Vector3(newScale[0], newScale[1], newScale[2]);
        this.model.scale.set(this.scale.x, this.scale.y, this.scale.z);
        this.color = newSettings.color ? newSettings.color : this.color;
        this.updateFabricCanvas();
      } else {
        await this.setFigure(this.model3dPath, []);
      }
      await this.initHDR();
      this.isLoaded = false;
      this.animate();
    },
    createDirectionalLight: function(brightness) {
      const dirLight = new DirectionalLight(0xffffff, brightness);
      dirLight.color.setHSL(0.1, 1, 1);
      dirLight.position.multiplyScalar(30);

      dirLight.castShadow = true;

      dirLight.shadow.mapSize.width = 2048;
      dirLight.shadow.mapSize.height = 2048;

      const d = 50;

      dirLight.shadow.camera.left = -d;
      dirLight.shadow.camera.right = d;
      dirLight.shadow.camera.top = d;
      dirLight.shadow.camera.bottom = -d;

      dirLight.shadow.camera.far = 3500;
      dirLight.shadow.bias = -0.0001;

      return dirLight;
    },
    save: async function() {
      let selectedDetails: string[] = [];
      this.modifications.forEach((modification) => {
        modification.sortDetails.forEach((sortDetail) => {
          if (sortDetail.visible) {
            selectedDetails.push(sortDetail.name);
          }
        });
      });

      const packedSettings = JSON.stringify({
        color: this.color,
        selectedDetails,
      });
      const packedScale = JSON.stringify([
        this.scale.x,
        this.scale.y,
        this.scale.z,
      ]);
      const packedTextData = JSON.stringify(this.fabricTextObjects);
      const packedColoringData = JSON.stringify(
        Array.from(this.coloringData, ([key, value]) => ({ key, value }))
      );

      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/api/v1/template/update3dModel/${this.$route.params.templateId}`,
        {
          settings: packedSettings,
          scaling: packedScale,
          textData: packedTextData,
          coloringData: packedColoringData,
        },
        { withCredentials: true }
      );
    },
    resizeRendererToDisplaySize: function(renderer: any) {
      const canvas = renderer.domElement;
      let width = window.innerWidth;
      let height = window.innerHeight;
      let canvasPixelWidth = canvas.width / window.devicePixelRatio;
      let canvasPixelHeight = canvas.height / window.devicePixelRatio;

      const needResize =
        canvasPixelWidth !== width || canvasPixelHeight !== height;
      if (needResize) {
        renderer.setSize(width, height, false);
      }
      return needResize;
    },
    fitCameraToObject: function(
      camera: PerspectiveCamera,
      object: Group,
      offset: number | null,
      controls: OrbitControls
    ) {
      offset = this.isMobile ? 9.0 : 5.5;

      const boundingBox = new Box3();

      // get bounding box of object - this will be used to setup controls and camera
      boundingBox.setFromObject(object);

      let center = new Vector3();
      let size = new Vector3();

      boundingBox.getCenter(center);
      boundingBox.getSize(size);

      // get the max side of the bounding box (fits to width OR height as needed )
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = camera.fov * (Math.PI / 180);
      let cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));
      cameraZ *= offset; // zoom out a little so that objects don't fill the screen
      camera.position.z = cameraZ;
      const minZ = boundingBox.min.z;
      const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

      camera.far = cameraToFarEdge * 30;

      if (controls) {
        // set camera to rotate around center of loaded object
        controls.target = center;

        // prevent camera from zooming out far enough to create far plane cutoff
        controls.maxDistance = cameraToFarEdge * 25;
        controls.minDistance = cameraToFarEdge * 0.5;

        controls.saveState();
        controls.update();
      } else {
        camera.lookAt(center);
      }
    },
  },
  components: {
    VSwatches,
    Loader,
    SaveAsBtn,
    ShopifySendBtn,
  },
});
export default vm;

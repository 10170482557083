









import Vue from 'vue'
export default Vue.extend({
  props: {
    colorLoader: {
      type: String,
      default: () => '#212121'
    }
  }
})













import { Vue } from "vue-property-decorator";
import CollectionSelector from "./CollectionSelector.vue";

let vm = Vue.component("SaveAsBtn", {
  data: function() {
    return {
      showModal: false
    };
  },
  methods: {
    changeModalStatus: async function() {
      this.showModal = !this.showModal;
    }
  },
  components: {
    CollectionSelector
  }
});

export default vm;

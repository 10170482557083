import React from 'react';
import { withStyles } from '@material-ui/core';

const classes = () => ({
    img: {
        marginLeft: '5px',
        height: '300px',
        width: '200px',
        objectFit: 'cover',
        objectPosition: 'center',
        cursor: 'pointer',
    },
    photographer: {
        position: 'absolute',
        marginLeft: '10px',
        bottom: '5px',
        color: 'white',
    },
    download: {
        position: 'absolute',
        top: '0',
        left: '0',
        textDecoration: 'underline',
        color: 'white',
        marginLeft: '10px',
        fontWeight: 'bold',
    },
});


class ImageFromApi extends React.Component {

    toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
  

    dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }
    

  render() {
    const { classes, photo, photographer,uploadImage , setIsModalActive} = this.props

    return (
        <div onClick={() => {
            this.toDataURL(photo, (url) => {
                let file = this.dataURLtoFile(url, 'image.jpeg');
                uploadImage(file);
                setIsModalActive();
            })
        }}>
        <img className={classes.img} src={photo}/>
        </div>
    );
  }
}

export default withStyles(classes)(ImageFromApi);

/* eslint-disable react/prop-types */
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import withStyles from '@material-ui/core/styles/withStyles';
import settingButton from './styles/SettingsButtonStyles';
import tooltipStyle from '../../../styles/Tooltip';
import {materialTheme} from '../../../materialTheme'

const { palette } = materialTheme


const style = () => ({
  ...tooltipStyle(palette),
  tooltip: {
    ...tooltipStyle(palette).tooltip,
    top: 6,
  },
  ...settingButton(palette),
});

class SettingButton extends React.Component {
  render() {
    const {
      onSettingChange, settingType, settingActive, settingIcon, classes
    } = this.props;
    return (
      <Tooltip
        arrow
        placement="top"
        title={settingType}
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      >
        <div
          onClick={() => onSettingChange(settingType)}
          className={
            `${classes.settingButton} ${settingActive && classes.settingButtonFocus}`
          }
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            {settingIcon}
          </svg>
        </div>
      </Tooltip>
    );
  }
}

export default withStyles(style)(SettingButton);
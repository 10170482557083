<template>
  <div class="modal" @click="close">
    <div class="modal__content" @click.stop="">
      <div class="modal__header">
        <slot name="header"> </slot>
        <div class="modal__close" @click="close">
          &times;
        </div>
      </div>
      <div class="modal__body">
        <slot name="body">
          {{ bodyText }}
        </slot>
      </div>
      <div class="modal__footer">
        <slot name="footer"> </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bodyText: { type: String },
  },
  created() {
    document.addEventListener("keydown", this.handleEscape);
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleEscape);
  },
  methods: {
    handleEscape(e) {
      if (e.key === "Escape") {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 11;
}

.modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 30px;
  border-radius: 5px;
  background: #fff;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 10px;
  height: fit-content;
  width: fit-content;
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
}

.modal__close:hover {
  color: red;
}
</style>

/* eslint-disable import/prefer-default-export,react/prop-types */
import React from 'react';

import LayerAlign from './LayerAlign';
import TextSettings from './TextSettings';
import ImageSettings from './ImageSettings';
import { getRectWidthAndHeight } from '../../helpers/helper';


class LayerSettings extends React.Component {
  getCanvasObject = id => {
    const { canvas } = this.props;
    const canvasObjects = canvas?.getObjects();
    const rectObject = canvasObjects?.find(object => object.id === id);

    return rectObject;
  };

  updateCanvasAndRect = () => {
    const { canvas, updateRectParams } = this.props;
    canvas.requestRenderAll();
    updateRectParams();
  };

  onLayerAlignChange = (id, value) => {
    const { canvas, layer, deleteFocus } = this.props;

    deleteFocus(true);
    const rectObject = this.getCanvasObject(id);
    const { width, height } = getRectWidthAndHeight(rectObject);

    layer.align = value;
    if (value === 'top') {
      rectObject.top = 0;
    } else if (value === 'left') {
      rectObject.left = 0;
    } else if (value === 'right') {
      rectObject.left = canvas.width - width;
    } else if (value === 'bottom') {
      rectObject.top = canvas.height - height;
    } else if (value === 'horizontal' || value === 'vertical') {
      rectObject.right = (canvas.height - height) / 2;
      rectObject.bottom = (canvas.width - width) / 2;
    }

    rectObject.setCoords();

    this.updateCanvasAndRect();
  };

  render() {
    const {
      canvas, layer, shapesIsActive, shapeClick, type
    } = this.props;

    return (
      <div>
      <LayerAlign
        layerAlign={layer.align}
        changelayerAlign={value => this.onLayerAlignChange(layer.id, value)}
      />
      {type === 'text' ? (
        <TextSettings
          canvas={canvas}
          layer={layer}
          getCanvasObject={this.getCanvasObject}
          updateCanvasAndRect={this.updateCanvasAndRect}
        />
      ) : (
        <ImageSettings
          layer={layer}
          getCanvasObject={this.getCanvasObject}
          updateCanvasAndRect={this.updateCanvasAndRect}
          shapeClick={shapeClick}
          shapesIsActive={shapesIsActive}
        />
      )
      }
    </div>
    );
  }
}

export default LayerSettings;

/* eslint-disable import/prefer-default-export,react/prop-types */
import _ from 'lodash';
import React from 'react';

// import makeStyles from '@material-ui/core/styles/makeStyles';

import withStyles from '@material-ui/core/styles/withStyles';
import AlignSelect from './AlignSelect';
import SelectColor from './SelectColor';
import EditorSelect from '../EditorSelect';
import SpacingSelect from './SpacingSelect';
import MoreSettingsSelect from './MoreSettingsSelect';

import fonts from '../../fonts';
import settings from './styles/SettingsStyles';

import {materialTheme} from '../../../materialTheme'

const { palette } = materialTheme


const styles = () => ({
  ...settings(palette),
  colorSettings: {
    marginLeft: 24,
    display: 'flex',
    alignItems: 'flex-end',
  },
});

const fontSizes = _.range(2, 400);
const fontWeights = _.range(400, 800, 200);

class TextSettings extends React.Component {
  state = {
    activeObjLength: 0
  }

  componentDidMount() {
    const { canvas } = this.props;
    const activeObjLength = canvas.getObjects().length;
    this.setState({ activeObjLength });
  }

  componentDidUpdate(prevProps, prevState) {
    const { canvas } = this.props;
    const { activeObjLength } = this.state;
    if (prevState.activeObjLength !== activeObjLength) {
      canvas.on('mouse:up', () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.text) {
          activeObject._clearCache();
          this.onTextChange(activeObject.id, 'fontSize', activeObject.fontSize);
        }
      });
      canvas.on('object:scaled', () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.text) {
          activeObject.fontSize *= activeObject.scaleX;
          activeObject.fontSize = activeObject.fontSize.toFixed(0);
          activeObject.scaleX = 1;
          activeObject.scaleY = 1;
          activeObject.fontSize *= activeObject.scaleX;
          activeObject.fontSize = activeObject.fontSize.toFixed(0);
          activeObject._clearCache();
          this.onTextChange(activeObject.id, 'fontSize', activeObject.fontSize);
        }
      });
    }
  }

  onTextChange = (id, type, value) => {
    const { layer, getCanvasObject, updateCanvasAndRect } = this.props;
    const rectObject = getCanvasObject(id);

    layer[type] = value;
    if (type === 'textCase') {
      const { text } = rectObject;
      rectObject.text = value === 'uppercase' ? text.toUpperCase() : text.toLowerCase();
    } else {
      rectObject.set(type, value);
    }

    updateCanvasAndRect();
  };

  render() {
    const { classes, layer, canvas } = this.props;
    return (
      <div>
        <div className={classes.settingsLine}>
          <div>
            <p className={classes.settingTitle}>Font</p>
            <EditorSelect
              styles={{
                width: 264,
                height: 360,
              }}
              values={fonts}
              currentValue={layer.fontFamily}
              changeValue={value => this.onTextChange(layer.id, 'fontFamily', value)}
            />
          </div>
        </div>
        <div className={classes.settingsLine}>
          <div>
            <p className={classes.settingTitle}>Size</p>
            <EditorSelect
              styles={{
                width: 120,
                height: 256,
              }}
              values={fontSizes}
              currentValue={layer.fontSize}
              changeValue={value => this.onTextChange(layer.id, 'fontSize', value)}
              canvas={canvas}
            />
          </div>
          <div className={classes.settingsMargin}>
            <p className={classes.settingTitle}>Weight</p>
            <EditorSelect
              styles={{
                width: 120,
                height: 115,
              }}
              values={fontWeights}
              currentValue={layer.fontWeight}
              changeValue={value => this.onTextChange(layer.id, 'fontWeight', value)}
            />
          </div>
          <div className={classes.colorSettings}>
            <SelectColor
              setTextColor={color => this.onTextChange(layer.id, 'fill', color)}
              layerColor={layer.fill}
            />
          </div>
        </div>
        <div className={classes.settingsLine}>
          <div>
            <AlignSelect
              type="horizontal"
              tooltipTitle="Text Align"
              layerAlign={layer.textAlign}
              onAlignChange={align => this.onTextChange(layer.id, 'textAlign', align)}
            />
          </div>
          <div className={classes.settingsMargin}>
            <SpacingSelect
              layerLineSpacing={layer.lineHeight}
              layerCharSpacing={layer.charSpacing}
              onSpacingChange={(type, spacing) => this.onTextChange(layer.id, type, spacing)}
            />
          </div>
          {/* <div className={classes.settingsMargin}>
            <AlignSelect
              type="vertical"
              tooltipTitle="Align Inside Block"
              layerAlign={layer.verticalAlign}
              onAlignChange={align => this.onTextChange(layer.id, 'verticalAlign', align)}
            />
          </div> */}
          <div className={classes.settingsMargin}>
            <MoreSettingsSelect
              layer={layer}
              changeSetting={(type, value) => this.onTextChange(layer.id, type, value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TextSettings);

// const TextSettings = ({
//   layer,
//   getCanvasObject,
//   updateCanvasAndRect,
//   canvas
// }) => {
//   const classes = useStyles();
//
//   const fontSizes = _.range(2, 400);
//   const fontWeights = _.range(400, 800, 200);
//   const activeObjLength = canvas.getObjects().length;
//
//   const onTextChange = (id, type, value) => {
//     const rectObject = getCanvasObject(id);
//
//     layer[type] = value;
//     if (type === 'textCase') {
//       const { text } = rectObject;
//       rectObject.text = value === 'uppercase' ? text.toUpperCase() : text.toLowerCase();
//     } else {
//       rectObject.set(type, value);
//     }
//
//     updateCanvasAndRect();
//   };
//
//   useEffect(() => {
//     canvas.on('mouse:up', () => {
//       const activeObject = canvas.getActiveObject();
//       if (activeObject && activeObject.text) {
//         activeObject.fontSize *= activeObject.scaleX;
//         activeObject.fontSize = activeObject.fontSize.toFixed(0);
//         activeObject.scaleX = 1;
//         activeObject.scaleY = 1;
//         activeObject.fontSize *= activeObject.scaleX;
//         activeObject.fontSize = activeObject.fontSize.toFixed(0);
//         activeObject._clearCache();
//         onTextChange(activeObject.id, 'fontSize', activeObject.fontSize);
//       }
//     });
//   }, [activeObjLength]);
//
//   return (
//     <>
//       <div className={classes.settingsLine}>
//         <div>
//           <p className={classes.settingTitle}>Font</p>
//           <EditorSelect
//             styles={{
//               width: 264,
//               height: 360,
//             }}
//             values={fonts}
//             currentValue={layer.fontFamily}
//             changeValue={value => onTextChange(layer.id, 'fontFamily', value)}
//           />
//         </div>
//       </div>
//       <div className={classes.settingsLine}>
//         <div>
//           <p className={classes.settingTitle}>Size</p>
//           <EditorSelect
//             styles={{
//               width: 120,
//               height: 256,
//             }}
//             values={fontSizes}
//             currentValue={layer.fontSize}
//             changeValue={value => onTextChange(layer.id, 'fontSize', value)}
//             canvas={canvas}
//           />
//         </div>
//         <div className={classes.settingsMargin}>
//           <p className={classes.settingTitle}>Weight</p>
//           <EditorSelect
//             styles={{
//               width: 120,
//               height: 115,
//             }}
//             values={fontWeights}
//             currentValue={layer.fontWeight}
//             changeValue={value => onTextChange(layer.id, 'fontWeight', value)}
//           />
//         </div>
//         <div className={classes.colorSettings}>
//           <SelectColor
//             setTextColor={color => onTextChange(layer.id, 'fill', color)}
//             layerColor={layer.fill}
//           />
//         </div>
//       </div>
//       <div className={classes.settingsLine}>
//         <div>
//           <AlignSelect
//             type="horizontal"
//             tooltipTitle="Text Align"
//             layerAlign={layer.textAlign}
//             onAlignChange={align => onTextChange(layer.id, 'textAlign', align)}
//           />
//         </div>
//         <div className={classes.settingsMargin}>
//           <SpacingSelect
//             layerLineSpacing={layer.lineHeight}
//             layerCharSpacing={layer.charSpacing}
//             onSpacingChange={(type, spacing) => onTextChange(layer.id, type, spacing)}
//           />
//         </div>
//         <div className={classes.settingsMargin}>
//           <AlignSelect
//             type="vertical"
//             tooltipTitle="Align Inside Block"
//             layerAlign={layer.verticalAlign}
//             onAlignChange={align => onTextChange(layer.id, 'verticalAlign', align)}
//           />
//         </div>
//         <div className={classes.settingsMargin}>
//           <MoreSettingsSelect
//             layer={layer}
//             changeSetting={(type, value) => onTextChange(layer.id, type, value)}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

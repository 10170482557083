const settings = palette => ({
  settingsLine: {
    marginBottom: 24,
    display: 'flex',
  },
  settingTitle: {
    fontFamily: palette.fontFamily,
    fontWeight: 700,
    fontSize: 16,
    color: palette.primary.main,
    lineHeight: '20px',
    marginBottom: 8,
  },
  settingsMargin: {
    marginLeft: 24,
  }
});

export default settings;

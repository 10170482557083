


































































































































































































import Vue from "vue";
import validator from "validator";
import axios from "axios";

const vm = Vue.component("signUp", {
  data: function() {
    return {
      forename: "",
      surname: "",
      email: "",
      password: "",
      passwordRepeated: "",
      signUpError: "",
      readTerms: false,
      isForenameInvalid: true,
      isSurnameInvalid: true,
      isEmailInvalid: true,
      isPasswordInvalid: true,
      isRepeatedPasswordInvalid: true,
      isPasswordsNotEqual: true,
      isSubmitDisabled: true,
      showPassword: false,
      shopify: false,
      API_KEY: "",
      API_SECRET_KEY: "",
      store: "",
    };
  },
  props: {
    shop: { type: String },
  },
  watch: {
    forename: "checkForForenameValidity",
    surname: "checkForSurnameValidity",
    email: "checkForEmailValidity",
    password: "checkForPasswordValidity",
    passwordRepeated: "checkForPasswordValidity",
  },
  methods: {
    signalChange: function() {
      this.$gtag.event("click_shopify");
    },
    changePassword: function() {
      this.showPassword = !this.showPassword;
    },
    updateIsSubmitDisabled: function() {
      this.isSubmitDisabled =
        this.isForenameInvalid ||
        this.isSurnameInvalid ||
        this.isEmailInvalid ||
        this.isPasswordInvalid ||
        this.isRepeatedPasswordInvalid;
    },
    checkForForenameValidity: function() {
      this.isForenameInvalid = this.forename.length <= 1;
      this.updateIsSubmitDisabled();
    },
    checkForSurnameValidity: function() {
      this.isSurnameInvalid = this.surname.length <= 1;
      this.updateIsSubmitDisabled();
    },
    checkForEmailValidity: function() {
      this.isEmailInvalid = !validator.isEmail(this.email);
      this.updateIsSubmitDisabled();
    },
    checkForPasswordValidity: function() {
      this.isPasswordInvalid = this.password.length < 5;
      this.isRepeatedPasswordInvalid = this.passwordRepeated.length < 5;
      this.isPasswordsNotEqual = !(this.password === this.passwordRepeated);
      this.updateIsSubmitDisabled();
    },
    signUp: async function() {
      if (this.password === this.passwordRepeated) {
        this.$gtag.event("click_registration");
        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/api/v1/sign-up/`,
            {
              forename: this.forename,
              surname: this.surname,
              email: this.email,
              password: this.password,
              API_KEY: this.API_KEY,
              API_SECRET_KEY: this.API_SECRET_KEY,
              store: this.store,
            }
          );
          this.$emit("signin");
        } catch (error) {
          if (error.response) {
            this.signUpError = error.response.data;
          }
        }
      } else {
        alert(`${this.$t("warn.enterSamePassword")}`);
      }
    },
    signUpForShopify: async function() {
      if (this.password === this.passwordRepeated) {
        this.$gtag.event("click_registration");
        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/api/v1/shopify/sign-up/`,
            {
              forename: this.forename,
              surname: this.surname,
              email: this.email,
              password: this.password,
              store: this.store,
            }
          );
          this.$emit("signin");
        } catch (error) {
          if (error.response) {
            this.signUpError = error.response.data;
          }
        }
      } else {
        alert("Enter the same passwords");
      }
    },
    handleSignUp() {
      if (this.shop) {
        return this.signUpForShopify();
      }
      return this.signUp();
    },
  },
  mounted() {
    if (this.shop) {
      this.shopify = true;
      this.store = this.shop;
    }
  },
});

export default vm;

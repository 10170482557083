/* eslint-disable react/prop-types,radix,no-shadow,react/require-default-props */
/* eslint-disable no-unused-expressions,react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TemplateEditModal from './Template/TemplateEditModal';

class TemplateEditor extends Component {

  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    fileList: [],
    templates: [],
    rects: [],
    file: null
  };

  handleChange = (newFileList) => {
    if (newFileList[0].type.indexOf('pdf') !== -1) {
      this.setState({ file: newFileList[0], fileList: newFileList });
    } else {
      console.log('error')
    }
  };

  setRects = rects => {
    this.setState({ rects });
  };

  setFile = file => {
    this.setState({ file });
  };


  render() {
    const { rects } = this.state;
    const { file, update, meta, bgImage } = this.props;
    
    return (
      <div>
        <div className="container new-article-container">
          <TemplateEditModal
            handleChange={this.handleChange}
            setFile={this.setFile}
            setRects={this.setRects}
            rects={rects}
            file={file}
            update={update}
            meta={meta}
            bgImage={bgImage}
          />
        </div>
      </div>
    );
  }
}

export default TemplateEditor;








// import React, { Component } from 'react';

// import { Upload } from 'antd';
// import PropTypes from 'prop-types';
// import { PlusOutlined } from '@ant-design/icons';

// import TemplateEditModal from './Template/TemplateEditModal';

// class TemplateEditor extends Component {
//   static propTypes = {
//     history: PropTypes.object,
//   };

//   state = {
//     fileList: [],
//     templates: [],
//     rects: []
//   };

//   handleChange = ({ fileList, file }) => {
//     if (this.state.fileList === fileList) {
//       this.setState({ fileList });
//     } else {
//       this.setState({ fileList, file });
//     }
//   };

//   setRects = rects => {
//     this.setState({ rects });
//   };

//   setWrapperRef = node => {
//     this.wrapperRef = node;
//   };

//   render() {
//     const { fileList, rects } = this.state;
//     const { file, update, meta } = this.props;

//     const upload = (
//       <Upload showUploadList={false} fileList={fileList} onChange={this.handleChange} listType="picture-card">
//         { file ? (
//           <div className="template-upload-button">
//             <PlusOutlined />
//           </div>
//         ) : '' }
//       </Upload>
//     );
//     return (
//       <React.Fragment>
//         <div className="container new-article-container">
//           <TemplateEditModal
//             upload={upload}
//             setRects={this.setRects}
//             rects={rects}
//             file={file}
//             update={update}
//             meta={meta}
//           />
//         </div>
//       </React.Fragment>
//     );
//   }
// }

// export default TemplateEditor;

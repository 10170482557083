/* eslint-disable react/prop-types */
import React from 'react';

export const HandIcon = () => (
  <svg style={{ width: 24, height: 24 }}>
    <path d="M16.2169 3.89099C16.9492 3.57358 17.2856 2.72261 16.9682 1.99028C16.6508 1.25796 15.7998 0.921592 15.0675 1.23899C14.3351 1.5564 13.9988 2.40737 14.3162 3.1397C14.6336 3.87202 15.4845 4.20839 16.2169 3.89099Z" fill="#2C3E66" />
    <path d="M11.5315 2.77155C12.2638 2.45414 12.6002 1.60317 12.2828 0.870844C11.9654 0.138516 11.1144 -0.197847 10.3821 0.119554C9.64973 0.436956 9.31337 1.28793 9.63077 2.02026C9.94817 2.75258 10.7991 3.08895 11.5315 2.77155Z" fill="#2C3E66" />
    <path d="M7.61235 3.4201C8.34467 3.1027 8.68104 2.25172 8.36364 1.5194C8.04623 0.78707 7.19526 0.450707 6.46293 0.768108C5.73061 1.08551 5.39424 1.93648 5.71164 2.66881C6.02905 3.40114 6.88002 3.7375 7.61235 3.4201Z" fill="#2C3E66" />
    <path d="M4.84263 6.72065C5.57496 6.40324 5.91132 5.55227 5.59392 4.81994C5.27652 4.08762 4.42555 3.75125 3.69322 4.06865C2.96089 4.38606 2.62453 5.23703 2.94193 5.96936C3.25933 6.70168 4.11031 7.03805 4.84263 6.72065Z" fill="#2C3E66" />
    <path d="M14.3162 3.1397L16.8066 8.88567C16.9123 9.12966 16.8001 9.4135 16.5561 9.51924C16.3122 9.62499 16.0283 9.5128 15.9226 9.26881L12.2828 0.870844L9.63077 2.02026L12.8875 9.53423C12.9932 9.77821 12.881 10.0621 12.637 10.1678C12.393 10.2735 12.1092 10.1613 12.0035 9.91737L8.36364 1.5194L5.71164 2.66881L9.35146 11.0668C9.45721 11.3108 9.34502 11.5946 9.10103 11.7003C8.85705 11.8061 8.57321 11.6939 8.46747 11.4499L5.59392 4.81994L2.94193 5.96936L7.73116 17.0193L4.87939 15.3456C3.99459 14.7578 2.84066 14.9324 2.28525 15.7297C1.7371 16.5364 2.00256 17.6625 2.87914 18.2507C2.87914 18.2507 7.66012 21.369 9.70137 22.7062C11.7426 24.0434 14.3701 24.6424 18.0979 23.0268C24.27 20.3517 22.7566 15.3457 22.1405 13.9242C21.5244 12.5028 16.9682 1.99028 16.9682 1.99028L14.3162 3.1397Z" fill="#2C3E66" />
  </svg>
);

export const RulerIcon = ({ active } = { active: false }) => (
  <svg style={{ width: 24, height: 24 }}>
    <path d="M4.78418 0L7.12886 2.34469L4.53323 4.94032L5.52583 5.93291L8.12146 3.33728L9.61603 4.83186L7.79523 6.65266L8.78782 7.64526L10.6086 5.82445L12.1032 7.31907L9.50761 9.9147L10.5002 10.9073L13.0958 8.31166L14.5904 9.80624L12.7696 11.627L13.7622 12.6196L15.583 10.7988L17.0776 12.2934L14.482 14.889L15.4745 15.8816L18.0702 13.286L19.5648 14.7806L17.7439 16.6014L18.7365 17.594L20.5574 15.7732L24 19.2158L19.2158 24L-4.57764e-05 4.78422L4.78418 0ZM19.4288 20.1922C19.8223 20.1922 20.1137 19.8706 20.1307 19.4904C20.1477 19.1113 19.7959 18.7885 19.4288 18.7885C19.0353 18.7885 18.744 19.1101 18.7269 19.4904C18.71 19.8694 19.0617 20.1922 19.4288 20.1922Z" fill={`${active ? '#00C0FF' : '#2C3E66'}`} />
  </svg>
);
export const TextFieldIcon = () => (
  <svg style={{ width: 24, height: 24 }}>
    <path d="M24 4.64841V0H19.3516V1.62108H4.64841V0H0V4.64841H4.64841V3.02733H19.3516V4.64841H20.9727V19.3516H19.3516V20.9727H4.64841V19.3516H0V24H4.64841V22.3789H19.3516V24H24V19.3516H22.3789V4.64841H24Z" fill="#2C3E66" />
    <path d="M1.62109 15.3025H3.02734V17.5037H1.62109V15.3025Z" fill="#2C3E66" />
    <path d="M1.62109 10.8994H3.02734V13.1006H1.62109V10.8994Z" fill="#2C3E66" />
    <path d="M1.62109 6.49634H3.02734V8.69754H1.62109V6.49634Z" fill="#2C3E66" />
    <path d="M13.4755 17.4844V9.46645H16.5291V6.51538H7.47095V9.46645H10.5245V17.4844H13.4755Z" fill="#2C3E66" />
  </svg>
);
export const ImageFieldIcon = () => (
  <svg style={{ width: 24, height: 24 }}>
    <path d="M8 6C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V8C18 6.89543 17.1046 6 16 6H8ZM12.8415 8.10989C13.3148 8.10989 13.6986 8.49365 13.6986 8.96703C13.6986 9.44041 13.3148 9.82418 12.8415 9.82418C12.3681 9.82418 11.9843 9.44041 11.9843 8.96703C11.9843 8.49365 12.3681 8.10989 12.8415 8.10989ZM15.8901 14.4599C15.8901 15.2498 15.2498 15.8901 14.4599 15.8901H9.3461C8.66336 15.8901 8.10989 15.3366 8.10989 14.6539C8.10989 14.3375 8.2312 14.0332 8.44884 13.8035L9.10486 13.1113C9.7915 12.3869 10.9072 12.2796 11.7193 12.8599L12.069 13.1098C12.5392 13.4458 13.1626 13.475 13.6621 13.1845C14.2512 12.8418 14.9986 12.9492 15.4674 13.4438L15.498 13.4761C15.7498 13.7418 15.8901 14.0939 15.8901 14.4599Z" fill="#2C3E66" />
    <path d="M24 4.64841V0H19.3516V1.62108H4.64841V0H0V4.64841H4.64841V3.02733H19.3516V4.64841H20.9727V19.3516H19.3516V20.9727H4.64841V19.3516H0V24H4.64841V22.3789H19.3516V24H24V19.3516H22.3789V4.64841H24Z" fill="#2C3E66" />
    <path d="M1.62109 15.3025H3.02734V17.5037H1.62109V15.3025Z" fill="#2C3E66" />
    <path d="M1.62109 10.8994H3.02734V13.1006H1.62109V10.8994Z" fill="#2C3E66" />
    <path d="M1.62109 6.49634H3.02734V8.69754H1.62109V6.49634Z" fill="#2C3E66" />
  </svg>
);

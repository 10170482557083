


































import Vue from "vue";
import validator from "validator";
import axios from "axios";
import jwt_decode from "jwt-decode";

const vm = Vue.component("SignIn", {
  data: function() {
    return {
      email: "",
      password: "",
      signInError: "",
      isEmailInvalid: true,
      isPasswordInvalid: true,
      isSubmitDisabled: true,
      showPassword: false,
    };
  },
  props: {
    shop: { type: String },
  },
  watch: {
    email: "checkForEmailValidity",
    password: "checkForPasswordValidity",
  },
  methods: {
    changePassword: function() {
      this.showPassword = !this.showPassword;
    },
    updateIsSubmitDisabled: function() {
      this.isSubmitDisabled = this.isEmailInvalid || this.isPasswordInvalid;
    },
    checkForEmailValidity: function() {
      this.isEmailInvalid = !validator.isEmail(this.email.trim());
      this.updateIsSubmitDisabled();
    },
    checkForPasswordValidity: function() {
      this.isPasswordInvalid = this.password.length < 5;
      this.updateIsSubmitDisabled();
    },
    signIn: async function() {
      this.$gtag.event("click_login");
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/sign-in`,
          {
            email: this.email.trim(),
            password: this.password,
          },
          { withCredentials: true }
        );
        if (response.data.token) {
          const jwtDecodeToken: any = jwt_decode(response.data.token);
          this.$store.commit("setAuthorized");
          if (this.shop) {
            this.$router.push({
              path: "/shopify",
            });
          } else {
            this.$router.push({
              name: "collections",
            });
          }
          this.$store.commit("setUser", jwtDecodeToken);
        } else {
          alert(response.data);
          this.email = "";
          this.password = "";
        }
      } catch (error) {
        if (error.response) {
          this.signInError = error.response.data;
        }
      }
    },
  },
});

export default vm;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Representation = void 0;
var three_1 = require("three");
var utils_1 = require("../../lib/utils");
var utilsGeneral_1 = require("./../../figures/utils/utilsGeneral");
var Representation = /** @class */ (function () {
    function Representation(shape, holesShape, connectionLineIndexes, childs, id, config, name) {
        this._rotationToOxy = new three_1.Matrix4().identity();
        this._shape = shape;
        this._holesShape = holesShape;
        this._connectionLineIndexes = connectionLineIndexes;
        this.childs = childs;
        this._id = id;
        this._config = config;
        this._name = name;
        this._color = utilsGeneral_1.generateRandomColor();
    }
    Object.defineProperty(Representation.prototype, "connectionLineIndexes", {
        get: function () {
            return this._connectionLineIndexes;
        },
        set: function (value) {
            this._connectionLineIndexes = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "normal", {
        get: function () {
            return this._normal;
        },
        set: function (value) {
            this._normal = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "holesShape", {
        get: function () {
            return this._holesShape;
        },
        set: function (value) {
            this._holesShape = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "shape", {
        get: function () {
            return this._shape;
        },
        set: function (value) {
            this._shape = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "childs", {
        get: function () {
            return this._childs;
        },
        set: function (value) {
            this._childs = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "config", {
        get: function () {
            return this._config;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "color", {
        get: function () {
            return this._color;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Representation.prototype, "rotationToOxy", {
        get: function () {
            return this._rotationToOxy;
        },
        set: function (matrix) {
            this._rotationToOxy = matrix;
        },
        enumerable: false,
        configurable: true
    });
    Representation.prototype.addHole = function (hole) {
        this._holesShape.push(hole);
    };
    Representation.prototype.applyMatrix4 = function (matrix) {
        var _a;
        this.shape.forEach(function (line) {
            line.applyMatrix4(matrix);
        });
        this.holesShape.forEach(function (holeLines) {
            holeLines.forEach(function (line) { return line.applyMatrix4(matrix); });
        });
        (_a = this.normal) === null || _a === void 0 ? void 0 : _a.applyMatrix4(matrix);
    };
    Representation.prototype.applyShift = function (shift) {
        this.shape.forEach(function (line) {
            line.applyShift(shift);
        });
        this.holesShape.forEach(function (holeLines) {
            holeLines.forEach(function (line) { return line.applyShift(shift); });
        });
    };
    Representation.prototype.applyScale = function (scale) {
        this.shape.forEach(function (line) { return line.applyScaling(scale); });
        this.holesShape.forEach(function (holeShape) { return holeShape.forEach(function (line) { return line.applyScaling(scale); }); });
        this.calculateNormal();
        var rotatedNormal = this.normal.clone().applyMatrix4(this.rotationToOxy);
        var additionalRotation = utils_1.Utils.calculateRotationToOzAxis(rotatedNormal);
        this.rotationToOxy.premultiply(additionalRotation);
    };
    Representation.prototype.calculateNormal = function () {
        var linesCount = this.shape.length;
        var directionVector1 = this.shape[0].calculateDirectionVector();
        var directionVector2 = this.shape[linesCount - 1].calculateDirectionVector();
        this.normal = new three_1.Vector3().crossVectors(directionVector1, directionVector2).normalize();
    };
    return Representation;
}());
exports.Representation = Representation;

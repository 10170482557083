









import { Vue } from "vue-property-decorator";
import axios from "axios";

let vm = Vue.component("SaveAsBtn", {
  data: function() {
    return {
      text: ""
    };
  },
  methods: {
    createComment: async function() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/comments/`,
          {
            templateId: this.$route.params.templateId,
            text: this.text
          },
          {
            withCredentials: true
          }
        );
        console.log(response);
        this.$emit("comment_posted");
        this.text = "";
      } catch (error) {
        console.log(error);
      }
    }
  },
  components: {}
});

export default vm;

/* eslint-disable import/prefer-default-export,react/prop-types */
import React, { useState } from 'react';

import {
  Select,
  SvgIcon,
  MenuItem,
  Typography,
  withStyles
} from '@material-ui/core';
// import makeStyles from '@material-ui/core/styles/makeStyles';
import { selectStyle } from '../../styles/Select'

import {materialTheme} from '../../materialTheme'

const { palette } = materialTheme

const classes = () => ({
  select: {
    border: `2px solid ${palette.paleBlue.main}`,
    borderRadius: '50px 10px 10px 50px',
    minWidth: 120,
    height: 48,
    color: palette.primary.main,
    background: palette.paleBlue.main,
    fontFamily: palette.fontFamily,
    '& > svg': {
      marginRight: 10,
      fontSize: 24,
      color: palette.primary.main
    }
  },
  selectMenu: {
    boxSizing: 'border-box',
    background: 'transparent',
    paddingRight: '16px !important',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontWeight: 700,
      margin: '4px 0 4px 3px',
      padding: '8px 8px 8px 12px',
      background: props => props.isOpen ? '#BCDEF080' : 'transparent',
      borderRadius: '50px 10px 10px 50px',
    },
    '&:focus': {
      width: '100%',
      background: 'transparent'
    }
  },
  ...selectStyle(palette),
  selectNoFile: {
    '&:after': {
      content: "' '",
      borderRadius: '50px 10px 10px 50px',
      position: 'absolute',
      background: '#F2FAFF',
      height: 48,
      minWidth: 120,
      opacity: 0.5,
    }
  },
  menuOptions: {
    maxHeight: 314,
    minWidth: 120,
    width: 120,
  },
});

class SelectScale extends React.Component {

  state = {
    open: false,
    topPosition: 0,
    leftPosition: 0
  }

  scales = [
    { title: '25%', value: 0.25 },
    { title: '50%', value: 0.5 },
    { title: '75%', value: 0.75 },
    { title: '100%', value: 1 },
    { title: '125%', value: 1.25 },
    { title: '150%', value: 1.5 },
    { title: '175%', value: 1.75 },
    { title: '200%', value: 2 },
  ];

  changeOpen = () => {
    this.props.deleteFocus(false);
    this.setState({open: !this.state.open})
  };

  onRefUnitChange = node => {
    const positionTop = node?.getBoundingClientRect().top + 60;
    const positionLeft = node?.getBoundingClientRect().left;
    
    this.setState({ topPosition: positionTop})
    this.setState({ leftPosition: positionLeft})
  };

  changeHandler = title => {
    const tragetScale = this.scales.find(scale => scale.title === title);
    this.props.changeScale(tragetScale);
  };

  setRef = ref => {
    this.ref = ref;
  }

  onRefChange = () => {
    const topPosition = this.ref.getBoundingClientRect().top + 60;
    const leftPosition = this.ref.getBoundingClientRect().left;
    this.setState({ topPosition, leftPosition });
  };

  

  render() {
    const { disabled, classes, currentScale } = this.props
    const {open, topPosition, leftPosition} = this.state
    return (
      <Select
        disabled={disabled}
        ref={this.setRef}
        onOpen={() => {
          this.changeOpen(); 
          this.onRefChange();
        }}
        onClose={this.changeOpen}
        open={open}
        onChange={e => this.changeHandler(e.target.value)}
        MenuProps={{
          anchorReference: 'anchorPosition',
          anchorPosition: { top: topPosition, left: leftPosition },
          MenuListProps: {
            classes: {
              root: `${classes.selectMenuWrapper} ${classes.menuOptions}`
            }
          },
          PopoverClasses: {
            paper: classes.popover,
          }
        }}
        classes={{ selectMenu: classes.selectMenu, select: classes.selectMenu }}
        // IconComponent={props => (
        //   <SvgIcon {...this.props}>
        //     <path
        //       d="M3 21H9M3 21V15M3 21L10 14M21 3H15M21 3V9M21 3L14 10"
        //       stroke="#2C3E66"
        //       strokeWidth="1.8"
        //       strokeLinecap="round"
        //       strokeLinejoin="round"
        //     />
        //   </SvgIcon>
        // )}
        disableUnderline
        value={currentScale.title}
        className={open
          ? `${classes.select} ${classes.selectFocus}`
          : `${disabled && classes.selectNoFile} ${classes.select}`
        }
      >
        {
          this.scales.map(scale => (
            <MenuItem
              key={scale.title}
              classes={{ root: classes.selectDropDown, selected: classes.selectedDropdown }}
              value={scale.title}
            >
              <Typography
                classes={{
                  root: `${classes.listItemText}`,
                }}
              >
                {scale.title}
              </Typography>
            </MenuItem>
          ))
        }
      </Select>
    );
  }

}


export default withStyles(classes)(SelectScale);










// const useStyles = makeStyles(({ palette }) => ({
//   select: {
//     border: `2px solid ${palette.paleBlue.main}`,
//     borderRadius: '50px 10px 10px 50px',
//     minWidth: 120,
//     height: 48,
//     color: palette.primary.main,
//     background: palette.paleBlue.main,
//     fontFamily: palette.fontFamily,
//     '& > svg': {
//       marginRight: 10,
//       fontSize: 24,
//       color: palette.primary.main
//     }
//   },
//   selectMenu: {
//     boxSizing: 'border-box',
//     background: 'transparent',
//     paddingRight: '16px !important',
//     width: '100%',
//     height: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     '& p': {
//       fontWeight: 700,
//       margin: '4px 0 4px 3px',
//       padding: '8px 8px 8px 12px',
//       background: props => props.isOpen ? '#BCDEF080' : 'transparent',
//       borderRadius: '50px 10px 10px 50px',
//     },
//     '&:focus': {
//       width: '100%',
//       background: 'transparent'
//     }
//   },
//   ...selectStyle(palette),
//   selectNoFile: {
//     '&:after': {
//       content: "' '",
//       borderRadius: '50px 10px 10px 50px',
//       position: 'absolute',
//       background: '#F2FAFF',
//       height: 48,
//       minWidth: 120,
//       opacity: 0.5,
//     }
//   },
//   menuOptions: {
//     maxHeight: 314,
//     minWidth: 120,
//     width: 120,
//   },
// }));

// const SelectScale = ({
//   disabled,
//   changeScale,
//   currentScale,
//   deleteFocus
// }) => {
//   const [open, setOpen] = useState(false);
//   const [topPosition, setTopPosition] = useState(0);
//   const [leftPosition, setLeftPosition] = useState(0);

//   const classes = useStyles({ isOpen: open });
//   const scales = [
//     { title: '25%', value: 0.25 },
//     { title: '50%', value: 0.5 },
//     { title: '75%', value: 0.75 },
//     { title: '100%', value: 1 },
//     { title: '125%', value: 1.25 },
//     { title: '150%', value: 1.5 },
//     { title: '175%', value: 1.75 },
//     { title: '200%', value: 2 },
//   ];

//   const changeOpen = () => {
//     deleteFocus(false);
//     setOpen(!open);
//   };

//   const onRefUnitChange = node => {
//     const positionTop = node?.getBoundingClientRect().top + 60;
//     const positionLeft = node?.getBoundingClientRect().left;
//     setTopPosition(positionTop);
//     setLeftPosition(positionLeft);
//   };

//   const changeHandler = title => {
//     const tragetScale = scales.find(scale => scale.title === title);
//     changeScale(tragetScale);
//   };

//   return (
//     <Select
//       disabled={disabled}
//       ref={onRefUnitChange}
//       onOpen={changeOpen}
//       onClose={changeOpen}
//       open={open}
//       onChange={e => changeHandler(e.target.value)}
//       MenuProps={{
//         anchorReference: 'anchorPosition',
//         anchorPosition: { top: topPosition, left: leftPosition },
//         MenuListProps: {
//           classes: {
//             root: `${classes.selectMenuWrapper} ${classes.menuOptions}`
//           }
//         },
//         PopoverClasses: {
//           paper: classes.popover,
//         }
//       }}
//       classes={{ selectMenu: classes.selectMenu, select: classes.selectMenu }}
//       IconComponent={props => (
//         <SvgIcon {...props}>
//           <path
//             d="M3 21H9M3 21V15M3 21L10 14M21 3H15M21 3V9M21 3L14 10"
//             stroke="#2C3E66"
//             strokeWidth="1.8"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//           />
//         </SvgIcon>
//       )}
//       disableUnderline
//       value={currentScale.title}
//       className={open
//         ? `${classes.select} ${classes.selectFocus}`
//         : `${disabled && classes.selectNoFile} ${classes.select}`
//       }
//     >
//       {
//         scales.map(scale => (
//           <MenuItem
//             key={scale.title}
//             classes={{ root: classes.selectDropDown, selected: classes.selectedDropdown }}
//             value={scale.title}
//           >
//             <Typography
//               classes={{
//                 root: `${classes.listItemText}`,
//               }}
//             >
//               {scale.title}
//             </Typography>
//           </MenuItem>
//         ))
//       }
//     </Select>
//   );
// };

// export default SelectScale;


/* eslint-disable import/prefer-default-export,react/prop-types */
import React from 'react';

import { withStyles } from '@material-ui/core';
import SettingButton from './SettingButton';

import settings from './styles/SettingsStyles';
import OpacitySelect from './OpacitySelect';

import {materialTheme} from '../../../materialTheme'

const { palette } = materialTheme


const styles = () => ({
  ...settings(palette),
  shapeSettings: {
    display: 'flex',
  }
});

class ImageSettings extends React.Component {
  onImageChange = (id, type, value) => {
    const { getCanvasObject, layer, updateCanvasAndRect } = this.props;
    const rectObject = getCanvasObject(id);

    layer[type] = value;
    rectObject.set(type, value);

    updateCanvasAndRect();
  };

  render() {
    const {
      shapeClick, shapesIsActive, layer, classes
    } = this.props;
    const settingIcons = {
      rectangle: (
        <rect width="26" height="22" rx="2" fill="#2C3E66" />
      ),
      ellipse: (
        <circle cx="12" cy="12" r="12" fill="#2C3E66" />
      ),
    };
    return (
      <div>
        <div className={classes.settingsLine}>
          <div>
            {shapesIsActive && (
              <div><p className={classes.settingTitle}>Shape</p>
                <div className={classes.shapeSettings}>
                  <div>
                    <SettingButton
                      onSettingChange={shapeClick}
                      settingType="Rectangle"
                      settingIcon={settingIcons.rectangle}
                      settingActive={layer.underline}
                    />
                  </div>
                  <div className={classes.settingsMargin}>
                    <SettingButton
                      onSettingChange={shapeClick}
                      settingType="Ellipse"
                      settingIcon={settingIcons.ellipse}
                      settingActive={layer.underline}
                    />
                  </div>
                </div></div>
            )}

            <p className={classes.settingTitle}>Image effects</p>
            <div className={classes.shapeSettings}>
              <div>
                <OpacitySelect
                  layerOpacity={layer.opacity}
                  onOpacityChange={value => this.onImageChange(layer.id, 'opacity', value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ImageSettings);

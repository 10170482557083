





























































import { Vue } from "vue-property-decorator";
import ObjectRender from "./ObjectRender.vue";
import Loader from "./Loader.vue";
import { Figure } from "packaging3d-engine/lib";
import { Vector3 } from "three";
import axios from "axios";
import TemplateCollection from "./TemplateCollection.vue";
import SearchInput from "./SearchInput.vue";
import { template } from "lodash";
import i18n from "../i18n";

interface Template {
  name: string;
  id: number;
  isChanged?: boolean;
  oldName: string;
  isNewNameUniq?: boolean;
  collectionId: number;
}

let vm = Vue.component("TemplateSelection", {
  data: function() {
    const options = Object.entries(Figure);
    return {
      figureState: Figure.Rectangle as String,
      searchQuery: "",
      options,
      scale: new Vector3(1, 1, 1),
      templates: [] as Template[],
      collection: {
        id: 0,
        name: "",
      },
      isLoaded: false,
      newTemplateName: "",
      isNewNameUniq: false,
      filtredTemplates: [] as Template[],
    };
  },
  computed: {
    checkedTemplates(): Template[] {
      return this.searchQuery ? this.filtredTemplates : this.templates;
    },
    figureTest(): object {
      return {
      Rectangle: this.$t("figures.Rectangle"),
      ConcaveRectangle: this.$t("figures.ConcaveRectangle"),
      RectangleWithHole: this.$t("figures.RectangleWithHole"),
      Trapeze: this.$t("figures.Trapeze"),
      ConcaveTrapeze: this.$t("figures.ConcaveTrapeze"),
      Triangle: this.$t("figures.Triangle"),
      Ark: this.$t("figures.Ark"),
      CuttedBottomTrapeze: this.$t("figures.CuttedBottomTrapeze"),
      CuttedEdgesRectangle: this.$t("figures.CuttedEdgesRectangle"),
      CuttedTowerTopConnection: this.$t("figures.CuttedTowerTopConnection"),
      HandleBox: this.$t("figures.HandleBox"),
      RoundedTrapeze: this.$t("figures.RoundedTrapeze"),
      RoundedCuttedBottomTrapeze: this.$t("figures.RoundedCuttedBottomTrapeze"),
      RoundedCuttedBottomTrapezeWithHole: this.$t("figures.RoundedCuttedBottomTrapezeWithHole"),
      Petal: this.$t("figures.Petal"),
      RoundedRectangle: this.$t("figures.RoundedRectangle"),
      TrapezeWithHole: this.$t("figures.TrapezeWithHole"),
      TriangleWithHalfHole: this.$t("figures.TriangleWithHalfHole"),
      RoundedCuttedBottomTrapezeWithHalfHole: this.$t("figures.RoundedCuttedBottomTrapezeWithHalfHole"),
      RoundedTriangle: this.$t("figures.RoundedTriangle"),
      UfoRectangle: this.$t("figures.UfoRectangle"),
      UfoRoundedRectangle: this.$t("figures.UfoRoundedRectangle"),
      Boot: this.$t("figures.Boot"),
      KShape: this.$t("figures.KShape"),
      RoundedHandleBox: this.$t("figures.RoundedHandleBox"),
      Rounded: this.$t("figures.Rounded"),
      Hat: this.$t("figures.Hat"),
      HookEar: this.$t("figures.HookEar"),
      RoundedTriangleWithGap: this.$t("figures.RoundedTriangleWithGap"),
      Trapezoid: this.$t("figures.Trapezoid"),
      UncenteredTrapeze: this.$t("figures.UncenteredTrapeze"),
      ShotgunBottom: this.$t("figures.ShotgunBottom"),
      TowerTopConnection: this.$t("figures.TowerTopConnection"),
      Tower2TopConnection: this.$t("figures.Tower2TopConnection"),
      SharkFin: this.$t("figures.SharkFin"),
      RoundedPetal: this.$t("figures.RoundedPetal"),
      TowerTopConnectionRounded: this.$t("figures.TowerTopConnectionRounded")
    };
    }
  },
  mounted: function() {
    this.getTemplates();
    console.log(this.options)
  },
  watch: {
    newTemplateName: async function(newVal, oldVal) {
      const isUniqInTable = await this.checForUniquenessOfNameInTable(
        newVal,
        this.collection.id
      );
      this.isNewNameUniq =
        this.checkForUniquenessOfNameInCollection(newVal, -1) && isUniqInTable;
    },
    searchQuery: function(newVal, oldVal): void {
      this.filtredTemplates = this.templates.filter((template: Template) => {
        return template.name
          .toLowerCase()
          .indexOf(this.searchQuery.toLowerCase()) >= 0
          ? template
          : null;
      });
    },
  },
  methods: {
    deleteFigure: async function(id: number) {
      try {
        this.isLoaded = false;
        const response = await axios.delete(
          `${process.env.VUE_APP_API_URL}/api/v1/template/${id}`,
          { withCredentials: true }
        );
        await this.getTemplates();
        this.isLoaded = true;
      } catch (error) {
        console.log(error);
      }
    },
    getTemplates: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/collection/${this.$route.params.collectionId}`,
          { withCredentials: true }
        );
        this.collection = response.data.collection;
        this.templates = response.data.templates.map(
          (template: { id: number; name: string }) => ({
            ...template,
            isChanged: false,
            isNewNameUniq: true,
          })
        );
        this.isLoaded = true;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    checForUniquenessOfNameInTable: async function(
      templateName: string,
      collection: number
    ): Promise<boolean> {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/template/name/uniqCheck`,
          {
            templateName,
            collection,
          },
          {
            withCredentials: true,
          }
        );
        console.log(response.data.isUniq);
        return response.data.isUniq;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    checkForUniquenessOfNameInCollection: function(
      newName: string,
      indexOfTemplateWithNewName: number
    ): boolean {
      return this.templates.every((template, index) => {
        return (
          template.name !== newName || index === indexOfTemplateWithNewName
        );
      });
    },
    updateName: async function(newName: string, index: number) {
      const newTemps = [...this.templates];
      newTemps[index].isChanged = true;
      newTemps[index].oldName = newTemps[index].oldName
        ? newTemps[index].oldName
        : newTemps[index].name;
      newTemps[index].name = newName;
      const isUniqInTable =
        newName === newTemps[index].oldName
          ? true
          : await this.checForUniquenessOfNameInTable(
              newName,
              this.collection.id
            );
      newTemps[index].isNewNameUniq =
        this.checkForUniquenessOfNameInCollection(newName, index) &&
        isUniqInTable;
      this.templates = newTemps;
    },
    updateTemplateName: async function(
      templateId: number,
      templateName: string
    ) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_API_URL}/api/v1/template/name/${templateId}`,
          {
            templateName,
          },
          {
            withCredentials: true,
          }
        );
        console.log(response);
        await this.getTemplates();
      } catch (error) {
        console.log(error);
      }
    },
    select: function(index: number, templateName: string) {
      this.$router.push({
        path: `/collection/template/${this.collection.id}/${
          index !== -1 ? this.templates[index].id : this.figureState
        }/${
          index !== -1
            ? this.templates[index].oldName ?? this.templates[index].name
            : templateName
        }`,
      });
    },
  },
  components: {
    Loader,
    TemplateCollection,
    SearchInput,
  },
});
export default vm;

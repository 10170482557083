





























import { Vue } from "vue-property-decorator";

import Loader from "./Loader.vue";
import { Figure } from "packaging3d-engine/lib";
import { Vector3 } from "three";
import axios from "axios";
import TemplateCollectionPublic from "./TemplateCollectionPublic.vue";
import SearchInput from "./SearchInput.vue";

interface Template {
  name: string;
  id: number;
  isChanged?: boolean;
  oldName: string;
  isNewNameUniq?: boolean;
  collectionId: number;
}

let vm = Vue.component("TemplateSelection", {
  data: function() {
    const options = Object.entries(Figure);
    return {
      figureState: Figure.Rectangle as String,
      searchQuery: "",
      options,
      scale: new Vector3(1, 1, 1),
      templates: [] as Template[],
      collection: {
        id: 0,
        name: "",
      },
      isLoaded: false,
      newTemplateName: "",
      isNewNameUniq: false,
      filtredTemplates: [] as Template[],
    };
  },
  computed: {
    checkedTemplates(): Template[] {
      return this.searchQuery ? this.filtredTemplates : this.templates;
    },
  },
  mounted: function() {
    this.getTemplates();
  },
  watch: {
    newTemplateName: async function(newVal, oldVal) {
      const isUniqInTable = await this.checForUniquenessOfNameInTable(newVal);
      this.isNewNameUniq =
        this.checkForUniquenessOfNameInCollection(newVal, -1) && isUniqInTable;
    },
    searchQuery: function(newVal, oldVal): void {
      this.filtredTemplates = this.templates.filter((template: Template) => {
        return template.name
          .toLowerCase()
          .indexOf(this.searchQuery.toLowerCase()) >= 0
          ? template
          : null;
      });
    },
  },
  methods: {
    getTemplates: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/collection_admin/${this.$route.params.collectionId}`,
          { withCredentials: true }
        );
        this.collection = response.data.collection;
        this.templates = response.data.templates.map(
          (template: { id: number; name: string }) => ({
            ...template,
            isChanged: false,
            isNewNameUniq: true,
          })
        );
        this.isLoaded = true;
        this.$gtag.event(`click_collections_${this.collection.name}`);
      } catch (error) {
        console.log(error);
      }
    },
    checForUniquenessOfNameInTable: async function(
      templateName: string
    ): Promise<boolean> {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/template/name/uniqCheck`,
          {
            templateName,
          },
          {
            withCredentials: true,
          }
        );
        return response.data.isUniq;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    checkForUniquenessOfNameInCollection: function(
      newName: string,
      indexOfTemplateWithNewName: number
    ): boolean {
      return this.templates.every((template, index) => {
        return (
          template.name !== newName || index === indexOfTemplateWithNewName
        );
      });
    },
    select: function(index: number, templateName: string) {
      this.$router.push({
        path: `/collection_public/template/${this.collection.id}/${
          index !== -1 ? this.templates[index].id : this.figureState
        }/${
          index !== -1
            ? this.templates[index].oldName ?? this.templates[index].name
            : templateName
        }`,
      });
    },
  },
  components: {
    Loader,
    TemplateCollectionPublic,
    SearchInput,
  },
});
export default vm;

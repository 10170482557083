"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CutHalfHoleHelper = void 0;
var three_1 = require("three");
var line3_1 = require("../../core/base/line3");
var CutHalfHoleHelper = /** @class */ (function () {
    function CutHalfHoleHelper() {
    }
    CutHalfHoleHelper.addProperties = function (config) {
        config.holeRadius = 0.2;
        return config;
    };
    CutHalfHoleHelper.drawHalfHole = function (centerPoint, config) {
        var radius = config.holeRadius;
        var aPoint = new three_1.Vector3(centerPoint.x + radius, centerPoint.y);
        var bPoint = new three_1.Vector3(centerPoint.x - radius, centerPoint.y);
        var cPoint = new three_1.Vector3(centerPoint.x, centerPoint.y + radius);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [bPoint, new three_1.Vector3(bPoint.x, cPoint.y), cPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, [cPoint, new three_1.Vector3(aPoint.x, cPoint.y), aPoint])
        ];
    };
    return CutHalfHoleHelper;
}());
exports.CutHalfHoleHelper = CutHalfHoleHelper;

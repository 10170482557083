"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FigureBase = void 0;
var three_1 = require("three");
var lodash_1 = require("lodash");
var representation_1 = require("./representation");
var line3_1 = require("./line3");
var typesDef_1 = require("./typesDef");
var utils_1 = require("../../lib/utils");
var availableHoleOptions_1 = require("./../../figures/utils/availableHoleOptions");
var utilsGeneral_1 = require("./../../figures/utils/utilsGeneral");
var FigureBase = /** @class */ (function () {
    function FigureBase(config, id, childs, parentSize) {
        this.name = "Figure Base";
        this.errorText = null;
        this._id = 0;
        this._childs = [];
        this._id = id !== null && id !== void 0 ? id : FigureBase.generateId();
        this._config = lodash_1.cloneDeep(config) || {
            options: new typesDef_1.Options(this.getConfigProperties(), parentSize),
            angle: 180,
            mirrored: false
        };
        if (!this.config.color) {
            this.config.color = utilsGeneral_1.generateRandomColor();
        }
        this._childs = !!childs ? childs : [];
        this.name = "Figure Base";
    }
    FigureBase.generateId = function () {
        var newId = Math.trunc(Math.random() * 10000);
        while (FigureBase._ids.has(newId)) {
            newId = Math.trunc(Math.random() * 10000);
        }
        FigureBase._ids.add(newId);
        return newId;
    };
    FigureBase.prototype.calculateBaseRepresentation = function (connectionLineIndex) {
        var mappedSides = this._childs.map(function (child) { return child.parentEdgeIndex; });
        if (connectionLineIndex !== undefined && connectionLineIndex !== null) {
            mappedSides.push(connectionLineIndex);
        }
        var shape = this.calculateLines();
        var holesShape = this.calculateHoleLines();
        var connectionIndexes = this.calculateConnectionIndexes().filter(function (index) { return mappedSides.indexOf(index) === -1; });
        return new representation_1.Representation(shape, holesShape, connectionIndexes, this._childs, this.id, this.config, this.name);
    };
    ;
    FigureBase.prototype.calculateAbsoluteRepresentation = function (parentLine, parentNormal, connectionLineIndex) {
        var target = this.calculateBaseRepresentation(connectionLineIndex);
        // general algorithm:
        // 0. applying reflection if needed
        // 1. set needed edge to 0y axis, so x coordinate of the connected line should be 0;
        // 2. rotate each line to angle from config;
        // 3. find transformation of the parent figure plane which rotate parent figure plane to be coplanar with Oxy plane;
        // 4. rotate the connected line to be collinear with the parent line (but with opposite direction);
        // 5. move the connected line to the parent line (centers of the corresponding lines should be equal);
        // 6. apply reverse rotation from bullet 3 to move figure at the needed place;
        // bullet 0
        if (this.config.mirrored == true) {
            this.reflectFromOyAxis(target);
        }
        // bullet 1
        //now figure lies in Oxy plane, so z coordinate in  direction vector is 0
        var lineIndex = connectionLineIndex !== null && connectionLineIndex !== void 0 ? connectionLineIndex : 0;
        var connectedLine = target.shape[lineIndex];
        var rotateConnectedLineToOy = FigureBase.calculateRotationToOyAxis(connectedLine);
        target.applyMatrix4(rotateConnectedLineToOy);
        //now the connected line is parallel to Oy axis. We should move it to the Oy axis.
        var shiftToOy = -connectedLine.startPoint.x;
        var movementToOyAxe = new three_1.Vector3(shiftToOy, 0, 0);
        target.applyShift(movementToOyAxe);
        //Bullet 2: rotate the figure in Oxz plane on an angle from config
        var rotationToRequiredAngle = FigureBase.calculateRotationToRequiredAngle(this.config.angle);
        target.applyMatrix4(rotationToRequiredAngle);
        //Bullet3
        var rotationToOzAxis = utils_1.Utils.calculateRotationToOzAxis(parentNormal);
        var inverseRotation = new three_1.Matrix4().getInverse(rotationToOzAxis);
        var transformedParentLine = lodash_1.cloneDeep(parentLine);
        transformedParentLine.applyMatrix4(rotationToOzAxis);
        var transformedParentDirection = transformedParentLine.calculateDirectionVector();
        var connectedLineDirection = connectedLine.calculateDirectionVector();
        var parentDirection2D = new three_1.Vector2(transformedParentDirection.x, transformedParentDirection.y).normalize();
        var connectedLineDirection2D = new three_1.Vector2(connectedLineDirection.x, connectedLineDirection.y);
        // bullet 4
        //searching for rotation which transforms vector connectedLineDirection to -parentDirection.
        var rotationToParentLine = FigureBase.calculateRotationToParentLine(connectedLineDirection2D, parentDirection2D);
        target.applyMatrix4(rotationToParentLine);
        // bullet 5
        // calculation of the shift
        var shiftToParentLine = FigureBase.calculateShiftToParentLine(transformedParentLine, connectedLine);
        target.applyShift(shiftToParentLine);
        // bullet 6;
        target.applyMatrix4(inverseRotation);
        target.calculateNormal();
        var rotationToOxy = new three_1.Matrix4().identity();
        rotationToOxy.premultiply(rotationToRequiredAngle).premultiply(rotationToParentLine).premultiply(inverseRotation);
        target.rotationToOxy = new three_1.Matrix4().getInverse(rotationToOxy);
        return target;
    };
    FigureBase.prototype.reflectFromOyAxis = function (target) {
        target.shape.forEach(function (line) {
            line.reflectFromOy();
            line.changeDirection();
        });
        target.shape = target.shape.splice(0, 1).concat(target.shape.reverse());
        target.holesShape.forEach(function (hole, index) {
            hole.forEach(function (line) {
                line.reflectFromOy();
                line.changeDirection();
            });
            target.holesShape[index] = hole.splice(0, 1).concat(hole.reverse());
        });
    };
    FigureBase.calculateShiftToParentLine = function (transformedParentLine, connectedLine) {
        var parentCenter = transformedParentLine.startPoint.clone().add(transformedParentLine.endPoint).multiplyScalar(0.5);
        var childCenter = connectedLine.startPoint.clone().add(connectedLine.endPoint).multiplyScalar(0.5);
        var shiftToParentLine = parentCenter.sub(childCenter);
        return shiftToParentLine;
    };
    FigureBase.calculateRotationToParentLine = function (connectedLineDirection2D, parentDirection2D) {
        var cosToParentLine = -(connectedLineDirection2D.x * parentDirection2D.x + connectedLineDirection2D.y * parentDirection2D.y);
        var sinToParentLine = -(connectedLineDirection2D.y * parentDirection2D.x - connectedLineDirection2D.x * parentDirection2D.y);
        var rotationToParentLine = new three_1.Matrix4().fromArray([
            cosToParentLine, -sinToParentLine, 0, 0,
            sinToParentLine, cosToParentLine, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        ]);
        return rotationToParentLine;
    };
    FigureBase.calculateRotationToRequiredAngle = function (angle) {
        var rotationAngle = three_1.MathUtils.degToRad(180 - angle);
        var _a = __read([Math.cos(rotationAngle), Math.sin(rotationAngle)], 2), c2 = _a[0], s2 = _a[1];
        var rotationToRequiredAngle = new three_1.Matrix4().fromArray([
            c2, 0, s2, 0,
            0, 1, 0, 0,
            -s2, 0, c2, 0,
            0, 0, 0, 1
        ]);
        return rotationToRequiredAngle;
    };
    FigureBase.calculateRotationToOyAxis = function (connectedLine) {
        var ownDirection = connectedLine.calculateDirectionVector();
        //rotate the figure so that the connected line becomes parallel to the Oy axis
        var _a = __read([ownDirection.y, -ownDirection.x], 2), cosToOy = _a[0], sinToOy = _a[1]; // length of the ownDirection vector is 1, so we can skip denominator;
        var rotateConnectedLineToOy = new three_1.Matrix4().fromArray([
            cosToOy, -sinToOy, 0, 0,
            sinToOy, cosToOy, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        ]);
        return rotateConnectedLineToOy;
    };
    FigureBase.prototype.isValid = function (nullable) {
        var _this = this;
        return Object.keys(this.config.options)
            .filter(function (e) { return !nullable || !nullable.includes(e); })
            .every(function (e) { return _this.config.options[e] >= 0; });
    };
    ;
    FigureBase.prototype.isValidHoles = function () {
        var holes = this.calculateHoleLines();
        var isntIntersection = true;
        holes.forEach(function (hole, index, arr) {
            holes.forEach(function (otherHole, otherIndex) {
                if (index !== otherIndex) {
                    hole.forEach(function (line) {
                        otherHole.forEach(function (otherLine) {
                            if (utilsGeneral_1.getIntersectionOnAPoint(line, otherLine)) {
                                isntIntersection = false;
                            }
                        });
                    });
                }
            });
        });
        return isntIntersection;
    };
    FigureBase.prototype.isIntersetingHole = function (hole, shape) {
        var isntIntersectingHole = true;
        hole.forEach(function (holeLine) {
            shape.forEach(function (shapeLine) {
                if (utilsGeneral_1.getIntersectionOnAPoint(holeLine, shapeLine) !== null) {
                    isntIntersectingHole = false;
                }
            });
        });
        return isntIntersectingHole;
    };
    FigureBase.prototype.isntHoleOutsideFigure = function () {
        var _this = this;
        var pointOutside = new three_1.Vector3(0, -20, 0);
        var holes = this.calculateHoleLines();
        var shape = this.calculateLines();
        var quantityHoles = holes.length;
        var holesLines = [];
        holes.forEach(function (hole) {
            if (_this.isIntersetingHole(hole, shape)) {
                hole.forEach(function (line) { return holesLines.push(line); });
            }
            else {
                quantityHoles -= 1;
            }
        });
        var quantityIntersecting = 0;
        if (holesLines.length) {
            shape.forEach(function (shapeLine) {
                holesLines.forEach(function (holeLine) {
                    if (holeLine.type === 0) {
                        var line = new line3_1.Line3(line3_1.LineType.Straight, [holeLine.startPoint, pointOutside]);
                        if (utilsGeneral_1.getIntersectionOnAPoint(line, shapeLine)) {
                            quantityIntersecting += 1;
                        }
                    }
                });
            });
        }
        var isHoleInsideFigure = quantityIntersecting <= (4 * quantityHoles) && quantityIntersecting !== 0;
        return isHoleInsideFigure || quantityHoles == 0;
    };
    FigureBase.prototype.calculateHoleLines = function () {
        var _this = this;
        var result = [];
        var shape = this.calculateLines();
        if (this._config.holes) {
            this._config.holes.forEach(function (hole) {
                switch (hole.name) {
                    case 'rectangle':
                        if (Array.isArray(_this._config.holesOptions)) {
                            var foundHole = _this._config.holesOptions.find(function (holeOpt) { return holeOpt.id == hole.id; });
                            var figureHeight = _this._config.options.height ? _this._config.options.height : 30;
                            var newHole = void 0;
                            if (foundHole) {
                                newHole = availableHoleOptions_1.AvailableHoleOptions.drawRectangleHole(foundHole, figureHeight, result, shape);
                            }
                            if (newHole) {
                                result.push(newHole);
                            }
                        }
                        break;
                }
            });
        }
        return result;
    };
    ;
    Object.defineProperty(FigureBase.prototype, "config", {
        get: function () {
            return this._config;
        },
        enumerable: false,
        configurable: true
    });
    FigureBase.prototype.addChild = function (parentEdgeIndex, childEdgeIndex, childId) {
        this._childs.push({ parentEdgeIndex: parentEdgeIndex, childEdgeIndex: childEdgeIndex, childId: childId });
    };
    Object.defineProperty(FigureBase.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: false,
        configurable: true
    });
    FigureBase._ids = new Set();
    return FigureBase;
}());
exports.FigureBase = FigureBase;





























































































import axios from "axios";
import { Vue } from "vue-property-decorator";
import {TreeNode, TreePack} from "packaging3d-engine/lib";
import ObjectRender from "./ObjectRender.vue";
import Loader from "./Loader.vue";
import {Vector3, AxesHelper, Group} from "three";
import { WebGLRenderer } from "three";
import {Representation} from "packaging3d-engine/lib/core/base/representation";
import {ColoringData} from "../types";
import Object3dModel from './Object3dModel.vue';
import { v4 } from 'uuid';
import { generateRandomColor } from "packaging3d-engine";

let vm = Vue.component("MappingUpload", {
  computed: {
    dragAndDropCapable: function(): boolean {
      let div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    validateFields: function(): string {
      if(!this.model3dPath){
        return !!!this.name || !!!this.nodes || !!!this.scaleString || !!!this.collectionId ? `${this.$t("warn.fillAllFields")}` : this.uploadJsonError
      }

      return !!!this.name || !!!this.collectionId ? `${this.$t("warn.fillAllFields")}` : this.uploadJsonError;
    },
    isDisableButton: function(): boolean {
      return !this.model3dPath ? !!!this.name || !!!this.nodes || !!!this.collectionId : !!!this.name || !!!this.collectionId;
    }
  },
  data: function() {
    const helperReal = new AxesHelper(200);
    return {
      files: new Array<any>(),
      uploadPercentage: 0,
      backgroundImage: '',
      representations: new Array<Representation>(),
      coloringData: new Map<number, ColoringData>(),
      scaleString: "",
      scale: new Vector3(1, 1, 1),
      uploadJsonError: "",
      name: "",
      nodes: "",
      collectionId: "",
      locked: false,
      collections: [],
      type: 1,
      rendererReal: new WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,
      }),
      helperReal,
      localGroupReal: new Group(),
      loaded: false,
      model3dPath: null
    };
  },
  mounted: function() {
    if (this.dragAndDropCapable) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(evt => {
        (this.$refs.fileform as any).addEventListener(
          evt,
          (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
          },
          false
        );
      });

      (this.$refs.fileform as any).addEventListener("drop", (e: DragEvent) => {
        for (let i = 0; i < e.dataTransfer!.files.length; i++) {
          this.files.push(e.dataTransfer!.files[i]);
        }
        
        this.submitFiles();
      });

      axios.get(
        `${process.env.VUE_APP_API_URL}/api/v1/allcollections/`,
          { withCredentials: true }
      ).then((response) => {
        this.collections = response.data.collections;
      });
    }
  },
  methods: {
    upload: async function() {
      if(!this.model3dPath) {
        return this.uploadJson();
      }

      return this.upload3dModel();
    },
    upload3dModel: async function() {
      const strMime = "image/jpeg";
      const imgData = this.rendererReal.domElement.toDataURL(strMime);
      
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/api/v1/template`,
        {
          typeFile: '3dModel',
          name: this.name,
          collectionId: this.collectionId,
          nodes: this.model3dPath,
          coloringData: '',
          imgData
        },
        { withCredentials: true }
      );

      const { templateId, collectionId } = response.data;

      this.$router.push({
          path: `/collection/template/${collectionId}/${templateId}/3dModel`
      });
    },
    uploadJson: async function() {
      try {
        const strMime = "image/jpeg";
        const imgData = this.rendererReal.domElement.toDataURL(strMime);
        const coloringDataToArray = Array.from(this.coloringData, ([key, value]) => ({ key, value }));
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/template`,
          {
            typeFile: 'pdf',
            name: this.name,
            collectionId: this.collectionId,
            nodes: this.nodes,
            scaling: this.scaleString,
            locked: this.locked,
            backgroundImage: this.backgroundImage, 
            meterage: 'mm',
            imgData,
            coloringData: JSON.stringify(coloringDataToArray),
          },
          { withCredentials: true }
        );
        const { templateId, collectionId } = response.data;
        this.$router.push({
          path: `/collection/template/${collectionId}/${templateId}/${this.name}`
        });
      } catch (error) {
        if (error.response) {
          this.uploadJsonError = error.response.data;
        }
      }
    },
    submitFiles: function() {
      let formData = new FormData();
      formData.append("file", this.files[0]);
      formData.append("meterage", 'mm');
      this.representations = [];
      this.loaded = false;
      if(this.files[0].type === 'application/pdf') {
        return this.submitPdf(formData)
      }

      this.submit3dModel(formData)
    },
    submit3dModel: async function(formData: FormData) {
      try {
      const request = await axios.post(`${process.env.VUE_APP_API_URL}/api/v1/upload/3dModel`, formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent: any) => {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
        },
        timeout: 180000
      })
      this.model3dPath = request.data 
      } catch(e) {
        console.log(e)
      }
    },
    submitPdf: function(formData: FormData) {
      axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },

          onUploadProgress: (progressEvent: any) => {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
          timeout: 180000
        })
        .then(response => {
          this.nodes = response.data.nodes;
          let templateNodes = JSON.parse(response.data.nodes) as TreeNode[];
          let templateScaling = response.data.scaling;
          let representations = TreePack.unpack(templateNodes, templateScaling);
          this.representations = representations.representations3d;
          this.backgroundImage = response.data.backgroundImage;
          this.scale = new Vector3(templateScaling.x, templateScaling.y, templateScaling.z);
          this.scaleString = JSON.stringify(this.scale.toArray());
          this.uploadPercentage = 0;
          this.files = [];
          this.loaded = true;
        })
        .catch(err => {
          console.log(err);
          this.files = [];
          this.nodes = "pls, check your file";
        });
    },
    handleFileChange: function(e: any) {
      for (let i = 0; i < e.target.files.length; ++i) {
        this.files.push(e.target.files[i]);
      }

      this.submitFiles();
    }
  },
  components: {
    ObjectRender,
    Loader,
    Object3dModel
  }
});

export default vm;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateRandomColor = exports.pseudoscalarProduct = exports.isPointOnLineAndBetweenPoints = exports.isParallel = exports.getIntersectionOnAPoint = void 0;
var three_1 = require("three");
exports.getIntersectionOnAPoint = function (line1, line2) {
    var intersection = null;
    var A = line1.startPoint;
    var B = line1.endPoint;
    var C = line2.startPoint;
    var D = line2.endPoint;
    // Line AB represented as a1x + b1y = c1 
    var a1 = B.y - A.y;
    var b1 = A.x - B.x;
    var c1 = a1 * (A.x) + b1 * (A.y);
    // Line CD represented as a2x + b2y = c2 
    var a2 = D.y - C.y;
    var b2 = C.x - D.x;
    var c2 = a2 * (C.x) + b2 * (C.y);
    var determinant = a1 * b2 - a2 * b1;
    if (determinant == 0) {
        // The lines are parallel.
    }
    else {
        var x = (b2 * c1 - b1 * c2) / determinant;
        var y = (a1 * c2 - a2 * c1) / determinant;
        intersection = new three_1.Vector3(x, y);
    }
    // if there is an intersection. verify intersection occurs on the two line segments
    // when calculating from start to end
    if (intersection) {
        var line1result = isPositive(line1.startPoint, line1.endPoint, intersection);
        var line2result = isPositive(line2.endPoint, line2.endPoint, intersection);
        var checkPonitOnLine1 = exports.isPointOnLineAndBetweenPoints(line1.startPoint, line1.endPoint, intersection);
        var checkPonitOnLine2 = exports.isPointOnLineAndBetweenPoints(line2.startPoint, line2.endPoint, intersection);
        if (line1result &&
            line2result &&
            checkPonitOnLine1 &&
            checkPonitOnLine2) {
            // do nothing when the intersection is not "false" as both results are "true"
        }
        else { // 
            // set intersection to null when the intersection is "false" as one of results is "false"
            intersection = null;
        }
    }
    return intersection;
};
exports.isParallel = function (line1, line2) {
    var intersection = false;
    var A = line1.startPoint;
    var B = line1.endPoint;
    var C = line2.startPoint;
    var D = line2.endPoint;
    // Line AB represented as a1x + b1y = c1 
    var a1 = B.y - A.y;
    var b1 = A.x - B.x;
    var c1 = a1 * (A.x) + b1 * (A.y);
    // Line CD represented as a2x + b2y = c2 
    var a2 = D.y - C.y;
    var b2 = C.x - D.x;
    var c2 = a2 * (C.x) + b2 * (C.y);
    var determinant = a1 * b2 - a2 * b1;
    if (determinant == 0) {
        intersection = true;
    }
    return intersection;
};
var isPositive = function (start, end, intersection) {
    var v1 = new three_1.Vector3().copy(end).sub(start);
    var v2 = new three_1.Vector3().copy(intersection).sub(start);
    return v1.dot(v2) >= 0;
};
exports.isPointOnLineAndBetweenPoints = function (pointA, pointB, pointToCheck) {
    // if (!isPointOnLine(pointA, pointB, pointToCheck)) {
    //     return false;
    // }
    var d = Math.round(pointA.distanceTo(pointB));
    return Math.round(pointA.distanceTo(pointToCheck)) <= d && Math.round(pointB.distanceTo(pointToCheck)) <= d;
};
exports.pseudoscalarProduct = function (dot, cross) {
    var angle = 0;
    if (dot >= 0) {
        angle = cross < 0 ? Math.acos(dot) : 2 * Math.PI - Math.acos(dot);
    }
    else {
        angle = cross >= 0 ? Math.PI + Math.asin(cross) : Math.acos(dot);
    }
    return angle;
};
var randHex = function () {
    return (Math.floor(Math.random() * 56) + 200).toString(16);
};
exports.generateRandomColor = function () {
    return "#" + randHex() + randHex() + randHex();
};

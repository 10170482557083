import  React, { PureComponent, createRef } from 'react';
import { ref } from 'framework-utils';
import _ from 'lodash'

/* global Reflect, Promise */
var extendStatics = function (d, b) {
  extendStatics = Object.setPrototypeOf || {
    __proto__: []
  } instanceof Array && function (d, b) {
    d.__proto__ = b;
  } || function (d, b) {
    for (const p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
  };

  return extendStatics(d, b);
};

function __extends(d, b) {
  extendStatics(d, b);

  function __() {
    this.constructor = d;
  }

  d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
}

class Ruler extends PureComponent {
  state = {
    scrollPos: 0
  };

  width = 0;
  height = 0;
  canvasElement = createRef();


  componentDidMount() {
    const canvas = this.canvasElement.current;
    const context = canvas.getContext('2d');
    this.canvasContext = context;
    this.resize();
  };

  resize() {
    const canvas = this.canvasElement.current;
    const _a = this.props;
    const { width } = _a;
    const { height } = _a;
    const { scrollPos } = _a;
    this.width = width || canvas.offsetWidth;
    this.height = height || canvas.offsetHeight;
    canvas.width = this.width * 2;
    canvas.height = this.height * 2;
    this.draw(scrollPos);
  };

  draw(scrollPos) {
    if (scrollPos === void 0) {
      scrollPos = this.state.scrollPos;
    }

    const { unit, zoom, type, backgroundColor, lineColor, textColor, direction, textFormat, activeSelection, size } = this.props;
    const { width, height, state } = this;
    state.scrollPos = scrollPos;
    const context = this.canvasContext;
    const isHorizontal = type === 'horizontal';
    const isDirectionStart = direction === 'start';

  
    const verticalRuler = document.getElementById('verticalRuler');
    const editorCanvas = document.getElementById('editorCanvas');
    const verticalPadding = editorCanvas?.getBoundingClientRect().top - verticalRuler?.getBoundingClientRect().top;
    const horizontalPadding = editorCanvas?.getBoundingClientRect().left - verticalRuler?.getBoundingClientRect().left;
    const padding = isHorizontal ? horizontalPadding : verticalPadding;

    if (backgroundColor === 'transparent') {
      // Clear existing paths & text
      context.clearRect(0, 0, width * 2, height * 2);
    } else {
      // Draw the background
      context.rect(0, 0, width * 2, height * 2);
      context.fillStyle = backgroundColor;
      context.fill();

      if (activeSelection) {
        context.fillStyle = 'rgba(0, 192, 255, 0.2)';
        context.fillRect((activeSelection.x * 2) + horizontalPadding * 2, 0, activeSelection.width * 2, 48);
        context.fillRect(0, (activeSelection.y * 2 + 2) + verticalPadding + 44, 48, activeSelection.height * 2);
      }
    }

    context.save();
    context.scale(2, 2);
    context.strokeStyle = lineColor;
    context.lineWidth = 1;
    context.font = '10px sans-serif';
    context.fillStyle = textColor;

    if (isDirectionStart) {
      context.textBaseline = 'top';
    }

    context.translate(0.5, 0);
    context.beginPath();
    const minRange = 0;
    const maxRange = Math.ceil(size / unit);
    const length = maxRange - minRange;

    for (let i = 0; i < length + 1; i += 1) {
      const pixels = i * size / length;
      const startPos = (pixels - scrollPos) + padding;

      if (startPos >= -unit && startPos < size + padding) {
        const _b = isHorizontal
          ? [startPos, isDirectionStart ? 17 : height - 17]
          : [isDirectionStart ? 17 : width - 17, startPos];
        const [startX, startY] = _b;
        const text = textFormat(pixels / zoom);

        const textWidth = context.measureText(text).width;
        if (isHorizontal) {
          const renderLastText = ((size + padding) - (startX + textWidth / 2) < 7);

          if (!renderLastText) {
            context.moveTo(startX, startY + 6 + 3);
            context.lineTo(startX, startY + 6 + 6 + 3);
            context.fillText(text, startX - textWidth / 2, startY + 6);
          }
        } else {
          
          const newLength = length - 1
          const pixels = i * size / newLength;
          const text = textFormat(pixels / zoom);
          context.save();
          context.translate(startX + 6, startY + textWidth / 2);
          context.rotate(-Math.PI / 2);
          context.fillText(text, 0, 0);
          context.moveTo(textWidth / 2, 3);
          context.lineTo(textWidth / 2, 9);
          context.restore();
        }
      } else {
        const [startX, startY] = [630, 7];
        const text = textFormat(pixels / zoom);

        const textWidth = context.measureText(text).width;
        if (isHorizontal) {
          context.moveTo(size + padding, startY + 6 + 3);
          context.lineTo(size + padding, startY + 6 + 6 + 3);
          context.fillText(text, size + padding - textWidth / 2, startY + 6);
        }
      }
    }

    context.stroke();
    context.restore();
  };

  componentDidMount() {
    const canvas = this.canvasElement.current;
    const context = canvas.getContext('2d');
    this.canvasContext = context;
    this.resize();
  };

  componentDidUpdate() {
    this.resize();
  };

  scroll(scrollPos) {
    this.draw(scrollPos);
  };

  render() {
    const {style} = this.props;

    return (
      <canvas style={style} ref={this.canvasElement}></canvas>
    )
  }
};

Ruler.defaultProps = {
  type: 'horizontal',
  zoom: 1,
  width: 0,
  height: 0,
  unit: 50,
  direction: 'end',
  style: {
    width: '100%',
    height: '100%'
  },
  activeSelection: null,
  backgroundColor: '#333333',
  textColor: '#ffffff',
  lineColor: '#777777'
};


export default Ruler;

/* eslint-disable react/prop-types */
import React from 'react';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Input from '@material-ui/core/Input';

import withStyles from '@material-ui/core/styles/withStyles';
import sliderInput from './styles/SliderInputStyles';
import {materialTheme} from '../../../materialTheme'

const { palette } = materialTheme


const styles = () => ({
  sliderWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  sliderIcon: {
    marginRight: 12,
  },
  ...sliderInput(palette, 64),
});

class EditorSlider extends React.Component {
  state = {
    spacing: this.props.layerSpacing
  }

  getValue = (value, maxValue, minValue) => {
    if (value < minValue) return minValue;
    if (value > maxValue) return maxValue;
    return value;
  };

  onSpacingChange = value => {
    const { spacingHandler, type } = this.props;
    let newValue;
    if (type === 'charSpacing') {
      newValue = this.getValue(value, 30, -7);
      const spacingValue = newValue * 25;
      spacingHandler(type, spacingValue);
    } else {
      newValue = this.getValue(value, 6.5, 0.5);
      spacingHandler(type, newValue);
    }
    this.setState({ spacing: newValue });
  };

  render() {
    const {
      step, type, sliderClass, min, max, start, classes
    } = this.props;
    const { spacing } = this.state;
    const icons = {
      lineHeight: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M10.5 10.5C10.5 10.0858 10.8358 9.75 11.25 9.75H21.75C22.1642 9.75 22.5 10.0858 22.5 10.5C22.5 10.9142 22.1642 11.25 21.75 11.25H11.25C10.8358 11.25 10.5 10.9142 10.5 10.5Z" fill="#A6CBDF" />
          <path d="M11.25 21.75C11.25 21.3358 11.5858 21 12 21H21.75C22.1642 21 22.5 21.3358 22.5 21.75C22.5 22.1642 22.1642 22.5 21.75 22.5H12C11.5858 22.5 11.25 22.1642 11.25 21.75Z" fill="#A6CBDF" />
          <path d="M19.2035 19.7901C19.3146 20.0679 19.5837 20.25 19.8828 20.25C20.4101 20.25 20.7642 19.7092 20.5535 19.2259L17.9917 13.3503C17.8328 12.9857 17.4728 12.75 17.0751 12.75H16.6749C16.2772 12.75 15.9172 12.9857 15.7583 13.3503L13.1965 19.2259C12.9858 19.7092 13.3399 20.25 13.8672 20.25C14.1663 20.25 14.4354 20.0679 14.5465 19.7901L14.9625 18.75H18.7875L19.2035 19.7901ZM15.5625 17.25L16.875 13.9725L18.1875 17.25H15.5625Z" fill="#A6CBDF" />
          <path d="M19.2035 8.54008C19.3146 8.81786 19.5837 9 19.8828 9C20.4101 9 20.7642 8.45924 20.5535 7.97595L17.9917 2.10034C17.8328 1.73573 17.4728 1.5 17.0751 1.5H16.6749C16.2772 1.5 15.9172 1.73573 15.7583 2.10034L13.1965 7.97595C12.9858 8.45924 13.3399 9 13.8672 9C14.1663 9 14.4354 8.81786 14.5465 8.54008L14.9625 7.5H18.7875L19.2035 8.54008ZM15.5625 6L16.875 2.7225L18.1875 6H15.5625Z" fill="#A6CBDF" />
          <path d="M4.5 11.8725L5.90625 13.2787C6.19857 13.5711 6.67226 13.5719 6.96562 13.2806C7.26044 12.9879 7.26129 12.5113 6.9675 12.2175L5.20711 10.4571C4.81658 10.0666 4.18342 10.0666 3.79289 10.4571L2.02875 12.2212C1.73643 12.5136 1.73559 12.9873 2.02688 13.2806C2.31963 13.5754 2.79621 13.5763 3.09 13.2825L4.5 11.8725Z" fill="#A6CBDF" />
          <path d="M4.5 20.3776L3.09375 18.9713C2.80143 18.679 2.32774 18.6782 2.03438 18.9694C1.73956 19.2622 1.73871 19.7388 2.0325 20.0326L3.79289 21.793C4.18342 22.1835 4.81658 22.1835 5.20711 21.793L6.97125 20.0288C7.26357 19.7365 7.26441 19.2628 6.97312 18.9694C6.68037 18.6746 6.20379 18.6738 5.91 18.9676L4.5 20.3776Z" fill="#A6CBDF" />
        </svg>
      ),
      charSpacing: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M19.5 14.9998C19.2062 14.706 18.7296 14.7068 18.4369 15.0017C18.1456 15.295 18.1464 15.7687 18.4388 16.061L19.6275 17.2498H4.3725L5.56125 16.061C5.85357 15.7687 5.85441 15.295 5.56312 15.0017C5.27037 14.7068 4.79379 14.706 4.5 14.9998L2.20711 17.2927C1.81658 17.6832 1.81658 18.3164 2.20711 18.7069L4.5 20.9998C4.79379 21.2936 5.27037 21.2927 5.56312 20.9979C5.85441 20.7045 5.85357 20.2309 5.56125 19.9385L4.3725 18.7498H19.6275L18.4388 19.9385C18.1464 20.2309 18.1456 20.7045 18.4369 20.9979C18.7296 21.2927 19.2062 21.2936 19.5 20.9998L21.7929 18.7069C22.1834 18.3164 22.1834 17.6832 21.7929 17.2927L19.5 14.9998Z" fill="#A6CBDF" />
          <path d="M19.3402 12.2707C19.4356 12.5569 19.7035 12.75 20.0053 12.75C20.49 12.75 20.8285 12.2697 20.6654 11.8132L17.487 2.91366C17.3449 2.51567 16.9679 2.25 16.5453 2.25H16.4547C16.0321 2.25 15.6551 2.51567 15.513 2.91366L12.3346 11.8132C12.1715 12.2697 12.51 12.75 12.9947 12.75C13.2965 12.75 13.5644 12.5569 13.6598 12.2707L14.25 10.5H18.75L19.3402 12.2707ZM14.7525 9L16.5 3.75L18.2475 9H14.7525Z" fill="#A6CBDF" />
          <path d="M11.0053 2.25C10.7035 2.25 10.4356 2.44308 10.3402 2.72933L7.5 11.25L4.65978 2.72933C4.56436 2.44308 4.29648 2.25 3.99474 2.25C3.50997 2.25 3.17152 2.73026 3.33457 3.18679L6.51298 12.0863C6.65512 12.4843 7.03211 12.75 7.45472 12.75H7.54528C7.96789 12.75 8.34488 12.4843 8.48702 12.0863L11.6654 3.18679C11.8285 2.73026 11.49 2.25 11.0053 2.25Z" fill="#A6CBDF" />
        </svg>
      )
    };

    return (
      <div className={classes.sliderWrapper}>
        <div className={classes.sliderIcon}>
          {icons[type]}
        </div>
        <Slider
          className={`${sliderClass}`}
          step={step}
          min={min}
          max={max}
          startPoint={start}
          value={spacing}
          onChange={this.onSpacingChange}
          handleStyle={{
            border: '1px solid #BCDEF0',
            width: 12,
            height: 12,
          }}
          railStyle={{
            height: 2,
            background: '#BCDEF0',
            borderRadius: 10,
          }}
          trackStyle={{
            background: '#2C3E66',
            height: 2,
            borderRadius: 10,
          }}
        />
        <Input
          value={spacing}
          onChange={e => this.onSpacingChange(e.target.value)}
          disableUnderline
          className={classes.sliderInput}
          classes={{ focused: classes.focusedInput }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(EditorSlider);
// /* eslint-disable react/prop-types */
// import React, { useState } from 'react';
//
// import Slider from 'rc-slider';
// import 'rc-slider/assets/index.css';
// import Input from '@material-ui/core/Input';
// import makeStyles from '@material-ui/core/styles/makeStyles';
//
// import sliderInput from './styles/SliderInputStyles';
//
// const useStyles = makeStyles(({ palette }) => ({
//   sliderWrapper: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   sliderIcon: {
//     marginRight: 12,
//   },
//   ...sliderInput(palette, 64),
// }));
//
// const EditorSlider = ({
//   step,
//   type,
//   sliderClass,
//   layerSpacing,
//   spacingHandler,
//   min,
//   max,
//   start
// }) => {
//   const [spacing, setSpacing] = useState(layerSpacing);
//
//   const classes = useStyles();
//
//   const getValue = (value, maxValue, minValue) => {
//     if (value < minValue) return minValue;
//     if (value > maxValue) return maxValue;
//     return value;
//   };
//
//   const onSpacingChange = value => {
//     let newValue;
//     if (type === 'charSpacing') {
//       newValue = getValue(value, 30, -7);
//       const spacingValue = newValue * 25;
//       spacingHandler(type, spacingValue);
//     } else {
//       newValue = getValue(value, 6.5, 0.5);
//       spacingHandler(type, newValue);
//     }
//     setSpacing(newValue);
//   };
//
//   const icons = {
//     lineHeight: (
//       <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
//         <path d="M10.5 10.5C10.5 10.0858 10.8358 9.75 11.25 9.75H21.75C22.1642 9.75 22.5 10.0858 22.5 10.5C22.5 10.9142 22.1642 11.25 21.75 11.25H11.25C10.8358 11.25 10.5 10.9142 10.5 10.5Z" fill="#A6CBDF" />
//         <path d="M11.25 21.75C11.25 21.3358 11.5858 21 12 21H21.75C22.1642 21 22.5 21.3358 22.5 21.75C22.5 22.1642 22.1642 22.5 21.75 22.5H12C11.5858 22.5 11.25 22.1642 11.25 21.75Z" fill="#A6CBDF" />
//         <path d="M19.2035 19.7901C19.3146 20.0679 19.5837 20.25 19.8828 20.25C20.4101 20.25 20.7642 19.7092 20.5535 19.2259L17.9917 13.3503C17.8328 12.9857 17.4728 12.75 17.0751 12.75H16.6749C16.2772 12.75 15.9172 12.9857 15.7583 13.3503L13.1965 19.2259C12.9858 19.7092 13.3399 20.25 13.8672 20.25C14.1663 20.25 14.4354 20.0679 14.5465 19.7901L14.9625 18.75H18.7875L19.2035 19.7901ZM15.5625 17.25L16.875 13.9725L18.1875 17.25H15.5625Z" fill="#A6CBDF" />
//         <path d="M19.2035 8.54008C19.3146 8.81786 19.5837 9 19.8828 9C20.4101 9 20.7642 8.45924 20.5535 7.97595L17.9917 2.10034C17.8328 1.73573 17.4728 1.5 17.0751 1.5H16.6749C16.2772 1.5 15.9172 1.73573 15.7583 2.10034L13.1965 7.97595C12.9858 8.45924 13.3399 9 13.8672 9C14.1663 9 14.4354 8.81786 14.5465 8.54008L14.9625 7.5H18.7875L19.2035 8.54008ZM15.5625 6L16.875 2.7225L18.1875 6H15.5625Z" fill="#A6CBDF" />
//         <path d="M4.5 11.8725L5.90625 13.2787C6.19857 13.5711 6.67226 13.5719 6.96562 13.2806C7.26044 12.9879 7.26129 12.5113 6.9675 12.2175L5.20711 10.4571C4.81658 10.0666 4.18342 10.0666 3.79289 10.4571L2.02875 12.2212C1.73643 12.5136 1.73559 12.9873 2.02688 13.2806C2.31963 13.5754 2.79621 13.5763 3.09 13.2825L4.5 11.8725Z" fill="#A6CBDF" />
//         <path d="M4.5 20.3776L3.09375 18.9713C2.80143 18.679 2.32774 18.6782 2.03438 18.9694C1.73956 19.2622 1.73871 19.7388 2.0325 20.0326L3.79289 21.793C4.18342 22.1835 4.81658 22.1835 5.20711 21.793L6.97125 20.0288C7.26357 19.7365 7.26441 19.2628 6.97312 18.9694C6.68037 18.6746 6.20379 18.6738 5.91 18.9676L4.5 20.3776Z" fill="#A6CBDF" />
//       </svg>
//     ),
//     charSpacing: (
//       <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
//         <path d="M19.5 14.9998C19.2062 14.706 18.7296 14.7068 18.4369 15.0017C18.1456 15.295 18.1464 15.7687 18.4388 16.061L19.6275 17.2498H4.3725L5.56125 16.061C5.85357 15.7687 5.85441 15.295 5.56312 15.0017C5.27037 14.7068 4.79379 14.706 4.5 14.9998L2.20711 17.2927C1.81658 17.6832 1.81658 18.3164 2.20711 18.7069L4.5 20.9998C4.79379 21.2936 5.27037 21.2927 5.56312 20.9979C5.85441 20.7045 5.85357 20.2309 5.56125 19.9385L4.3725 18.7498H19.6275L18.4388 19.9385C18.1464 20.2309 18.1456 20.7045 18.4369 20.9979C18.7296 21.2927 19.2062 21.2936 19.5 20.9998L21.7929 18.7069C22.1834 18.3164 22.1834 17.6832 21.7929 17.2927L19.5 14.9998Z" fill="#A6CBDF" />
//         <path d="M19.3402 12.2707C19.4356 12.5569 19.7035 12.75 20.0053 12.75C20.49 12.75 20.8285 12.2697 20.6654 11.8132L17.487 2.91366C17.3449 2.51567 16.9679 2.25 16.5453 2.25H16.4547C16.0321 2.25 15.6551 2.51567 15.513 2.91366L12.3346 11.8132C12.1715 12.2697 12.51 12.75 12.9947 12.75C13.2965 12.75 13.5644 12.5569 13.6598 12.2707L14.25 10.5H18.75L19.3402 12.2707ZM14.7525 9L16.5 3.75L18.2475 9H14.7525Z" fill="#A6CBDF" />
//         <path d="M11.0053 2.25C10.7035 2.25 10.4356 2.44308 10.3402 2.72933L7.5 11.25L4.65978 2.72933C4.56436 2.44308 4.29648 2.25 3.99474 2.25C3.50997 2.25 3.17152 2.73026 3.33457 3.18679L6.51298 12.0863C6.65512 12.4843 7.03211 12.75 7.45472 12.75H7.54528C7.96789 12.75 8.34488 12.4843 8.48702 12.0863L11.6654 3.18679C11.8285 2.73026 11.49 2.25 11.0053 2.25Z" fill="#A6CBDF" />
//       </svg>
//     )
//   };
//
//   return (
//     <div className={classes.sliderWrapper}>
//       <div className={classes.sliderIcon}>
//         {icons[type]}
//       </div>
//       <Slider
//         className={`${sliderClass}`}
//         step={step}
//         min={min}
//         max={max}
//         startPoint={start}
//         value={spacing}
//         onChange={onSpacingChange}
//         handleStyle={{
//           border: '1px solid #BCDEF0',
//           width: 12,
//           height: 12,
//         }}
//         railStyle={{
//           height: 2,
//           background: '#BCDEF0',
//           borderRadius: 10,
//         }}
//         trackStyle={{
//           background: '#2C3E66',
//           height: 2,
//           borderRadius: 10,
//         }}
//       />
//       <Input
//         value={spacing}
//         onChange={e => onSpacingChange(e.target.value)}
//         disableUnderline
//         className={classes.sliderInput}
//         classes={{ focused: classes.focusedInput }}
//       />
//     </div>
//   );
// };
//
// export default EditorSlider;

export default [
  'Times new Roman',
  'Poppins',
  'Roboto',
  'Oswald',
  'Arial',
  'Lobster',
  'Pacifico',
  'Satisfy',
  'Bangers',
  'Audiowide',
  'Sacramento',
];

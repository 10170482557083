"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RectangleWithHole = void 0;
var rectangle_1 = require("./rectangle");
var CutHoleHelper_1 = require("../../utils/CutHoleHelper");
var RectangleWithHole = /** @class */ (function (_super) {
    __extends(RectangleWithHole, _super);
    function RectangleWithHole() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RectangleWithHole.prototype.calculateHoleLines = function () {
        return [CutHoleHelper_1.CutHoleHelper.drawHolderHole(this.config.options)];
    };
    RectangleWithHole.prototype.isValid = function () {
        if (!_super.prototype.isValid.call(this))
            return false;
        var options = this.config.options;
        var _a = __read([options.width, options.height], 2), width = _a[0], height = _a[1];
        var holeRadius = options.holeRadius;
        var holeCenterX = options.holeCenterX;
        var holeCenterY = options.holeCenterY;
        var holeStretch = options.holeStretch;
        if ((holeCenterX + holeStretch + holeRadius) > width / 2 || (holeCenterX - holeStretch - holeRadius) < -width / 2)
            return false;
        return !(holeCenterY + holeRadius > height || holeCenterY - holeRadius < 0);
    };
    RectangleWithHole.prototype.getConfigProperties = function () {
        return CutHoleHelper_1.CutHoleHelper.addConfigProperties(_super.prototype.getConfigProperties.call(this));
    };
    return RectangleWithHole;
}(rectangle_1.Rectangle));
exports.RectangleWithHole = RectangleWithHole;

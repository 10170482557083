"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConcaveTrapeze = void 0;
var trapeze_1 = require("./trapeze");
var line3_1 = require("../../../core/base/line3");
var arcFitter_1 = require("../../utils/arcFitter");
var ConcaveTrapeze = /** @class */ (function (_super) {
    __extends(ConcaveTrapeze, _super);
    function ConcaveTrapeze() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConcaveTrapeze.prototype.calculateHoleLines = function () {
        var radiusTop = this.config.options.radiusTop;
        var radiusBottom = this.config.options.radiusBottom;
        var lines = this.calculateLines();
        var bottomLine = lines[0];
        var additionalPointsBottom = arcFitter_1.ArcFitter.fitArcCircle(bottomLine.startPoint, bottomLine.endPoint, radiusBottom);
        var topLine = lines[2];
        var additionalPointsTop = arcFitter_1.ArcFitter.fitArcCircle(topLine.startPoint, topLine.endPoint, radiusTop);
        return [[
                bottomLine.clone(),
                new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPointsBottom[0]),
                new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPointsBottom[1])
            ], [
                new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPointsTop[0]),
                new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPointsTop[1]),
                topLine.clone()
            ]];
    };
    ConcaveTrapeze.prototype.getConfigProperties = function () {
        var config = _super.prototype.getConfigProperties.call(this);
        config.radiusTop = 0.7;
        config.radiusBottom = 0.7;
        return config;
    };
    return ConcaveTrapeze;
}(trapeze_1.Trapeze));
exports.ConcaveTrapeze = ConcaveTrapeze;

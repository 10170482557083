
















import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { Vue } from "vue-property-decorator";
import axios from "axios";
import PostComment from "./PostComment.vue";
import Comment from "./Comment.vue";
import { Message } from "../types";

let vm = Vue.component("Comments", {
  props: {
    visible: Boolean
  },
  data: function() {
    return {
      message: "",
      comments: [] as Message[],
      count: 0,
      pages: [] as number[],
      scrollPosition: 0,
      page: 1
    };
  },
  beforeDestroy: function() {},
  mounted: function() {
    this.fetchComments(this.page);
  },
  watch: {
    visible: function(newVal, oldVal) {
      if (newVal) {
        this.fetchComments(this.page);
      }
    },
    page: function(newVal, oldVal) {
      this.fetchComments(this.page);
    }
  },
  methods: {
    fetchComments: async function(page: number) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/comments/${this.$route.params.templateId}/${page}`,
          {
            withCredentials: true
          }
        );
        console.log(response);
        this.comments = [...response.data.comments];
        this.count = Math.ceil(response.data.count / 10);
      } catch (error) {
        console.log(error);
      }
    }
  },
  components: {
    PostComment,
    Comment
  }
});

export default vm;









// @ts-nocheck
import { Vue } from "vue-property-decorator";
import TemplateEditModal from './../components-react/TemplateEditor/TemplateEditor.js';
import { PropOptions } from "vue";
import { Vector3 } from "three";
import {TreeNode} from "packaging3d-engine/lib/core/unpack/treePack";
import axios from "axios";

let vm = Vue.component("PdfEditor", {
  props: {
    isShowEditor: Boolean,
    backgroundImage: {},
    nodes: {
      type: Array
    } as PropOptions<TreeNode[]>,
    pattern:{ type: Number},
    scale: {
      type: Vector3,
      default: () => new Vector3(1, 1, 1)
    },
    meterage: String,
    save: Function
  },
  data: function() {
    return {
      pdf: new Blob(),
    };
  },
  computed: {
    meta() {
      const packed = JSON.stringify(this.nodes);

      return {
        pattern: this.pattern,
        data: packed,
        scaling: this.scale,
        meterage: this.meterage,
        name: this.$route.params.templateName,
        peforation: {
          space: 5,
          length: 5
        }
      };
    }
  },
  methods: {
    download(): void {
      const packed = JSON.stringify(this.nodes);

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/download/pdf/${this.$route.params.templateId}`, {
            pattern: this.pattern,
            data: packed,
            scaling: this.scale,
            meterage: this.meterage,
            isBackground: false,
            peforation: {
              space: 5,
              length: 5
            }
        },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          this.pdf = response.data;
        });
    }
  },
  components: {
      TemplateEditModal
  },
  watch: {
    isShowEditor: function(newVal) {
      if(newVal) this.download();
    }
  }
});

export default vm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundedCuttedBottomTrapezeWithHole = void 0;
var roundedCuttedBottomTrapeze_1 = require("../trapeze/roundedCuttedBottomTrapeze");
var CutHoleHelper_1 = require("../../utils/CutHoleHelper");
var RoundedCuttedBottomTrapezeWithHole = /** @class */ (function (_super) {
    __extends(RoundedCuttedBottomTrapezeWithHole, _super);
    function RoundedCuttedBottomTrapezeWithHole() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoundedCuttedBottomTrapezeWithHole.prototype.calculateHoleLines = function () {
        return [CutHoleHelper_1.CutHoleHelper.drawHolderHole(this.config.options)];
    };
    RoundedCuttedBottomTrapezeWithHole.prototype.getConfigProperties = function () {
        return CutHoleHelper_1.CutHoleHelper.addConfigProperties(_super.prototype.getConfigProperties.call(this));
    };
    RoundedCuttedBottomTrapezeWithHole.prototype.isValid = function (nullable) {
        return _super.prototype.isValid.call(this, ["holeCenterX", "holeStretch"]);
    };
    return RoundedCuttedBottomTrapezeWithHole;
}(roundedCuttedBottomTrapeze_1.RoundedCuttedBottomTrapeze));
exports.RoundedCuttedBottomTrapezeWithHole = RoundedCuttedBottomTrapezeWithHole;

import React from 'react';
import { withStyles } from '@material-ui/core';
import ImageFromApi from './ImageFromApi';

const classes = () => ({
    modal: {
        display: 'block',
        position: 'fixed',
        zIndex: 1,
        paddingTop: '100px',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    modalContent: {
        backgroundColor: '#fefefe',
        margin: 'auto',
        padding: '20px',
        border: '1px solid #888',
        width: '80%',
        height: '95%',
    },
    close: {
        color: '#aaaaaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        '&:focus': {
            color: '#000',
            textDecoration: 'none',
            cursor: 'pointer',
        }
    },
    gallery: {
        width: '90%',
        margin: '12vh 50%',
        transform: 'translateX(-50%)',
        display: 'grid',
        gridTemplateColumns: 'repeat(5,200px)',
        rowGap: '10px',
        columnGap: '10px',
        justifyContent: 'center',
        height: '480px',
    },
    searchButton: {
        padding: '0.5rem 3rem',
        marginLeft: '10px',
        background: 'blue',
        color: 'white',
        fontSize: '1.2rem',
        cursor: 'pointer',
        textAlign: 'center',
    },
    search: {
        position: 'fixed',
        // top: '0',
        // left: '0',
        width: '73vw',
        display: 'flex',
        minHeight: '10vh',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0,0,0,0.3)',
        zIndex: '1',
        background: 'white'
    },
    input: {
        padding: '0.5rem',
        outline: 'none',
        fontSize: '1rem'
    },
    next: {
        position: 'fixed',
        bottom: '65px',
        padding: '0.5rem 0.5rem',
        border: '1px solid black',
        width: '100px',
        textAlign: 'center',
        cursor: 'pointer',
    }
});


class ModalImage extends React.Component {

state = {
    pagenr: 1,
    search: false,
    query: '',
    images: {}
}    

auth = "563492ad6f917000010000014b3c236201ab4f4aabb435759aca683a";


handleChange = (event) => {
    console.log(this.state.query)
    this.setState({query: event.target.value});
  }

async CuratedPhotos(pagenr) {
    const data = await fetch(`https://api.pexels.com/v1/curated?per_page=15&page=${pagenr}`,
    {
        method: "GET", 
        headers: {
            Accept: 'application/json',
            Authorization: this.auth,
        }
    })

    const result = await data.json();
    this.setState({images: result})
}

async searchPhotos(query,pagenr) {
    const data = await fetch(`https://api.pexels.com/v1/search?query=${query}&per_page=15&page=${pagenr}`,
    {
        method: "GET", 
        headers: {
            Accept: 'application/json',
            Authorization: this.auth,
        }
    })

    const result = await data.json();
    this.setState({images: result})
}

clear() {
    this.setState({
        pagenr: 1
    })
}

searchButton = (query, pagenr) => {
    if (query === '') return;
    this.clear();
    this.setState({search: true})
    this.searchPhotos(query, pagenr);
    this.setState({pagenr: this.state.pagenr + 1})
}

nextImages = () => {
    if(!this.state.search) {
        this.setState({pagenr: this.state.pagenr + 1})
        this.CuratedPhotos(this.state.pagenr)
    }else {
        if(this.state.query === '') return;
        console.log(this.state.query);
        this.setState({pagenr: this.state.pagenr + 1});
        console.log(this.state.query, 'query');
        console.log(this.state.pagenr, 'pagenr');
        this.searchPhotos(this.state.query, this.state.pagenr);
    }
}



componentDidMount() {
    this.CuratedPhotos(this.state.pagenr)
}



  render() {
    const { classes, setIsModalActive, uploadImage } = this.props

    return (
        <div>
            <div className={classes.modal}>
                <div className={classes.modalContent}>
                    <button className={classes.close} onClick={() => setIsModalActive()}>&times;</button>
                    <div style={{minWidth: '100vh', overflowX: 'hidden'}}>
                        <div className={classes.search}>
                            <input className={classes.input} value={this.state.query} onChange={this.handleChange} type="text"/>
                            <div className={classes.searchButton} onClick={() => this.searchButton(this.state.query,this.state.pagenr)}>Search</div>
                        </div>
                        <div style={{position: 'relative'}} className={classes.gallery}>
                        {this.state.images.photos && 
                            this.state.images.photos.map(photo => {
                                return <ImageFromApi uploadImage={uploadImage} setIsModalActive={setIsModalActive} photo={photo.src.large} photographer={photo.photographer} />
                            })
                        }
                        </div>
                        <div onClick={() => this.nextImages()} className={classes.next}>Next Page</div>
                    </div>
                </div>
            </div>
        </div>
        
    );
  }
}

export default withStyles(classes)(ModalImage);

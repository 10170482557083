




























































































































































































import { Vue } from "vue-property-decorator";
import { Config, Figure, TreePack } from "packaging3d-engine/lib";

import ObjectRender from "./ObjectRender.vue";
// import DownloadButton from "./DownloadButton.vue";
import SaveButton from "./SaveButton.vue";
import ShopifySendBtn from "./ShopifySendBtn.vue";
// import SaveAsBtn from "./SaveAsBtn.vue";
import ViewerSettings from "./ViewerSettings.vue";
import MapperSettings from "./MapperSettings.vue";
import ObjectSettings from "./ObjectSettings.vue";
import UndoRedoBtns from "./UndoRedoBtns.vue";
// import CommentsBtn from "./CommentsBtn.vue";
import ScalingSettings from "./ScalingSettings.vue";
import Loader from "./Loader.vue";
import Notifier from "./Notifier.vue";
import { AxesHelper, Group, Vector3, WebGLRenderer, Vector2 } from "three";
import { cloneDeep } from "lodash";
import axios from "axios";
import { FigureBase } from "packaging3d-engine/lib/core/base/figureBase";
import { AvailableHoleOptions } from "packaging3d-engine/lib/figures/utils/availableHoleOptions";
import { Representation } from "packaging3d-engine/lib/core/base/representation";
import {
  AddObject,
  Command,
  ConfigObject,
  RemoveObject,
  RotateObject,
  ScaleTemplate,
} from "packaging3d-engine/lib/command";
import { TreeNode } from "packaging3d-engine/lib/core/unpack/treePack";
import { ChildNode } from "packaging3d-engine";
import { TreeNodeUtils } from "packaging3d-engine/lib/core/unpack/treeNode";
import { ColoringData, PatternData, TextData, BackgroundTexture } from "@/types";
import { NotificationType, Notification } from "@/types/notification";
// import PatternSettings from "@/components/PatternSettings.vue";
import HoleModal from "./HoleModal.vue";
import { v4 } from "uuid";
// import EditBtn from "./EditBtn.vue";
import PdfEditor from "./PdfEditor.vue";
import { generateRandomColor } from 'packaging3d-engine';

const THREE = require("three");

let vm = Vue.component("ObjectMapper", {
  data: function() {
    const helperReal = new AxesHelper(200);
    helperReal.name = "helper";
    const helperImg = new AxesHelper(200);
    helperImg.name = "helper";
    const data = {
      rendererReal: new WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,
      }),
      rendererImage: new WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,
      }),
      localGroupReal: new Group(),
      localGroupImg: new Group(),
      helperReal,
      helperImg,
      notification: null as Notification | null,
      boxOptions: {
        width: 0,
        heigth: 0,
        topHeigth: 0,
        depth: 0,
        isProportional: false,
      },
      selectedObject: null as Representation | null,
      pattern: 0,
      scale: new Vector3(1, 1, 1),
      selectedSide: null as { elem: Representation; side: number } | null,
      selectedFigure: Figure.Rectangle,
      typeReal: 1,
      typeImaginary: 0,
      repReal: [] as Representation[],
      repImag: [] as Representation[],
      coloringData: new Map<number, ColoringData>(),
      wereUpdated: false,
      copyData: null as { elem: Representation; side: number } | null,
      showBorders: true,
      showGrid: true,
      showHelper: true,
      backgroundImage: '',
      opacity: 0.7,
      previousSettings: {
        showBorders: true,
        showGrid: true,
        opacity: 0.7,
        showHelper: true,
      },
      isLoaded: false,
      isFigureChanged: false,
      nodes: [] as TreeNode[],
      lastAddedObjectId: -1,
      selectedMeterage: "mm",
      isHoleModal: false,
      backgroundTexture: [] as BackgroundTexture[],
      patternDataTracker: 0,
      block1W: "50%",
      size2d: new Vector2(window.innerWidth / 2, window.innerHeight - 55),
      size3d: new Vector2(window.innerWidth / 2, window.innerHeight - 55),
      isShowEditor: false,
      timeoutIds: [] as number[],
      conservation: false,
      lockedModel: false,
      renderTracker: 0,
      patternData: new Map<number, PatternData>(),
      selectedTextureId: null as null | number,
      fabricRenderCount: 0,
      removedObjectId2d: null as null | number,
      removedObjectId3d: null as null | number,
      coloringDataTracker: 0,
      fabricTextObjects: [] as TextData[],
      imagePathShopify: "",
    };
    return {
      ...data,
      data: { redo: {}, undo: {} },
    };
  },
  computed: {
    isTexture: function(): Boolean {
      return Boolean(this.pattern || this.backgroundImage);
    },
    isBackgroundTexture: function(): boolean {
      return Boolean(this.backgroundTexture?.length);
    },
    templateName: function() {
      return this.$route?.params?.templateName;
    },
    availableHoles: function() {
      return new AvailableHoleOptions();
    },
    templateId: function() {
      return this.$route?.params?.templateId;
    },
    checkFrame: function() {
      let isFramed = false;
      try {
        isFramed =
          window != window.top ||
          document != top.document ||
          self.location != top.location;
      } catch (e) {
        isFramed = true;
      }
      return isFramed;
    },
  },
  beforeDestroy: function() {
    document.removeEventListener("keydown", this.keyListener);
    this.repReal.length = 0;
    this.repImag.length = 0;
    this.copyData = null;
    this.selectedObject = null;
    this.$store.commit("setNameTemplate", null);
  },
  mounted: async function() {
    if (
      !!this.$route.params.templateId &&
      parseInt(this.$route.params.templateId)
    ) {
      await this.getTemplate();
    } else {
      if (!!this.$route.params.templateId) {
        let result = Object.entries(Figure).filter(
          (pair) => pair[0] === this.$route.params.templateId
        );
        if (!!result.length) {
          let rootNode = {
            type: result[0][1],
            id: FigureBase.generateId(),
            config: null,
            childs: [] as ChildNode[],
          } as TreeNode;
          this.nodes.push(rootNode);
        }
      }
    }
    this.$store.commit("setNameTemplate", this.templateName);
    await this.initColoringData();
    this.isLoaded = true;
    document.addEventListener("keydown", this.keyListener);
  },
  watch: {
    isLoaded: function(newVal, oldVal) {
      if (!newVal) {
        this.localGroupReal = new Group();
        this.localGroupImg = new Group();
      }
    },
    nodes: {
      handler: function(val, newVal) {
        this.recalculateRepresentations();
      },
    },
    isFigureChanged(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!!this.timeoutIds.length) {
          const lastTimeOutId = this.timeoutIds.pop();
          if (!!lastTimeOutId) {
            clearInterval(lastTimeOutId);
          }
        }
        const timeoutId = setTimeout(async () => {
          const index = this.timeoutIds.findIndex((val: number) => {
            return timeoutId === val;
          });
          if (index !== -1) {
            this.timeoutIds = [...this.timeoutIds.splice(index, 1)];
          }
        }, 0);
        this.timeoutIds.push(timeoutId);
      }
    },
    scale: {
      handler: function(newVal, oldVal) {},
      deep: true,
    },
  },
  methods: {
    addText: async function() {
      if (this.selectedObject) {
        const result = await axios.put(
          `${process.env.VUE_APP_API_URL}/api/v1/template/shopify/updateTextData/${this.$route.params.templateId}`,
          {
            selectedObjectId: this.selectedObject.id,
          },
          { withCredentials: true }
        );

        this.fabricTextObjects = JSON.parse(result.data);
        this.coloringDataTracker += 5;
      }
    },
    deleteBackgroundTexture: async function() {
      try {
        await axios.put(
          `${process.env.VUE_APP_API_URL}/api/v1/template/deleteBackgroundTexture/${this.$route.params.templateId}`,{},
          { withCredentials: true }
        );
        this.backgroundImage = '';
        this.renderTracker += 1;
      } catch(e) {
        console.log(e);
      }
    },
    updateTextureOfEditor: async function(pdf) {
      let data = new FormData();

      data.append("pdf", pdf)

      try {
        this.backgroundTexture = [];
        this.hideEditor();
        this.conservation = true; 
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/template/updateTextureOfEditor/${this.$route.params.templateId}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      
        this.backgroundImage = response.data;
        this.conservation = false;
        this.renderTracker += 1;
      } catch(e) {
        console.log(e);
      }
    },
    changeTextureData: function(objectParams) {
      const objectType = objectParams.get("type");

      if (this.selectedObject) {
        switch (objectType) {
          case "image":
            const selectedColoringData = this.coloringData.get(
              this.selectedObject?.id
            );
            const selectedImage = selectedColoringData?.images.find(
              (image) => image.id == objectParams.customKey
            ) as any;

            if (selectedImage) {
              selectedImage.x = objectParams.left;
              selectedImage.y = objectParams.top;
              selectedImage.scaleX = objectParams.scaleX;
              selectedImage.scaleY = objectParams.scaleY;
              selectedImage.angle = objectParams.angle;
              this.coloringDataTracker += 1;
            }
            break;
          case "textbox":
            const selectedTextObject = this.fabricTextObjects.find(
              (textObject) => textObject.id == this.selectedObject?.id
            );
            const selectedText = selectedTextObject?.texts.find(
              (text) => text.id == objectParams.customKey
            );

            if (selectedText) {
              selectedText.x = objectParams.left;
              selectedText.y = objectParams.top;
              selectedText.width = objectParams.width;
              selectedText.height = objectParams.height;
              selectedText.angle = objectParams.angle;
              selectedText.text = objectParams.text;
              this.coloringDataTracker += 1;
            }
            break;
        }
      }
    },
    hideModal: function() {
      this.isHoleModal = false;
    },
    selectTexture: function(textureId) {
      this.selectedTextureId = textureId;
    },
    openEditor: function() {
      this.isShowEditor = true;
    },
    hideEditor: function() {
      this.isShowEditor = false;
    },
    drag: function(e: MouseEvent) {
      let dragX = e.clientX;
      let block = this.$refs.mapperContainer as any;
      let that = this;
      document.onmousemove = function onMouseMove(e) {
        block.style.width = block.offsetWidth + e.clientX - dragX + "px";
        dragX = e.clientX;
        that.size2d = new Vector2(
          block.offsetWidth + e.clientX - dragX,
          window.innerHeight - 55
        );
        that.size3d = new Vector2(
          window.innerWidth - that.size2d.x,
          window.innerHeight - 55
        );
      };
      document.onmouseup = () =>
        (document.onmousemove = document.onmouseup = null);
    },
    save: async function(makeScreen: boolean = true, pdf?: string) {
      this.conservation = true;

      setTimeout(async () => {
        try {
          let response;
          const strMime = "image/jpeg";
          const imgData = this.rendererReal.domElement.toDataURL(strMime);
          let data = new FormData();
          const coloringDataToArray = Array.from(
            this.coloringData,
            ([key, value]) => ({ key, value })
          );
          const settings = {
            showHelper: this.showHelper,
            showGrid: this.showGrid,
            showBorders: this.showBorders,
            opacity: this.opacity,
          };

          if (pdf) {
            data.append("file", pdf);
          }
          data.append("imgData", imgData);
          data.append("settings", JSON.stringify(settings));
          data.append("nodes", JSON.stringify(this.nodes));
          data.append("name", this.$route.params.templateName);
          data.append("collectionId", this.$route.params.collectionId);
          data.append("scaling", JSON.stringify(this.scale.toArray()));
          data.append("meterage", this.selectedMeterage);
          data.append("coloringData", JSON.stringify(coloringDataToArray));
          if (parseInt(this.$route.params.templateId)) {
            response = await axios.put(
              `${process.env.VUE_APP_API_URL}/api/v1/template/${this.$route.params.templateId}`,
              data,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
              }
            );

            if (pdf) await this.initColoringData();
          } else {
            response = await axios.post(
              `${process.env.VUE_APP_API_URL}/api/v1/template`,
              data,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
              }
            );
            this.$router.replace({
              path: `/collection/template/${this.$route.params.collectionId}/${response.data.templateId}/${this.$route.params.templateName}`,
            });
          }
          this.conservation = false;
        } catch (error) {
          console.log(error);
        }
      }, 0);
      this.hideEditor();
    },
    initColoringData: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/template/pattern/${this.templateId}`,
          { withCredentials: true }
        );
        const newColoringData = response.data.coloringData
          ? JSON.parse(response.data.coloringData)
          : [];
        const newPatternData = response.data.patternData
          ? JSON.parse(response.data.patternData)
          : [];
        const newTextData = response.data.textData
          ? JSON.parse(response.data.textData)
          : [];

        this.fabricTextObjects = newTextData;

        newColoringData.forEach((item) => {
          this.coloringData.set(item.key, item.value);
        });

        this.patternData.clear();

        newPatternData.forEach((item) => {
          this.patternData.set(item.key, item.value);
        });
      } catch (e) {
        console.log(e);
      }
    },
    recalculateRepresentations: function() {
      let tstart = Date.now();
      let representations = TreePack.unpack(this.nodes, this.scale);
      this.repReal.length = 0;
      this.repImag.length = 0;
      this.repReal.push(...representations.representations3d);
      this.repImag.push(...representations.representations2d);
      this.nodes.forEach((node) => {
        let id = node.id;
        if (!this.coloringData.has(id)) {
          this.coloringData.set(id, {
            id: v4(),
            color: generateRandomColor(),
            images: [],
          });
        }
      });

      if (this.lastAddedObjectId > 0) {
        this.objectSelect(this.lastAddedObjectId);
        this.lastAddedObjectId = -1;
      }
    },
    changeMeterage: function(newVal: string): void {
      this.selectedMeterage = newVal;
    },
    showHoles: function() {
      this.isHoleModal = true;
    },
    rotate: function(element: Representation) {
      let parent = this.nodes.find((node) => {
        let child = node.childs?.find(
          (childNode) => childNode.childId === element.id
        );
        return !!child;
      });

      if (!!parent) {
        let redoCommand = new RotateObject(
          this.nodes,
          this.repReal,
          parent.id,
          element.id,
          false
        );
        let undoCommand = new RotateObject(
          this.nodes,
          this.repReal,
          parent.id,
          element.id,
          true
        );
        redoCommand.execute();
        this.data = {
          redo: redoCommand,
          undo: undoCommand,
        };
        this.isFigureChanged = !this.isFigureChanged;
      }
    },
    addHole: function(hole: any) {
      const selelctedNode = this.nodes.find(
        (node) => node.id === this.selectedObject?.id
      );

      if (selelctedNode && this.selectedObject) {
        this.availableHoles.addHole(
          selelctedNode,
          this.selectedObject,
          hole.name
        );
        this.updateState();

        this.recalculateRepresentations();
      }
    },
    deleteHole: function(id: string) {
      const selelctedNode = this.nodes.find(
        (node) => node.id === this.selectedObject?.id
      );

      if (selelctedNode && selelctedNode.config) {
        this.availableHoles.deleteHole(selelctedNode.config, id);
        this.updateState();
        this.recalculateRepresentations();
      }
    },
    updateBoxOptions: async function(data: {
      width: number;
      height: number;
      depth: number;
    }) {
      this.isLoaded = false;
      const { width, height, depth } = data;
      await this.$nextTick(function() {
        setTimeout(() => {}, 0);
        setTimeout(() => {
          this.isLoaded = true;
          this.isFigureChanged = !this.isFigureChanged;
        }, 0);
      });
    },
    getTemplate: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/shopify/template/${this.$route.params.templateId}`,
          { withCredentials: true }
        );
        if (!!response.data.nodes) {
          this.backgroundImage = response.data.backgroundImage;
          this.scale.fromArray(JSON.parse(response.data.scaling));
          this.nodes = JSON.parse(response.data.nodes);
          this.lockedModel = response.data.locked;
          this.pattern = response.data.pattern;
        }
        this.selectedMeterage =
          response.data.meterage === "null" ? "mm" : response.data.meterage;
      } catch (error) {
        console.log(error);
      }
    },
    scaleChanged: async function(scale: Vector3) {
      let redoCommand = new ScaleTemplate(this.nodes, this.scale, scale);
      let undoCommand = new ScaleTemplate(this.nodes, this.scale, this.scale);
      redoCommand.execute();

      this.data = {
        redo: redoCommand,
        undo: undoCommand,
      };

      this.isFigureChanged = !this.isFigureChanged;
    },
    patternChanged: async function(pattern: number) {
      this.pattern = pattern;
      this.isLoaded = false;
      const result = await axios.put(
        `${process.env.VUE_APP_API_URL}/api/v1/template/generatePattern/${this.$route.params.templateId}`,
        {
          scaling: this.scale,
          pattern: this.pattern,
          data: JSON.stringify(this.nodes),
          meterage: this.selectedMeterage,
        },
        { withCredentials: true }
      );

      await this.initColoringData();

      this.isLoaded = true;
    },
    meshsWereCreated: function() {
      this.isLoaded = true;
    },
    returnSettings: function() {
      const {
        showBorders,
        showGrid,
        opacity,
        showHelper,
      } = this.previousSettings;
      this.showBorders = showBorders;
      this.showGrid = showGrid;
      this.opacity = this.opacity;
      this.showHelper = showHelper;
    },
    settingsChanged: function(
      showBorders: boolean,
      showGrid: boolean,
      opacity: number | null,
      showHelper: boolean
    ) {
      this.previousSettings = {
        showBorders: this.showBorders,
        showGrid: this.showGrid,
        showHelper: this.showHelper,
        opacity: this.opacity,
      };
      this.showBorders = showBorders;
      this.showGrid = showGrid;
      this.opacity = opacity ?? this.opacity;
      this.showHelper = showHelper;
    },
    keyListener: function(event: any) {
      if (event.target instanceof HTMLInputElement) return;
      if (event.keyCode === 46) {
        if (this.selectedObject) {
          this.objectRemove(this.selectedObject);
        }
      }
      if (event.code == "KeyC" && (event.ctrlKey || event.metaKey)) {
        if (this.selectedObject && !this.selectedSide) {
          let parent = this.nodes.find((node) => {
            let child = node.childs?.find(
              (childNode) => childNode.childId === this.selectedObject?.id
            );
            return !!child;
          });
          let childNode = parent?.childs?.find(
            (item) => item.childId === this.selectedObject?.id
          );
          if (!!childNode) {
            this.copyData = {
              elem: this.selectedObject,
              side: childNode?.childEdgeIndex ?? 0,
            };
          } else {
            this.notification = {
              type: NotificationType.Error,
              message: "Copying the root element is not allowed",
            };
          }
        }
      }
      if (event.code == "KeyV" && (event.ctrlKey || event.metaKey)) {
        if (this.selectedObject && this.selectedSide) {
          if (!!this.copyData) {
            let to = this.selectedSide.elem;
            if (!!to) {
              let startNode = this.nodes.find(
                (node) => node.id == this.copyData?.elem.id
              );
              if (!!startNode) {
                let clonedNodes = [] as TreeNode[];
                TreeNodeUtils.clone(this.nodes, startNode, clonedNodes);
                let redoCommand = new AddObject(
                  this.nodes,
                  to.id,
                  clonedNodes,
                  this.selectedSide.side,
                  this.copyData?.side
                );
                let undoCommand = new RemoveObject(
                  this.nodes,
                  to.id,
                  clonedNodes[0].id
                );
                redoCommand.execute();

                this.data = {
                  redo: redoCommand,
                  undo: undoCommand,
                };

                this.isFigureChanged = !this.isFigureChanged;
              }
            }
          }
        }
      }
    },
    sideSelect: function(id: number, side: number) {
      let obj = this.repReal.find((obj) => id === obj.id);
      if (obj && side >= 0) {
        this.selectedSide = {
          elem: obj,
          side,
        };
      }
    },
    clearTexture: async function() {
      if (this.selectedObject && this.selectedTextureId) {
        this.coloringData.set(this.selectedObject.id, {
          id: v4(),
          color: generateRandomColor(),
          images: [],
        });
        let selectedText = this.fabricTextObjects.find(
          (obj) => obj.id == this.selectedObject?.id
        );
        if (selectedText) selectedText.texts = [];

        await axios.put(
          `${process.env.VUE_APP_API_URL}/api/v1/template/updateTexture/${this.$route.params.templateId}`,
          {
            coloringData: {
              key: this.selectedTextureId,
              value: this.coloringData.get(this.selectedTextureId),
            },
            textData: selectedText,
          },
          { withCredentials: true }
        );
        this.recalculateRepresentations();
      }
    },
    setTexture: async function(e) {
      const image = e.target.files[0];

      if (image && this.selectedObject) {
        let data = new FormData();
        data.append("objectId", `${this.selectedObject.id}`);
        data.append("file", image);

        const result = await axios.put(
          `${process.env.VUE_APP_API_URL}/api/v1/template/shopify/updateColoringData/${this.$route.params.templateId}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );

        const newColoringData = result.data ? JSON.parse(result.data) : "";

        if (newColoringData) {
          newColoringData.forEach((item) => {
            this.coloringData.set(item.key, item.value);
          });
        }

        this.coloringDataTracker += 5;
      }
    },
    addTexture: function(id: number, side: number) {
      const input = document.createElement("input");
      input.type = "file";

      input.onchange = this.setTexture;

      input.click();
    },
    addCallback: function() {
      if (this.selectedSide) {
        this.objectAdd(
          this.selectedSide.elem.id,
          this.selectedSide.side,
          false
        );
      }
    },
    objectAdd: function(id: number, side: number, cache: boolean) {
      let parentRepresentation = this.repReal.find((obj) => id === obj.id);
      let parentNode = this.nodes.find((obj) => id === obj.id);
      if (!!parentRepresentation && !!parentNode) {
        let parentLine = parentRepresentation.shape[side].clone();
        let inverseScaling = new Vector3(
          1 / this.scale.x,
          1 / this.scale.y,
          1 / this.scale.z
        );
        parentLine.applyScaling(inverseScaling);
        let parentSize = parentLine.length();

        let childNode = {
          type: this.selectedFigure,
          id: FigureBase.generateId(),
          config: null,
          parentSize,
          childs: [] as ChildNode[],
        } as TreeNode;

        const redoCommand = new AddObject(
          this.nodes,
          parentNode.id,
          [childNode],
          side,
          0
        );
        let undoCommand = new RemoveObject(
          this.nodes,
          parentNode.id,
          childNode.id
        );

        redoCommand.execute();

        this.data = {
          redo: redoCommand,
          undo: undoCommand,
        };

        this.lastAddedObjectId = childNode.id;
        this.isFigureChanged = !this.isFigureChanged;
      }
    },
    objectSelect: function(id: number) {
      let obj = this.repReal.find((obj) => id == obj.id);
      this.selectedObject = null;
      this.selectedSide = null;

      if (obj) {
        this.selectedObject = obj;
      }
    },
    objectRemove: function(obj: Representation) {
      this.removedObjectId2d = obj.id;
      this.removedObjectId3d = obj.id;
      let parent = this.nodes.find((node) => {
        let child = node.childs?.find(
          (childNode) => childNode.childId === this.removedObjectId2d
        );
        return !!child;
      });

      if (parent) {
        let removedNode = this.nodes.find(
          (node) => node.id === this.removedObjectId2d
        );
        let removedChild = parent.childs?.find(
          (childNode) => childNode.childId === this.removedObjectId2d
        );
        if (!!removedNode && !!removedChild) {
          let clonedNodes = [] as TreeNode[];
          TreeNodeUtils.clone(this.nodes, removedNode, clonedNodes, false);
          let redoCommand = new RemoveObject(
            this.nodes,
            parent.id,
            this.removedObjectId2d
          );
          let undoCommand = new AddObject(
            this.nodes,
            parent.id,
            clonedNodes,
            removedChild.parentEdgeIndex,
            removedChild.childEdgeIndex
          );
          redoCommand.execute();
          this.data = {
            redo: redoCommand,
            undo: undoCommand,
          };

          this.isFigureChanged = !this.isFigureChanged;
        }
        this.objectSelect(parent.id);
      }
    },
    objectUpdate: function(config: Config) {
      if (this.selectedObject) {
        const oldConfig = cloneDeep(this.selectedObject!.config);
        const newConfig = cloneDeep(config);
        const redoCommand = new ConfigObject(
          this.nodes,
          newConfig,
          this.selectedObject.id
        );
        const undoCommand = new ConfigObject(
          this.nodes,
          oldConfig,
          this.selectedObject.id
        );

        try {
          redoCommand.execute();
          this.data = {
            redo: redoCommand,
            undo: undoCommand,
          };
          this.isFigureChanged = !this.isFigureChanged;
        } catch (e) {
          this.notification = this.notification = {
            type: NotificationType.Error,
            message: e.message,
          };
          undoCommand.execute();
          this.updateState();
        }
      }
    },
    figureUpdate: function(figure: Figure) {
      this.selectedFigure = figure;
    },
    updateState: function() {
      this.data = { undo: {}, redo: {} };
      this.selectedObject = null;
      this.selectedSide = null;
      this.isFigureChanged = !this.isFigureChanged;
    },
    undo: function(item: Command) {
      this.selectedObject = null;
      this.selectedSide = null;

      item.execute();
      this.isFigureChanged = !this.isFigureChanged;
    },
    redo: function(item: Command) {
      this.selectedObject = null;
      this.selectedSide = null;

      item.execute();
      this.isFigureChanged = !this.isFigureChanged;
    },
  },
  components: {
    ObjectRender,
    MapperSettings,
    ObjectSettings,
    ViewerSettings,
    // DownloadButton,
    SaveButton,
    UndoRedoBtns,
    Loader,
    // SaveAsBtn,
    ShopifySendBtn,
    // CommentsBtn,
    ScalingSettings,
    // PatternSettings,
    Notifier,
    HoleModal,
    // EditBtn,
    PdfEditor,
  },
});

export default vm;

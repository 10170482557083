/* eslint-disable import/prefer-default-export */
// 1px is 0.2645833333mm
const PIXEL_TO_MM = 0.2645833333;

const pxToMM = pixels => Math.round(pixels * PIXEL_TO_MM);

const pxToCm = pixels => Math.round(pixels * PIXEL_TO_MM) / 10;

const pxToM = pixels => Math.round(pixels * PIXEL_TO_MM) / 1000;

export const roundUnit = (pixels, unitType) => {
  let value = pxToMM(pixels);

  if (unitType === 'cm') {
    value = pxToCm(pixels);
  } else if (unitType === 'm') {
    value = pxToM(pixels);
  }

  return value;
};

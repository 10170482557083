var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowEditor),expression:"!isShowEditor"}],staticClass:"object-container"},[_c('div',{ref:"mapperContainer",staticClass:"object-mapper-container",style:({ width: _vm.block1W })},[(!_vm.lockedModel)?_c('UndoRedoBtns',{attrs:{"data":_vm.data},on:{"undo":_vm.undo,"redo":_vm.redo}}):_vm._e(),(_vm.selectedObject && !_vm.selectedSide && !_vm.lockedModel)?_c('ObjectSettings',{attrs:{"selectedObject":_vm.selectedObject,"meterage":_vm.selectedMeterage},on:{"clearTexture":_vm.clearTexture,"update":_vm.objectUpdate,"remove":_vm.objectRemove,"rotate":_vm.rotate,"addHole":_vm.showHoles,"addText":_vm.addText,"deleteHole":_vm.deleteHole}}):_vm._e(),(_vm.selectedObject && _vm.selectedSide && !_vm.lockedModel)?_c('MapperSettings',{attrs:{"selectedFigure":_vm.selectedFigure},on:{"update":_vm.figureUpdate,"add":_vm.addCallback}}):_vm._e(),(!_vm.isLoaded)?_c('div',{staticClass:"loader-wrapper"},[_c('Loader')],1):_c('ObjectRender',{ref:"render2d",attrs:{"localGroup":_vm.localGroupImg,"renderer":_vm.rendererImage,"fabricTextObjects":_vm.fabricTextObjects,"settings":{
          showHelper: false,
          showGrid: _vm.showGrid,
          showBorders: _vm.showBorders,
          size: _vm.size2d,
          type: _vm.typeImaginary,
          showControls: true,
          opacity: _vm.opacity,
        },"representations":_vm.repImag,"representations2d":_vm.repImag,"coloringData":_vm.coloringData,"isTexture":_vm.isTexture,"patternData":_vm.patternData,"wereUpdated":_vm.wereUpdated,"backgroundImage":_vm.backgroundImage,"renderTracker":_vm.renderTracker,"selectedObject":_vm.selectedObject,"selectedSide":_vm.selectedSide,"backgroundTexture":_vm.backgroundTexture,"scale":_vm.scale,"helper":_vm.helperImg,"lockedModel":_vm.lockedModel,"patternDataTracker":_vm.patternDataTracker,"removedObjectId":_vm.removedObjectId2d,"coloringDataTracker":_vm.coloringDataTracker},on:{"add":_vm.sideSelect,"select":_vm.objectSelect,"addTexture":_vm.addTexture,"selectTexture":_vm.selectTexture,"objectRemoved":function($event){_vm.removedObjectId2d = null},"changeTextureData":_vm.changeTextureData}})],1),_c('div',{staticClass:"slider",on:{"mousedown":_vm.drag}}),_c('div',{staticClass:"object-mapper-container flex-wrapper"},[(!_vm.isLoaded)?_c('div',{staticClass:"loader-wrapper"},[_c('Loader')],1):_c('ObjectRender',{ref:"render3d",attrs:{"localGroup":_vm.localGroupReal,"renderer":_vm.rendererReal,"representations":_vm.repReal,"representations2d":_vm.repImag,"wereUpdated":_vm.wereUpdated,"renderTracker":_vm.renderTracker,"isTexture":_vm.isTexture,"selectedObject":_vm.selectedObject,"backgroundTexture":_vm.backgroundTexture,"fabricTextObjects":_vm.fabricTextObjects,"coloringData":_vm.coloringData,"backgroundImage":_vm.backgroundImage,"patternData":_vm.patternData,"helper":_vm.helperReal,"scaling":_vm.scale,"settings":{
          showHelper: _vm.showHelper,
          showGrid: _vm.showGrid,
          showBorders: _vm.showBorders,
          size: _vm.size3d,
          type: _vm.typeReal,
          showControls: false,
          opacity: _vm.opacity,
        },"scale":_vm.scale,"lockedModel":_vm.lockedModel,"removedObjectId":_vm.removedObjectId3d,"coloringDataTracker":_vm.coloringDataTracker,"patternDataTracker":_vm.patternDataTracker},on:{"select":_vm.objectSelect,"update_done":_vm.meshsWereCreated,"objectRemoved":function($event){_vm.removedObjectId3d = null}}}),(!_vm.lockedModel)?_c('div',{staticStyle:{"position":"absolute","right":"10px","top":"10px","max-width":"280px"}},[_c('ScalingSettings',{attrs:{"scaling":_vm.scale},on:{"update_scale":_vm.scaleChanged}}),_c('ViewerSettings',{attrs:{"showAxes":_vm.showHelper,"showGrid":_vm.showGrid,"showBorders":_vm.showBorders,"opacity":_vm.opacity,"backgroundImage":_vm.backgroundImage},on:{"update":_vm.settingsChanged,"input":_vm.changeMeterage,"deleteBackgroundTexture":_vm.deleteBackgroundTexture},model:{value:(_vm.selectedMeterage),callback:function ($$v) {_vm.selectedMeterage=$$v},expression:"selectedMeterage"}}),_c('div',{staticClass:"actions-wrapper"},[_c('EditBtn',{staticClass:"button-wrapper",on:{"openEditor":_vm.openEditor}}),_c('SaveButton',{staticClass:"button-wrapper",attrs:{"conservation":_vm.conservation},on:{"update":_vm.save}}),(!_vm.checkFrame)?_c('ShopifySendBtn',{staticClass:"button-wrapper",attrs:{"scrinImage":_vm.imagePathShopify}}):_vm._e()],1)],1):_vm._e()],1),_c('Notifier',{attrs:{"notification":_vm.notification}}),(_vm.isHoleModal)?_c('HoleModal',{attrs:{"holes":_vm.availableHoles.list},on:{"closeModal":_vm.hideModal,"addHole":_vm.addHole}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowEditor),expression:"isShowEditor"}]},[_c('PdfEditor',{attrs:{"isShowEditor":_vm.isShowEditor,"nodes":_vm.nodes,"coloringData":_vm.coloringData,"pattern":_vm.pattern,"scale":_vm.scale,"meterage":_vm.selectedMeterage,"save":_vm.updateTextureOfEditor},on:{"hideEditor":_vm.hideEditor}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }





























































































































import Vue from "vue";
import AddCollection from "./AddCollection.vue";
import axios from "axios";
import SearchInput from "./SearchInput.vue";
import jwt_decode from "jwt-decode";

const vm = Vue.component("Collections", {
  data: function() {
    return {
      showModal: false,
      searchQuery: "",
      collections: [] as any,
      pagesCount: 0,
      pages: [1],
      currentPage: 1,
      diff: 0,
      translate: "translateX(0px)",
      foundTemplates: [],
      publicBtn: false,
      user: {} as any,
    };
  },
  watch: {
    searchQuery: "getCollectionsFromQuery",
  },
  methods: {
    changeDiff: function(newDiff: number) {
      console.log("here");
      this.diff = newDiff;
    },
    updateCollectionAtServer: async function(
      id: number,
      name: string,
      index: number
    ) {
      try {
        let response = await axios.put(
          `${process.env.VUE_APP_API_URL}/api/v1/collection/${id}`,
          {
            name,
          },
          { withCredentials: true }
        );
        (this.collections[index] as any).isChanged = false;
      } catch (error) {
        console.log(error);
      }
    },
    updateCollectionName: function(value: string, index: number) {
      (this.collections[index] as any).isChanged = true;
    },
    moveToTemplates: function(collectionId: number) {
      this.$router.push({
        name: "collection/templates",
        params: { collectionId: collectionId.toString() },
      });
    },
    changeModalStatus: function() {
      this.showModal = !this.showModal;
    },
    handlePageChange: async function(page: number) {
      this.currentPage = page;
      this.getCollections(page);
    },
    deleteCollection: async function(idForDel: number) {
      try {
        let response = await axios.delete(
          `${process.env.VUE_APP_API_URL}/api/v1/collection/${idForDel}`,
          { withCredentials: true }
        );
        this.getCollections(this.currentPage);
      } catch (error) {
        console.log(error);
      }
    },
    getCollections: async function(page: number) {
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/collections/${page}/`,
          { withCredentials: true }
        );

        this.collections = response.data.collections.map((collection: any) => {
          return {
            ...collection,
            isChanged: false,
          };
        });
        this.pagesCount = response.data.amountOfPages;
        this.pages = Array.from(Array(response.data.amountOfPages).keys());
        this.$store.commit("setAuthorized");
      } catch (error) {
        console.log(error);
        if (error.response?.status === 401) {
          this.$router.push({
            path: `auth/`,
          });
        }
      }
    },
    getTemplatesInCollections: async function(query: string) {
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/collections/1/${this.searchQuery}`,
          { withCredentials: true }
        );
        this.foundTemplates = response.data.templates;
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          this.$router.push({
            path: `auth/`,
          });
        }
      }
    },
    getCollectionsFromQuery: function() {
      this.getTemplatesInCollections(this.searchQuery);
    },
    showAddedItems: function() {
      this.getCollections(1);
      this.changeModalStatus();
    },
    changeIsPublic: async function(collectionId: any) {
      try {
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/collection/activate`,
          {
            id: collectionId,
          },
          { withCredentials: true }
        );
        if (this.collections) {
          const foundCollection = this.collections.find(
            (item: any) => item.id == collectionId
          );
          if (foundCollection) foundCollection.isPublic = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted: function() {
    this.getCollections(1);
  },
  created: function() {
    let results = document.cookie.match("(^|;) ?" + "token" + "=([^;]*)(;|$)");
    if (results) {
      let decodeToken: any = jwt_decode(results[2]);
      this.user.role = decodeToken.role;
    }
  },

  components: {
    AddCollection,
    SearchInput,
  },
});

export default vm;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Resize = void 0;
var command_1 = require("./command");
var Resize = /** @class */ (function (_super) {
    __extends(Resize, _super);
    function Resize(nodes) {
        var _this = _super.call(this, nodes) || this;
        //this._root = root;
        _this._width = 100;
        _this._depth = 100;
        _this._height = 100;
        _this._rotatedEdges = [];
        return _this;
    }
    Resize.prototype.action = function () {
    };
    return Resize;
}(command_1.Command));
exports.Resize = Resize;




















import { Vue } from "vue-property-decorator";

interface PatternSettings {
  patternNumber: number
}

let vm = Vue.component("PatternSettings", {
  props: {
    pattern: {
      type: Number
    }
  },
  watch: {
    pattern: function() {
      this.patternNumber = this.pattern;
    }
  },
  data: function () {
    return {
      patternNumber: this.pattern as number
    } as PatternSettings
  },
  methods: {
    updatePattern: function (): void {
      this.$emit("update_pattern", this.patternNumber);
    }
  },
})

export default vm;


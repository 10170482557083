"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
var three_1 = require("three");
var Utils = /** @class */ (function () {
    function Utils() {
    }
    // calculates rotation which brings given normal vector to (0,0,1);
    Utils.calculateRotationToOzAxis = function (normal) {
        var normalCopy = normal.clone();
        var xzPartLength = Math.pow((Math.pow(normalCopy.x, 2) + Math.pow(normalCopy.z, 2)), 0.5);
        var rotationOxz = new three_1.Matrix4().identity();
        if (xzPartLength > Utils.ZERO_TRESHOLD) {
            var _a = __read([-normalCopy.z / xzPartLength, normalCopy.x / xzPartLength], 2), cosOxz = _a[0], sinOxz = _a[1];
            rotationOxz.fromArray([
                cosOxz, 0, -sinOxz, 0,
                0, 1, 0, 0,
                sinOxz, 0, cosOxz, 0,
                0, 0, 0, 1
            ]);
            normalCopy.applyMatrix4(rotationOxz);
        }
        var yzPartLength = Math.pow((Math.pow(normalCopy.y, 2) + Math.pow(normalCopy.z, 2)), 0.5);
        var rotationOyz = new three_1.Matrix4().identity();
        if (yzPartLength > Utils.ZERO_TRESHOLD) {
            var _b = __read([normalCopy.z / yzPartLength, -normalCopy.y / yzPartLength], 2), cosOyz = _b[0], sinOyz = _b[1];
            rotationOyz.fromArray([
                1, 0, 0, 0,
                0, cosOyz, -sinOyz, 0,
                0, sinOyz, cosOyz, 0,
                0, 0, 0, 1
            ]);
            normalCopy.applyMatrix4(rotationOyz);
        }
        var forwardRotation = rotationOyz.clone().multiply(rotationOxz);
        return forwardRotation;
    };
    Utils.ZERO_TRESHOLD = 0.0001;
    return Utils;
}());
exports.Utils = Utils;

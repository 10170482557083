/* eslint-disable import/prefer-default-export,react/prop-types */
import { withStyles } from '@material-ui/core';
import React from 'react';
import Dropzone from 'react-dropzone';

import {materialTheme} from '../../materialTheme'

const { palette } = materialTheme

const classes = () => ({
  uploadWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F7FCFE',
  },
  uploadTitle: {
    fontFamily: palette.fontFamily,
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    color: palette.blue.main,
  },
});

class AddTemplate extends React.Component {
  
  render() {
    const { classes, handleChange } = this.props

    return (
      <Dropzone onDrop={acceptedFiles => handleChange(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div
            className={classes.uploadWrapper}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M27.9988 72.0004C23.008 72.0004 18.2215 70.104 14.6924 66.7283C11.1633 63.3527 9.18066 58.7743 9.18066 54.0004C9.18066 49.2265 11.1633 44.6481 14.6924 41.2725C18.2215 37.8968 23.008 36.0004 27.9988 36.0004V36.0004C29.1776 30.7491 32.6259 26.1343 37.5852 23.1713C40.0408 21.7041 42.7934 20.6866 45.6859 20.1769C48.5785 19.6671 51.5542 19.6751 54.4433 20.2004C57.3324 20.7257 60.0782 21.758 62.524 23.2383C64.9698 24.7187 67.0677 26.6181 68.6979 28.8281C70.3281 31.0382 71.4586 33.5155 72.025 36.1188C72.5914 38.7221 72.5825 41.4002 71.9988 44.0004H75.9988C79.7119 44.0004 83.2728 45.4754 85.8983 48.1009C88.5239 50.7264 89.9988 54.2874 89.9988 58.0004C89.9988 61.7134 88.5239 65.2744 85.8983 67.8999C83.2728 70.5254 79.7119 72.0004 75.9988 72.0004H71.9988" stroke="#BCDEF0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M36 60L48 48L60 60"
                stroke="#BCDEF0"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M48 48V84" stroke="#BCDEF0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p className={classes.uploadTitle}>
              Upload PDF
            </p>
          </div>
        )}
      </Dropzone>
    );
  }
}

export default withStyles(classes)(AddTemplate);














// import makeStyles from '@material-ui/core/styles/makeStyles';


// const useStyles = makeStyles(({ palette }) => ({
//   uploadWrapper: {
//     width: '100%',
//     height: '100%',
//     display: 'flex',
//     cursor: 'pointer',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     background: '#F7FCFE',
//   },
//   uploadTitle: {
//     fontFamily: palette.fontFamily,
//     fontSize: 24,
//     lineHeight: '32px',
//     fontWeight: 500,
//     color: palette.blue.main,
//   },
// }));

// const AddTemplate = ({ handleChange }) => {
//   const classes = useStyles();

//   return (
//     <Dropzone onDrop={acceptedFiles => handleChange(acceptedFiles)}>
//       {({ getRootProps, getInputProps }) => (
//         <div
//           className={classes.uploadWrapper}
//           {...getRootProps()}
//         >
//           <input {...getInputProps()} />
//           <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M27.9988 72.0004C23.008 72.0004 18.2215 70.104 14.6924 66.7283C11.1633 63.3527 9.18066 58.7743 9.18066 54.0004C9.18066 49.2265 11.1633 44.6481 14.6924 41.2725C18.2215 37.8968 23.008 36.0004 27.9988 36.0004V36.0004C29.1776 30.7491 32.6259 26.1343 37.5852 23.1713C40.0408 21.7041 42.7934 20.6866 45.6859 20.1769C48.5785 19.6671 51.5542 19.6751 54.4433 20.2004C57.3324 20.7257 60.0782 21.758 62.524 23.2383C64.9698 24.7187 67.0677 26.6181 68.6979 28.8281C70.3281 31.0382 71.4586 33.5155 72.025 36.1188C72.5914 38.7221 72.5825 41.4002 71.9988 44.0004H75.9988C79.7119 44.0004 83.2728 45.4754 85.8983 48.1009C88.5239 50.7264 89.9988 54.2874 89.9988 58.0004C89.9988 61.7134 88.5239 65.2744 85.8983 67.8999C83.2728 70.5254 79.7119 72.0004 75.9988 72.0004H71.9988" stroke="#BCDEF0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
//             <path
//               d="M36 60L48 48L60 60"
//               stroke="#BCDEF0"
//               strokeWidth="3"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//             />
//             <path d="M48 48V84" stroke="#BCDEF0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
//           </svg>
//           <p className={classes.uploadTitle}>
//             Upload PDF
//           </p>
//         </div>
//       )}
//     </Dropzone>
//   );
// };

// export default AddTemplate;

import { render, staticRenderFns } from "./CommentsBtn.vue?vue&type=template&id=02aee0e7&scoped=true&"
import script from "./CommentsBtn.vue?vue&type=script&lang=ts&"
export * from "./CommentsBtn.vue?vue&type=script&lang=ts&"
import style0 from "./CommentsBtn.vue?vue&type=style&index=0&id=02aee0e7&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02aee0e7",
  null
  
)

export default component.exports
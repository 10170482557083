








import Vue from "vue";
import {Notification} from "@/types/notification";

let vm = Vue.component("Notifier", {
  props: ["notification"],
  data: function () {
    return {
      changeTrigger: 1,
      lastAddedKey: 1,
      notificationQueue: new Map<number, Notification>()
    };
  },
  computed: {
    visibleNotifications(): Map<number, Notification>{
      let tmp = this.changeTrigger;
      return this.notificationQueue;
    }
  },
  methods: {
    addNotification: function(notification: Notification): void {
      this.lastAddedKey++;
      let key = this.lastAddedKey;
      this.notificationQueue.set(key, notification);
      setTimeout(this.removeNotification, 10000, key);
      this.changeTrigger *= -1;
    },
    removeNotification: function (key: number): void {
      if(this.notificationQueue.has(key)) {
        this.notificationQueue.delete(key);
      }
      this.changeTrigger *= -1;
    }
  },
  watch: {
    notification: function(newVal: Notification, oldVal: Notification) {
      if(newVal.type !== undefined && !!newVal.message) {
        this.addNotification(newVal);
      }
    }
  }
})
export default vm;


"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeNodeUtils = void 0;
var treePack_1 = require("./treePack");
var lodash_1 = require("lodash");
var TreeNodeUtils = /** @class */ (function () {
    function TreeNodeUtils() {
    }
    TreeNodeUtils.addChild = function (existingNodes, source) {
        existingNodes.push(source);
    };
    TreeNodeUtils.removeChild = function (existingNodes, source) {
        var _this = this;
        if (source == undefined)
            return;
        var deletedIndex = existingNodes.findIndex(function (node) { return node.id === source.id; });
        if (deletedIndex > -1) {
            existingNodes.splice(deletedIndex, 1);
        }
        if (source.childs != undefined && source.childs.length > 0) {
            source.childs.map(function (e) { return existingNodes.find(function (node) { return node.id === e.childId; }); })
                .forEach(function (removedNode) { return _this.removeChild(existingNodes, removedNode); });
        }
    };
    TreeNodeUtils.clone = function (existingNodes, startNode, clonedNodes, renewId) {
        if (renewId === void 0) { renewId = true; }
        var clonedNode = lodash_1.cloneDeep(startNode);
        if (renewId) {
            delete clonedNode.id;
        }
        var figure = treePack_1.TreePack.initialize(clonedNode);
        clonedNode.id = figure.id;
        clonedNodes.push(clonedNode);
        if (clonedNode.childs !== undefined && clonedNode.childs.length > 0) {
            clonedNode.childs.forEach(function (childNode) {
                var child = existingNodes.find(function (node) { return node.id === childNode.childId; });
                if (!!child) {
                    var newChildId = TreeNodeUtils.clone(existingNodes, child, clonedNodes);
                    childNode.childId = newChildId;
                }
            });
        }
        return clonedNode.id;
    };
    return TreeNodeUtils;
}());
exports.TreeNodeUtils = TreeNodeUtils;

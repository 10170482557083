import Vue from "vue";
import VueRouter from "vue-router";
import TemplatesCollection from "../components/TemplatesCollection.vue";
import TemplatesCollectionPublic from "../components/TemplatesCollectionPublic.vue";
import Authorization from "../components/Authorization.vue";
import Collections from "../components/Collections.vue";
import TemplateRender from "../components/TemplateRender.vue";
import Object3dModel from "../components/Object3dModel.vue";
import CollectionsPublic from "../components/CollectionsPublic.vue";
import TemplateRenderPublic from "../components/TemplateRenderPublic.vue";
import PublicTemplateRenderNew from "../components/PublicTemplateRenderNew.vue";
import IframeModel from "../components/IframeModel.vue";
import OrdersFromShopify from "../components/OrdersFromShopify.vue";
import IframeTest from "../components/IframeTest.vue";
import Shopify from "../Shopify/Shopify.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Collections,
  },
  {
    path: "/auth",
    name: "auth",
    component: Authorization,
  },
  {
    path: "/shopify/auth/:shop",
    name: "shopifyAuth",
    component: Authorization,
  },
  {
    path: "/shopify",
    name: "shopifyHome",
    component: Shopify,
  },
  {
    path: "/collections",
    name: "collections",
    component: Collections,
  },
  {
    path: "/collection/templates/:collectionId",
    name: "collection/templates",
    component: TemplatesCollection,
  },
  {
    path: "/collections_public/templates/:collectionId",
    name: "collections_public/templates",
    component: TemplatesCollectionPublic,
  },
  {
    path: "/collection/template/:collectionId/:templateId/3dModel",
    name: "collection/template",
    component: Object3dModel,
  },
  {
    path: "/collection/template/:collectionId/:templateId/:templateName",
    name: "collection/template",
    component: TemplateRender,
  },
  {
    path: "/collection_public/template/:collectionId/:templateId/:templateName",
    name: "collection/template",
    component: TemplateRenderPublic,
  },
  {
    path: "/collection/public/template/:collectionId/:templateId/3dModel",
    name: "publicTemplate",
    component: Object3dModel,
  },
  {
    path: "/upload",
    name: "upload",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/MappingUpload.vue"),
  },
  {
    path: "/new_renderer",
    name: "new renderer",
    component: TemplateRender,
  },
  {
    path: "/collections_public",
    name: "collections public",
    component: CollectionsPublic,
  },
  {
    path: "/templatetest/:templateId",
    name: "templatetest/template",
    component: PublicTemplateRenderNew,
  },
  {
    path: "/iframeModel/:templateId/:customization",
    name: "iFrame",
    component: IframeModel,
  },
  {
    path: "/orders",
    name: "orders",
    component: OrdersFromShopify,
  },
];

const router = new VueRouter({
  routes,
});

export default router;












import Vue from "vue";
import axios from "axios";

const vm = Vue.component("orders", {
  data: function() {
    return {
      orders: [] as any
    };
  },
  watch: {
  },
  methods: {
    getOrders: async function() {
      console.log('d')
       const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/orders`,
          { withCredentials: true }
        )
      this.orders = response.data.orders;
      console.log(this.orders)
    }
  },
  mounted: function() {
  },
  created: function() {

  },
  components: {
  },
});

export default vm;

import settingButton from './SettingsButtonStyles';
import tooltipStyle from '../../../../styles/Tooltip';
import { selectStyle } from '../../../../styles/Select';

const settingSelect = palette => ({
  ...tooltipStyle(palette),
  tooltip: {
    ...tooltipStyle(palette).tooltip,
    top: -6,
  },
  root: {
    width: '100%',
    height: 14
  },
  select: {
    ...settingButton(palette).settingButton
  },
  ...selectStyle(palette),
  selectFocus: {
    ...settingButton(palette).settingButtonFocus
  },
  selectMenu: {
    color: palette.primary.main,
    fontFamily: palette.fontFamily,
    fontSize: 16,
    fontWeight: 500,
    boxSizing: 'border-box',
    background: 'transparent',
    padding: 12,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      width: '100%',
      background: 'transparent'
    }
  },
  placeholder: {
    display: 'none'
  },
  selectMenuWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingInline: 12,
    overflowX: 'hidden',
  },
  defaultPopover: {
    ...selectStyle(palette).popover,
  },
  popover: {
    ...selectStyle(palette).popover,
    '&::after': {
      content: '\' \'',
    },
  },
});

export default settingSelect;

"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Trapeze = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var Trapeze = /** @class */ (function (_super) {
    __extends(Trapeze, _super);
    function Trapeze() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Trapeze.prototype.getConfigProperties = function () {
        return {
            widthBottom: 1,
            widthTop: 0.7,
            height: 1,
        };
    };
    Trapeze.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 2, 3];
    };
    Trapeze.prototype.calculateLines = function () {
        var coords = this.config.options;
        var _a = __read([
            coords.widthBottom,
            coords.height,
            coords.widthTop,
        ], 3), widthBottom = _a[0], height = _a[1], widthTop = _a[2];
        var aPoint = new three_1.Vector3(widthBottom / 2, 0);
        var bPoint = new three_1.Vector3(-widthBottom / 2, 0);
        var cPoint = new three_1.Vector3(-widthTop / 2, height);
        var dPoint = new three_1.Vector3(widthTop / 2, height);
        return [
            new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, aPoint])
        ];
    };
    return Trapeze;
}(figureBase_1.FigureBase));
exports.Trapeze = Trapeze;













import Vue from "vue";
import { History } from "packaging3d-engine/lib/history";
import _ from "lodash";

let vm = Vue.component("UndoRedoBtns", {
  data: function() {
    return {
      history: new History()
    };
  },
  mounted: function() {
    document.addEventListener("keydown", this.keyListener);
  },
  beforeDestroy: function() {
    document.removeEventListener("keydown", this.keyListener);
    delete this.history;
  },
  props: ["data"],
  methods: {
    undo: function() {
      this.$emit("undo", this.history.undo());
    },
    redo: function() {
      this.$emit("redo", this.history.redo());
    },
    keyListener: function(event: any) {
      if (event.code == "KeyZ" && (event.ctrlKey || event.metaKey)) {
        if (this.history.canUndo) {
          this.undo();
        }
      }
      if (event.code == "KeyY" && (event.ctrlKey || event.metaKey)) {
        if (this.history.canRedo) {
          this.redo();
        }
      }
    }
  },
  watch: {
    data: function(newVal, oldVal) {
      if (
        Object.keys(newVal.redo).length > 0 &&
        Object.keys(newVal.undo).length > 0
      ) {
        this.history.addHistoryItem(newVal, []);
      }
    }
  }
});

export default vm;























import Vue from "vue";
import axios from "axios";

const vm = Vue.component("AddCollection", {
  data: function() {
    return {
      name: "",
      error: ""
    };
  },
  methods: {
    createCollection: async function() {
      this.$gtag.event('click_create_collection');
      try {
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/collection`,
          {
            name: this.name
          },
          {
            withCredentials: true
          }
        );
        this.$emit("close");
      } catch (error) {
        this.error = error.response.data;
      }
    }
  }
});

export default vm;

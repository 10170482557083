"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rounded = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var arcFitter_1 = require("../../utils/arcFitter");
var Rounded = /** @class */ (function (_super) {
    __extends(Rounded, _super);
    function Rounded() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Rounded.prototype.calculateConnectionIndexes = function () {
        return [0, 1];
    };
    Rounded.prototype.calculateLines = function () {
        var height = this.config.options.height;
        var angle = this.config.options.angle * Math.PI / 180;
        var aPoint = new three_1.Vector3(height / 2, 0);
        var bPoint = new three_1.Vector3(-height / 2, 0);
        var cPoint = new three_1.Vector3(-height / 2 + Math.cos(angle) * height, Math.sin(angle) * height);
        var additionalPoints = arcFitter_1.ArcFitter.fitArcCircle(aPoint, cPoint, height);
        return [new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPoints[1].reverse()),
            new line3_1.Line3(line3_1.LineType.BazierCurve, additionalPoints[0].reverse())
        ];
    };
    Rounded.prototype.isValid = function (nullable) {
        if (!_super.prototype.isValid.call(this, nullable))
            return false;
        var angle = this.config.options.angle;
        return angle <= 180;
    };
    Rounded.prototype.getConfigProperties = function () {
        return {
            height: 1,
            angle: 0.9
        };
    };
    return Rounded;
}(figureBase_1.FigureBase));
exports.Rounded = Rounded;

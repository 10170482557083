"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Options = void 0;
var Options = /** @class */ (function () {
    function Options(o, parentSize) {
        var _this = this;
        var size = parentSize !== null && parentSize !== void 0 ? parentSize : 100;
        if (o) {
            Object.keys(o).forEach(function (key) {
                _this[key] = size * o[key];
            });
        }
    }
    return Options;
}());
exports.Options = Options;

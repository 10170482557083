"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CuttedTowerTopConnection = void 0;
var figureBase_1 = require("../../../core/base/figureBase");
var line3_1 = require("../../../core/base/line3");
var three_1 = require("three");
var CuttedTowerTopConnection = /** @class */ (function (_super) {
    __extends(CuttedTowerTopConnection, _super);
    function CuttedTowerTopConnection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CuttedTowerTopConnection.prototype.calculateConnectionIndexes = function () {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    };
    CuttedTowerTopConnection.prototype.calculateLines = function () {
        var width = this.config.options.width;
        var height = this.config.options.height;
        var innerWidth = this.config.options.innerWidth;
        var innerBotHeight = this.config.options.innerBotHeight;
        var innerTopHeight = this.config.options.innerTopHeight;
        var towerWidth = this.config.options.towerWidth;
        var aPoint = new three_1.Vector3(width / 2, 0, 0);
        var bPoint = new three_1.Vector3(-width / 2, 0, 0);
        var cPoint = new three_1.Vector3(-width / 2, height, 0);
        var dPoint = new three_1.Vector3(-width / 2 + towerWidth, height, 0);
        var ePoint = new three_1.Vector3(-innerWidth / 2, innerTopHeight + innerBotHeight, 0);
        var fPoint = new three_1.Vector3(-innerWidth / 2, innerBotHeight, 0);
        var gPoint = new three_1.Vector3(innerWidth / 2, innerBotHeight, 0);
        var hPoint = new three_1.Vector3(innerWidth / 2, innerBotHeight + innerTopHeight, 0);
        var iPoint = new three_1.Vector3(width / 2 - towerWidth, height, 0);
        var jPoint = new three_1.Vector3(width / 2, height, 0);
        return [new line3_1.Line3(line3_1.LineType.Straight, [aPoint, bPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [bPoint, cPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [cPoint, dPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [dPoint, ePoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [ePoint, fPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [fPoint, gPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [gPoint, hPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [hPoint, iPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [iPoint, jPoint]),
            new line3_1.Line3(line3_1.LineType.Straight, [jPoint, aPoint])
        ];
    };
    CuttedTowerTopConnection.prototype.isValid = function () {
        if (!_super.prototype.isValid.call(this))
            return false;
        var width = this.config.options.width;
        var height = this.config.options.height;
        var innerWidth = this.config.options.innerWidth;
        var innerBotHeight = this.config.options.innerBotHeight;
        var innerTopHeight = this.config.options.innerTopHeight;
        var towerWidth = this.config.options.towerWidth;
        if (width - towerWidth * 2 - innerWidth <= 0)
            return false;
        return height - innerBotHeight - innerTopHeight > 0;
    };
    CuttedTowerTopConnection.prototype.getConfigProperties = function () {
        return {
            width: 1,
            height: 1,
            innerWidth: 0.5,
            innerBotHeight: 0.5,
            innerTopHeight: 0.4,
            towerWidth: 0.15
        };
    };
    return CuttedTowerTopConnection;
}(figureBase_1.FigureBase));
exports.CuttedTowerTopConnection = CuttedTowerTopConnection;

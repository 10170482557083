<template>
  <div class="snackbar">
    <div class="snackbar__content">
      {{ text }}
    </div>
    <div class="snackbar__close" @click="close">
      &times;
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      closeTimer: null,
    };
  },
  props: {
    text: { type: String },
    timeout: { type: Number, default: 5000 },
  },
  mounted() {
    this.closeTimer = setTimeout(this.close, this.timeout);
  },
  methods: {
    close() {
      clearTimeout(this.closeTimer);
      this.$emit("close");
    },
  },
};
</script>
<style lang="css" scoped>
.snackbar {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #f8c4c4;
}

.snackbar__close {
  font-size: 26px;
  padding-left: 10px;
  cursor: pointer;
}
</style>

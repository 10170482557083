/* eslint-disable react/require-default-props,react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { fabric } from 'fabric';

class Image extends React.Component {
  // static propTypes = {
  //   canvas: PropTypes.object,
  //   scale: PropTypes.number,
  //   path: PropTypes.string,
  //   id: PropTypes.number,
  // };

  // static defaultProps = {
  //   path: ''
  // };

  componentDidMount() {
    const {
      canvas, scale, path, id
    } = this.props;

    fabric.Image.fromURL(path, image => {
      image.id = id;
      image.scaleX = scale;
      image.scaleY = scale;
   
      canvas.add(image);
      if (image.width * image.scaleX > image.canvas.width) {
        image.scaleToWidth(image.canvas.width - 10, false);
      }
      if (image.height * image.scaleY > image.canvas.height) {
        image.scaleToHeight(image.canvas.height - 10, false);
      }
    });
  }

  render() {
    return null;
  }
}

export default Image;







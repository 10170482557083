"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var command_1 = require("./command");
Object.defineProperty(exports, "Command", { enumerable: true, get: function () { return command_1.Command; } });
var addObject_1 = require("./addObject");
Object.defineProperty(exports, "AddObject", { enumerable: true, get: function () { return addObject_1.AddObject; } });
var configObject_1 = require("./configObject");
Object.defineProperty(exports, "ConfigObject", { enumerable: true, get: function () { return configObject_1.ConfigObject; } });
var removeObject_1 = require("./removeObject");
Object.defineProperty(exports, "RemoveObject", { enumerable: true, get: function () { return removeObject_1.RemoveObject; } });
var resize_1 = require("./resize");
Object.defineProperty(exports, "Resize", { enumerable: true, get: function () { return resize_1.Resize; } });
var rotateObject_1 = require("./rotateObject");
Object.defineProperty(exports, "RotateObject", { enumerable: true, get: function () { return rotateObject_1.RotateObject; } });
var scaleTemplate_1 = require("./scaleTemplate");
Object.defineProperty(exports, "ScaleTemplate", { enumerable: true, get: function () { return scaleTemplate_1.ScaleTemplate; } });
// export { AddHole } from "./addHole";
















import { Vue } from "vue-property-decorator";
import { Figure } from "packaging3d-engine/lib";
import { PropOptions } from "vue";
import ShapeSelection from "./ShapeSelection.vue";
import {Representation} from "packaging3d-engine/lib/core/base/representation";

let vm = Vue.component("MapperSettings", {
  props: {
    selectedFigure: {
      type: String
    } as PropOptions<Figure>
  },
  data: function() {
    const options = Object.entries(Figure)
      .map((val: [string, Figure]) => val[1]);
    return {
      figureState: this.selectedFigure,
      options,
      isModalOpen: false
    };
  },
  methods: {
    figureSelected: function(figure: Figure) {
      this.$emit("update", figure);
      this.$emit("add");
      this.isModalOpen = !this.isModalOpen;
    },
    update: function() {
      this.$emit("update", this.figureState);
    },
    add: function() {
      this.$emit("add");
    },
    changeModalState: function() {
      this.isModalOpen = !this.isModalOpen;
    }
  },
  components: {
    ShapeSelection
  }
});

export default vm;































import Vue from "vue";
import axios from "axios";
import { Collection } from "@/types/collection";

const vm = Vue.component("CollectionSelector", {
  data: function() {
    return {
      collections: [] as Collection[],
      selectedCollection: null as Collection | null,
    };
  },
  mounted: function() {
    this.getAllCollections();
  },
  watch: {
    selectedCollection: function(newVal, oldVal) {
      console.log(newVal);
    },
  },
  methods: {
    getAllCollections: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/v1/allcollections/`,
          {
            withCredentials: true,
          }
        );
        console.log(response);
        this.collections = response.data.collections;
      } catch (error) {
        console.log(error);
      }
    },
    saveAs: async function() {
      try {
        console.log(this.selectedCollection);
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/v1/template/saveAs`,
          {
            templateId: this.$route.params.templateId,
            collectionId: this.selectedCollection!.id,
          },
          {
            withCredentials: true,
          }
        );
        this.$router.push({
          name: "collection/templates",
          params: { collectionId: this.selectedCollection!.id.toString() },
        });
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
});

export default vm;
